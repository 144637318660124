<!--==================Fellowship ===================-->
<div class="hero_section">
    <div class="section_left">
        <h5 class="main-title">
            Take Back Control of your Healthcare through the Power of the Fellowship HealthShare Community
        </h5>
        <h6 class="help-text">
            Healthcare sharing is a modern, intuitive, non-insurance approach for managing healthcare expenses.
        </h6>
    </div>
    <div class="section_right">
        <img alt="Fellowship" height="auto" src="assets/images/BannerImg.webp" width="100%">
    </div>
</div>

<div class="section_one">
    <div class="container">
        <div class="row">

            <div class="col-md-7 md-order-2">

                <h2 class="primary-title">How it works</h2>
                <p class="description">Whether you are an individual, a family, or an employer, we have
                    membership programs that will serve you well in your quest for quality healthcare.
                    Fellowship
                    HealthShare is a monthly Membership Community made up of people just like you, who are
                    committed
                    to living a healthy life! Members submit monthly Contributions that are used to share
                    medical
                    expenses if and when they arise.
                </p>


                <a class="readmore_link" href="https://hopehealthshare.com/about/" target="_blank">
                    Learn More <i class="fa fa-angle-right ml-2"></i>
                </a>

            </div>

            <div class="col-md-5 text-center md-order-1">

                <div class="">
                    <img alt="Image Description" class="img-fluid" height="auto" src="assets/images/howitworks.webp"
                         width="100%">
                </div>

            </div>
        </div>
    </div>
</div>

<div class="section-devider">
    <div class="container">
        <div class="row md-text-center">
            <div class="col-md-8">
                <h3 class="secondary-title">
                    Join the Fellowship Community Today and Take Back Control of your Healthcare!
                </h3>
            </div>
            <div class="col-md-4 text-center">
                <button class="btn btn-primary" routerLink="/DirectMemberEnrollment" type="submit">
                    Become A Member
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Our Features  -->

<div class="whyus-section">
    <div class="container">
        <div class="row">

            <div class="col-md-4">
                <div class="service-card">
                    <div class="service-icon">
                        <img alt="fellowship service icon" class="img-icon" src="assets/images/icons/chat-withus.svg">
                    </div>
                    <h5 class="service-title">
                        Chat With Us
                    </h5>
                    <p class="service-description">
                        This is where we sit down, grab a cup of coffee and dial in the details.
                    </p>

                    <a class="service-link" href="https://hopehealthshare.com/contact/" target="_blank">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="service-card">
                    <div class="service-icon">
                        <img alt="fellowship service icon" class="img-icon" src="assets/images/icons/services.svg">
                    </div>
                    <h5 class="service-title">
                        Fellowship Programs
                    </h5>
                    <p class="service-description">
                        Now that you know a little bit more about us, take a look at the different programs that you can
                        choose
                        from!
                    </p>

                    <a class="service-link" href="https://hopehealthshare.com/faq/" target="_blank">
                        <i class="bi bi-arrow-right"></i>
                    </a>

                </div>
            </div>

            <div class="col-md-4">
                <div class="service-card">
                    <div class="service-icon">
                        <img alt="fellowship service icon" class="img-icon" src="assets/images/icons/support.svg">
                    </div>
                    <h5 class="service-title">
                        Support
                    </h5>
                    <p class="service-description">
                        We’re here to support you whether you’re a current, previous, or prospective member!
                        <!-- Our customer service
                        line is available from 8:00am–6:00pm CST Monday through Friday but if you’d like, you can write us an email
                        or utilize our chat feature and someone will get back to you as soon as possible! -->
                    </p>

                    <a class="service-link" href="https://hopehealthshare.com/faq/" target="_blank">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="section-devider">
    <div class="container">
        <div class="row">

            <div class="col-md-8 service-content md-order-2">
                <h3 class="secondary-title">
                    How it Works
                </h3>
                <p class="service-description">
                    Fellowship HealthShare is a non-profit healthcare sharing organization. We guide our Medical Cost
                    Sharing
                    Community to share what they have to bless those in need. Our members give and receive financial
                    support for
                    medical needs. We believe that healthcare in God’s image is open to everyone, easy to enroll in, and
                    stress
                    free.
                </p>
                <a class="mb-3 r-m" href="https://hopehealthshare.com/faq/" target="_blank"> Our
                    Programs <i class="fa fa-angle-right align-middle ml-1"></i></a>
            </div>

            <div class="col-md-4 md-order-1 text-center">
                <img alt="fellowship services" class="services-img" height="auto" src="assets/images/whatwedo.png"
                     width="80%">
            </div>
        </div>
    </div>
</div>
<div class="section-devider bg-white">
    <div class="container">

        <div class="row">
            <div class="col-md-4 md-order-1 text-center">
                <img alt="fellowship services" class="services-img" height="auto" src="assets/images/whatwedo1.png"
                     width="80%">
            </div>
            <div class="col-md-8 service-content md-order-2">
                <h3 class="secondary-title">
                    What We Do
                </h3>
                <p class="service-description">
                    Our Community’s Common Ethos: As a Fellowship HealthShare member, you’ll feel confident that you’re
                    sharing
                    healthcare costs with a community that shares in your beliefs and ethics: Adult Members of
                    Fellowship
                    Healthshare profess the following Statement of Ethics to qualify for membership.
                </p>
                <a class="mb-3 r-m" href="https://hopehealthshare.com/about/" target="_blank">
                    Read our Statement of Ethics <i class="fa fa-angle-right align-middle ml-1"></i></a>
            </div>
        </div>

    </div>
</div>
<div class="section-devider ">
    <div class="container">

        <div class="row">
            <div class="col-md-8 service-content md-order-2">
                <h3 class="secondary-title">
                    What We Offer
                </h3>
                <p class="service-description">
                    As a nonprofit organization that has been sharing medical needs for decades, we are committed to
                    providing
                    best in class service to our members through charitable donations and medical expense sharing. The
                    services we
                    offer are not insurance but we believe that the members are better served through the financial
                    stewardship
                    that comes with a Health Sharing Community membership.
                </p>
                <a class="mb-3 r-m" href="https://hopehealthshare.com/faq/" target="_blank">
                    Learn more <i class="fa fa-angle-right align-middle ml-1"></i></a>
            </div>
            <div class="col-md-4 md-order-1 text-center">
                <img alt="fellowship services" class="services-img" height="auto" src="assets/images/whatweoffer.png"
                     width="80%">
            </div>

        </div>

    </div>
</div>
