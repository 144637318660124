<div class="row table-search d-flex align-items-center">
    <div class="col-md-9">
        <div class="searchinput">
            <input (ngModelChange)="searchTextValueEmit()" [(ngModel)]="searchText" class="form-control form-control-sm ip-search"
                   name="x" placeholder="Search term..." type="text">
            <i class="ri-search-line"></i>
        </div>
    </div>

    <div class="col-md-3 text-end px-3">
        <div class="dropdown">
            <button (click)="toggleDropdown()" class="btn btn-col" id="dropdownButton">Columns
                <!-- <img src="../assets/images/Column_filter_icon.svg" >  -->
            </button>
            <div [ngClass]="{ 'show': dropdownOpen }" aria-labelledby="dropdownButton" class="dropdown-menu">
                <form>
                    <ng-container *ngFor="let col of availableColumns">
                        <div *ngIf="col.title!='' && col.id!='index'" class="form-check d-flex justify-content-between">
                            <label (click)="toggleCheckbox($event)" class="form-check-label" for="{{ col.title }}">
                                {{ col.title }}
                            </label>
                            <div (click)="toggleCheckbox($event)" class="form-check-right">
                                <input (change)="onCheckboxChange($event.target.checked, col)" [checked]="col.displayed" class="form-check-input"
                                       id="{{ col.title }}" type="checkbox">
                            </div>
                        </div>
                    </ng-container>
                </form>

            </div>
        </div>
        <div (click)="closeDropdown()" *ngIf="dropdownOpen" class="dropdown-backdrop"></div>


    </div>
</div>
