<div class="container m-top demograpy">
    <div *ngIf="navigatedFrom !='summary'" class="applicationProgress">
        <div *ngIf="typedisabled" class="appProgStep " id="app2" style="z-index:6; padding-left: 30px;"> <span>Demographics
            &amp; Dependents</span>
        </div>
        <div class="appProgStep active" id="app1" style="z-index:5; padding-left: 40px;"><span>Member Agreement</span>
        </div>
        <div *ngIf="typedisabled" class="appProgStep " id="app3" style="z-index:3;"><span>Health-Based Questions</span>
        </div>
        <div *ngIf="typedisabled" class="appProgStep" id="app4" style="z-index:2;"><span>Payment Details</span></div>
        <div class="appProgStep appProgStep-last" id="app5" style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>
    <div class="container mt-4 mb-4 membership_info">
        <form [formGroup]="Form">
            <div *ngIf="data.length>0">
                <div class="header">
                    {{ data[0]?.agreement.name }}

                </div>
                <div class="">
                    {{ data[0].agreement.desc }}
                </div>
                <div class="">I, <input (keypress)="onFocusoutname()" class="main-textbox" formControlName="fullname"
                                        id="fullnameinput"
                                        type="text">
                    {{ data[0].agreement.instructions }}
                </div>


                <div *ngFor="let data of data[0].agreementcontent" class="sub_headers_bold">
                    <div>
                        {{ data.heading }}
                        <div *ngFor="let item of data.planDetailList" class="sub_headers_bold">
                            {{ item.agreementname }}

                            <div style="font-weight: 400;">
                                {{ item.text }}
                            </div>

                            <div *ngFor="let datalist of item.details">
                                <ng-container>
                                    <ul>
                                        <li style="font-weight: 400;">
                                            {{ datalist.name }}

                                        </li>

                                    </ul>
                                </ng-container>
                            </div>
                            <div *ngFor="let desc of item.desc" style="font-weight: 400;">
                                <input [formControlName]="desc.controlname" class="form-control" id="fullnameinput"
                                       type="text"> {{ desc.name }}
                            </div>
                        </div>
                    </div>


                </div>

            </div>

            <div [ngClass]="{'d-none': navigatedFrom=='summary'}" class="form-group">
                <h6 class="title-hr">
                    <span>Signature<span class="text-danger">*</span></span>
                </h6>


                <ul class="nav nav-tabs" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button (click)="onTabClick('esignature')" aria-controls="home-tab-pane" aria-selected="true"
                                class="nav-link active"
                                data-bs-toggle="tab" data-bs-target="#home-tab-pane"  id="home-tab" role="tab"
                                type="button">
                            Draw
                        </button>
                       
                    </li>
                    <li class="nav-item" role="presentation">
                        <button (click)="onTabClick('image')" aria-controls="image-tab-pane" aria-selected="false"
                                class="nav-link"  data-bs-toggle="tab"
                                data-bs-target="#image-tab-pane" id="image-tab" role="tab"
                                type="button">
                            Upload
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div aria-labelledby="home-tab" class="tab-pane fade show active" id="home-tab-pane" role="tabpanel"
                         tabindex="0">
                        <div class="row">
                            <div class="col-12">
                                <div class="signature-container">
                                    <canvas #canvas (click)="signatureField('signatureValid')"
                                            class="sigPad"

                                            id="signpad"></canvas>
                                </div>

                            </div>
                            <div class="col-lg-7">
                                {{ est_date }}
                            </div>
                            <div class="col-lg-5">
                                <div class="clearMainBtn">
                                    <button (click)="clear()" class="btn btn-outline-danger" type="button">Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div aria-labelledby="image-tab" class="tab-pane fade uploadimage" id="image-tab-pane"
                         role="tabpanel" tabindex="0" >
                         <div class="form-group">
                        <input (change)="upload($event)" accept="image/png, image/jpeg,image/jpg, image/gif" c
                               class="form-control uploadImage" formControlName="uploadImage" id="uploadFIle"
                               multiple="multiple" name="uploadFile" type="file">
                        <div class="mt-2 text_change">
                            File sizes should not exceed 20MB; Formats Accepted (jpeg,jpg,png,gif).
                        </div>
                        <div class="mt-2 ">
                            {{ est_date }}
                        </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="message">
                    <small class="errormsg">
                        {{ message }}
                    </small>
                </div>
                <div *ngIf="uploadedFile " class="mt-2">
                    <img *ngIf="imageUrl" [src]="imageUrl" class="displayImage">
                </div>
            </div>
            <div *ngIf="navigatedFrom !='summary'" class="row mb-4">
                <div class="col-md-6">
                    <div class="signatureblock mb-3">
                        <label> Name:</label>
                        <input class="bordernonetextbox" disabled formControlName="fullname" type="text">
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="signatureblock">
                        <label> Date:</label>
                        <input class="input" class="bordernonetextbox" disabled placeholder="date"
                               value="{{est_date | date:'MM/dd/yyyy'}}"/>
                    </div>
                </div>
            </div>
            <div *ngIf="navigatedFrom !='summary'" class="row ">
                <div class="col-6 ">
                    <button (click)="backto()" *ngIf="typedisabled" class="btn btn-primary"
                            type="button">Previous
                    </button>
                </div>
                <div class="col-6" style="text-align: right;">
                    <button (click)="submit(Form.value)" class="btn btn-success" type="button">Next</button>
                </div>
            </div>

        </form>
    </div>

</div>


<button class="btn btn-primary" data-target="#limitExceeds" data-toggle="modal" hidden id="modalLimitExceeds"
        type="button">
</button>
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" id="limitExceeds" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">File Limit Exceeded</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">×</button>
            </div>
            <div class="modal-body">
                One of the files you have chosen exceeds the limit of 20MB
            </div>


        </div>
    </div>
</div>
