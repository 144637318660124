import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TestService} from '../../../services/behaviourService';
import {SharingService} from '../../../services/sharing.service';

@Component({
    selector: 'app-applicationcart',
    templateUrl: './applicationcart.component.html',
    styleUrls: ['./applicationcart.component.scss']
})
export class ApplicationcartComponent implements OnInit {
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    bmiinfo: any;
    additionalMember: any;
    orderDetails: any;
    oneTimeFee: any;
    cartProducts: any;
    groupInfo: any;
    monthlyContribution: any;
    monthlyContributionStartDate: any;
    addOnProducts = [];
    supplementalProducts = [];
    totalAddOnsPrice: number = 0;
    @Output() valueChange = new EventEmitter();
    company_id: any;
    cart_data: any;
    member_id: any;
    family_id: any;
    cart_id: void;
    plan_data: any
    monthly_contribution_day: any;
    application_id: any;
    cart_group_discount: any;
    nicotineData: any[] = [];
    total_price: any;

    constructor(
        private objectService: TestService,
        private services: SharingService,
    ) {
        this.updateCart();
    }


    ngOnInit(): void {
        this.groupInfo = this.services.getAgentGroupInfo();//G2C flow
        let nicotin = localStorage.getItem('nicotineselected');

        //this.updateCart();
    }


    updateCart() {
        this.cart_group_discount = [];

        this.objectService.appcartData.subscribe((data) => {
            if (data != '') {
                this.nicotineData = [];
                this.cartFee = data.cart_fee;
                this.cartFee.forEach(element => {
                    if (element.fee_name == "Nicotine Fee") {
                        this.nicotineData.push(element);
                    }
                });
                this.cartData = data.cart_data;
                this.cartProducts = data.cart_products;
                this.monthlyContribution = data.monthly_contribution;
                this.monthlyContributionStartDate = data.member_applications_data[0].monthly_contribution_start_date;
                this.monthly_contribution_day = data.member_applications_data[0].monthly_contribution_day
                this.oneTimeFee = data.total_one_time_fee;
                this.cart_group_discount = data.cart_group_discount;
                this.addOnProducts = data.cart_addon_products;
                this.application_id = data.member_applications_data[0].id
                this.supplementalProducts = data.cart_supplement_products;
                this.total_price = this.cartData[0].total_price;
                // this.totalAddOnsPrice = ((this.cartProducts.reduce((subtotal, item) => subtotal + item.total_price, 0)) +
                //   this.addOnProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0) +
                //   this.supplementalProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0));

                // if(this.nicotineData.length>0)
                // {
                //   this.totalAddOnsPrice=this.totalAddOnsPrice +this.nicotineData[0].fee;
                //   this.monthlyContribution= this.monthlyContribution +this.nicotineData[0].fee;
                //   this.oneTimeFee= this.oneTimeFee - this.nicotineData[0].fee;
                // }
            } else {
                this.plan_data = sessionStorage.getItem("cartplan");
                this.plan_data = JSON.parse(this.plan_data);
                if (this.plan_data != '' && this.plan_data != null) {
                    this.cartFee = this.plan_data.cart_fee;
                    this.cartData = this.plan_data.cart_data;
                    this.cartProducts = this.plan_data.cart_products;
                    this.cart_group_discount = this.plan_data.cart_group_discount
                    this.monthlyContribution = this.plan_data.monthly_contribution;
                    this.monthlyContributionStartDate = this.plan_data.member_applications_data[0].monthly_contribution_start_date;
                    this.monthly_contribution_day = this.plan_data.member_applications_data[0].monthly_contribution_day
                    this.application_id = this.plan_data.member_applications_data[0].id
                    this.oneTimeFee = this.plan_data.total_one_time_fee;
                    this.addOnProducts = this.plan_data.cart_addon_products;
                    this.supplementalProducts = this.plan_data.cart_supplement_products;
                    this.total_price = this.cartData[0].total_price;

                    // this.totalAddOnsPrice = ((this.cartProducts.reduce((subtotal, item) => subtotal + item.total_price, 0)) +
                    //   this.addOnProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0) +
                    //   this.supplementalProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0));
                }
            }
        })
    }

}
