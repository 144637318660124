<div class="container m-top demograpy">
    <div class="applicationProgress">
        <div class="appProgStep active"
             id="app2"
             style="z-index:6; padding-left: 30px;"><span>Demographics &amp; Dependents</span>
        </div>
        <div class="appProgStep "
             id="app1"
             style="z-index:5; padding-left: 40px;"><span>Member Agreement</span>
        </div>
        <div class="appProgStep "
             id="app3"
             style="z-index:3;">
            <span>Health-Based Questions</span>
        </div>
        <div class="appProgStep"
             id="app4"
             style="z-index:2;">
            <span>Payment Details</span>
        </div>
        <div class="appProgStep appProgStep-last"
             id="app5"
             style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <div class="row demomargin">
            <div class="col-md-4 padding_demo">
                <div class="card">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <p class="cart-card-data">Effective Start Date</p>
                        </div>
                        <div class="col-4 pl-0">
                            <p class="cart-card-value text-right"> {{ startdate | date:'MM/dd/yyyy' }}</p>
                        </div>
                    </div>
                </div>
                <div class="card  ">
                    <div class="row">
                        <div class="col-md-6 col-md-6 col-xs-5">
                            <p class="cart-card-title">Application Cart</p>
                        </div>
                        <div class="col-md-6 col-md-6 col-xs-6"
                             style=" text-align: right;">
                            <div (click)="backtoD2C()"
                                 class="returnPlane">
                                <span style="color: white;">Return to Programs</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <p class="cart-card-data">Your Application Number</p>
                        </div>

                        <div class="col-md-4">
                            <p class="cart-card-value"> {{ application_id }}</p>
                        </div>
                    </div>
                </div>


                <app-applicationcart></app-applicationcart>

            </div>
            <div class="col-md-8 padding_demo1">
                <form *ngIf="auxConfigform"
                      [formGroup]="auxConfigform">
                    <div class="card">
                        <!-- ============******Primary Form*****=============== -->
                        <div *ngFor="let primary of demgPrimaryArray;let i =index"
                             formArrayName="primaryArray">
                            <div [formGroupName]="i">
                                <div *ngIf="disableGFields"
                                     class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    Primary
                                </div>
                                <div *ngIf="!disableGFields"
                                     class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    Guardian
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">First Name <span class="fieldMan">*</span></label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="firstname"
                                               maxlength="25"
                                               placeholder="First Name"
                                               type="text">
                                        <div *ngIf="!primary.controls.firstname.valid && (primary.controls.firstname.touched || submitAttempt)"
                                             class="errormsg">
                                            First name is required
                                        </div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">Middle Name</label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="middlename"
                                               maxlength="25"
                                               placeholder="Middle Name"
                                               type="text">
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">Last Name <span class="fieldMan">*</span></label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="lastname"
                                               maxlength="25"
                                               placeholder="Last Name"
                                               type="text">
                                        <div *ngIf="!primary.controls.lastname.valid && (primary.controls.lastname.touched || submitAttempt)"
                                             class="errormsg">
                                            Last name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Phone Number <span class="fieldMan">*</span></label>
                                        <input (focusout)="onfocusoutphnumber('primary')"
                                               (keypress)="numberOnly($event)"
                                               (keyup)="onkeyupPhone($event)"
                                               appPhonenumberValidation
                                               class="form-control"
                                               disabled
                                               formControlName="phone"
                                               id="phnumber"
                                               maxlength="12"
                                               placeholder="Phone Number"
                                               type="text">
                                        <div *ngIf="!primary.controls.phone.valid && (primary.controls.phone.touched || submitAttempt)"
                                             class="errormsg">
                                            Phone Number is required
                                        </div>
                                        <div class="errormsg"
                                             id="phnumbererrmsg">
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Email<span class="fieldMan">*</span></label>
                                        <input class="form-control"
                                               disabled
                                               formControlName="email"
                                               placeholder="Email"
                                               type="text">
                                        <div *ngIf=" (primary.controls.email.dirty && primary.controls.email.touched ) && primary.controls.email.hasError('pattern')"
                                             class="errormsg">
                                            enter a valid Email Address
                                        </div>
                                        <div *ngIf="!primary.controls.email.valid && (primary.controls.email.touched || submitAttempt)"
                                             class="errormsg">
                                            Email Address is required
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Social Security </label>
                                        <input (focus)="onFocusPrimary($event)"
                                               (focusout)="onprimaryssnFocusout($event)"
                                               (keypress)="numberOnly($event)"
                                               (keyup)="onTypingSocialSecurity()"
                                               class="form-control"
                                               formControlName="ssn"
                                               id="social-security"
                                               maxlength="11"
                                               placeholder="Social Security"
                                               socialSecurity
                                               type="text">
                                        <div *ngIf="number!=''"
                                             class="errormsg">
                                            {{ number }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <!-- <div class="col-6" *ngIf="disableGFields">
                                            <div class="head_txt"> Height <span class="fieldMan">*</span></div>
                                            <input class="form-control" type="text" disabled placeholder="height"
                                                formControlName="height">
                                        </div>
                                        <div class="col-6" style="padding-left: 0;" *ngIf="disableGFields">
                                            <div class="head_txt">Weight <span class="fieldMan">*</span></div>
                                            <div class="input-group">
                                                <input class="form-control" type="text" disabled name="surname"
                                                    placeholder="" formControlName="weight"><span
                                                    class="input-group-addon" style="border: none;">lbs</span>
                                            </div>
                                        </div> -->
                                    <div *ngIf="disableGFields"
                                         class="col-4 form-group">
                                        <label class="head_txt">Gender <span class="fieldMan">*</span></label>
                                        <input class="form-control"
                                               disabled
                                               formControlName="gender"
                                               type="text"
                                               value="Male">
                                    </div>
                                    <div class="col-5 form-group pl-0">
                                        <label class="head_txt">Date Of Birth <span class="fieldMan">*</span></label>
                                        <p-calendar [disabled]="isDisabled1"
                                                    [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                    formControlName="dob"></p-calendar>
                                        <div *ngIf="!primary.controls.dob.valid && (primary.controls.dob.touched || submitAttempt)"
                                             class="errormsg">
                                            Date Of Birth is required
                                        </div>
                                    </div>
                                    <div class="col-3 form-group"
                                         style="padding-left: 0;">
                                        <label class="head_txt">Age </label>
                                        <input class="form-control"
                                               disabled
                                               formControlName="age"
                                               name="surname"
                                               placeholder=""
                                               type="text">
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 1 <span class="fieldMan">*</span></label>
                                        <input (blur)="InputChange('address1')"
                                               class="form-control"
                                               formControlName="address1"
                                               maxlength="60"
                                               placeholder="Address 1"
                                               type="text">
                                        <div *ngIf="!primary.controls.address1.valid && (primary.controls.address1.touched || submitAttempt)"
                                             class="errormsg">
                                            Address is required
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 2</label>
                                        <input (blur)="InputChange('address2')"
                                               class="form-control"
                                               formControlName="address2"
                                               maxlength="60"
                                               placeholder="Address 2"
                                               type="text">
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">City <span class="fieldMan">*</span></label>
                                        <input (blur)="InputChange('city')"
                                               appAlphabetOnly
                                               class="form-control"
                                               formControlName="city"
                                               placeholder="City"
                                               type="text">
                                        <div *ngIf="!primary.controls.city.valid && (primary.controls.city.touched || submitAttempt)"
                                             class="errormsg">
                                            City is required
                                        </div>
                                    </div>
                                    <div class="col-md-5 form-group">
                                        <label class="head_txt">State <span class="fieldMan">*</span></label>
                                        <select (blur)="InputChange('state')"
                                        class="form-control  form-select"
                                                disabled
                                                formControlName="state">
                                            <option [ngValue]="null"
                                                    disabled
                                                    selected
                                                    value="">Select Your State
                                            </option>
                                            <option *ngFor="let item of states"
                                                    [value]=item.name>{{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="!primary.controls.state.valid && (primary.controls.state.touched || submitAttempt)"
                                             class="errormsg">
                                            State is required
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Zip Code <span class="fieldMan">*</span></label>
                                        <input (blur)="InputChange('zip')"
                                               (keypress)="numberOnly($event)"
                                               class="form-control"
                                               formControlName="zip"
                                               maxlength="5"
                                               placeholder="Zip Code"
                                               type="text">
                                        <!-- [attr.disabled]="primary.controls.disabled.value?'':null" -->
                                        <div *ngIf="primary.controls.zip.errors?.required && (primary.controls.zip.touched || submitAttempt)"
                                             class="errormsg">
                                            Zip Code is required
                                        </div>
                                        <div *ngIf="primary.controls['zip'].hasError('pattern')"
                                             class="errormsg">Please enter 5 digit
                                            Zip Code
                                        </div>
                                        <!-- <div class="errormsg" *ngIf="(primary.controls.zip.touched || submitAttempt) && primary.controls.zip.errors?.maxlength ">Please enter valid zipcode</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ============******End Primary Form*****=============== -->
                        <!-- ============******Spouse Form*****=============== -->
                        <div *ngFor="let spouse of demgSpouseArray;let i =index"
                             formArrayName="SpouseArray">
                            <div [formGroupName]="i">
                                <div class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    <div class="row">
                                        <div class="col-md-6">
                                            Spouse
                                        </div>
                                        <div *ngIf="CheckBoxVisible"
                                             class="col-md-6"
                                             style="text-align: right;">
                                            <div><input (change)="checkBoXChange(i,'SpouseArray')"
                                                        formControlName="checkBox"
                                                        type="checkbox"> Address same as
                                                Primary
                                                Applicant
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">First Name <span class="fieldMan">*</span>
                                        </label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="firstname"
                                               maxlength="25"
                                               placeholder="First Name"
                                               type="text">
                                        <div *ngIf="!spouse.controls.firstname.valid && (spouse.controls.firstname.touched || submitAttempt)"
                                             class="errormsg">
                                            First name is required
                                        </div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">Middle Name</label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="middlename"
                                               maxlength="25"
                                               placeholder="Middle Name"
                                               type="text">
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">Last Name <span class="fieldMan">*</span>
                                        </label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="lastname"
                                               maxlength="25"
                                               placeholder="Last Name"
                                               type="text">
                                        <div *ngIf="!spouse.controls.lastname.valid && (spouse.controls.lastname.touched || submitAttempt)"
                                             class="errormsg">
                                            Last name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Phone Number </label>
                                        <input (focusout)="onfocusoutphnumber('spouse')"
                                               (keypress)="numberOnly($event)"
                                               appPhonenumberValidation
                                               class="form-control"
                                               formControlName="phone"
                                               id="spousephnumber"
                                               maxlength="12"
                                               placeholder="Phone Number"
                                               type="text">
                                        <div class="errormsg"
                                             id="spousephnumbererrmsg">
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group px-md-0">
                                        <label class="head_txt">Email <small>(Required for Member portal and
                                            Mobile App access)</small> </label>
                                        <input class="form-control"
                                               formControlName="email"
                                               placeholder="Email"
                                               type="text">
                                        <div *ngIf=" (spouse.controls.email.dirty && spouse.controls.email.touched ) && spouse.controls.email.hasError('pattern')"
                                             class="errormsg">
                                            Please enter a valid Email Address
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Social Security </label>
                                        <input (focus)="onFocusSpouse($event)"
                                               (focusout)="onspousessnFocusout($event)"
                                               (keypress)="numberOnly($event)"
                                               (keyup)="onTypingSocialSecurity()"
                                               class="form-control"
                                               formControlName="ssn"
                                               id="social-security-spouse"
                                               maxlength="11"
                                               placeholder="Social Security"
                                               socialSecurity
                                               type="text">
                                        <div *ngIf="numberSpouse!=''"
                                             class="errormsg">
                                            {{ numberSpouse }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <!-- <div class="col-md-3 col-xs-4">
                                                    <div class="head_txt"> Height <span class="fieldMan">*</span></div>
                                                    <input class="form-control" disabled (keypress)="numberOnly($event)"
                                                        type="text" placeholder="height" formControlName="height">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.height.valid && (spouse.controls.height.touched || submitAttempt)">
                                                        Height is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-xs-4" style="padding-left: 0;">
                                                    <div class="head_txt">Weight <span class="fieldMan">*</span>
                                                    </div>
                                                    <div class="input-group">
                                                        <input class="form-control" (keypress)="numberOnly($event)"
                                                            type="text" name="surname" disabled
                                                            formControlName="weight"><span class="input-group-addon"
                                                            style="border: none;">lbs</span>
                                                    </div>
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.weight.valid && (spouse.controls.weight.touched || submitAttempt)">
                                                        Weight is required
                                                    </div>
                                                </div> -->
                                    <div class="col-4 form-group">
                                        <label class="head_txt">Gender <span class="fieldMan">*</span>
                                        </label>
                                        <input class="form-control"
                                               disabled
                                               formControlName="gender"
                                               type="text"
                                               value="Male">
                                        <div *ngIf="!spouse.controls.gender.valid && (spouse.controls.gender.touched || submitAttempt)"
                                             class="errormsg">
                                            Gender is required
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-7 form-group">
                                                <label class="head_txt">Date Of Birth <span class="fieldMan">*</span>
                                                </label>
                                                <p-calendar [disabled]="isDisabled1"
                                                            [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                            formControlName="dob"></p-calendar>
                                                <div *ngIf="!spouse.controls.dob.valid && (spouse.controls.dob.touched || submitAttempt)"
                                                     class="errormsg">
                                                    Date Of Birth is required
                                                </div>
                                            </div>
                                            <div class="col-5 form-group"
                                                 style="padding-left: 0;">
                                                <label class="head_txt">Age </label>
                                                <input class="form-control"
                                                       disabled
                                                       formControlName="age"
                                                       name="surname"
                                                       type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 1 <span class="fieldMan">*</span>
                                        </label>
                                        <input [attr.disabled]="spouse.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="address1"
                                               maxlength="60"
                                               placeholder="Address 1"
                                               type="text">
                                        <div *ngIf="!spouse.controls.address1.valid && (spouse.controls.address1.touched || submitAttempt)"
                                             class="errormsg">
                                            Address is required
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 2</label>
                                        <input [attr.disabled]="spouse.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="address2"
                                               maxlength="60"
                                               placeholder="Address 2"
                                               type="text">
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">City <span class="fieldMan">*</span>
                                        </label>
                                        <input [attr.disabled]="spouse.controls.disabled.value?'':null"
                                               appAlphabetOnly
                                               class="form-control"
                                               formControlName="city"
                                               placeholder="City"
                                               type="text">
                                        <div *ngIf="!spouse.controls.city.valid && (spouse.controls.city.touched || submitAttempt)"
                                             class="errormsg">
                                            City is required
                                        </div>
                                    </div>
                                    <div class="col-md-5 form-group">
                                        <label class="head_txt">State <span class="fieldMan">*</span>
                                        </label>
                                        <select [attr.disabled]="spouse.controls.disabled.value?'':null"
                                        class="form-control  form-select"
                                                formControlName="state">
                                            <option [ngValue]="null"
                                                    disabled
                                                    selected
                                                    value="">Select Your
                                                State
                                            </option>
                                            <option *ngFor="let item of states"
                                                    value={{item.name}}>
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="!spouse.controls.state.valid && (spouse.controls.state.touched || submitAttempt)"
                                             class="errormsg">
                                            State is required
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Zip Code <span class="fieldMan">*</span></label>
                                        <input (keypress)="numberOnly($event)"
                                               [attr.disabled]="spouse.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="zip"
                                               maxlength="5"
                                               placeholder="Zip Code"
                                               type="text">
                                        <div *ngIf="spouse.controls.zip.errors?.required && (spouse.controls.zip.touched || submitAttempt)"
                                             class="errormsg">
                                            Zip Code is required
                                        </div>
                                        <div *ngIf="(spouse.controls.zip.touched || submitAttempt) && spouse.controls.zip.errors?.maxlength "
                                             class="errormsg">
                                            Please enter valid zipcode
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ============******Child Form*****=============== -->
                        <div *ngFor="let child of demgChildArray;let i =index"
                             formArrayName="childArray">
                            <div [formGroupName]="i">
                                <div class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    <div class="row">
                                        <div *ngIf="disableGFields"
                                             class="col-md-6">
                                            Child {{ i + 1 }}
                                        </div>
                                        <div *ngIf="!disableGFields"
                                             class="col-md-6">
                                            Dependent {{ i + 1 }}
                                        </div>
                                        <div *ngIf="CheckBoxVisible"
                                             class="col-md-6"
                                             style="text-align: right;">
                                            <div><input (change)="checkBoXChange(i,'childArray')"
                                                        formControlName="checkBox"
                                                        type="checkbox"> Address same
                                                as
                                                Primary
                                                Applicant
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group ">
                                        <label class="head_txt">First Name <span class="fieldMan">*</span>
                                        </label>
                                        <input class="form-control"
                                               formControlName="firstname"
                                               maxlength="25"
                                               placeholder="First Name"
                                               type="text">
                                        <div *ngIf="!child.controls.firstname.valid && (child.controls.firstname.touched || submitAttempt)"
                                             class="errormsg">
                                            First name is required
                                        </div>
                                    </div>
                                    <div class="col-md-4 form-group ">
                                        <label class="head_txt">Middle Name</label>
                                        <input class="form-control"
                                               formControlName="middlename"
                                               maxlength="25"
                                               placeholder="Middle Name"
                                               type="text">
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">Last Name <span class="fieldMan">*</span>
                                        </label>
                                        <input (keypress)="omit_special_char($event)"
                                               class="form-control"
                                               formControlName="lastname"
                                               maxlength="25"
                                               placeholder="Last Name"
                                               type="text">
                                        <div *ngIf="!child.controls.lastname.valid && (child.controls.lastname.touched || submitAttempt)"
                                             class="errormsg">
                                            Last name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Phone Number
                                        </label>
                                        <input (focusout)="onfocusoutphnumber('child',i, $event)"
                                               (keypress)="numberOnly($event)"
                                               appPhonenumberValidation
                                               class="form-control"
                                               formControlName="phone"
                                               id="{{'childphnumber' +i}}"
                                               maxlength="12"
                                               placeholder="Phone Number"
                                               type="text">
                                        <div class="errormsg"
                                             id="{{ 'childpherror' + i}}"></div>
                                    </div>
                                    <div class="col-md-6 form-group px-md-0">
                                        <label class="head_txt">Email <small>(Required for Member portal
                                            and Mobile App access)</small>
                                        </label>
                                        <input class="form-control"
                                               formControlName="email"
                                               placeholder="Email"
                                               type="text">
                                        <div *ngIf=" (child.controls.email.dirty && child.controls.email.touched ) && child.controls.email.hasError('pattern')"
                                             class="errormsg">
                                            Please enter a valid Email Address
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Social Security </label>
                                        <input (focus)="onFocusChild($event)"
                                               (focusout)="onchildssnFocusout($event)"
                                               (keypress)="numberOnly($event)"
                                               (keyup)="onTypingSocialSecuritychild(i, $event)"
                                               attr.id="social-security-child-{{i}}"
                                               class="form-control"
                                               formControlName="ssn"
                                               maxlength="11"
                                               placeholder="Social Security"
                                               socialSecurity
                                               type="text">
                                        <div class="errormsg"
                                             id="{{ 'error' + i}}"></div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <!-- <div class="col-md-3 col-xs-4">
                                                        <div class="head_txt"> Height <span class="fieldMan">*</span>
                                                        </div>
                                                        <input class="form-control" disabled
                                                            (keypress)="numberOnly($event)" type="text"
                                                            placeholder="height" formControlName="height">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.height.valid && (child.controls.height.touched || submitAttempt)">
                                                            Height is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 col-xs-4" style="padding-left: 0;">
                                                        <div class="head_txt">Weight <span class="fieldMan">*</span>
                                                        </div>
                                                        <div class="input-group">
                                                            <input class="form-control" disabled
                                                                (keypress)="numberOnly($event)" type="text"
                                                                name="surname" formControlName="weight"><span
                                                                class="input-group-addon"
                                                                style="border: none;">lbs</span>
                                                        </div>
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.weight.valid && (child.controls.weight.touched || submitAttempt)">
                                                            Weight is required
                                                        </div>
                                                    </div> -->
                                    <div class="col-4 form-group">
                                        <label class="head_txt">Gender <span class="fieldMan">*</span>
                                        </label>
                                        <input class="form-control"
                                               disabled
                                               formControlName="gender"
                                               type="text"
                                               value="Male">
                                        <div *ngIf="!child.controls.gender.valid && (child.controls.gender.touched || submitAttempt)"
                                             class="errormsg">
                                            Gender is required
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-7 form-group">
                                                <label class="head_txt">Date Of Birth <span class="fieldMan">*</span>
                                                </label>
                                                <p-calendar [disabled]="isDisabled1"
                                                            [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                            formControlName="dob"></p-calendar>
                                                <div *ngIf="!child.controls.dob.valid && (child.controls.dob.touched || submitAttempt)"
                                                     class="errormsg">
                                                    Date Of Birth is required
                                                </div>
                                            </div>
                                            <div class="col-5 form-group"
                                                 style="padding-left: 0;">
                                                <label class="head_txt">Age </label>
                                                <input class="form-control"
                                                       disabled
                                                       formControlName="age"
                                                       name="surname"
                                                       type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 1 <span class="fieldMan">*</span>
                                        </label>
                                        <input [attr.disabled]="child.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="address1"
                                               maxlength="60"
                                               placeholder="Address 1"
                                               type="text">
                                        <div *ngIf="!child.controls.address1.valid && (child.controls.address1.touched || submitAttempt)"
                                             class="errormsg">
                                            Address is required
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="head_txt">Address 2</label>
                                        <input [attr.disabled]="child.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="address2"
                                               maxlength="60"
                                               placeholder="Address 2"
                                               type="text">
                                    </div>
                                </div>
                                <div class="row demomargin">
                                    <div class="col-md-4 form-group">
                                        <label class="head_txt">City <span class="fieldMan">*</span>
                                        </label>
                                        <input [attr.disabled]="child.controls.disabled.value?'':null"
                                               appAlphabetOnly
                                               class="form-control"
                                               formControlName="city"
                                               placeholder="City"
                                               type="text">
                                        <div *ngIf="!child.controls.city.valid && (child.controls.city.touched || submitAttempt)"
                                             class="errormsg">
                                            City is required
                                        </div>
                                    </div>
                                    <div class="col-md-5 form-group">
                                        <label class="head_txt">State <span class="fieldMan">*</span>
                                        </label>
                                        <select [attr.disabled]="child.controls.disabled.value?'':null"
                                        class="form-control  form-select"
                                                formControlName="state">
                                            <option [ngValue]="null"
                                                    disabled
                                                    selected
                                                    value="">
                                                Select Your State
                                            </option>
                                            <option *ngFor="let item of states"
                                                    value={{item.name}}>
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="!child.controls.state.valid && (child.controls.state.touched || submitAttempt)"
                                             class="errormsg">
                                            State is required
                                        </div>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class="head_txt">Zip Code <span class="fieldMan">*</span>
                                        </label>
                                        <input (keypress)="numberOnly($event)"
                                               [attr.disabled]="child.controls.disabled.value?'':null"
                                               class="form-control"
                                               formControlName="zip"
                                               maxlength="5"
                                               placeholder="Zip Code"
                                               type="text">
                                        <!--  <div class="errormsg"
                                         *ngIf="!child.controls.zip.valid && (child.controls.zip.touched || submitAttempt)">
                                        Zip Code is required
                                    </div> -->
                                        <div *ngIf="(child.controls.zip.touched || submitAttempt) && child.controls.zip.errors?.maxlength "
                                             class="errormsg">
                                            Please enter valid zipcode
                                        </div>
                                        <div *ngIf="child.controls.zip.errors?.required && (child.controls.zip.touched || submitAttempt)"
                                             class="errormsg">
                                            Zip Code is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row demomargin">
                        <div class="col-lg-12"
                             style="text-align: right;">
                            <button (click)="submitPrimaryForm(auxConfigform.value)"
                                    class="btn btn-success"
                                    type="button">Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
