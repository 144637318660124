import {Component, OnInit} from '@angular/core';
import {TestService} from '../services/behaviourService';
import {SharingService} from '../services/sharing.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    loadedData: any;
    url: string;
    companyId: string;
    companyName: string;
    companyInfo: any;
    companyContent: boolean = true;
    social_links: string;
    disclaimer: string;
    safeDisclaimer: SafeHtml;
    footerMenus: any = [];
    routepath: string;
    template: any;
    isDemoPortal: boolean = false;

    constructor(private behaviourservice: TestService, private service: SharingService, private sanitizer: DomSanitizer) {

        this.service.clientData.subscribe((data) => {
            if (data != '' || data != undefined) {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.companyName = this.companyInfo.company_name;
                this.isDemoPortal = this.companyInfo.demo_portal === '1' ? true : false; //Boolean to use only for the demo portal(s)
                this.url = this.companyInfo.portalURL;
                this.template = data?.template;
            }
        })

        this.behaviourservice.getClientInfo().subscribe((data) => {
            if (data != '') {
                this.footerMenus = data[1]?.footer[2]?.menus;
                this.disclaimer = JSON.parse(localStorage.getItem('disclaimer'));
                this.safeDisclaimer = this.sanitizer.bypassSecurityTrustHtml(this.disclaimer)
                this.social_links = JSON.parse(localStorage.getItem('social_media'))
            }
        })

    }

    route(value) {
        if (value == 'HOME') {
            this.routepath = 'https://hopehealthshare.com'
        } else if (value == 'PROGRAMS' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/programs/'
        } else if (value == 'ABOUT') {
            this.routepath = 'https://hopehealthshare.com/about/'
        } else if (value == 'FAQ') {
            this.routepath = 'https://hopehealthshare.com/faq/'
        } else if (value == 'CONTACT US' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/contact/'
        } else if (value == 'WHO WE ARE') {
            this.routepath = 'https://www.joppahealth.org/who-we-are/'
        } else if (value == 'HOW IT WORKS') {
            this.routepath = 'https://www.joppahealth.org/how-it-works/'
        } else if (value == 'PROGRAMS') {
            this.routepath = 'https://www.joppahealth.org/programs/ '
        } else if (value == 'CONTACT US' || value == 'CONTACT') {
            this.routepath = 'https://www.joppahealth.org/contact/'
        } else if (value == 'STATE NOTICES') {
            this.routepath = 'https://www.joppahealth.org/state-specific-notices/'
        } else if (value == 'TERMS & CONDITIONS') {
            this.routepath = 'https://www.joppahealth.org/terms-conditions/'
        } else if (value == "PRIVACY POLICY") {
            this.routepath = 'https://www.joppahealth.org/privacy-policy/'
        }

    }

    ngOnInit(): void {

    }

}
