<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    <div class="progress_bar">
        <ul [ngClass]="{'progessbar': A2Ccode, 'progess-bar': !A2Ccode}" class=" mb-7">
            <li *ngIf="!A2Ccode">Enrollment</li>
            <li *ngIf="!A2Ccode">Products</li>
            <li *ngIf="!A2Ccode ">Payment Details</li>
            <li class="active">Beneficiary</li>
            <li>Agreement</li>
            <li>Disclosure 1</li>
            <li>Disclosure 2</li>
            <li>Summary</li>
            <li>Confirmation</li>
        </ul>
    </div>
</div>
<div class="container">
    <div class="card p-4">
        <div class="row">
            <div class="col-lg-12">
                <!--Agent Info Section-->
                <div *ngIf="agentInfo?.id>0" class="card shadow-sm mb-3">
                    <div class="card-body">
                        <h6 class="title-mainhead">
                            <span>Agent Information</span>
                        </h6>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="info-box">
                                    <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{
                                            agentInfo.firstname
                                        }}</span>
                                        <span *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span>
                                    </p>
                                    <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span>
                                    </p>
                                    <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{
                                            agentInfo.mobile
                                        }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="addProductForm">
            <div *ngFor="let item of primary_info; let i=index" class="row mb-4">
                <div class="col-md-8">
                    <h5 class="summary-title">
                        <span class="d-block  title-label mb-1">Beneficiary report for ({{ item.relation }}) :</span>
                        <span class="title-color-primary">
                                {{ item.firstname | titlecase }} {{ item.lastname | titlecase }}
                            </span>
                        <span class="title-helptxt">- (Voluntary Life benefit)</span>
                    </h5>
                    <div>
                        <h5 class="summary-title title-color-primary mt-4 mb-3 ">Primary Beneficiaries</h5>
                        <div class="price-category-container" formArrayName="member_medications">
                            <div *ngFor="let item of member_medications?.controls;let i = index; let last = last;"
                                 class="row price-category">
                                <div [formGroupName]="i" [ngClass]="{'marginTop30': i}" class="row price-category">
                                    <div class="col-md-3 form-group">
                                        <label class=" ">First Name</label>
                                        <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                               placeholder="First Name" type="text"/>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class=" ">Last Name </label>
                                        <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                               placeholder="Last Name" type="text"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="">Gender </label>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="gender" type="radio"
                                                   value="Male"/>
                                            <label class="form-check-label"> Male</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="gender" type="radio"
                                                   value="Female"/>
                                            <label class="form-check-label"> Female</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3 ">
                                        <div class="form-column">
                                            <div class="input-group">
                                                <input (blur)="onFocusPercentage('member_medications',$event.target.value)" (input)="onMemberChange($event.target.value)" (keypress)="numberOnly($event)"
                                                       class="form-control"
                                                       formControlName="percentage" maxlength="3" numbersOnly
                                                       placeholder=""
                                                       type="text">
                                                <span class="input-group-text">%</span>
                                            </div>
                                            <div *ngIf=" !item.controls.percentage.valid && item.controls['percentage'].errors['invalid-Percentage']"
                                                 class="errormsg">
                                                Total must be equal to 100%
                                            </div>
                                            <button (click)="removememberFamily(i,'member_medications')" class="btn btn-danger"
                                                    type="button">
                                                <i class="ri-delete-bin-line mr-1 color-red"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="addProductForm.controls['member_percentage_cal']">
                                    <ng-container *ngIf="last">
                                        <div *ngIf=" !addProductForm.controls.member_percentage_cal.valid && addProductForm.controls.member_percentage_cal.errors['invalid-Percentage']"
                                             class="errormsg">
                                            Total must be equal to 100%
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div *ngIf="member_medicationsPercentage !=0" class="row price-category">
                                <div class="form-group col-md-8 mb-0">
                                    <h6 class="summary-help-title title-color-secondary text-end me-2">Total
                                    </h6>
                                </div>
                                <div class="form-group col-md-2 mb-0">
                                    <h6 class="summary-help-title title-color-secondary text-end me-2 max-100">
                                        {{ member_medicationsPercentage }} %
                                    </h6>
                                </div>
                            </div>
                            <div class="button-group d-flex justify-content-start" style="margin-top: 8px;">
                                <button (click)="addPrimary(item.member_id)" class="btn btn-success"
                                        type="submit"><i class="ri-add-line mr-1"></i>
                                    Add
                                </button>
                            </div>
                        </div>
                        <h5 class="summary-title title-color-primary mt-4 mb-3 ">Contingent Beneficiaries</h5>
                        <div class="price-category-container" formArrayName="contingent_member_medications">
                            <div *ngFor="let item of contingent_member_medications.controls;let i = index; let last = last;"
                                 class="row price-category">
                                <div [formGroupName]="i" [ngClass]="{'marginTop30': i}" class="row price-category">
                                    <div class="col-md-3 form-group">
                                        <label class=" ">First Name</label>
                                        <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                               placeholder="First Name" type="text"/>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label class=" ">Last Name </label>
                                        <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                               placeholder="Last Name" type="text"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="">Gender </label>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="gender" type="radio"
                                                   value="Male"/>
                                            <label class="form-check-label"> Male</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="gender" type="radio"
                                                   value="Female"/>
                                            <label class="form-check-label"> Female</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3 ">
                                        <div class="form-column">
                                            <div class="input-group">
                                                <input (blur)="onFocusPercentage('contingent_member_medications',$event.target.value)" (keypress)="numberOnly($event)"
                                                       class="form-control"
                                                       formControlName="percentage" maxlength="3" placeholder=""
                                                       type="text">
                                                <span class="input-group-text">%</span>

                                            </div>
                                            <button (click)="removeContingentFamily(i,'contingent_member_medications')" class="btn btn-danger height-inherit"
                                                    type="button">
                                                <i class="ri-delete-bin-line mr-1 color-red"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <ng-container *ngIf="addProductForm.controls['contingent'] !=undefined">
                                    <ng-container *ngIf="last">
                                        <div *ngIf=" !addProductForm.controls.contingent.valid && addProductForm.controls.contingent.errors['invalid-Percentage']"
                                             class="errormsg">
                                            Total must be equal to 100%
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div *ngIf="cn_member_medicationsPercentage !=0" class="row price-category">
                                <div class="form-group col-md-8 mb-0">
                                    <h6 class="summary-help-title title-color-secondary text-end me-2">Total
                                    </h6>
                                </div>
                                <div class="form-group col-md-2  mb-0">
                                    <h6 class="summary-help-title title-color-secondary text-end max-100 me-2">
                                        {{ cn_member_medicationsPercentage }}%
                                    </h6>
                                </div>
                            </div>
                            <div class="button-group d-flex justify-content-start" style="margin-top: 8px;">
                                <button (click)="ContingentaddSpouse()" class="btn btn-success" type="submit"><i
                                        class="ri-add-line mr-1"></i>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="summary-data-grid">
                        <span>Generated on</span>
                        <strong>{{ item?.created_at | date: 'd-MMM-y, h:mm:ss a' }}</strong>
                    </div>

                    <h5 class="summary-title title-color-primary mt-4 mb-3 ">Policy Information</h5>
                    <div class="summary-data-grid">
                        <span>Carrier</span>
                        <strong>Allstate Benefits</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Plan</span>
                        <strong>{{ item?.product_name }}</strong>
                    </div>

                    <div class="summary-data-grid">
                        <span>Benefit Amount</span>
                        <strong>{{ item?.policy_amount | currency:'USD' }}</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Effective Date</span>
                        <strong>{{ start_date | date: 'd-MMM-y' }}</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Plan Renewal Date</span>
                        <strong>{{ todaydate | date: 'd-MMM-y' }}</strong>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-6 mt-2">

                    </div>
                </div>
            </div>

            <div *ngFor="let item of spouse_info; let i=index" class="row mb-4">
                <div class="col-md-8">
                    <h5 class="summary-title">
                        <span class="d-block title-label mb-1">Beneficiary report for ({{ item.relation }}) :</span>
                        <span class="title-color-primary">
                                {{ item.firstname | titlecase }} {{ item.lastname | titlecase }}
                            </span>
                        <span class="title-helptxt">- (Voluntary Life benefit)</span>
                    </h5>
                    <h5 class="summary-title title-color-primary mt-4 mb-3 ">Spouse Beneficiaries</h5>
                    <div class="price-category-container" formArrayName="spouse_member_medications">
                        <div *ngFor="let item of spouse_member_medications?.controls;let i = index;  let last = last;"
                             class="row price-category">
                            <div [formGroupName]="i" [ngClass]="{'marginTop30': i}" class="row price-category">
                                <div class="col-md-3 form-group">
                                    <label class=" ">First Name</label>
                                    <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                           placeholder="First Name" type="text"/>
                                </div>
                                <div class="col-md-3 form-group">
                                    <label class=" ">Last Name </label>
                                    <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                           placeholder="Last Name" type="text"/>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="">Gender </label>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                               value="Male"/>
                                        <label class="form-check-label"> Male</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                               value="Female"/>
                                        <label class="form-check-label"> Female</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 ">
                                    <div class="form-column">
                                        <div class="input-group">
                                            <input (blur)="onFocusPercentage('spouse_member_medications',$event.target.value)" (keypress)="numberOnly($event)" class="form-control"
                                                   formControlName="percentage"
                                                   maxlength="3" numbersOnly placeholder=""
                                                   type="text">
                                            <span class="input-group-text">%</span>
                                        </div>

                                        <button (click)="removespousememberFamily(i,'spouse_member_medications')" class="btn btn-danger height-inherit"
                                                type="button">
                                            <i class="ri-delete-bin-line mr-1 color-red"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="addProductForm.controls['spouse_percentage_cal'] !=undefined">
                                <ng-container *ngIf="last">
                                    <div *ngIf=" !addProductForm.controls.spouse_percentage_cal.valid && addProductForm.controls.spouse_percentage_cal.errors['invalid-Percentage']"
                                         class="errormsg">
                                        Total must be equal to 100%
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="spouse_member_medicationsPercentage !=0" class="row price-category">
                            <div class="form-group col-md-8 mb-0">
                                <h6 class="summary-help-title title-color-secondary text-end me-2">Total
                                </h6>
                            </div>
                            <div class="form-group col-md-2 mb-0 ">
                                <h6 class="summary-help-title title-color-secondary text-end max-100 me-2">
                                    {{ spouse_member_medicationsPercentage }} %
                                </h6>
                            </div>
                        </div>
                        <div class="button-group d-flex justify-content-start" style="margin-top: 8px;">
                            <button (click)="addspouse()" class="btn btn-success" type="submit"><i
                                    class="ri-add-line mr-1"></i>
                                Add
                            </button>
                        </div>
                    </div>


                    <h5 class="summary-title title-color-primary mt-4 mb-3">Contingent Beneficiaries</h5>
                    <div class="price-category-container" formArrayName="spouse_contingent_member_medications">
                        <div *ngFor="let item of spouse_contingent_member_medications?.controls;let i = index; let last = last;"
                             class="row price-category">
                            <div [formGroupName]="i" [ngClass]="{'marginTop30': i}" class="row price-category">
                                <div class="col-md-3 form-group">
                                    <label class=" ">First Name</label>
                                    <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                           placeholder="First Name" type="text"/>
                                </div>
                                <div class="col-md-3 form-group">
                                    <label class=" ">Last Name </label>
                                    <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                           placeholder="Last Name" type="text"/>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="">Gender </label>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                               value="Male"/>
                                        <label class="form-check-label"> Male</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                               value="Female"/>
                                        <label class="form-check-label"> Female</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 ">
                                    <div class="form-column">
                                        <div class="input-group">
                                            <input (blur)="onFocusPercentage('spouse_contingent_member_medications',$event.target.value)" (keypress)="numberOnly($event)"
                                                   class="form-control"
                                                   formControlName="percentage" maxlength="3" placeholder=""
                                                   type="text">
                                            <span class="input-group-text">%</span>
                                        </div>
                                        <button (click)="newremoveContingentFamily(i,'spouse_contingent_member_medications')" class="btn btn-danger"
                                                type="button">
                                            <i class="ri-delete-bin-line mr-1 color-red"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 my-auto">

                                </div>
                            </div>
                            <ng-container *ngIf="addProductForm.controls['newcontingent'] !=undefined">

                                <ng-container *ngIf="last">
                                    <div *ngIf=" !addProductForm.controls.newcontingent.valid && addProductForm.controls.newcontingent.errors['invalid-Percentage'] "
                                         class="errormsg">
                                        Total must be equal to 100%
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="new_cn_member_medicationsPercentage !=0" class="row price-category">
                            <div class="form-group col-md-8 mb-0">
                                <h6 class="summary-help-title title-color-secondary text-end me-2">Total
                                </h6>
                            </div>
                            <div class="form-group col-md-2 mb-0 ">
                                <h6 class="summary-help-title title-color-secondary  text-end me-2 max-100">
                                    {{ new_cn_member_medicationsPercentage }}%
                                </h6>
                            </div>
                        </div>
                        <div class="button-group d-flex justify-content-start" style="margin-top: 8px;">
                            <button (click)="newContingentaddSpouse()" class="btn btn-success" type="submit"><i
                                    class="ri-add-line mr-1"></i>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="summary-data-grid">
                        <span>Generated on</span>
                        <strong>{{ item?.created_at | date: 'd-MMM-y, h:mm:ss a' }}</strong>
                    </div>

                    <h5 class="summary-title title-color-primary mt-4 mb-3 ">Policy Information</h5>
                    <div class="summary-data-grid">
                        <span>Carrier</span>
                        <strong>Allstate Benefits</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Plan</span>
                        <strong>{{ item?.product_name }}</strong>
                    </div>

                    <div class="summary-data-grid">
                        <span>Benefit Amount</span>
                        <strong>{{ item?.policy_amount | currency:'USD' }}</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Effective Date</span>
                        <strong>{{ start_date | date: 'd-MMM-y' }}</strong>
                    </div>
                    <div class="summary-data-grid">
                        <span>Plan Renewal Date</span>
                        <strong>{{ todaydate | date: 'd-MMM-y' }}</strong>
                    </div>


                </div>
            </div>

             <div formArrayName="child_info"> 
                <div class="row" *ngFor="let comp of addProductForm.get('child_info')['controls']; let i=index">
                    <div [formGroupName]="i" class="col-md-12 mb-2">
                        <div class="child_data">
                            <div class="summary-data-grid">
                                <span>Generated on</span>
                                <strong>{{ comp.get('created_at').value | date: 'd-MMM-y, h:mm:ss a' }}
                                </strong>
                            </div>
                            <h5 class="summary-title title-color-primary mt-4 mb-3 ">Policy Information</h5>
                            <div class="summary-data-grid">
                                <span>Carrier</span>
                                <strong>Allstate Benefits</strong>
                            </div>
                            <div class="summary-data-grid">
                                <span>Plan</span>
                                <strong>{{ comp.get('product_name').value }}</strong>
                            </div>

                            <div class="summary-data-grid">
                                <span>Benefit Amount</span>
                                <strong>{{ comp.get('policy_amount').value | currency:'USD' }}</strong>
                            </div>
                            <div class="summary-data-grid">
                                <span>Effective Date</span>
                                <strong>{{ start_date | date: 'd-MMM-y' }}</strong>
                            </div>
                            <div class="summary-data-grid">
                                <span>Plan Renewal Date</span>
                                <strong>{{ todaydate | date: 'd-MMM-y' }}</strong>
                            </div>

                        </div>
                        <h5 class="summary-title">
                                <span class="d-block title-label mb-1">Beneficiary report for
                                    ({{ comp.get('relation').value }}):</span>
                            <span class="title-color-primary">
                                    {{ comp.get('fullname').value }}
                                </span>
                            <span class="title-helptxt">- (Voluntary Life benefit)</span>
                        </h5>
                        <!-- <div formArrayName="projects"> // future use
                            <h5 class="summary-title title-color-primary  mb-3 ">Child Beneficiaries
                            </h5>
                            <div class="row">
                                <div class="col-md-12">
                                    <div *ngFor="let project of comp.get('projects').controls; let j=index;let last=last"
                                         class="child_width">
                                        <div [formGroupName]="j" class="row price-category">
                                            <div class="col-md-3 form-group">
                                                <label class=" ">First Name</label>
                                                <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                                       placeholder="First Name" type="text"/>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label class=" ">Last Name </label>
                                                <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                                       placeholder="Last Name" type="text"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="">Gender </label>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input class="form-check-input" formControlName="gender" type="radio"
                                                           value="Male"/>
                                                    <label class="form-check-label"> Male</label>
                                                </div>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input class="form-check-input" formControlName="gender" type="radio"
                                                           value="Female"/>
                                                    <label class="form-check-label"> Female</label>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3 ">
                                                <div class="form-column">
                                                    <div class="input-group">
                                                        <input (blur)="onFocusoutChildPercentage('projects',project,j,comp)" (keypress)="numberOnly($event)"
                                                               class="form-control"
                                                               formControlName="percentage" maxlength="3" numbersOnly
                                                               placeholder="" type="text">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <button (click)="deleteProject(comp.controls.projects, j);onFocusoutChildPercentage('projects',project,j,comp)" class="btn btn-danger height-inherit"
                                                            type="button">
                                                        <i class="ri-delete-bin-line mr-1 color-red"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="last">
                                            <div *ngIf=" !comp.controls.child_count.valid"
                                                 class="row price-category ">
                                                <div class="form-group col-md-8 mb-0">
                                                    <h6
                                                            class="summary-help-title title-color-secondary text-end me-2">
                                                        Total
                                                    </h6>
                                                </div>
                                                <div class="form-group col-md-2 mb-0">
                                                    <h6
                                                            class="summary-help-title title-color-secondary text-end me-2 max-100">
                                                        {{ comp.controls.child_count }} %
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div *ngIf=" !comp.controls.percentageCalculation.valid && comp.controls['percentageCalculation'].errors['invalid-Percentage']"
                                         class="errormsg">
                                        Total must be equal to 100%
                                    </div>

                                    <button (click)="addNewProject(comp.controls.projects)" class="btn btn-success"
                                            type="button"><i
                                            class="ri-add-line mr-1"></i>Add
                                    </button>
                                </div>
                            </div>

                        </div> -->
                        <!-- <div formArrayName="projects1"> // future use
                            <h5 class="summary-title title-color-primary mt-4 mb-3 ">Contingent
                                Beneficiaries</h5>
                            <div class="row m-0">
                                <div class="col-md-8">
                                    <div *ngFor="let project of comp.get('projects1').controls; let j=index; let last=last"
                                         class="child_conti_width">
                                        <div [formGroupName]="j" class="row price-category">
                                            <div class="col-md-3 form-group">
                                                <label class=" ">First Name</label>
                                                <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                                       placeholder="First Name" type="text"/>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label class=" ">Last Name </label>
                                                <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                                       placeholder="Last Name" type="text"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="">Gender </label>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input class="form-check-input" formControlName="gender" type="radio"
                                                           value="Male"/>
                                                    <label class="form-check-label"> Male</label>
                                                </div>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input class="form-check-input" formControlName="gender" type="radio"
                                                           value="Female"/>
                                                    <label class="form-check-label"> Female</label>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3 ">
                                                <div class="form-column">
                                                    <div class="input-group">
                                                        <input (blur)="onFocusoutChildContigent('projects1',project,j,comp)" (keypress)="numberOnly($event)"
                                                               class="form-control"
                                                               formControlName="percentage" maxlength="3" numbersOnly
                                                               placeholder="" type="text">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <button (click)="deleteProject(comp.controls.projects1, j);onFocusoutChildContigent('projects1',project,j,comp)" class="btn btn-danger height-inherit"
                                                            type="button">
                                                        <i class="ri-delete-bin-line mr-1 color-red"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="last">
                                            <div *ngIf=" !comp.controls?.contigent_count?.valid"
                                                 class="row price-category">
                                                <div class="form-group col-md-8 mb-0">
                                                    <h6
                                                            class="summary-help-title title-color-secondary text-end me-2">
                                                        Total
                                                    </h6>
                                                </div>
                                                <div class="form-group col-md-2 mb-0">
                                                    <h6
                                                            class="summary-help-title title-color-secondary text-end me-2 max-100">
                                                        {{ comp.controls.contigent_count }} %
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf=" !comp.controls?.contigent_percentageCalculation?.valid && comp.controls['contigent_percentageCalculation']?.errors['invalid-Percentage']"
                                         class="errormsg">
                                        Total must be equal to 100%
                                    </div>
                                    <button (click)="addNewProject(comp.controls.projects1)" class="btn btn-success"
                                            type="button"><i
                                            class="ri-add-line mr-1"></i>Add
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <hr>
                </div>
            </div> 
            <div *ngIf="!A2Ccode" class="d-flex justify-content-between mt-3 mb-2">
                <button (click)="onPrevious()" class="btn btn-outline-primary ">Previous</button>
                <button (click)="export()" class="btn btn-primary">Next</button>
            </div>
            <div *ngIf="A2Ccode" class="d-flex justify-content-end mt-3 mb-2">
                <button (click)="export()" class="btn btn-primary">Next</button>
            </div>
        </form>
    </div>
</div>
