<div class="container">
  <div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active" *ngFor="let item of steps"></div>
        </div>
    </div>
    <div class="progress_bar">
        <ul class="progess-bar mb-7">
            <li *ngFor="let item of steps" [ngClass]="item.active ? 'active' : '' ">{{item.step}}</li>
        </ul>
    </div>
</div>

  <div *ngIf="!isSubmitted" class="row bg-white m-0 p-2 pt-3">
    <div class="col-md-8">
      <div *ngFor="let member of members" class="card applicant mb-3">
        <div class="card-body applicant-info">
          <div class="applicant-header px-0 pt-0">
            <div class="applicant-title">
              <h6 class="card-subtitle">{{ member?.applicanttype }}</h6>
              <h4 class="card-title">
                <strong>{{ member?.firstname }} {{ member?.lastname }}</strong>
              </h4>
            </div>
          </div>
          <div class="info mt-2">
            <div class="row m-0 p-0 mb-2">
              <div class="col-md-4 p-0">
                <span class="text-muted"
                  >Gender : <strong>{{ member?.gender }}</strong>
                </span>
              </div>
              <div class="col-md-4 p-0">
                <span class="text-muted"
                  >DOB :
                  <strong>{{ member?.dob | date : dateFormat }} </strong></span
                >
              </div>
            </div>
            <div class="row m-0 p-0 mb-2">
              <div class="col-md-4 p-0">
                <span class="text-muted"
                  >Age : <strong>{{ member?.age }} </strong>
                </span>
              </div>
            </div>
            <div *ngIf="member.phone != null" class="row m-0 p-0 mb-2">
              <div class="col-md-4 p-0">
                <span class="text-muted"
                  >Mobile Number : <strong>{{ member?.phone }} </strong>
                </span>
              </div>
            </div>
            <div *ngIf="member.email != null" class="row m-0 p-0 mb-2">
              <div class="col-md-4 p-0">
                <span class="text-muted text_space"
                  >Email address : <strong>{{ member?.email }} </strong>
                </span>
              </div>
            </div>
            <div *ngIf="member.address1 != null" class="row m-0 p-0 mb-2">
              <div class="col-md-4 p-0">
                <div class="text-muted">Address :</div>

                <span class="addres-td">
                  <span class="pe-1"><strong>{{ member?.address1 }},</strong>
                  </span>
                  <span *ngIf="member?.address2">
                    <strong>{{ member.address2 }},</strong>
                  </span>
                  <span class="d-block"
                    ><strong
                      >{{ member.city }}, {{ member.state }},
                      {{ member.zip }}</strong
                    >
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h6  class="section-title">Payment Details</h6>

      <div class="card cart-info">
        <div class="">
          <div class="pmnt-type">{{ payment_info?.payment_type }} Details</div>
          <ng-container *ngIf="payment_info?.payment_type == 'CARD'">
            <div class="row mb-2 mt-2">
              <span class="text-muted"
                >Name : <strong>{{ payment_info?.name }} </strong>
              </span>
              <span class="text-muted"
                >Card Number : <strong>{{ payment_info?.card_number }} </strong>
              </span>
              <span class="text-muted"
                >Expiry Date :
                <strong>{{ payment_info?.mm }} /{{ payment_info?.yy }} </strong>
              </span>
              <span class="text-muted"
                >Billing Date :
                <strong
                  >{{ payment_info?.payment_date | date : dateFormat }}
                </strong>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="payment_info?.payment_type == 'BANK'">
            <div class="row mb-2 mt-2 p-2">
              <span class="text-muted"
                >Name : <strong>{{ payment_info?.name }} </strong>
              </span>
              <span class="text-muted"
                >Account Type :
                <strong
                  >{{
                    payment_info?.account_type == "C"
                      ? "Checking"
                      : payment_info?.account_type == "S"
                      ? "Saving"
                      : ""
                  }}
                </strong>
              </span>

              <span class="text-muted"
                >Account Number :
                <strong>{{ payment_info?.account_4 }} </strong>
              </span>
              <span class="text-muted"
                >Routing Number :
                <strong>{{ payment_info?.routing_number }} </strong>
              </span>
              <span class="text-muted"
                >Billing Date :
                <strong
                  >{{ payment_info?.payment_date | date : dateFormat }}
                </strong>
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <!--Agent Info Section-->
      <div *ngIf="agentInfo?.id > 0" class="mt-4 mb-3">
        <h5>{{ agent_text }} Information</h5>
      </div>

      <div *ngIf="agentInfo?.id > 0" class="card cart-info">
        <div class="card-body pt-2">
          <div *ngIf="agentInfo" class="info-box">
            <p>
              <b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{
                agentInfo.firstname
              }}</span>
              <span *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span>
            </p>
            <p>
              <span *ngIf="agentInfo.email"
                ><b>Email:</b>&nbsp;{{ agentInfo.email }}</span
              >
            </p>
            <p>
              <span *ngIf="agentInfo.mobile"
                ><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <!--Discount Section-->
      <div *ngIf="showDiscountSection" class="card cart-info mb-3">
        <div class="row m-0 p-2">
          <div class="col-lg-12 mb-2">
            <span class="text-muted">Enrollment Discount Code</span>
          </div>
          <div class="col-lg-12 mb-2">
            <div class="input-group">
              <div *ngIf="discountType == 'amount'" class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                [(ngModel)]="discountValue"
                class="form-control discount-field"
                type="text"
              />
              <button
                (click)="applyDiscount()"
                class="input-group-text btn btn-primary apply-btn"
              >
                Apply
              </button>
            </div>
            <p *ngIf="discountError" class="discount-error text-danger m-0">
              {{ discountError }}
            </p>
          </div>
        </div>
      </div>

      <div class="card cart-info mb-3">
        <div class="row m-0 p-0 border-btm">
          <div class="info d-flex justify-content-between">
            <span class="text-muted">Effective start date</span>
            <span>
              <strong>{{ effective_start_date | date : dateFormat }} </strong>
            </span>
          </div>
        </div>
        <div *ngFor="let item of cart_products" class="row m-0 p-0 border-btm">
          <div class="info d-flex justify-content-between">
            <span
              ><b> {{ item.product_name }} </b></span
            >
            <span
              ><b> {{ item.amount | currency : "USD" }} </b></span
            >
          </div>
        </div>
        <div class="row m-0 p-0 border-btm">
          <div
            *ngFor="let item of cart_fee"
            class="info d-flex justify-content-between"
          >
            <span class="text-muted">{{ item?.name }}</span>
            <span> {{ item?.amount | currency : "USD" }} </span>
          </div>

          <div
            *ngIf="cart_data && cart_data[0] && cart_data[0].discount > 0"
            class="info d-flex justify-content-between"
          >
            <span class="text-muted">
              Discount
              <button
                (click)="removeDiscount()"
                class="btn-danger remove-discount-btn"
              >
                Remove
              </button>
            </span>
            <span> -{{ cart_data[0]?.discount | currency : "USD" }} </span>
          </div>

          <div class="info d-flex justify-content-between">
            <span class="text-muted"><b>Total Payment Today</b></span>
            <span>
              <b>{{ total_payment_today | currency : "USD" }} </b></span
            >
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="info d-flex justify-content-between">
            <span class="text-muted">Monthly Contribution </span>
            <span>
              <strong>{{ monthly_contribution | currency : "USD" }} </strong>
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button (click)="onPrevious()" class="btn btn-outline-primary">
          Previous
        </button>
        <button (click)="submit()" class="btn btn-primary">
          {{ enroll_btn }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isSubmitted" class="row bg-white m-0 p-2 pt-3">
    <div class="col-md-12 text-center">
      <div class="confirmation">
        <h4>Thank You For Your Application</h4>
        <h5>{{ paymentMessage }}</h5>

        <button (click)="backToEnrollment()" class="btn btn-primary mt-3">
          New Enrollment
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="loader">
  <div id="loading"></div>
</div>
