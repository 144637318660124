import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss'
})
export class PaymentFailedComponent {
  family_id: any;
  template: any;
  companyId: any;
  constructor(
    private service: SharingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
    ) {
    this.family_id = this.route.snapshot.paramMap.get('family_id');
  }

  ngOnInit(): void {
    this.service.clientData.subscribe((data) => {
      if (data !== '') {
        this.template = data?.template;
        this.companyId = data?.company_id
      }
    });
  }

  proceed(){
    if(this.template == 'template_3'){
        let origin = window.location.pathname.split('/')[1]
        this.router.navigate(['/'+ origin+'/payment', this.companyId, this.family_id]);
    }
  }
}

