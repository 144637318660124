import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TestService} from '../services/behaviourService';
import {SharingService} from '../services/sharing.service';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-healthquestions',
    templateUrl: './healthquestions.component.html',
    styleUrls: ['./healthquestions.component.scss'],
    providers: [DatePipe]
})
export class HealthquestionsComponent implements OnInit {
    companyId: any;
    healthArray: any = [];
    data: any;
    Form: UntypedFormGroup;
    medicalHistoryInfo: Array<any> = [];
    employeedetails: boolean;
    maxDate: Date;
    invalidDates1: Date[];
    minimumDate: Date;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private objectService: TestService,
        private services: SharingService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
    ) {
        this.services.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;
                this.getHealthQuestions();
            }
        })
    }

    ngOnInit(): void {
        let today = new Date();
        this.maxDate = new Date();
        let invalidDate1 = new Date();
        invalidDate1.setDate(today.getDate() + 3);
        this.invalidDates1 = [today, invalidDate1];
        this.minimumDate = new Date();
        this.Form = this.formBuilder.group({
            spouse_Array: this.formBuilder.array([]),

        });


    }

    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [''],
            dob: [''],
            relationship: [''],
            physician_name: [''],
            diagnosis: [''],
            date_of_diagnosis: ['']
        })
    }

    spouse_Array(): UntypedFormArray {
        return this.Form.get("spouse_Array") as UntypedFormArray
    }

    addSpouse() {//add new spouse
        this.spouse_Array().push(this.newEmployee1());
    }

    removeEmployee(empIndex1, spouseValues, values) {//remove spouse array
        this.spouse_Array().removeAt(empIndex1);

    }

    getHealthQuestions() {
        var url = 'ListHealthQuestions/3';
        this.services.getData(url).subscribe((obj: any) => {
            this.data = obj.data;
        }, err => {
            console.log(err)
        })
    }

    selectoptions(value, isChecked: boolean, i) {

        if (isChecked == true) {
            let obj = {
                health_issue_id: value.health_issue_id,
                health_id: value.health_id,
                active: 1,
                member_id: "284",
            }
            this.medicalHistoryInfo.push(obj);
        } else {
            let index = this.medicalHistoryInfo.findIndex(el => el.health_issue_id == value.health_issue_id)
            if (index > -1) {
                this.medicalHistoryInfo.splice(index, 1);
            }
        }

    }

    selectoptionsradio(data, e, i) {
        let obj = {
            health_id: data.health_id,
            health_issue_id: data.health_issue_id,
            active: 1,
            member_id: "284",
        }
        if (this.medicalHistoryInfo.length > 0) {
            let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_id == obj.health_id)
            if (index > -1) {
                this.medicalHistoryInfo.splice(index, 1);
                this.medicalHistoryInfo.push(obj)
            } else {
                this.medicalHistoryInfo.push(obj);
            }
        } else {
            this.medicalHistoryInfo.push(obj);
        }
        if (i == '3') {
            if (data.value == 'YES') {
                this.employeedetails = true;
                this.spouse_Array().push(this.newEmployee1());
            } else {
                this.employeedetails = false;
            }
        }
    }

    submit(value) {
        for (var i = 0; i < value.spouse_Array.length; i++) {
            value.spouse_Array[i].dob = this.datePipe.transform(value.spouse_Array[i].dob, "yyyy-MM-dd")
            value.spouse_Array[i].date_of_diagnosis = this.datePipe.transform(value.spouse_Array[i].date_of_diagnosis, "yyyy-MM-dd")
        }
        let body = {
            company_id: "2",
            family_id: 116,
            cart_id: null,
            primary_member_id: null,
            member_personal_data: this.medicalHistoryInfo,
            member_diagnosis: value.spouse_Array
        }
        var url = 'CreateMemberHealthIssue';
        this.services.postData(url, body).subscribe((obj: any) => {
            if (obj.status = true) {

            }
        }, err => {
            console.log(err)
        })
    }

}
