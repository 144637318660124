<!-- <app-header></app-header> -->
<section class="section-enrollment">
    <div class="container">
        <div class="enrlmnt-demographic">
            <div class="stepper">
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="dependent-card">
                    <div class="text-center margin" >
                        <h4 class="mb-3">{{successMessage}}</h4>
                       <p class="mb-3"> <span class="name">{{name | titlecase}}</span></p> 
                        <p class="mt-3 mb-5 " *ngIf="applicationNumber !='addgroup' && applicationNumber !='applicant' &&  applicationNumber !=undefined">Your Application Number : <span class="applactNo">{{applicationNumber}}</span></p> 
                       <i class="ri-check-fill successIcon mt-6"></i>
                        <p class="mt-3"> <i>Please close this browser window.</i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
