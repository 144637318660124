import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TestService} from '../../../services/behaviourService';
import {SharingService} from '../../../services/sharing.service';
import {SessionService} from '../../../services/session.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';

declare var $;

@Component({
    selector: 'app-health-based-questions',
    templateUrl: './health-based-questions.component.html',
    styleUrls: ['./health-based-questions.component.scss'],
    providers: [DatePipe]
})
export class HealthBasedQuestionsComponent implements OnInit {
    applicant: any;
    cart_id: any;
    user_id: any;
    cartProducts: any;
    memberdata: any;
    selectedLink: string = "";
    healthFormArray: any[] = [];
    healthFormArray1: any[] = [];
    healthFormArrayunchecked: any[] = [];
    Form: UntypedFormGroup;
    medicalHistoryInfo: Array<any> = [];
    employeedetails: boolean;
    healthQuestions: any[] = [];
    newarr = [];
    newarr2 = [];
    newarr3 = [];
    finalarr = [];
    arr = []
    arr1 = []
    errMsg: string;
    isHealthquestions: boolean = false;
    questions: any;
    questionsData: any;
    data: any;
    healthissue: any;
    mydata: '';
    showQuestionsmsg: boolean = false;
    mH_id: any;
    healthArray: any[] = [];
    selectedQuestions: any[] = [];
    selectedQuestionsprevbtn: any[] = [];
    companyId: string;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    bmiinfo: any;
    additionalMember: any;
    newHealthBasedQts: any;
    expandedIndex: any = -0;
    checkIfOthersAreSelected: boolean = false
    submitAttempt: boolean = false;
    newArr: any = [];
    newmembercheckArr: any = [];
    newmemberlastcheckArr: any = []
    mgalert: any;
    seleted_data: any;
    responseNewArr: any = [];
    responseNewArr1: any = [];
    responseNewArr2: any = [];
    questionsvalidation: any = [];
    companyInfo: any;
    oneTimeFee: any;
    groupID: any;
    maxDate: Date;
    invalidDates1: Date[];
    minimumDate: Date;
    questionslist: any;
    member_diagnosis: any;
    cmpId: any;
    memId: any;
    cartId: any;
    questionData: any;
    application_id: any;
    isMandatory: boolean = false;
    program_id: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private objectService: TestService,
        private services: SharingService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private sessionService: SessionService,
        private route: ActivatedRoute
    ) {
        this.cmpId = this.sessionService.getData("company");
        this.memId = this.sessionService.getData("member_id");
        this.cartId = this.sessionService.getData("cart_id");
        this.route.queryParams.subscribe(param => {
            this.program_id = param?.program_id
        })
        this.services.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                var url = 'GetMemberDependents/' + this.cmpId + '/' + this.memId + '/' + this.cartId;
                this.services.getDataFromUrl(url).subscribe((Object: any) => {
                    this.applicant = Object;
                    this.application_id = Object.application_id
                    this.cartProducts = this.applicant.cart_products;
                    this.memberdata = this.applicant.member_personal_data;
                    this.cart_id = this.applicant.cart_data[0].id;
                    this.user_id = this.applicant.member_personal_data[0].user_id;
                    this.cartFee = this.applicant.cart_fee;
                    this.cartData = this.applicant.cart_data;
                    this.memberDiscount = this.applicant.agent_cart_discount;
                    this.bmiinfo = this.applicant.cart_bmi;
                    this.additionalMember = this.applicant.additional_member_fee;
                    this.oneTimeFee = this.applicant.total_one_time_fee;
                })
            }
        })
        this.objectService.questionsObject.subscribe(result => {
            if (result != '') {
                this.mydata = result ? result.data : null;
                this.seleted_data = result.data;

            } else {
                this.questionData = this.sessionService.getData("questionList")
                this.mydata = this.questionData ? this.questionData.data : null;
                this.seleted_data = this.questionData ? this.questionData.data : null;

            }
        })
        this.objectService.demographyObject.subscribe(result => {
            /* this.applicant = result;
            this.cartProducts = this.applicant.cart_products;
            this.memberdata = this.applicant.member_personal_data;
            this.cart_id = this.applicant.cart_data[0].id;
            this.user_id = this.applicant.member_personal_data[0].user_id;
            this.cartFee = this.applicant.cart_fee;
            this.cartData = this.applicant.cart_data;
            this.memberDiscount = this.applicant.agent_cart_discount;
            this.bmiinfo = this.applicant.cart_bmi;
            this.additionalMember = this.applicant.additional_member_fee;
            this.oneTimeFee = this.applicant.total_one_time_fee; */
        })
    }

    isHealthQuestionsChecked() {
        // checked
        this.isHealthquestions = true;
        this.Form.controls.spouse_Array['controls'].forEach(element => {
            console.log(element.controls);
            element.controls.date_of_diagnosis.setValidators([Validators.required]);
            element.controls.date_of_diagnosis.updateValueAndValidity({emitEvent: false});
            element.controls.diagnosis.setValidators([Validators.required]);
            element.controls.diagnosis.updateValueAndValidity({emitEvent: false});
            element.controls.dob.setValidators([Validators.required]);
            element.controls.dob.updateValueAndValidity({emitEvent: false});
            element.controls.name.setValidators([Validators.required]);
            element.controls.name.updateValueAndValidity({emitEvent: false});
            element.controls.physician_name.setValidators([Validators.required]);
            element.controls.physician_name.updateValueAndValidity({emitEvent: false});
            element.controls.relationship.setValidators([Validators.required]);
            element.controls.relationship.updateValueAndValidity({emitEvent: false});
        })
    }

    isHealthQuestionsUnchecked() {
        // unchecked
        this.isHealthquestions = false;
        this.Form.controls.spouse_Array['controls'].forEach(element => {
            console.log(element.controls);
            element.controls.date_of_diagnosis.clearValidators();
            element.controls.date_of_diagnosis.updateValueAndValidity({emitEvent: false});
            element.controls.diagnosis.clearValidators();
            element.controls.diagnosis.updateValueAndValidity({emitEvent: false});
            element.controls.diagnosis.clearValidators();
            element.controls.diagnosis.updateValueAndValidity({emitEvent: false});
            element.controls.dob.clearValidators();
            element.controls.dob.updateValueAndValidity({emitEvent: false});
            element.controls.name.clearValidators();
            element.controls.name.updateValueAndValidity({emitEvent: false});
            element.controls.physician_name.clearValidators();
            element.controls.physician_name.updateValueAndValidity({emitEvent: false});
            element.controls.relationship.clearValidators();
            element.controls.relationship.updateValueAndValidity({emitEvent: false});
        });
    }

    ngOnInit(): void {
        let today = new Date();
        this.maxDate = new Date();
        let invalidDate1 = new Date();
        invalidDate1.setDate(today.getDate() + 3);
        this.invalidDates1 = [today, invalidDate1];
        this.minimumDate = new Date();
        this.objectService.pushheaderCondition("showheader");
        this.Form = this.formBuilder.group({
            acknowledgeCheck: [false, Validators.requiredTrue],
            spouse_Array: this.formBuilder.array([]),
        });
        this.getHealthQuestions();
        this.getHealthBasedQts();
        var healthQts = localStorage.getItem('healthQUestions')

        // if (healthQts == "health_questions") {
        //    this.getHealthBasedQts();
        //} else {
        var healthQtsSession = this.sessionService.getData("healthQUestions");
        if (healthQtsSession == "health_questions") {
            // this.getHealthBasedQts();
        }

        //}
    }


    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [''],
            dob: [''],
            relationship: [''],
            physician_name: [''],
            diagnosis: [''],
            date_of_diagnosis: ['']
        })
    }

    spouse_Array(): UntypedFormArray {
        return this.Form.get("spouse_Array") as UntypedFormArray
    }

    addSpouse() {//add new spouse
        this.spouse_Array().push(this.newEmployee1());
    }

    removeEmployee(empIndex1, spouseValues, values) {//remove spouse array
        this.spouse_Array().removeAt(empIndex1);

    }

    getHealthQuestions() {
        var url = 'ListHealthQuestions';
        let body = {
            company_id: this.cmpId,
            program_id: localStorage.getItem("program_id") == null ? this.program_id : localStorage.getItem("program_id")

        }
        this.services.postDataUrl(url, body).subscribe((obj: any) => {
            this.buildForm(obj.data)
            this.healthQuestions = obj.data;
            this.employeedetails = true;
            if (this.seleted_data) {
                this.spouse_Array().push(this.newEmployee1());
                for (var i = 0; i < this.seleted_data.length; i++) {
                    for (var z = 0; z < this.healthQuestions.length; z++) {
                        for (var p = 0; p < this.healthQuestions[z].health_issue.length; p++) {
                            if (this.healthQuestions[z].health_issue[p].health_issue_id == this.seleted_data[i].health_issue_id) {
                                this.seleted_data[i]['field_type'] = this.healthQuestions[z].health_issue[p].field_type
                                this.healthQuestions[z].health_issue[p].Checked = true;
                                let obj = {
                                    member_health_issue_id: this.seleted_data[i].member_health_issue_id,
                                    member_id: this.seleted_data[i].member_id,
                                    health_issue_id: this.seleted_data[i].health_issue_id,
                                    active: 1,
                                    health_id: this.seleted_data[i].health_id
                                }
                                this.medicalHistoryInfo.push(obj);

                            }
                        }
                    }
                }
                if (this.member_diagnosis && this.member_diagnosis.length > 0) {
                    this.employeedetails = true;
                    for (var i = 0; i < this.member_diagnosis.length; i++) {
                        this.Form.controls['spouse_Array']['controls'][i].patchValue({
                            name: this.member_diagnosis[i].name,
                            dob: this.datePipe.transform(this.member_diagnosis[i].dob, "MM/dd/yyyy"),
                            relationship: this.member_diagnosis[i].relationship,
                            physician_name: this.member_diagnosis[i].physician_name,
                            diagnosis: this.member_diagnosis[i].diagnosis,
                            date_of_diagnosis: this.datePipe.transform(this.member_diagnosis[i].date_of_diagnosis, "MM/dd/yyyy"),


                        })

                    }


                }
                this.seleted_data.forEach(element => {
                    if (element['field_type'] == "Multiple-Checkbox") {
                        setTimeout(() => {
                            let x = <HTMLInputElement>document.getElementById('question_' + element.health_issue_id);
                            x.checked = true;
                        }, 10);
                    } else if (element['field_type'] == "Checkbox") {
                        let controlname = "question_" + element.health_id
                        this.Form.controls.healthIssues['controls'][controlname].setValue(element.value)
                    }
                });
            } else {
                if (this.spouse_Array().length <= 0) {
                    this.spouse_Array().push(this.newEmployee1());
                }
            }
        }, err => {
            console.log(err)
        })
    }

    buildForm(data) {
        const healthIssuesControls = data.reduce((acc, question) => {
            acc[`question_${question.id}`] = this.formBuilder.control(
                '',
                question.required === 'yes' ? Validators.required : []
            );
            return acc;
        }, {});
        this.Form.addControl('healthIssues', this.formBuilder.group(healthIssuesControls));
    }

    async getHealthBasedQts() {
        var url = 'GetMemberHealthIssue/' + this.cmpId + '/' + this.memId;
        this.services.getDataFromUrl(url).subscribe((obj: any) => {
            if (obj.status = true) {
                this.seleted_data = obj.data;
                this.member_diagnosis = obj.member_diagnosis

            }

        })
    }

    selectoptions(value, isChecked: boolean, i) {
        let obj = {
            health_issue_id: value.health_issue_id,
            health_id: value.health_id,
            active: 1,
            member_id: this.memberdata[0].member_id,
            isCheckbox: !!isChecked
        }
        if (isChecked == true) {

            this.medicalHistoryInfo.push(obj);
            this.isHealthQuestionsChecked()
            this.isMandatory = true;
        } else {

            let index = this.medicalHistoryInfo.findIndex(el => el.health_issue_id == value.health_issue_id)
            if (index > -1) {
                this.medicalHistoryInfo.splice(index, 1);
            }
            this.isHealthQuestionsUnchecked()
            let anyCheckboxSelected = this.medicalHistoryInfo.some(el => el.isCheckbox);
            let anyRadioSelectedAsYes = this.medicalHistoryInfo.some(el => el.val == 1);
            this.isMandatory = anyCheckboxSelected || anyRadioSelectedAsYes;

        }

        this.updateValidators();
    }

    selectoptionsradio(data, e, i) {


        let obj = {
            health_id: data.health_id,
            health_issue_id: data.health_issue_id,
            active: 1,
            member_id: this.memberdata[0].member_id,
            val: data.value == "YES" ? 1 : 0
        }
        if (this.medicalHistoryInfo.length > 0) {
            let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_id == obj.health_id)
            if (index > -1) {
                this.medicalHistoryInfo.splice(index, 1);
                this.medicalHistoryInfo.push(obj)
                this.questionsvalidation.splice(index, 1);
                this.questionsvalidation.push(obj);

            } else {
                this.medicalHistoryInfo.push(obj);
                this.questionsvalidation.push(obj);
            }
        } else {
            this.medicalHistoryInfo.push(obj);
            this.questionsvalidation.push(obj);
        }

        let anyCheckboxSelected = this.medicalHistoryInfo.some(el => el.isCheckbox);
        let anyRadioSelectedAsYes = this.medicalHistoryInfo.some(el => el.val == 1);
        this.isMandatory = anyCheckboxSelected || anyRadioSelectedAsYes;

        this.updateValidators();
    }

    updateValidators() {
        if (this.isMandatory) {
            this.isHealthQuestionsChecked();
        } else {
            this.isHealthQuestionsUnchecked();
        }
    }


    submit(value) {

        this.submitAttempt = true;
        if (this.questionsvalidation.length > 0 && this.medicalHistoryInfo[0].member_health_issue_id == undefined) {
            if (this.Form.valid) {
                for (var i = 0; i < value.spouse_Array.length; i++) {
                    value.spouse_Array[i].dob = this.datePipe.transform(value.spouse_Array[i].dob, "yyyy-MM-dd")
                    value.spouse_Array[i].date_of_diagnosis = this.datePipe.transform(value.spouse_Array[i].date_of_diagnosis, "yyyy-MM-dd")
                }
                let body = {
                    company_id: this.companyId,
                    family_id: this.memberdata[0].family_id,
                    cart_id: null,
                    primary_member_id: this.memberdata[0].member_id,
                    member_personal_data: this.medicalHistoryInfo,
                    member_diagnosis: value.spouse_Array
                }
                var url = 'CreateMemberHealthIssue';
                this.services.postData(url, body).subscribe((obj: any) => {
                    if (obj.status = true) {
                        this.services.pushQuestionsList(this.selectedQuestions);
                        this.router.navigate(['paymentdetails']);
                    }
                }, err => {
                    console.log(err)
                    this.toastr.error(err.message);
                })
            }
        } else {
            if (this.seleted_data.length > 0) {
                if (this.medicalHistoryInfo[0].member_health_issue_id != undefined) {
                    if (this.Form.valid) {
                        for (var i = 0; i < value.spouse_Array.length; i++) {
                            value.spouse_Array[i].dob = this.datePipe.transform(value.spouse_Array[i].dob, "yyyy-MM-dd")
                            value.spouse_Array[i].date_of_diagnosis = this.datePipe.transform(value.spouse_Array[i].date_of_diagnosis, "yyyy-MM-dd")
                        }
                        let body = {
                            company_id: this.companyId,
                            family_id: this.memberdata[0].family_id,
                            cart_id: null,
                            primary_member_id: this.memberdata[0].member_id,
                            member_personal_data: this.medicalHistoryInfo,
                            member_diagnosis: value.spouse_Array
                        }
                        var url = 'CreateMemberHealthIssue';
                        this.services.postData(url, body).subscribe((obj: any) => {
                            if (obj.status = true) {
                                this.services.pushQuestionsList(this.selectedQuestions);
                                this.router.navigate(['paymentdetails']);
                            }
                        }, err => {
                            console.log(err)
                            this.toastr.error(err.message);
                        })
                    }
                }

            } else {
                this.toastr.error('Please select the mandatory fields.');
            }
        }
    }


    changeToDemography() {
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.addCartPlan(Object);
            this.router.navigate(['statementofbeliefs']);
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }

}
