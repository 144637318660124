import {ChangeDetectorRef, Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ToastrService} from 'ngx-toastr';
import {DatePipe, Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SharingService} from 'src/app/services/sharing.service';

@Component({
    selector: 'app-disclosure-1',
    // standalone: true,
    // imports: [],
    templateUrl: './disclosure-1.component.html',
    styleUrl: './disclosure-1.component.scss'
})
export class Disclosure1Component {
    companyId: any;
    agreementData: any[] = [];
    code: any;
    memberId: any = 0;
    documentId: any;
    pdfSrc: any | ArrayBuffer | null = null;
    role: string;
    sample: any;
    pdfViewer: any;
    pdfBytes: ArrayBuffer | null = null;
    dateFormat: any;
    dateTimeFormat: any;
    decryptedMemberId: any;
    decryptData: any;
    agencyName: any;
    route_company_name: any;
    isRiderAvailable: boolean = false
    member_document_id: string;
    mainMemberId: any;
    family_id: string;
    A2Ccode: string;
    agentInfo: any = null;
    loader: boolean = false;
    private shouldReload = true;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private deviceService: DeviceDetectorService,
        private service: SharingService,
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private location: Location,
        private cdr: ChangeDetectorRef
    ) {
        this.mainMemberId = this.route.snapshot.paramMap.get('id');
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        this.A2Ccode = this.route.snapshot.paramMap.get('code');
        localStorage.removeItem('from_discloser')

    }

    ngOnInit(): void {


        this.pdfViewer = document.querySelector('#pdfViewer');


        // let x= document.getElementById("footer-container");
        //   x?.classList.add("custom-footerclass")

        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;
                this.route_company_name = data.route_company;
                //  this.getDecryptMemberIdA2C();
                this.listEsignMemberDocumentA2C();
            }
        })

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
        }

    }

    getDecryptMemberIdA2C() {
        var url = 'DecryptMemberIdA2C/' + this.code;
        this.service.getData(url).subscribe((Object: any) => {

            this.decryptedMemberId = Object.data[0].member_id;
            this.decryptData = Object.data;
            localStorage.setItem("decrypted_member_id", Object.data[0].member_id);

            // this.listEsignMemberDocumentA2C(Object.data[0].member_id)
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }

    listEsignMemberDocumentA2C() {
        let body = {"member_id": this.mainMemberId, "category": "Disclosure1"}
        var url = 'ListEsignMemberDocumentA2C'
        this.service.postData(url, body).subscribe((Object: any) => {
            this.agreementData = Object.data;
            this.isRiderAvailable = Object.is_rider_available
            localStorage.setItem('isRiderAvailable', this.isRiderAvailable ? 'yes' : 'no')
            this.agreementData.forEach(element => {
                element.index = this.agreementData.indexOf(element) + 1;
            });

            this.member_document_id = this.agreementData[0].member_document_id;
            //  localStorage.setItem("A2c_familyId",this.decryptData[0].family_id)


            this.getUserInfo()

        }, err => {
            this.toastr.error(err.error.message);
        })
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.registerPdfSaveCallback()
        }, 300);

    }

    registerPdfSaveCallback() {
        const pdfViewerApplication = (window as any).PDFViewerApplication;

        if (pdfViewerApplication) {
            // Ensure that the viewer is loaded and the APIs are accessible
            if (pdfViewerApplication.pdfDocument) {
                // Do nothing if pdfDocument is already available (typically the case when the PDF is already rendered)
            } else {
                // This listens to an event which indicates that the PDF has been loaded into the viewer
                document.addEventListener('pdfloaded', () => this.registerPdfSaveCallback());
            }
        }
    }

    getUserInfo() {
        this.loader = true;
        var url = 'DownloadEsignMemberDocument/' + this.member_document_id
        this.service.downloadData(url).subscribe((obj: any) => {

            const reader = new FileReader();
            reader.readAsArrayBuffer(new Blob([obj], {type: obj.type})); // Change to readAsArrayBuffer
            reader.onloadend = () => {
                this.pdfSrc = URL.createObjectURL(new Blob([reader.result as ArrayBuffer])); // Use URL.createObjectURL to create a Blob URL for ngx-extended-pdf-viewer

                this.pdfBytes = reader.result as ArrayBuffer; // Save the ArrayBuffer for use with pdf-lib
                this.getFormFieldNames();
            }
            this.cdr.detectChanges()
            this.loader = false;
            this.registerPdfSaveCallback()

        }, err => {
            this.loader = false;
        })
    }

    async getFormFieldNames() {
        if (!this.pdfBytes) {
            console.error("PDF not loaded");
            return undefined;
        }


        // const pdfDoc = await PDFDocument.load(this.pdfBytes);
        // const form = pdfDoc.getForm();
        // const formFields = form.getFields();
        // const formFieldNames = formFields.map(field => field.getName());

        // console.log(formFieldNames);
        // return formFieldNames;
    }


    async export() {
        const pdfViewerApplication = (window as any).PDFViewerApplication;

        if (pdfViewerApplication && pdfViewerApplication.pdfDocument) {
            try {
                const pdfDocument = pdfViewerApplication.pdfDocument;
                // This saves the PDF as an ArrayBuffer
                const pdfData = await pdfDocument.saveDocument(pdfDocument.annotationStorage);

                // Convert the ArrayBuffer to a Blob
                const blob = new Blob([pdfData], {type: 'application/pdf'});
                //to download
                // FileSaver.saveAs(blob, "filled.pdf");
                let formData: FormData = new FormData();
                this.sample
                formData.append('member_id', this.mainMemberId);
                formData.append('category', "Disclosure1");
                formData.append('member_doccument', blob, '${this.sample.name}.pdf');
                formData.append('state_form_id', this.agreementData[0].state_form_id);

                var url = 'CreateEsignMemberDocumentA2C'
                this.service.postData(url, formData).subscribe((Object: any) => {
                    this.router.navigate(['/disclosure_two', this.mainMemberId, this.family_id]);
                    // if(this.A2Ccode !=undefined && this.A2Ccode !=null){
                    //   this.router.navigate([this.route_company_name+'/A2C/directagreement', this.mainMemberId, this.family_id, this.A2Ccode]);

                    // }else{
                    //   if (this.location.path().includes('d2c')) {
                    //   this.router.navigate([this.route_company_name+'/d2c/directagreement', this.mainMemberId, this.family_id]);
                    //   }
                    //   if (this.location.path().includes('acc')) {
                    //     this.router.navigate([this.route_company_name+'/acc/directagreement', this.mainMemberId, this.family_id]);
                    //     }

                    // }

                }, err => {
                    this.toastr.error(err.error.message);
                })

                var blobURL = URL.createObjectURL(blob);
                // this.pdfSrc = blobURL;


                // Clean up
                URL.revokeObjectURL(blobURL);
            } catch (error) {
                console.error("Error exporting PDF:", error);
            }
        }
    }

    // navigateTodownloadFile(item) {
    //   console.log(item);

    //   this.router.navigate(['ark/A2C/a2cenrollment/', this.code,'/downloadfile/'+this.agreementData[0].member_id]);

    // }
    onPrevious() {
        this.router.navigate(['/agrement', this.mainMemberId, this.family_id]);
        // if (this.A2Ccode != undefined && this.A2Ccode != null) {
        //   this.router.navigate([this.route_company_name + '/A2C/memberagreement', this.mainMemberId, this.family_id, this.A2Ccode]);
        // } else {
        //   if (this.location.path().includes('d2c')) {
        //   this.router.navigate([this.route_company_name + '/d2c/memberagreement', this.mainMemberId, this.family_id]);
        //   }
        //   if (this.location.path().includes('acc')) {
        //     this.router.navigate([this.route_company_name + '/acc/memberagreement', this.mainMemberId, this.family_id]);
        //     }
        // }
        localStorage.setItem('from_discloser', 'Beneficiary_form')
    }
}
