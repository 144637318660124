import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
// import { ArkserviceService } from '../service/arkservice.service';
import { DatePipe } from '@angular/common';
// import { isValidMobileNumber } from '../service/phone-number-validation';
import SignaturePad from 'signature_pad';
import { interval } from 'rxjs';
// import { isValidSSNNumber } from 'src/app/service/ssn-number-validation';
import { HttpClient } from '@angular/common/http';
import { isValidSSNNumber } from '../services/ssn-number-validation';
import { isValidMobileNumber } from '../services/phone-number-validation';
import { SharingService } from '../services/sharing.service';

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrl: './group-member.component.scss'
})
export class GroupMemberComponent implements OnInit {

  paramName: string | null;
  companyId: any;
  form: any;
  submitAttempt: boolean = false;
  number: any;
  maxDate: Date;
  minimumDate: Date;
  invalidDates: Date[];
  invalidDates1: Date[];
  usaStates: any;
  disabled: boolean = false;
  COBRAeffectivedate: boolean = true;
  waiverreasonedit: boolean = true;
  requestedeffective: boolean = true;
  employeinfo: boolean = false;
  spouseinfo: boolean = false;
  childinfo: boolean = false;
  displaymedicalplanAns: boolean = false;
  displaymedicarepartAns: boolean = false;
  displayqt1options: boolean = false;
  displayqtthreeoptions: boolean = false;
  displaydentalans: boolean = false;
  displaycoverageoffer: boolean = false;
  waivercoverdisplay: boolean = false;
  requestedeffectivedisplay: boolean = false;
  waiverpersonscovered: boolean = false;
  childAge: any;
  ageError: string;
  feetArray: any[] = [];
  inchArray: any[] = [];
  phoneError: any;
  objectData: any = {};
  spouseObj: any = {};
  member_personal_data: any = {};
  member_medications_prescribed: any = {};
  date = new Date();
  healthQuestions: any;
  disabledemployeeform: boolean = true;
  enrollment_other_description: boolean = true
  group_id: any;
  loader: boolean = false;
  companyaddress: any;
  qualifyinglifeeventdisplay: boolean = true;
  signaturedisabled: boolean = true;
  alphabets = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
  ]
  medicalHistoryInfo: Array<any> = [];
  firstElementWithError: any;
  image: any;
  spouseNumber: string;
  DecryptData: any;
  Age: number;
  applicantAge: string;
  spouseAge: string;
  onTabClickData: any = 'esignature';
  firstDiv: boolean = true;
  secondDiv: boolean = false;
  thirdDiv: boolean = false;
  uploadedFile: any;
  signature: any;
  message: any;
  signaturePad: SignaturePad;
  imageUrl: any;
  signatureVAlid: any;
  displayDateandTime: any;
  signatureImg: string;
  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  timevalue: any;
  est_date: any;
  spouseHeight: any;
  ipAddress: any;
  employeedetails: boolean = false;
  autosaveApplicantObj: any = {};
  applicantData: any = {};
  finalApplicantdata: any = {};
  phoneGroupControls: any[] = [];
  checkPhoneGroupStatus: any;
  family_id: any = '';
  member_staging_id: any = '';
  childage: string;

  apiurl: string;
  constructor(private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
    private service: SharingService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.feetArray = this.service.feetArray;
    this.inchArray = this.service.inchArray;
    this.paramName = this.route.snapshot.paramMap.get('id');


  }

  ngOnInit(): void {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    interval(1 * 60).subscribe(t => {
      const _date = new Date();
      this.est_date = _date.toLocaleString("en-US", { timeZone: "America/New_York" });
    })
    localStorage.removeItem('name')
    let today = new Date();
    this.maxDate = new Date();
    let invalidDate1 = new Date();
    invalidDate1.setDate(today.getDate() + 3);
    this.invalidDates1 = [today, invalidDate1];
    this.minimumDate = new Date();
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyId = data.company_id;
        this.getUSstates();
        this.addSpouse();
        this.getHealthQuestionsList();
        if (window.location.href.includes('EmployeeEnrollment')) {
          this.getGroupInfo();
        } else if (window.location.href.includes('MemberEnrollment')) {
          this.getGroupInfo();
        }
        else {
          this.getUserInfo();
        }

      }
    })
    this.form = this.formBuilder.group({
      companyname: [''],
      companyaddress: [''],
      firstname: ['', Validators.required],
      middlename: [''],
      lastname: ['', Validators.required],
      ssn: ['', Validators.compose([isValidSSNNumber])],
      gender: [''],
      dob: ['', Validators.required],
      age: [''],
      dateemployed: [''],
      avghrworkedperweek: [''],
      jobtitle: [''],
      email: ['', Validators.compose([Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      homeaddress: [''],
      homecity: [''],
      homestate: [''],
      homezip: ['', [Validators.pattern('^[0-9]{5}$')]],
      checkBox: [''],
      mailingaddress: [''],
      mailingcity: [''],
      mailingstate: [''],
      mailingzip: ['', [Validators.pattern('^[0-9]{5}$')]],
      phoneformgroup: this.formBuilder.group({
        homephone: ['', isValidMobileNumber],
        workphone: ['', isValidMobileNumber],
        cellphone: ['', isValidMobileNumber],
      }),

      besttimetocall: [''],
      maritalstatus: [''],
      statuscheck: [''],
      cobraeffectivedate: [''],
      employeestatus: [''],
      earningsbasis: [''],
      beneficiary_firstname: [''],
      beneficiary_lastname: [''],
      beneficiary_middlename: [''],
      beneficiary_relationship: [''],
      beneficiary_address1: [''],
      beneficiary_city: [''],
      beneficiary_state: [''],
      beneficiary_zip: ['', [Validators.pattern('^[0-9]{5}$')]],
      newenrollment: [''],
      lifeevent: [''],
      lifeeventdate: [''],
      waivercoverage: [''],
      waivercoverageOther: [''],
      signature: [''],
      signdate: [''],
      printedsignature: [''],
      employeddate: [''],
      requestdate: [''],
      personscovered: [''],
      empfirstname: [''],
      emplastname: [''],
      empssn: ['', isValidSSNNumber],
      empgender: [''],
      empdob: [''],
      emptobaccouse: [''],
      empfeet: [''],
      empinch: [''],
      empweight: ['', Validators.max(600)],
      empmotercycle: [''],
      empmovingviolation: [''],
      empduiowi: [''],
      waiver_signature: [''],
      applicant_signature: [''],
      group_medical_plan: [''],
      dental_coverage_list_enrolling: [''],
      dental_plan: [''],
      date_of_event: [''],
      vision_coverage_list_enrolling: [''],
      special_enrollment_reason_description: [''],
      covered_under_medicare_description: [''],
      current_medical_plan_remain_active_description: [''],
      covered_under_medicare_active: [''],
      active_carrier_id: [''],
      termination_date: [''],
      spousefirstname: [''],
      spouselastname: [''],
      spouseemail: ['', Validators.compose([Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      spousephone: ['', isValidMobileNumber],
      spousessn: ['', isValidSSNNumber],
      spousegender: [''],
      spousedob: [''],
      spouseage: [''],
      spousetobaccouse: [''],
      spouseduiowi: [''],
      spousemovingviolation: [''],
      spousemotercycle: [''],
      spouseweight: ['', Validators.max(600)],
      spousefeet: [''],
      spouseinch: [''],
      child_Array: this.formBuilder.array([]),
      member_medications: this.formBuilder.array([]),
      currentmedicalplan: [''],
      medicalpart: [''],
      questionone: [''],
      empquestiontwo: [''],
      empquestiontwofuture: [''],
      questionthree: [''],
      questionthreeduedate: [''],
      questionfour: [''],
      changedental: [''],
      coverageoffered: [''],
      enrollingemployer: [''],
      enrollingemployeroptionb: [''],
      anticipated: [''],
      birthsexpected: [''],
      anticipating: [''],
      coverageremain: [''],
      covercheck: [''],
      rightscheck: [false],
      termscheck: [false],
      enrollment_other_description: [''],
      loss_of_coverage: [''],
      childage: [''],
    });

    this.form.get("personscovered").valueChanges.subscribe(value => {
      if (value == "Employee Spouse" || value == "Family: Employee, Spouse, & Child(ren)") {
        this.form.get('spousefirstname').setValidators([Validators.required]);
        this.form.get('spouselastname').setValidators([Validators.required]);
        this.form.get('spousedob').setValidators([Validators.required]);
      }
      else {
        this.form.get('spousefirstname').clearValidators();
        this.form.get('spouselastname').clearValidators();
        this.form.get('spousedob').clearValidators();
        this.form.get("spousefirstname")
          .updateValueAndValidity({ emitEvent: false });
        this.form.get("spouselastname")
          .updateValueAndValidity({ emitEvent: false });
        this.form.get("spousedob")
          .updateValueAndValidity({ emitEvent: false });
      }
    });
    // this.form.get("newenrollment").valueChanges.subscribe(value => {
    //   if (value == "Waiver of Coverage") {
    //     this.form.get('empmotercycle').clearValidators();
    //     this.form.get('empmovingviolation').clearValidators();
    //     this.form.get('empduiowi').clearValidators();
    //     this.form.get("empmotercycle")
    //       .updateValueAndValidity({ emitEvent: false });
    //     this.form.get("empmovingviolation")
    //       .updateValueAndValidity({ emitEvent: false });
    //     this.form.get("empduiowi")
    //       .updateValueAndValidity({ emitEvent: false });
    //   }

    // });
    // this.form.get("personscovered").valueChanges.subscribe(value => {
    //   if (value == "Employee Spouse" || value == "Family: Employee, Spouse, & Child(ren)"
    //     || value == "Employee Child(ren)" || value == "Employee Only") {
    //     this.form.get('empmotercycle').setValidators([Validators.required]);
    //     this.form.get('empmovingviolation').setValidators([Validators.required]);
    //     this.form.get('empduiowi').setValidators([Validators.required]);

    //     this.form.get("empmotercycle")
    //       .updateValueAndValidity({ emitEvent: false });
    //     this.form.get("empmovingviolation")
    //       .updateValueAndValidity({ emitEvent: false });
    //     this.form.get("empduiowi")
    //       .updateValueAndValidity({ emitEvent: false });
    //   }
    // });
    this.form.get("personscovered").valueChanges.subscribe(value => {
      if (value == "Employee Spouse" || value == "Family: Employee, Spouse, & Child(ren)"
        || value == "Employee Child(ren)" || value == "Employee Only") {
        setTimeout(() => {
          this.dobClearValidators();
        }, 10);
      }
      else {
        setTimeout(() => {
          this.dobSetValidators()
        }, 10);
      }
    });
  }
  dobClearValidators() {
    for (var i = 0; i < this.child_Array().length; i++) {
      this.child_Array().controls[i]['controls'].childFirstName.clearValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childFirstName
        .updateValueAndValidity({ emitEvent: false });
      this.child_Array().controls[i]['controls'].childLastName.clearValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childLastName
        .updateValueAndValidity({ emitEvent: false });
      this.child_Array().controls[i]['controls'].childDob.clearValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childDob
        .updateValueAndValidity({ emitEvent: false });
    }
  }
  dobSetValidators() {
    for (var i = 0; i < this.child_Array().length; i++) {
      this.child_Array().controls[i]['controls'].childFirstName.setValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childFirstName
        .updateValueAndValidity({ emitEvent: false });
      this.child_Array().controls[i]['controls'].childLastName.setValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childLastName
        .updateValueAndValidity({ emitEvent: false });
      this.child_Array().controls[i]['controls'].childDob.setValidators([Validators.required]);
      this.child_Array().controls[i]['controls'].childDob
        .updateValueAndValidity({ emitEvent: false });
    }
  }

  getHealthQuestionsList() {
    let url = 'ListHealthQuestions';
    let body = { company_id: this.companyId }
    this.service.postData(url, body).subscribe((res: any) => {
      if (res.status == true) {
        this.healthQuestions = res.data;
        for (var i = 0; i < this.healthQuestions.length; i++) {
          for (var j = 0; j < this.healthQuestions[i].health_issue.length; j++) {
            if (this.healthQuestions[i].health_issue[j].health_issue_options != undefined) {
              for (var k = 0; k < this.healthQuestions[i].health_issue[j].health_issue_options.length; k++) {
                if (this.healthQuestions[i].health_issue[j].health_issue_options[k].child != undefined) {
                  for (var s = 0; s < this.healthQuestions[i].health_issue[j].health_issue_options[k].child.length; s++) {
                    this.healthQuestions[i].health_issue[j].health_issue_options[k].child[s].disabled = true;
                  }
                }
              }
            }
          }
        }
      }

    }, err => {
      this.loader = false;
      this.toastr.error(err.error.message);
      console.log(err);
    })
  }


  newChild(): FormGroup {
    return this.formBuilder.group({
      childFirstName: [''],
      childLastName: [''],
      childDob: [''],
      childSsn: ['', isValidSSNNumber],
      childGender: [''],
      childTobaccouse: [''],
      childEmail: ['', Validators.compose([Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      childPhone: ['', isValidMobileNumber],
      childage: [''],
    });
  }
  newEmployee1(): FormGroup {
    return this.formBuilder.group({
      empquestion: [''],
      person: [''],
      conditiondiagnosis: [''],
      datestreated: [''],
      dosage: [''],
      datelasttaken: [''],
      prognosis: ['']
    })
  }
  child_Array(): FormArray {
    return this.form.get("child_Array") as FormArray
  }
  addNewForm() {
    this.child_Array().push(this.newChild());

  }
  removeEmployee(empIndex) {//remove child Array
    this.child_Array().controls[empIndex]['controls'].childDob.clearValidators([Validators.required]);
    this.child_Array().controls[empIndex]['controls'].childDob
      .updateValueAndValidity({ emitEvent: false });
    this.child_Array().removeAt(empIndex);
  }

  member_medications(): FormArray {
    return this.form.get("member_medications") as FormArray
  }
  removeSpouse(empIndex1) {
    this.member_medications().removeAt(empIndex1);
  }
  addSpouse() {//add new spouse
    //   if (this.spouse_Array().length <= 0) { // future use
    this.member_medications().push(this.newEmployee1());
    //}
  }
  getUSstates() {
    var url = 'GroupListSalableUsaStates/'+this.companyId;
    this.service.getData(url).subscribe((Object: any) => {
      if (Object !== null) {
        this.usaStates = Object.data;
      }
    }, err => {
      console.log(err);
    })
  }

  getGroupInfo() {
    var url = 'GroupInfo/' + this.paramName;
    this.service.getData(url).subscribe((Object: any) => {
      if (Object != null) {
        var data = Object.data;
        if (Object.data[0].group_id != null) {
          this.group_id = Object.data[0].group_id;
        }
        if (data != null) {
          if (data[0].address1 != null && data[0].city) {
            this.companyaddress = data[0].address1 + ', ' + data[0].city + ', ' + data[0].state + ', ' + data[0].zip;
            this.form.controls.companyaddress.setValue(this.companyaddress);
          }
          if (data[0].name != null) {
            this.form.controls.companyname.setValue(data[0].name);
          }
        }
        if (Object.group_additional_info.length > 0) {
          if (Object.group_additional_info[0].logo != null) {
            this.image = Object.group_additional_info[0].logo;
          }
          this.service.getGroupLogo(this.image);
        }
      }
    }, err => {
      console.log(err);
      this.toastr.error(err.error.Message);
    })
  }

  getUserInfo() {
    let body = { type: "decrypt", encrypted_user_id: this.paramName }
    var url = 'DecryptUserInfo'
    this.service.postData(url, body).subscribe((Object: any) => {
      if (Object != null) {
        this.DecryptData = Object.user_id;
      }
    }, err => {
      this.toastr.error(err.error.message);
    })
  }

  onSearchChange(value) {
    this.form.controls.empdob.setValue(this.datePipe.transform(this.form.value.dob, "MM/dd/yyyy"));
    this.autosaveApplicantFields();
  }
  qualifyingdate(value) {
    this.form.controls.signdate.setValue(this.datePipe.transform(this.form.value.lifeeventdate, "MM/dd/yyyy"));
    this.autosaveApplicantFields();
  }
  changgender(value) {
    this.form.controls.empgender.setValue(this.form.value.gender);
  }
  changeemployedate() {
    this.form.controls.employeddate.setValue(this.datePipe.transform(this.form.value.dateemployed, "MM/dd/yyyy"));
    this.autosaveApplicantFields();

  }
  primarytoempChange(value) {
    if (this.form.value.firstname != '') {
      if (this.form.get("personscovered").value == '') {
        this.form.get('personscovered').setValue('Employee Only');
      }
      this.employeinfo = true;
    }
    if (this.form.value) {
      this.form.patchValue({
        empfirstname: this.form.value.firstname,
        emplastname: this.form.value.lastname,
        empssn: this.form.value.ssn,
      })
    }
  }

  InputChange(ControlName) {// home address same as mailing address
    var ControlValue = this.form.controls[ControlName].value
    this.checkBoXChange(this.form.value.checkBox == true)
  }
  checkBoXChange(value) {// home address same as mailing address
    this.disabled = this.form.value.checkBox ? true : false;
    if (this.form.value.checkBox) {
      this.form.patchValue({
        mailingaddress: this.form.value.homeaddress,
        mailingcity: this.form.value.homecity,
        mailingstate: this.form.value.homestate,
        mailingzip: this.form.value.homezip
      })
    }
    else {
      this.form.patchValue({
        mailingaddress: '',
        mailingcity: '',
        mailingstate: '',
        mailingzip: '',
      })
    }
  }

  medicalHistoryDetails(e, data, index, el) {
    let obj = {
      health_id: '',
      health_issue_id: ''
    }
    if (index == '3') {
      if (el == 'Yes') {
        this.employeedetails = true;
      } else {
        this.employeedetails = false;
      }
    }
    data.health_issue.forEach((element, i) => {
      if (element.health_issue_id == data.selected_val) {
        obj.health_id = element.health_id;
        obj.health_issue_id = element.health_issue_id;
      } else {
        if (this.medicalHistoryInfo.length > 0) {
          let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_id == element.health_id)
          if (index > -1)
            this.medicalHistoryInfo = this.medicalHistoryInfo.filter(({ health_id }) => health_id !== element.health_id);
        }
      }
    });

    if (this.medicalHistoryInfo.length > 0) {
      let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_id == obj.health_id)
      if (index > -1) {
        this.medicalHistoryInfo.splice(index, 1);
        this.medicalHistoryInfo.push(obj);
      }
      else {
        this.medicalHistoryInfo.push(obj);
      }
    }
    else {
      this.medicalHistoryInfo.push(obj);
    }

    this.autosaveApplicantFields();

  }

  medicalHistorySubQues(e, data) {
    let obj = {
      health_id: data.health_id,
      health_issue_id: Number(e.target.value),
      id: data.health_issue_id
    }


    if (this.medicalHistoryInfo.length > 0) {
      let index = this.medicalHistoryInfo.findIndex((el: any) => el.id == obj.id)
      if (index > -1) {
        this.medicalHistoryInfo.splice(index, 1);
        this.medicalHistoryInfo.push(obj)
      } else {
        this.medicalHistoryInfo.push(obj);
      }
    } else {
      this.medicalHistoryInfo.push(obj);
    }
    this.autosaveApplicantFields();
  }

  medicalHealthIssueOptions(e, data, option) {
    let obj = {
      health_id: data.health_id,
      health_issue_id: Number(e.target.value),
      id: option.id
    }
    if (e.target.checked) {
      this.medicalHistoryInfo.push(obj);
      for (var i = 0; i < this.healthQuestions.length; i++) {
        for (var j = 0; j < this.healthQuestions[i].health_issue.length; j++) {
          if (this.healthQuestions[i].health_issue[j].health_issue_options != undefined) {
            for (var k = 0; k < this.healthQuestions[i].health_issue[j].health_issue_options.length; k++) {
              if (this.healthQuestions[i].health_issue[j].health_issue_options[k].child != undefined) {
                for (var s = 0; s < this.healthQuestions[i].health_issue[j].health_issue_options[k].child.length; s++) {
                  if (this.healthQuestions[i].health_issue[j].health_issue_options[k].id == option.id) {
                    this.healthQuestions[i].health_issue[j].health_issue_options[k].child[s].disabled = false;
                  }
                }
              }
            }


          }

        }

      }
    }
    else {
      let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_issue_id == obj.health_issue_id)
      if (index > -1) {
        this.medicalHistoryInfo = this.medicalHistoryInfo.filter(({ id }) => id !== obj.health_issue_id);
      }
      for (var i = 0; i < this.healthQuestions.length; i++) {
        for (var j = 0; j < this.healthQuestions[i].health_issue.length; j++) {
          if (this.healthQuestions[i].health_issue[j].health_issue_options != undefined) {
            for (var k = 0; k < this.healthQuestions[i].health_issue[j].health_issue_options.length; k++) {
              if (this.healthQuestions[i].health_issue[j].health_issue_options[k].child != undefined) {
                for (var s = 0; s < this.healthQuestions[i].health_issue[j].health_issue_options[k].child.length; s++) {
                  if (this.healthQuestions[i].health_issue[j].health_issue_options[k].id == option.id) {
                    this.healthQuestions[i].health_issue[j].health_issue_options[k].child[s].disabled = true;
                  }
                }
              }
            }
          }

        }

      }
    }
    this.autosaveApplicantFields();
  }

  medicalHealthIssueOptionsChild(e, data, option) {
    let obj = {
      health_id: data.health_id,
      health_issue_id: Number(e.target.value),
      id: option.id
    }
    if (e.target.checked) {
      this.medicalHistoryInfo.push(obj);

    }
    else {
      let index = this.medicalHistoryInfo.findIndex((el: any) => el.health_issue_id == obj.health_issue_id)
      if (index > -1) {
        this.medicalHistoryInfo.splice(index, 1);
      }
    }
    this.autosaveApplicantFields();
  }
  onFocusoutQtns() {
    this.autosaveApplicantFields();
  }
  onSelectQtns() {
    this.autosaveApplicantFields();
  }
  healthOptionSubQues(e, data, parent) {
    let obj = {
      health_id: data.health_id,
      health_issue_id: Number(e.target.value),
      id: parent.id
    }

    let index = this.medicalHistoryInfo.findIndex((el: any) => el.id == obj.id)
    if (index > -1) {
      this.medicalHistoryInfo = this.medicalHistoryInfo.filter(({ id }) => id !== obj.id);
    }
    this.medicalHistoryInfo.push(obj)
    this.autosaveApplicantFields();
  }

  healthOptionDate(val, parent, option) {
    val = this.datePipe.transform(val, "yyyy-MM-dd")
    let obj = {
      health_id: parent.health_id,
      health_issue_id: option.id,
      health_issue_description: val
    }
    this.medicalHistoryInfo.push(obj);
    this.autosaveApplicantFields();
  }

  changeCheck(evt) { /// cobra effective date display

    var target = evt.target;
    if (target.checked && target.value == 'COBRA') {
      this.COBRAeffectivedate = false;
    } else {
      this.COBRAeffectivedate = true;
      this.form.controls.cobraeffectivedate.setValue('');
    }
  }

  waiverofCoverage(evt) { /// waiverofcoverage

    var target = evt.target;
    if (target.checked && target.value == 'Waiver of Coverage') {
      this.waiverreasonedit = false;
      this.waivercoverdisplay = true;
      this.requestedeffective = true;
      this.qualifyinglifeeventdisplay = true;
      this.enrollment_other_description = true;
      this.form.controls.enrollment_other_description.setValue('');
      // below section desibled conditions
      this.waiverpersonscovered = true;
      this.form.get('personscovered').setValue('');
    } else if (target.checked && target.value == 'New-Hire' || target.value == 'Qualifying Life Event' || target.value == 'Re-hire') {
      if (target.value == 'Qualifying Life Event') {
        this.qualifyinglifeeventdisplay = false;
      }
      this.requestedeffective = false;
      this.requestedeffectivedisplay = true;
      this.waiverreasonedit = true;
      this.waivercoverdisplay = false;
      this.enrollment_other_description = true;
      this.form.controls.waivercoverageOther.setValue('');
      this.form.controls.waivercoverage.setValue('');
      this.form.controls.enrollment_other_description.setValue('');
      // below section desibled conditions
      this.waiverpersonscovered = false;

    } else if (target.checked && target.value == 'Other') {
      this.enrollment_other_description = false;
      this.requestedeffective = true;
      this.waiverreasonedit = true;
      this.waiverpersonscovered = true;
    }
    else {
      this.requestedeffective = true;
      this.requestedeffectivedisplay = false;
      this.waiverreasonedit = true;
      this.waivercoverdisplay = false;
      // below section desibled conditions
      this.waiverpersonscovered = false;

      this.qualifyinglifeeventdisplay = true;
      this.enrollment_other_description = true;
      this.form.controls.waivercoverageOther.setValue('');
      this.form.controls.waivercoverage.setValue('');
      this.form.controls.lifeevent.setValue('');

      this.form.controls.requestdate.setValue('');
      this.form.controls.group_medical_plan.setValue('');
      this.form.controls.changedental.setValue('');
      this.form.controls.dental_coverage_list_enrolling.setValue('');
      this.form.controls.dental_plan.setValue('');
      this.form.controls.coverageoffered.setValue('');
      this.form.controls.vision_coverage_list_enrolling.setValue('');
      this.form.controls.enrollingemployer.setValue('');
      this.form.controls.date_of_event.setValue('');
      this.form.controls.enrollingemployeroptionb.setValue('');
      this.form.controls.special_enrollment_reason_description.setValue('');
      this.form.controls.enrollment_other_description.setValue('');
      this.form.controls.termination_date.setValue('');
    }
    this.autosaveApplicantFields();
  }
  changePersons(evt) { /// PERSONS TO BE COVERED

    var target = evt.target;
    if (target.checked) {
      this.employeinfo = true;
      if (target.value == 'Employee Only') {
        this.spouseinfo = false;
        this.childinfo = false;
        this.clearChildControls();
        this.resetSpouseFields();
      }
      else if (target.value == 'Employee Spouse') {
        this.spouseinfo = true;
        this.childinfo = false;
        this.clearChildControls();
      }
      else if (target.value == 'Employee Child(ren)') {
        this.resetSpouseFields();
        this.spouseinfo = false;
        this.childinfo = true;
        if (this.form.value.child_Array.length == 0) {
          this.child_Array().push(this.newChild());
        }
      }
      else if (target.value == 'Family: Employee, Spouse, & Child(ren)') {
        this.dobSetValidators();
        this.spouseinfo = true;
        this.childinfo = true;
        if (this.form.value.child_Array.length == 0) {
          this.child_Array().push(this.newChild());
        }
      }

    } else {

    }
  }
  clearChildControls() {
    let arr = <FormArray>this.form.controls['child_Array'];
    arr.clear();
  }
  resetSpouseFields() {
    this.form.get('spousefirstname').reset();
    this.form.get('spouselastname').reset();
    this.form.get('spousessn').reset();
    this.form.get('spousegender').reset();
    this.form.get('spousedob').reset();
    this.form.get('spousetobaccouse').reset();
    this.form.get('spouseemail').reset();
    this.form.get('spousephone').reset();
  }
  medicalplanCheck(evt) {
    //medical plan check
    var target = evt.target;
    if (target.checked && target.value == 'Yes') {
      this.displaymedicalplanAns = true;
    } else {
      this.displaymedicalplanAns = false;
      this.form.controls.current_medical_plan_remain_active_description.setValue('');
      this.form.controls.active_carrier_id.setValue('');
    }
    this.autosaveApplicantFields();
  }
  medicalpartCheck(evt) {
    var target = evt.target;
    if (target.checked && target.value == 'Yes') {
      this.displaymedicarepartAns = true;
    } else {
      this.displaymedicarepartAns = false;
      this.form.controls.covered_under_medicare_description.setValue('');
      this.form.controls.covered_under_medicare_active.setValue('');
    }
    this.autosaveApplicantFields();
  }
  questionsCheck(evt) {
    var target = evt.target;
    if (target.checked && target.value == 'Yes') {
      this.displayqt1options = true;
      //  this.spouse_Array().push(this.newEmployee1());
    } else {
      this.displayqt1options = false;
    }
  }

  questionsthreeCheck(evt) {
    var target = evt.target;
    if (target.checked && target.value == 'Yes') {

      this.displayqtthreeoptions = true;
    } else {
      this.displayqtthreeoptions = false;
    }
  }

  changedentalcoverage(evt) {

    var target = evt.target;
    if (target.checked && target.value == 'Yes') {
      this.displaydentalans = true;
    } else {
      this.displaydentalans = false;
    }
    this.autosaveApplicantFields()
  }
  onSelectterminationdate() {
    this.autosaveApplicantFields()
  }
  onSelectdateevent() {
    this.autosaveApplicantFields()
  }
  changecoverageoffered(evt) {

    var target = evt.target;
    if (target.checked && target.value == 'Yes') {
      this.displaycoverageoffer = true;
    } else {
      this.displaycoverageoffer = false;
    }
    this.autosaveApplicantFields();
  }
  phonenumbervalidation(value) {
    if (value != '') {
      this.phoneError = ''
    }

  }
  signatureChange() {
    this.form.controls.printedsignature.setValue(this.form.value.waiver_signature);
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    this.firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(this.firstElementWithError);
  }
  onSelectTime($event) {
    let hour = new Date($event).getHours();
    let min = new Date($event).getMinutes();
    this.timevalue = `${hour}:${min}`;
    this.form.controls['besttimetocall'].setValue(this.timevalue);
    this.autosaveApplicantFields();
  }
  // createEnrollment(values) {
  //   this.submitAttempt = true;

  //   if (this.signature != undefined && this.signature != '') {
  //     this.finalSubmit(values)
  //     this.uploadedFile = '';
  //   }
  //   else if (this.uploadedFile != undefined && this.uploadedFile != '') {
  //     this.finalSubmit(values);
  //     this.form.controls.enterText.setValue('');
  //   }
  //   else if (this.signatureVAlid == 'signatureValid' && this.signaturePad.toData().length > 0) {
  //     this.finalSubmit(values)
  //   }
  //   else {
  //     this.message = "Signature is required";
  //   }
  // }  need for future
  finalSubmit(values) {
    this.submitAttempt = true;
    this.dobSetValidators();
    if (this.form.valid) {
      if (values.age >= 18) {
        this.loader = true;
        this.objectData.member_personal_data = new Array<any>();
        this.objectData.member_medications_prescribed = new Array<any>();
        this.objectData.member_healthissue_data = [...this.medicalHistoryInfo];
        this.member_personal_data['relation'] = "Primary";
        this.member_personal_data.firstname = values.firstname;
        this.member_personal_data.lastname = values.lastname;
        this.member_personal_data.middlename = values.middlename;
        this.member_personal_data.ssn = values.ssn;
        this.member_personal_data.dob = this.datePipe.transform(values.dob, "yyyy-MM-dd");
        this.member_personal_data.gender = values.gender;
        this.member_personal_data.date_employed_full_time = this.datePipe.transform(values.dateemployed, "yyyy-MM-dd");
        this.member_personal_data.hours_worked_per_week = values.avghrworkedperweek;
        this.member_personal_data.job_title = values.jobtitle;
        this.member_personal_data.address1 = values.homeaddress;
        this.member_personal_data.address2 = '';
        this.member_personal_data.city = values.homecity;
        this.member_personal_data.state = values.homestate;
        this.member_personal_data.zip = values.homezip;
        this.member_personal_data.mailing_address = values.mailingaddress;
        this.member_personal_data.mailing_city = values.mailingcity;
        this.member_personal_data.mailing_state = values.mailingstate;
        this.member_personal_data.mailing_zip = values.mailingzip;
        this.member_personal_data.mobile = values.phoneformgroup.cellphone;
        this.member_personal_data.phone = values.phoneformgroup.homephone;
        this.member_personal_data.work_phono = values.phoneformgroup.workphone;
        this.member_personal_data.best_time_to_call = values.besttimetocall;
        if (values.email == '') {
          var date = this.datePipe.transform(values.dob, "MMddyyyy");
          this.member_personal_data.email = values.firstname + values.lastname + date + '@yopmail.com'
        } else {
          this.member_personal_data.email = values.email;
        }
        this.member_personal_data.marital_status = values.maritalstatus;
        this.member_personal_data.types_of_employment = values.statuscheck;
        this.member_personal_data.cobra_effective_date = this.datePipe.transform(values.cobraeffectivedate, "yyyy-MM-dd");
        this.member_personal_data.employee_status = values.employeestatus;
        this.member_personal_data.earnings_basis = values.earningsbasis;
        this.member_personal_data.beneficiary_firstname = values.beneficiary_firstname;
        this.member_personal_data.beneficiary_lastname = values.beneficiary_lastname;
        this.member_personal_data.beneficiary_middlename = values.beneficiary_middlename;
        this.member_personal_data.beneficiary_relationship = values.beneficiary_relationship;
        this.member_personal_data.beneficiary_address = values.beneficiary_address1;
        this.member_personal_data.beneficiary_city = values.beneficiary_city;
        this.member_personal_data.beneficiary_state = values.beneficiary_state;
        this.member_personal_data.beneficiary_zip = values.beneficiary_zip;
        this.member_personal_data.enrollment = values.newenrollment;
        this.member_personal_data.enrollment_qle_description = values.lifeevent;
        this.member_personal_data.enrollment_other_description = values.enrollment_other_description;
        this.member_personal_data.enrollment_date = this.datePipe.transform(values.lifeeventdate, "yyyy-MM-dd");
        this.member_personal_data.waiver_reason = values.waivercoverage;
        this.member_personal_data.waiver_description = values.waivercoverageOther;
        this.member_personal_data.requested_effective_date = this.datePipe.transform(values.requestdate, "yyyy-MM-dd");
        this.member_personal_data.group_medical_plan = values.group_medical_plan;
        this.member_personal_data.dental_coverage = values.changedental;
        this.member_personal_data.dental_coverage_list_enrolling = values.dental_coverage_list_enrolling;
        this.member_personal_data.dental_plan = values.dental_plan;
        this.member_personal_data.vision_coverage = values.coverageoffered;
        this.member_personal_data.vision_coverage_list_enrolling = values.vision_coverage_list_enrolling;
        this.member_personal_data.enrollment_period = values.enrollingemployer;
        this.member_personal_data.date_of_event = this.datePipe.transform(values.date_of_event, "yyyy-MM-dd");
        this.member_personal_data.special_enrollment_reason = values.enrollingemployeroptionb;
        this.member_personal_data.loss_of_coverage = values.loss_of_coverage;
        this.member_personal_data.special_enrollment_reason_other_description = values.special_enrollment_reason_description;
        this.member_personal_data.termination_date = this.datePipe.transform(values.termination_date, "yyyy-MM-dd");
        if (values.empfeet != '') {
          this.member_personal_data.height = values.empfeet + '.' + values.empinch;
        } else {
          this.member_personal_data.height = '';
        }
        this.member_personal_data.weight = values.empweight;
        this.member_personal_data.tobacco = values.emptobaccouse;
        this.member_personal_data.own_motorcycle = values.empmotercycle;
        this.member_personal_data.moving_violation = values.empmovingviolation;
        this.member_personal_data.dui_owi = values.empduiowi;
        this.member_personal_data.waiver_signature = values.waiver_signature;
        this.member_personal_data.applicant_signature = values.applicant_signature;
        this.member_personal_data.persons_covered = values.personscovered;
        this.member_personal_data.current_medical_plan_remain_active = values.currentmedicalplan;
        this.member_personal_data.current_medical_plan_remain_active_description = values.current_medical_plan_remain_active_description;
        this.member_personal_data.current_medical_plan_remain_active_carrier_id = values.active_carrier_id;
        this.member_personal_data.covered_under_medicare = values.medicalpart;
        this.member_personal_data.covered_under_medicare_description = values.covered_under_medicare_description;
        this.member_personal_data.covered_under_medicare_active = values.covered_under_medicare_active;
        this.objectData.member_personal_data.push(this.member_personal_data);
        this.objectData.member_personal_data = [...new Map(this.objectData.member_personal_data.map(obj => [JSON.stringify(obj), obj])).values()];

        //==spouse and child==
        if (values.personscovered == 'Employee Spouse' || values.personscovered == 'Family: Employee, Spouse, & Child(ren)') {
          if (values.spousefeet != '') {
            this.spouseHeight = values.spousefeet + "." + values.spouseinch;
          }
          else {
            this.spouseHeight = '';
          }
          this.spouseObj = {
            ['relation']: "Spouse",
            firstname: values.spousefirstname,
            lastname: values.spouselastname,
            ssn: values.spousessn,
            dob: this.datePipe.transform(values.spousedob, "yyyy-MM-dd"),
            gender: values.spousegender,
            tobacco: values.spousetobaccouse,
            height: this.spouseHeight,
            weight: values.spouseweight,
            own_motorcycle: values.spousemotercycle,
            moving_violation: values.spousemovingviolation,
            dui_owi: values.spouseduiowi,
            email: values.spouseemail,
            phone: values.spousephone
          }
          this.objectData.member_personal_data.push(this.spouseObj);
          this.objectData.member_personal_data = [...new Map(this.objectData.member_personal_data.map(obj => [JSON.stringify(obj), obj])).values()];

        }
        for (var i = 0; i < values.child_Array.length; i++) {
          this.member_personal_data = {};
          this.member_personal_data['relation'] = "Child";
          this.member_personal_data.firstname = values.child_Array[i].childFirstName;
          this.member_personal_data.lastname = values.child_Array[i].childLastName;
          this.member_personal_data.ssn = values.child_Array[i].childSsn.replace(/-/g, "");
          this.member_personal_data.dob = this.datePipe.transform(values.child_Array[i].childDob, "yyyy-MM-dd");
          this.member_personal_data.gender = values.child_Array[i].childGender;
          this.member_personal_data.tobacco = values.child_Array[i].childTobaccouse;
          this.member_personal_data.email = values.child_Array[i].childEmail;
          this.member_personal_data.phone = values.child_Array[i].childPhone;
          this.objectData.member_personal_data.push(this.member_personal_data);
          this.objectData.member_personal_data = [...new Map(this.objectData.member_personal_data.map(obj => [JSON.stringify(obj), obj])).values()];

        }
        for (var j = 0; j < values.member_medications.length; j++) {
          this.member_medications_prescribed = {};
          this.member_medications_prescribed.question = values.member_medications[j].empquestion;
          this.member_medications_prescribed.person = values.member_medications[j].person;
          this.member_medications_prescribed.condition_diagnosis = values.member_medications[j].conditiondiagnosis;
          this.member_medications_prescribed.date_treated = this.datePipe.transform(values.member_medications[j].datestreated, "yyyy-MM-dd");
          this.member_medications_prescribed.treatment_including_medications_dosage = values.member_medications[j].dosage;
          this.member_medications_prescribed.date_last_taken = this.datePipe.transform(values.member_medications[j].datelasttaken, "yyyy-MM-dd");
          this.member_medications_prescribed.prognosis = values.member_medications[j].prognosis;
          this.objectData.member_medications_prescribed.push(this.member_medications_prescribed);
        }
        this.objectData.company_id = this.companyId;
        this.objectData.group_id = '' + this.group_id;
        this.objectData.status = 1;
        var finalObject: any = {}
        finalObject = this.objectData;
        // <!-----------member_personal_data-------->
        let formData: FormData = new FormData();
        formData.append('company_id', this.companyId);
        formData.append('group_id', this.group_id);
        formData.append('status', '1');
        finalObject.member_personal_data.forEach(function (object) {
          for (let key in object) {
            if (object[key] == "null" || object[key] == null || object[key] == 'undefined')
              object[key] = '';
          }
        });
        finalObject.member_healthissue_data.forEach(function (object) {
          for (let key in object) {
            if (object[key] == "null" || object[key] == null || object[key] == 'undefined')
              object[key] = '';
          }
        });
        finalObject.member_medications_prescribed.forEach(function (object) {
          for (let key in object) {
            if (object[key] == "null" || object[key] == null || object[key] == 'undefined')
              object[key] = '';
          }
        });


        if (finalObject.member_personal_data.length > 0) {
          for (var i = 0; i < finalObject.member_personal_data.length; i++) {
            if (finalObject.member_personal_data[i].relation == "Primary") {
              formData.append("member_personal_data[" + i + "][relation]", finalObject.member_personal_data[i].relation);
              formData.append("member_personal_data[" + i + "][firstname]", finalObject.member_personal_data[i].firstname);
              formData.append("member_personal_data[" + i + "][lastname]", finalObject.member_personal_data[i].lastname);
              formData.append("member_personal_data[" + i + "][middlename]", finalObject.member_personal_data[i].middlename);
              formData.append("member_personal_data[" + i + "][ssn]", finalObject.member_personal_data[i].ssn);
              formData.append("member_personal_data[" + i + "][dob]", finalObject.member_personal_data[i].dob);
              formData.append("member_personal_data[" + i + "][gender]", finalObject.member_personal_data[i].gender);
              formData.append("member_personal_data[" + i + "][date_employed_full_time]", finalObject.member_personal_data[i].date_employed_full_time);
              formData.append("member_personal_data[" + i + "][hours_worked_per_week]", finalObject.member_personal_data[i].hours_worked_per_week);
              formData.append("member_personal_data[" + i + "][job_title]", finalObject.member_personal_data[i].job_title);
              formData.append("member_personal_data[" + i + "][address1]", finalObject.member_personal_data[i].address1);
              formData.append("member_personal_data[" + i + "][address2]", finalObject.member_personal_data[i].address2);
              formData.append("member_personal_data[" + i + "][city]", finalObject.member_personal_data[i].city);
              formData.append("member_personal_data[" + i + "][state]", finalObject.member_personal_data[i].state);
              formData.append("member_personal_data[" + i + "][zip]", finalObject.member_personal_data[i].zip);
              formData.append("member_personal_data[" + i + "][mailing_address]", finalObject.member_personal_data[i].mailing_address);
              formData.append("member_personal_data[" + i + "][mailing_city]", finalObject.member_personal_data[i].mailing_city);
              formData.append("member_personal_data[" + i + "][mailing_state]", finalObject.member_personal_data[i].mailing_state);
              formData.append("member_personal_data[" + i + "][mailing_zip]", finalObject.member_personal_data[i].mailing_zip);
              formData.append("member_personal_data[" + i + "][mobile]", finalObject.member_personal_data[i].mobile);
              formData.append("member_personal_data[" + i + "][phone]", finalObject.member_personal_data[i].phone);
              formData.append("member_personal_data[" + i + "][work_phono]", finalObject.member_personal_data[i].work_phono);
              formData.append("member_personal_data[" + i + "][best_time_to_call]", finalObject.member_personal_data[i].best_time_to_call);
              formData.append("member_personal_data[" + i + "][email]", finalObject.member_personal_data[i].email);
              formData.append("member_personal_data[" + i + "][marital_status]", finalObject.member_personal_data[i].marital_status);
              formData.append("member_personal_data[" + i + "][types_of_employment]", finalObject.member_personal_data[i].types_of_employment);
              formData.append("member_personal_data[" + i + "][cobra_effective_date]", finalObject.member_personal_data[i].cobra_effective_date);
              formData.append("member_personal_data[" + i + "][employee_status]", finalObject.member_personal_data[i].employee_status);
              formData.append("member_personal_data[" + i + "][earnings_basis]", finalObject.member_personal_data[i].earnings_basis);
              formData.append("member_personal_data[" + i + "][beneficiary_firstname]", finalObject.member_personal_data[i].beneficiary_firstname);
              formData.append("member_personal_data[" + i + "][beneficiary_lastname]", finalObject.member_personal_data[i].beneficiary_lastname);
              formData.append("member_personal_data[" + i + "][beneficiary_middlename]", finalObject.member_personal_data[i].beneficiary_middlename);
              formData.append("member_personal_data[" + i + "][beneficiary_relationship]", finalObject.member_personal_data[i].beneficiary_relationship);
              formData.append("member_personal_data[" + i + "][beneficiary_address]", finalObject.member_personal_data[i].beneficiary_address);
              formData.append("member_personal_data[" + i + "][beneficiary_city]", finalObject.member_personal_data[i].beneficiary_city);
              formData.append("member_personal_data[" + i + "][beneficiary_state]", finalObject.member_personal_data[i].beneficiary_state);
              formData.append("member_personal_data[" + i + "][beneficiary_zip]", finalObject.member_personal_data[i].beneficiary_zip);
              formData.append("member_personal_data[" + i + "][enrollment]", finalObject.member_personal_data[i].enrollment);
              formData.append("member_personal_data[" + i + "][enrollment_qle_description]", finalObject.member_personal_data[i].enrollment_qle_description);
              formData.append("member_personal_data[" + i + "][enrollment_other_description]", finalObject.member_personal_data[i].enrollment_other_description);
              formData.append("member_personal_data[" + i + "][enrollment_date]", finalObject.member_personal_data[i].enrollment_date);
              formData.append("member_personal_data[" + i + "][waiver_reason]", finalObject.member_personal_data[i].waiver_reason);
              formData.append("member_personal_data[" + i + "][waiver_description]", finalObject.member_personal_data[i].waiver_description);
              formData.append("member_personal_data[" + i + "][requested_effective_date]", finalObject.member_personal_data[i].requested_effective_date);
              formData.append("member_personal_data[" + i + "][group_medical_plan]", finalObject.member_personal_data[i].group_medical_plan);
              formData.append("member_personal_data[" + i + "][dental_coverage]", finalObject.member_personal_data[i].dental_coverage);
              formData.append("member_personal_data[" + i + "][dental_coverage_list_enrolling]", finalObject.member_personal_data[i].dental_coverage_list_enrolling);
              formData.append("member_personal_data[" + i + "][dental_plan]", finalObject.member_personal_data[i].dental_plan);
              formData.append("member_personal_data[" + i + "][vision_coverage]", finalObject.member_personal_data[i].vision_coverage);
              formData.append("member_personal_data[" + i + "][vision_coverage_list_enrolling]", finalObject.member_personal_data[i].vision_coverage_list_enrolling);
              formData.append("member_personal_data[" + i + "][enrollment_period]", finalObject.member_personal_data[i].enrollment_period);
              formData.append("member_personal_data[" + i + "][date_of_event]", finalObject.member_personal_data[i].date_of_event);
              formData.append("member_personal_data[" + i + "][special_enrollment_reason]", finalObject.member_personal_data[i].special_enrollment_reason);
              formData.append("member_personal_data[" + i + "][loss_of_coverage]", finalObject.member_personal_data[i].loss_of_coverage);
              formData.append("member_personal_data[" + i + "][special_enrollment_reason_other_description]", finalObject.member_personal_data[i].special_enrollment_reason_other_description);
              formData.append("member_personal_data[" + i + "][termination_date]", finalObject.member_personal_data[i].termination_date);
              formData.append("member_personal_data[" + i + "][height]", finalObject.member_personal_data[i].height);
              formData.append("member_personal_data[" + i + "][weight]", finalObject.member_personal_data[i].weight);
              formData.append("member_personal_data[" + i + "][tobacco]", finalObject.member_personal_data[i].tobacco);
              formData.append("member_personal_data[" + i + "][own_motorcycle]", finalObject.member_personal_data[i].own_motorcycle);
              formData.append("member_personal_data[" + i + "][moving_violation]", finalObject.member_personal_data[i].moving_violation);
              formData.append("member_personal_data[" + i + "][dui_owi]", finalObject.member_personal_data[i].dui_owi);
              formData.append("member_personal_data[" + i + "][waiver_signature]", finalObject.member_personal_data[i].waiver_signature);
              formData.append("member_personal_data[" + i + "][applicant_signature]", finalObject.member_personal_data[i].applicant_signature);
              formData.append("member_personal_data[" + i + "][persons_covered]", finalObject.member_personal_data[i].persons_covered);
              formData.append("member_personal_data[" + i + "][current_medical_plan_remain_active]", finalObject.member_personal_data[i].current_medical_plan_remain_active);
              formData.append("member_personal_data[" + i + "][current_medical_plan_remain_active_description]", finalObject.member_personal_data[i].current_medical_plan_remain_active_description);
              formData.append("member_personal_data[" + i + "][current_medical_plan_remain_active_carrier_id]", finalObject.member_personal_data[i].current_medical_plan_remain_active_carrier_id);
              formData.append("member_personal_data[" + i + "][covered_under_medicare]", finalObject.member_personal_data[i].covered_under_medicare);
              formData.append("member_personal_data[" + i + "][covered_under_medicare_description]", finalObject.member_personal_data[i].covered_under_medicare_description);
              formData.append("member_personal_data[" + i + "][covered_under_medicare_active]", finalObject.member_personal_data[i].covered_under_medicare_active);
            }
            if (finalObject.member_personal_data[i].relation == "Spouse") {
              formData.append("member_personal_data[" + i + "][relation]", finalObject.member_personal_data[i].relation);
              formData.append("member_personal_data[" + i + "][firstname]", finalObject.member_personal_data[i].firstname);
              formData.append("member_personal_data[" + i + "][lastname]", finalObject.member_personal_data[i].lastname);
              formData.append("member_personal_data[" + i + "][ssn]", finalObject.member_personal_data[i].ssn);
              formData.append("member_personal_data[" + i + "][dob]", finalObject.member_personal_data[i].dob);
              formData.append("member_personal_data[" + i + "][gender]", finalObject.member_personal_data[i].gender);
              formData.append("member_personal_data[" + i + "][height]", finalObject.member_personal_data[i].height);
              formData.append("member_personal_data[" + i + "][weight]", finalObject.member_personal_data[i].weight);
              formData.append("member_personal_data[" + i + "][tobacco]", finalObject.member_personal_data[i].tobacco);
              formData.append("member_personal_data[" + i + "][own_motorcycle]", finalObject.member_personal_data[i].own_motorcycle);
              formData.append("member_personal_data[" + i + "][moving_violation]", finalObject.member_personal_data[i].moving_violation);
              formData.append("member_personal_data[" + i + "][dui_owi]", finalObject.member_personal_data[i].dui_owi);
              formData.append("member_personal_data[" + i + "][email]", finalObject.member_personal_data[i].email);
              formData.append("member_personal_data[" + i + "][phone]", finalObject.member_personal_data[i].phone);
            }
            if (finalObject.member_personal_data[i].relation == "Child") {
              formData.append("member_personal_data[" + i + "][relation]", finalObject.member_personal_data[i].relation);
              formData.append("member_personal_data[" + i + "][firstname]", finalObject.member_personal_data[i].firstname);
              formData.append("member_personal_data[" + i + "][lastname]", finalObject.member_personal_data[i].lastname);
              formData.append("member_personal_data[" + i + "][ssn]", finalObject.member_personal_data[i].ssn);
              formData.append("member_personal_data[" + i + "][dob]", finalObject.member_personal_data[i].dob);
              formData.append("member_personal_data[" + i + "][gender]", finalObject.member_personal_data[i].gender);
              formData.append("member_personal_data[" + i + "][tobacco]", finalObject.member_personal_data[i].tobacco);
              formData.append("member_personal_data[" + i + "][email]", finalObject.member_personal_data[i].email);
              formData.append("member_personal_data[" + i + "][phone]", finalObject.member_personal_data[i].phone);

            }


          }
        }

        // <!-----------member_personal_data-------->

        if (finalObject.member_healthissue_data.length > 0) {
          for (var i = 0; i < finalObject.member_healthissue_data.length; i++) {
            formData.append("member_healthissue_data[" + i + "][health_id]", finalObject.member_healthissue_data[i].health_id);
            formData.append("member_healthissue_data[" + i + "][health_issue_id]", finalObject.member_healthissue_data[i].health_issue_id);
            if (finalObject.member_healthissue_data[i].id != undefined) {
              formData.append("member_healthissue_data[" + i + "][id]", finalObject.member_healthissue_data[i].id);

            }

          }
        }
        // <!-----------member_healthissue_data-------->
        // <!-----------member_medications_prescribed-------->
        if (finalObject.member_medications_prescribed.length > 0) {
          for (var i = 0; i < finalObject.member_medications_prescribed.length; i++) {
            formData.append("member_medications_prescribed[" + i + "][question]", finalObject.member_medications_prescribed[i].question);
            formData.append("member_medications_prescribed[" + i + "][person]", finalObject.member_medications_prescribed[i].person);
            formData.append("member_medications_prescribed[" + i + "][condition_diagnosis]", finalObject.member_medications_prescribed[i].condition_diagnosis);

            formData.append("member_medications_prescribed[" + i + "][date_treated]", finalObject.member_medications_prescribed[i].date_treated);
            formData.append("member_medications_prescribed[" + i + "][treatment_including_medications_dosage]", finalObject.member_medications_prescribed[i].treatment_including_medications_dosage);
            formData.append("member_medications_prescribed[" + i + "][date_last_taken]", finalObject.member_medications_prescribed[i].date_last_taken);
            formData.append("member_medications_prescribed[" + i + "][prognosis]", finalObject.member_medications_prescribed[i].prognosis);

          }
        }
        // <!-----------member_medications_prescribed-------->
        if (this.signature) {
          if (this.signature != undefined) {
            formData.append('member_signature[esignature]', this.signature);
            formData.append('member_signature[esignature_type]', 'text');
            formData.append('member_signature[browser_name]', this.deviceService.browser);
            formData.append('member_signature[browser_version]', this.deviceService.browser_version);
            formData.append('member_signature[ip]', this.ipAddress);
          }

        } else if (this.onTabClickData == 'esignature') {
          if (this.signatureImg != undefined) {
            formData.append('member_signature[esignature]', this.signatureImg);
            formData.append('member_signature[esignature_type]', 'esignature');
            formData.append('member_signature[browser_name]', this.deviceService.browser);
            formData.append('member_signature[browser_version]', this.deviceService.browser_version);
            formData.append('member_signature[ip]', this.ipAddress);
          }
        } else if (this.onTabClickData === 'image') {
          if (this.uploadedFile != undefined) {
            formData.append('member_signature[esignature]', this.uploadedFile);
            formData.append('member_signature[esignature_type]', 'image');
            formData.append('member_signature[browser_name]', this.deviceService.browser);
            formData.append('member_signature[browser_version]', this.deviceService.browser_version);
            formData.append('member_signature[ip]', this.ipAddress);
          }
        }
        if (window.location.href.includes('MemberEnrollment')) {
          this.apiurl = 'AddMemberToGroup';
        }
        else {
          this.apiurl = 'CreateEmployeeEnrollment';
        }
        this.service.postData(this.apiurl, formData).subscribe((Object: any) => {
          if (Object.status == true) {
            this.loader = false;
            this.toastr.success(Object.message);
            this.form.reset();
            this.submitAttempt = false;
            if (Object.application_no != undefined) {
              this.router.navigate(['confirmation', Object.application_no]);
            } else {
              this.router.navigate(['confirmation']);
            }
            localStorage.setItem('name', values.firstname + ' ' + values.lastname);
          }
        }, err => {
          if (err.status == 404) {
            this.toastr.error(err.error.message);
          }

          this.loader = false;
          if (err.error.errors != undefined) {
            this.toastr.error(err.error.errors['member_personal_data.0.email']);
          } else {
            this.toastr.error(err.error.message);
          }
          console.log(err);
        })
      }
    }
    else {
      this.scrollToError();
    }

  }

  onprimaryssnFocusout(e) {
    let primaryssnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
    if (primaryssnvalue.length == 11) {
      var substr = primaryssnvalue.substring(primaryssnvalue.length - 4, primaryssnvalue.length);
      let str = "***-**-"
      str = str + substr;
      (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
    }
  }
  onFocusPrimary(event) {// future use
    // let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
    // if (ssn.length == 11) {
    //   var sessionstoragessn = ''
    //   sessionStorage.getItem(event.target.id);
    //   (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
    // }
  }
  onSelectRequestdate() {
    this.autosaveApplicantFields();
  }




  //===============Age Calculation==========//
  onSelectDate(value, type) {
    if (value.dob != null) {
      if (type == 'spouse') {
        var convertAge = new Date(value.spousedob);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        this.form.controls.spouseage.setValue(this.Age);
        if (this.Age <= 17) {
          this.spouseAge = "Applicant  can't be younger than 18 years of age";
        }
        if (this.Age > 18) {
          this.spouseAge = "";
        }
      } else {
        var convertAge = new Date(value.dob);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        this.form.controls.age.setValue(this.Age);
        if (this.Age <= 17) {
          this.applicantAge = "Applicant  can't be younger than 18 years of age";
        }
        if (this.Age > 18) {
          this.applicantAge = "";
        }
      }

    }
  }

  onEnterDate(value, type) {
    if (value.dob != null) {
      if (type == 'spouse') {
        const convertAge = new Date(value.dob);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        this.form.controls.spouseage.setValue(this.Age);
        if (this.Age <= 17) {
          this.spouseAge = "Applicant  can't be younger than 18 years of age";
        }
        if (this.Age > 18) {
          this.spouseAge = "";
        }
      } else {
        const convertAge = new Date(value.dob);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        this.form.controls.age.setValue(this.Age);
        if (this.Age <= 17) {
          this.applicantAge = "Applicant  can't be younger than 18 years of age";
        }
        if (this.Age > 18) {
          this.applicantAge = "";
        }
      }

    }
  }
  public atLeastOneValidator: any = (control: FormGroup): ValidationErrors | any => {
    let controls = control.controls;
    if (controls) {
      let theOne = Object.keys(controls).findIndex(key => controls[key].value !== '');
      if (theOne === -1) {
        return {
          atLeastOneRequired: {
            text: ''
          }
        }
      }
    }
  }

  onfocusoutSignature() {
    this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
    this.message = '';
  }
  onTabClick(data: any) {
    this.onTabClickData = data;
    if (data === 'text') {
      this.firstDiv = false;
      this.secondDiv = true;
      this.thirdDiv = false;
      this.uploadedFile = '';
      this.onfocusoutSignature();
      this.form.controls.uploadImage.setValue('');
      this.signaturePad.clear();
    } else if (data === 'image') {
      this.firstDiv = false;
      this.secondDiv = false;
      this.thirdDiv = true;
      this.signature = '';
      this.form.controls.enterText.setValue('');
      this.signaturePad.clear();
    } else if (data === 'esignature') {
      this.firstDiv = true;
      this.secondDiv = false;
      this.thirdDiv = false;
      this.imageUrl = '';
      this.uploadedFile = '';
      this.form.controls.enterText.setValue('');
      this.form.controls.uploadImage.setValue('');
    }
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
  }
  signatureField(value) {
    this.autoSaveSignature()
    if (this.signaturePad.toData().length > 0) {
      this.message = "";
      this.signatureVAlid = value;
      this.signatureImg = this.signaturePad.toDataURL();
    } else {
      this.message = "Signature is required";
    }
  }
  clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }
  upload(event) {
    this.uploadedFile = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.message = '';
    }
    reader.readAsDataURL(this.uploadedFile);
  }

  // <!---------------Auto Save Fields---------------------!>

  onFocusoutApplicant(formcontrol) {
    if (this.form.get(formcontrol).value != '') {
      if (this.autosaveApplicantObj[formcontrol] == undefined) {
        this.autosaveApplicantFields();
      }
      if (this.form.get(formcontrol).value != this.autosaveApplicantObj[formcontrol] && this.autosaveApplicantObj[formcontrol] != undefined) {
        this.autosaveApplicantFields();
      }
      this.autosaveApplicantObj[formcontrol] = this.form.get(formcontrol).value;
    }
  }

  autosaveApplicantFields() {
    if (this.form.controls.firstname.status == "VALID" && this.form.controls.lastname.status == "VALID"
      && this.form.controls.ssn.status == "VALID" && this.form.controls.dob.status == "VALID"
      && this.form.controls.gender.status == "VALID" && this.form.controls.dateemployed.status == "VALID"
      && this.form.controls.jobtitle.status == "VALID" && this.form.controls.homezip.status == "VALID") {
      this.objectData.member_personal_data = new Array<any>();

      this.objectData.member_healthissue_data = [...this.medicalHistoryInfo];
      this.member_personal_data['relation'] = "Primary";
      this.member_personal_data.firstname = this.form.controls.firstname.value;
      this.member_personal_data.lastname = this.form.controls.lastname.value,
        this.member_personal_data.middlename = this.form.controls.middlename.value,

        this.member_personal_data.ssn = this.form.controls.ssn.value,
        this.member_personal_data.dob = this.datePipe.transform(this.form.controls.dob.value, "yyyy-MM-dd");
      this.member_personal_data.gender = this.form.controls.gender.value,

        this.member_personal_data.date_employed_full_time = this.datePipe.transform(this.form.controls.dateemployed.value, "yyyy-MM-dd");
      this.member_personal_data.job_title = this.form.controls.jobtitle.value,

        this.member_personal_data.hours_worked_per_week = this.form.controls.avghrworkedperweek.value,
        this.member_personal_data.address1 = this.form.controls.homeaddress.value,
        this.member_personal_data.address2 = '',

        this.member_personal_data.city = this.form.controls.homecity.value,
        this.member_personal_data.state = this.form.controls.homestate.value,
        this.member_personal_data.zip = this.form.controls.homezip.value

      this.member_personal_data.mailingaddress = this.form.controls.mailingaddress.value
      this.member_personal_data.mailingcity = this.form.controls.mailingcity.value
      this.member_personal_data.mailingstate = this.form.controls.mailingstate.value
      this.member_personal_data.mailingzip = this.form.controls.mailingzip.value

      this.member_personal_data.mobile = this.form.get('phoneformgroup').controls['homephone'].value
      this.member_personal_data.work_phono = this.form.get('phoneformgroup').controls['workphone'].value
      this.member_personal_data.phone = this.form.get('phoneformgroup').controls['cellphone'].value
      this.member_personal_data.best_time_to_call = this.form.controls.besttimetocall.value
      this.member_personal_data.email = this.form.controls.email.value
      this.member_personal_data.marital_status = this.form.controls.maritalstatus.value;
      this.member_personal_data.employee_status = this.form.controls.employeestatus.value;
      this.member_personal_data.types_of_employment = this.form.controls.statuscheck.value;
      this.member_personal_data.cobra_effective_date = this.datePipe.transform(this.form.controls.cobraeffectivedate.value, "yyyy-MM-dd");
      this.member_personal_data.earnings_basis = this.form.controls.earningsbasis.value;
      this.member_personal_data.beneficiary_firstname = this.form.controls.beneficiary_firstname.value;
      this.member_personal_data.beneficiary_lastname = this.form.controls.beneficiary_lastname.value;
      this.member_personal_data.beneficiary_middlename = this.form.controls.beneficiary_middlename.value;
      this.member_personal_data.beneficiary_relationship = this.form.controls.beneficiary_relationship.value;
      this.member_personal_data.beneficiary_address = this.form.controls.beneficiary_address1.value;
      this.member_personal_data.beneficiary_city = this.form.controls.beneficiary_city.value;
      this.member_personal_data.beneficiary_state = this.form.controls.beneficiary_state.value;
      this.member_personal_data.beneficiary_zip = this.form.controls.beneficiary_zip.value;

      this.member_personal_data.enrollment = this.form.controls.newenrollment.value;
      this.member_personal_data.enrollment_qle_description = this.form.controls.lifeevent.value;
      this.member_personal_data.enrollment_other_description = this.form.controls.enrollment_other_description.value;
      this.member_personal_data.enrollment_date = this.datePipe.transform(this.form.controls.lifeeventdate.value, "yyyy-MM-dd");
      this.member_personal_data.waiver_reason = this.form.controls.waivercoverage.value;
      this.member_personal_data.waiver_description = this.form.controls.waivercoverageOther.value;
      this.member_personal_data.requested_effective_date = this.datePipe.transform(this.form.controls.requestdate.value, "yyyy-MM-dd");
      this.member_personal_data.group_medical_plan = this.form.controls.group_medical_plan.value;

      this.member_personal_data.dental_coverage = this.form.controls.changedental.value;
      this.member_personal_data.dental_coverage_list_enrolling = this.form.controls.dental_coverage_list_enrolling.value;
      this.member_personal_data.dental_plan = this.form.controls.dental_plan.value;
      this.member_personal_data.vision_coverage = this.form.controls.coverageoffered.value;
      this.member_personal_data.vision_coverage_list_enrolling = this.form.controls.vision_coverage_list_enrolling.value;
      this.member_personal_data.enrollment_period = this.form.controls.enrollingemployer.value;
      this.member_personal_data.date_of_event = this.datePipe.transform(this.form.controls.date_of_event.value, "yyyy-MM-dd");
      this.member_personal_data.special_enrollment_reason = this.form.controls.enrollingemployeroptionb.value;
      this.member_personal_data.loss_of_coverage = this.form.controls.loss_of_coverage.value;
      this.member_personal_data.special_enrollment_reason_other_description = this.form.controls.special_enrollment_reason_description.value;
      this.member_personal_data.termination_date = this.datePipe.transform(this.form.controls.termination_date.value, "yyyy-MM-dd");
      if (this.form.controls.empfeet.value != '') {
        this.member_personal_data.height = this.form.controls.empfeet.value + '.' + this.form.controls.empinch.value;
      } else {
        this.member_personal_data.height = '';
      }
      this.member_personal_data.weight = this.form.controls.empweight.value;
      this.member_personal_data.tobacco = this.form.controls.emptobaccouse.value;
      this.member_personal_data.own_motorcycle = this.form.controls.empmotercycle.value;
      this.member_personal_data.moving_violation = this.form.controls.empmovingviolation.value;
      this.member_personal_data.dui_owi = this.form.controls.empduiowi.value;
      this.member_personal_data.waiver_signature = this.form.controls.waiver_signature.value;
      this.member_personal_data.applicant_signature = this.form.controls.applicant_signature.value;
      this.member_personal_data.persons_covered = this.form.controls.personscovered.value;

      this.member_personal_data.current_medical_plan_remain_active = this.form.controls.currentmedicalplan.value;
      this.member_personal_data.current_medical_plan_remain_active_description = this.form.controls.current_medical_plan_remain_active_description.value;
      this.member_personal_data.current_medical_plan_remain_active_carrier_id = this.form.controls.active_carrier_id.value;
      this.member_personal_data.covered_under_medicare = this.form.controls.medicalpart.value;
      this.member_personal_data.covered_under_medicare_description = this.form.controls.covered_under_medicare_description.value;
      this.member_personal_data.covered_under_medicare_active = this.form.controls.covered_under_medicare_active.value;

      this.objectData.member_personal_data.push(this.member_personal_data);

      this.objectData.company_id = this.companyId;
      this.objectData.group_id = this.group_id;
      if (this.family_id != '') {
        this.objectData.family_id = this.family_id;
        this.objectData.member_staging_id = this.member_staging_id;
      }

      var finalObject: any = {}
      finalObject = this.objectData;
      var url = 'CreateApplicantsTemp'
      this.service.postData(url, finalObject).subscribe((Object: any) => {
        this.family_id = Object.family_id;
        this.member_staging_id = Object.member_staging_id;
        if (Object != null) {

        }
      }, err => {
        console.log(err);
      })
    }
  }
  handleChange(e, control) {

    this.autosaveApplicantFields();
  }
  onSelectCobradate() {
    this.autosaveApplicantFields();
  }
  onfocusOutphonegroup(control) {
    if (this.phoneGroupControls.length > 0) {
      this.phoneGroupControls.forEach(obj => {
        if (Object.values(obj).indexOf(control) > -1) {
          let i = this.phoneGroupControls.indexOf(obj);
          if (i > -1) {
            this.phoneGroupControls.splice(i, 1);
          }
        }
      })
    }
    let obj = {
      name: control,
      value: this.form.get('phoneformgroup').get(control).status
    }
    this.phoneGroupControls.push(obj);
    this.checkPhoneGroupStatus = this.phoneGroupControls.every(element => {
      if (element.value === "VALID") {
        return true;
      }
      return false;
    })

    if (this.checkPhoneGroupStatus) {
      this.autosaveApplicantFields();
    }

  }

  // <!---------------Auto Save Fields---------------------!>

  // <!---------------Auto Save Signature---------------------!>
  autoSaveSignature() {
    if (this.family_id != '') {
      let formData: FormData = new FormData();
      formData.append('family_id', this.family_id);
      if (this.signature) {
        formData.append('member_signature[esignature]', this.signature);
        formData.append('member_signature[esignature_type]', 'text');
        formData.append('member_signature[browser_name]', this.deviceService.browser);
        formData.append('member_signature[browser_version]', this.deviceService.browser_version);
        formData.append('member_signature[ip]', this.ipAddress);

      } else if (this.onTabClickData == 'esignature') {
        this.signatureImg = this.signaturePad.toDataURL();
        formData.append('member_signature[esignature]', this.signatureImg);
        formData.append('member_signature[esignature_type]', 'esignature');
        formData.append('member_signature[browser_name]', this.deviceService.browser);
        formData.append('member_signature[browser_version]', this.deviceService.browser_version);
        formData.append('member_signature[ip]', this.ipAddress);
      } else if (this.onTabClickData === 'image') {
        formData.append('member_signature[esignature]', this.uploadedFile);
        formData.append('member_signature[esignature_type]', 'image');
        formData.append('member_signature[browser_name]', this.deviceService.browser);
        formData.append('member_signature[browser_version]', this.deviceService.browser_version);
        formData.append('member_signature[ip]', this.ipAddress);
      }


      var url = 'CreateMemberSignatureTemp'
      this.service.postData(url, formData).subscribe((Object: any) => {
      }, err => {
        console.log(err);
      })
    }

  }
  // <!---------------Auto Save Signature---------------------!>


  // <!---------------Auto Save MedicationsTemp---------------------!>
  onFocusOutEmployeequestions(formcontrol) {
    this.autoSaveMedicationsTemp();
  }
  onSelectEmployeedetails() {
    this.autoSaveMedicationsTemp();
  }
  autoSaveMedicationsTemp() {
    let res = this.member_medications().value.every(element => Object.values(element).every(val => val === ""));
    if (!res) {
      this.objectData.member_medications_prescribed = new Array<any>();
      for (var j = 0; j < this.member_medications().value.length; j++) {
        this.member_medications_prescribed = {};
        this.member_medications_prescribed.question = this.member_medications().value[j].empquestion;
        this.member_medications_prescribed.person = this.member_medications().value[j].person;
        this.member_medications_prescribed.condition_diagnosis = this.member_medications().value[j].conditiondiagnosis;
        this.member_medications_prescribed.date_treated = this.datePipe.transform(this.member_medications().value[j].datestreated, "yyyy-MM-dd");
        this.member_medications_prescribed.treatment_including_medications_dosage = this.member_medications().value[j].dosage;
        this.member_medications_prescribed.date_last_taken = this.datePipe.transform(this.member_medications().value[j].datelasttaken, "yyyy-MM-dd");
        this.member_medications_prescribed.prognosis = this.member_medications().value[j].prognosis;
        this.objectData.member_medications_prescribed.push(this.member_medications_prescribed);
      }
    }
    var finalObject: any = {}
    finalObject = this.objectData;

    if (!res && this.family_id != '') {

      var url = 'CreateMedicationsTemp'
      this.service.postData(url, finalObject).subscribe((Object: any) => {
      }, err => {
        console.log(err);
        if (err.error.errors != null) {

        }
      })
    }

  }
  // <!---------------Auto Save MedicationsTemp---------------------!>
  //===============Child Age Calculation==========//
  onSelectChildDate(index) {
    let childDob = this.child_Array().controls[index]['controls'].childDob.value;
    if (childDob != null) {
      var convertAge = new Date(childDob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.child_Array().controls[index]['controls'].childage.setValue(this.Age);
    }
  }
}
