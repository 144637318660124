import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharingService {

    options: any;
    questionslist: any[] = [];
    previousQuestions: any[] = [];
    number = 0;
    orderDetails: any[] = [];
    orderMembers: any[] = [];
    initialcheck = 'front';
    selectedRadioQuestions: any[] = [];
    selectedcheckedQuestions: any[] = [];
    demographic = 'hideD2c';
    loadData: any;
    base_Url: any;
    apiKey: any;
    companyId: any;
    blogDetails: any;
    agentGroupdata: any;
    private client = new BehaviorSubject<any>('');
    clientData = this.client.asObservable();
    private signImg = new BehaviorSubject<any>('');
    signatureData = this.signImg.asObservable();
    private logo = new BehaviorSubject<any>('');
    grouplogo = this.logo.asObservable();

    feetArray = [
        { id: 0, feet: '0' }, { id: 1, feet: '1' }, { id: 2, feet: '2' }, { id: 3, feet: '3' }, { id: 4, feet: '4' },
        { id: 5, feet: '5' }, { id: 6, feet: '6' }, { id: 7, feet: '7' }, { id: 8, feet: '8' }, { id: 9, feet: '9' },
        { id: 10, feet: '10' },
    ]
    inchArray = [
        { id: 0, inch: '0', inchValue: '00' }, { id: 1, inch: '1', inchValue: '01' }, { id: 2, inch: '2', inchValue: '02' },
        { id: 3, inch: '3', inchValue: '03' }, { id: 4, inch: '4', inchValue: '04' }, { id: 5, inch: '5', inchValue: '05' },
        { id: 6, inch: '6', inchValue: '06' }, { id: 7, inch: '7', inchValue: '07' }, { id: 8, inch: '8', inchValue: '08' },
        { id: 9, inch: '9', inchValue: '09' }, { id: 10, inch: '10', inchValue: '10' }, { id: 11, inch: '11', inchValue: '11' },
    ]


    constructor(public http: HttpClient) {
        this.client.subscribe((data) => {
            if (data != '') {
                this.base_Url = data.apiURL;
                this.apiKey = data.api_Key;
                this.companyId = data.company_id;
            }
        });
    }

    getClientData(data: any) {
        this.base_Url = data.apiURL;
        this.apiKey = data.api_Key;
        this.companyId = data.company_id;
        this.client.next(data);
    }

    baseApiUrl() {
        return this.base_Url;
    }

    getData(url) {
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId
            })
        };
        const apiUrl = this.base_Url + url;
        return this.http.get(apiUrl, this.options).pipe(map(res => res));
    }

    getDataFromUrl(url) {
        this.options = {

            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId
            })
        };
        const apiUrl = this.base_Url + url;
        return this.http.get(apiUrl, this.options).pipe(map(res => res));
    }

    getDataFromUrlDemo(url, apiURL, api_Key, companyId) {

        this.options = {

            headers: new HttpHeaders({
                'X-Authorization': api_Key,
                'X-Company': companyId
            })
        };
        const apiUrl = apiURL + url;
        return this.http.get(apiUrl, this.options).pipe(map(res => res));
    }

    postData(url, body) {
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId
            })
        };
        const apiUrl = this.base_Url + url;
        return this.http.post(apiUrl, body, this.options).pipe(map(res => res));
    }

    downloadPostData(url, body) {
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId,
                ContentType: 'application/octet-stream',
            }),
            responseType: 'blob' as 'json',
        };
        const apiUrl = this.base_Url + url;
        return this.http.post(apiUrl, body, this.options).pipe(map(res => res));
    }

    postDataUrl(url, body) {
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId
            })
        };
        const apiUrl = this.base_Url + url;
        return this.http.post(apiUrl, body, this.options).pipe(map(res => res));
    }

    putData(url, body) {
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId
            })
        };
        const apiUrl = this.base_Url + url;
        return this.http.put(apiUrl, body, this.options);
    }

    downloadData(url: string) {
        const apiUrl = this.base_Url + url;
        this.options = {
            headers: new HttpHeaders({
                'X-Authorization': this.apiKey,
                'X-Company': this.companyId,
            }),
            responseType: 'blob' as 'json',
        };
        return this.http.get(apiUrl, this.options);
    }

    pushQuestionsList(data) {
        this.questionslist = data;
    }

    getQuestionsList() {
        return this.questionslist;
    }

    pushCondition(data) {
        this.number = data;
    }

    getCondition() {
        return this.number;
    }

    pushOrderDetails(data) {
        this.orderDetails = data;
    }

    getOrderDetails() {
        return this.orderDetails;
    }

    pushOrderMembers(data) {
        this.orderMembers = data;
    }

    getOrderMembers() {
        return this.orderMembers;
    }

    pushCheckCondition(data) {
        this.initialcheck = data;
    }

    getcheckCondition() {
        return this.initialcheck;
    }

    pushRadioQuestions(data) {
        this.selectedRadioQuestions = data;
    }

    getRadioQuestions() {
        return this.selectedRadioQuestions;
    }

    pushSelectedCheckedQuestions(data) {
        this.selectedcheckedQuestions = data;
    }

    getSelectedCheckedQuestions() {
        return this.selectedcheckedQuestions;
    }

    // <!--D2C--->

    pushD2c(data) {
        this.demographic = data;
    }

    getD2c() {
        return this.demographic;
    }

    // <!----->
    pushBlogdetails(data) {
        this.blogDetails = data;
    }

    getBlogdetails() {
        return this.blogDetails;
    }

    //    Used in the sign-up page to save the agent/marketer/group information
    pushAgentGroupInfo(data) {
        this.agentGroupdata = data;
    }

    getAgentGroupInfo() {
        return this.agentGroupdata;
    }

    //    Method ends

    // Method for esignature //

    getESignature(data: any) {
        this.signImg.next(data);
    }

    getGroupLogo(data: any) {
        this.logo.next(data);
    }
}
