import {Directive, HostListener} from "@angular/core";
import {NgControl} from "@angular/forms";

@Directive({
    selector: '[appPhonenumberValidation]'
})
export class PhonenumberValidationDirective {

    constructor(public ngControl: NgControl) {
    }

    @HostListener("input", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        let trimmed = input.value.replace(/\s+/g, "").replace(/-/g, "");

        // Remove leading zeros
        trimmed = trimmed.replace(/^0+/, "");

        // Truncate to 12 characters (if needed)
        if (trimmed.length > 12) {
            trimmed = trimmed.substr(0, 12);
        }

        // Break down into parts for formatting
        let numbers = [];
        numbers.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 3) !== "") numbers.push(trimmed.substr(3, 3));
        if (trimmed.substr(6, 4) != "") numbers.push(trimmed.substr(6, 4));

        // Set the value to the formatted string
        input.value = numbers.join("-");

        // Update the form control value
        this.ngControl.control.setValue(input.value);
    }
}
