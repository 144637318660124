import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    getData(dataKey): any {
        const data = sessionStorage.getItem(dataKey);
        return data ? JSON.parse(data) : null;
    }

    setData(dataKey: any, data: any): void {
        sessionStorage.setItem(dataKey, JSON.stringify(data));
    }
}
