import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
        if (!filter) {
            return items;
        }

        if (!Array.isArray(items)) {
            return items;
        }

        if (filter && Array.isArray(items)) {
            return items.filter(obj => Object.keys(obj).some(key => String(obj[key]).toLowerCase().includes(filter.toLowerCase())));
        }
    }
}
