<!-- <section [ngClass]="showCustomScroll ? 'customScroll' : ''"> -->
<section #tableContainer [ngClass]="showCustomScroll ? 'customScroll' : ''" class="table-container">
    <div *ngIf="isFiltered" class="table-header-container">
        <app-table-header (filteredColumns)="filteredColumns($event)" (search)="search($event)"
                          [columns]="columns"></app-table-header>
    </div>
    <div #tableResponsive class="table-responsive table-mobile-grid" id="table">
        <table class="table table-bordered table-hover text-nowrap">
            <thead>
            <tr>
                <th *ngFor="let key of columns ; let i = index" [appSort]="key.isSortable?data:''" [ngClass]="key.isSortable ? 'sorting' : '' "
                    [style.width.%]="key.width ? key.width : null" class="sticky-top" data-name="{{key.id}}"
                    data-order="desc" data-type="name" pTooltip="{{key.tooltip_text}}" scope="col"
                    tooltipPosition="top" tooltipStyleClass="prime-tooltip">
              <span class="Sortablecol">
                <span *ngIf="selectAll && i == 0 && relativeColumns.length == 0">
                  <input (change)="selectAllData($event)" [checked]="allSelected" class="form-check-input"
                         type="checkbox">
                </span>
                <span *ngIf="selectAll && i == 1 && relativeColumns.length > 0" class="">
                  <input (change)="selectAllData($event)" [checked]="allSelected" class="form-check-input" id="seletedallmembers"
                         type="checkbox">
                </span>
                <span *ngIf="selectAll && i != 0 && relativeColumns.length == 0">{{ key.title }}</span>
                <span *ngIf="selectAll && i != 1 && relativeColumns.length > 0">{{ key.title }}</span>
                <span *ngIf="!selectAll"> {{ key.title }}</span>
                <i *ngIf="key.isSortable" class="fa fa-fw fa-sort"></i>
              </span>
                </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="!!data && data.length > 0 && !hideRecords;else no_records">
                <ng-container *ngIf="data as result">
                    <ng-container *ngIf="result.length > 0 ;else no_records">
                        <ng-container *ngFor="let record of result; index as i">
                            <ng-container *ngIf="(i+ 1 === data.length )&& infiniteScroll">
                                <tr #getMoreContent (click)="toggleState(i)" [attr.data-bs-target]="'#' + modalName"
                                    [attr.data-bs-toggle]="toggle" [class.active]="columnName == 'id' ? record.id == columnValue : columnName == 'index' ? record.index == columnValue : ''"
                                    [ngClass]="record.highlight ? 'highlight' :''"
                                    class="mobile-grid-datacontainer">
                                    <td *ngFor="let key of columns ; let i = index"
                                        [ngClass]="isExpandable?'expandable':''">


                                        <div *ngIf="!key.actions;else action_content" class="mobile-grid-datapair">
                                            <span class="mobile-grid-keydata"> {{ key.title }}: </span>
                                            <span class="mobile-grid-keyvalue">{{ record[key.id] }}</span>
                                        </div>


                                        <ng-template #action_content>
                                            <ng-container [ngTemplateOutletContext]="{data:record, idx:i}"
                                                          [ngTemplateOutlet]="key.template">
                                            </ng-container>
                                        </ng-template>
                                    </td>

                                    <td class="md-grid-view-link">
                                        <button (click)="viewDetails(record)" class="btn btn-link" type="button">
                                            view details <i class="ri-arrow-right-line"></i>
                                        </button>
                                    </td>
                                </tr>

                            </ng-container>
                            <ng-container *ngIf="(i+ 1 !== data.length ) && infiniteScroll">
                                <tr (click)="toggleState(i)" [attr.data-bs-target]="'#' + modalName"
                                    [attr.data-bs-toggle]="toggle" [class.active]="columnName == 'id' ? record.id == columnValue : columnName == 'index' ? record.index == columnValue : ''"
                                    [ngClass]="record.highlight ? 'highlight' :''"
                                    class="mobile-grid-datacontainer">
                                    <td *ngFor="let key of columns ; let i = index"
                                        [ngClass]="isExpandable?'expandable':''">

                                        <ng-container
                                                #getMoreContent
                                                *ngIf="((i+1 == data.length) && infiniteScroll);else notInfiniteScroll">
                                            <div *ngIf="!key.actions;else action_content" class="mobile-grid-datapair">
                                                <span class="mobile-grid-keydata"> {{ key.title }}: </span>
                                                <span class="mobile-grid-keyvalue">{{ record[key.id] }}</span>
                                            </div>
                                        </ng-container>
                                        <ng-template #notInfiniteScroll>
                                            <div *ngIf="!key.actions;else action_content" class="mobile-grid-datapair">
                                                <span class="mobile-grid-keydata">{{ key.title }}: </span>
                                                <span class="mobile-grid-keyvalue">{{ record[key.id] }}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template #action_content>
                                            <ng-container [ngTemplateOutletContext]="{data:record, idx:i}"
                                                          [ngTemplateOutlet]="key.template">
                                            </ng-container>
                                        </ng-template>
                                    </td>

                                    <td class="md-grid-view-link">
                                        <button (click)="viewDetails(record)" class="btn btn-link" type="button">
                                            view details <i class="ri-arrow-right-line"></i>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!infiniteScroll">
                                <tr (click)="toggleState(i)" [attr.data-bs-target]="'#' + modalName"
                                    [attr.data-bs-toggle]="toggle"
                                    [class.active]="columnName == 'id' ? record.id == columnValue : columnName == 'index' ? record.index == columnValue : ''"
                                    class="mobile-grid-datacontainer">
                                    <td *ngFor="let key of columns ; let i = index"
                                        [ngClass]="isExpandable?'expandable':''">

                                        <ng-container
                                                #getMoreContent
                                                *ngIf="((i+1 == data.length) && infiniteScroll);else notInfiniteScroll">
                                            <div *ngIf="!key.actions;else action_content" class="mobile-grid-datapair">
                                                <span class="mobile-grid-keydata"> {{ key.title }}: </span>
                                                <span class="mobile-grid-keyvalue">{{ record[key.id] }}</span>
                                            </div>
                                        </ng-container>
                                        <ng-template #notInfiniteScroll>
                                            <div *ngIf="!key.actions;else action_content" class="mobile-grid-datapair">
                                                <span class="mobile-grid-keydata">{{ key.title }}: </span>
                                                <span class="mobile-grid-keyvalue">{{ record[key.id] }}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template #action_content>
                                            <ng-container [ngTemplateOutletContext]="{data:record, idx:i}"
                                                          [ngTemplateOutlet]="key.template">
                                            </ng-container>
                                        </ng-template>
                                    </td>

                                    <td class="md-grid-view-link">
                                        <button (click)="viewDetails(record)" class="btn btn-link" type="button">
                                            view details <i class="ri-arrow-right-line"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="showTotal && i+1 == data.length" class="mobile-grid-datacontainer">
                                    <td [attr.colspan]="columns.length - 1">Total</td>
                                    <td>{{ totalAmount | currency: "USD" }}</td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="isExpandable" class="expanadable-tr">
                                <td id="td-empty"></td>
                                <td colspan="12" id="td-empty">
                                    <div class="table-responsive table-mobile-grid">
                                        <table [@contentExpansion]="expanded.has(i) ? 'expanded':'collapsed'"
                                               [class.expanded]="expanded.has(i)"
                                               class=" table table-bordered table-hover text-nowrap ">
                                            <thead>
                                            <tr>
                                                <th *ngFor="let config of relativeColumns"
                                                    class="expanded-table-header">{{ config.title }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <ng-container
                                                    *ngIf="!!record[relativeData] && record[relativeData].length > 0 ;else no_records">
                                                <tr *ngFor="let child of record[relativeData]">
                                                    <td *ngFor="let config of relativeColumns">
                                    <span *ngIf="!config.actions;else relative_action_content">
                                      {{ child[config.id] }}
                                    </span>
                                                        <ng-template #relative_action_content>
                                                            <ng-container [ngTemplateOutletContext]="{data:child, index: i}"
                                                                          [ngTemplateOutlet]="config.template">
                                                            </ng-container>
                                                        </ng-template>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            </tbody>

                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #no_records>
                <tr *ngIf="!hideRecords">
                    <td class="text-center" colspan="12"><i>No records found</i></td>
                </tr>
                <tr *ngIf="hideRecords">
                    <td class="text-center member_count" colspan="12">
                        <i>
                            <ng-container *ngIf="noShowTemplate == '';else no_show_template">
                                {{ noShowMessage }}
                            </ng-container>
                            <ng-template #no_show_template>
                                <ng-container [ngTemplateOutlet]="noShowTemplate"></ng-container>
                            </ng-template>
                        </i>
                    </td>
                </tr>
            </ng-template>
            </tbody>
        </table>
    </div>
</section>
