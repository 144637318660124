import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table.component';
import {TablePaginationComponent} from './table-pagination/table-pagination.component';
import {SortDirective} from '../directives/sort.directive';
import {PaginationDirective} from '../directives/pagination.directive';
import {FilterPipe} from '../pipes/filter.pipe';
import {FormsModule} from '@angular/forms';
import {TableHeaderComponent} from './table-header/table-header.component';
import {TooltipModule} from 'primeng/tooltip';
import {PhoneMaskPipe} from '../pipes/phonemask.filter';

@NgModule({
    declarations: [
        TableComponent,
        TablePaginationComponent,
        SortDirective,
        PaginationDirective,
        TableHeaderComponent,
        FilterPipe,
        PhoneMaskPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule
    ],
    exports: [
        TableComponent,
        SortDirective,
        PhoneMaskPipe,
        PaginationDirective, FilterPipe
    ]
})
export class ReusableTableModule {
}
