<div *ngIf="loader"
     class="loader">
    <div id="loading"></div>
</div>
<div *ngIf="!payment_view" class="container m-top">
    <div class="applicationProgress">
        <div *ngIf="typedisabled"
             class="appProgStep "
             id="app2"
             style="z-index:6; padding-left: 30px;"><span>Demographics &amp; Dependents</span>
        </div>
        <div class="appProgStep "
             id="app1"
             style="z-index:5; padding-left: 40px;"><span>Member Agreement</span>
        </div>
        <div *ngIf="typedisabled"
             class="appProgStep "
             id="app3"
             style="z-index:3;"><span>Health-Based Questions</span></div>
        <div *ngIf="typedisabled"
             class="appProgStep"
             id="app4"
             style="z-index:2;"><span>Payment Details</span></div>
        <div class="appProgStep appProgStep-last active"
             id="app5"
             style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>

    <form [formGroup]="formGroup">
        <div class="container m-top">
            <div class="row demomargin">
                <div class="col-md-7 padding_demo2">
                    <div class="left-column">
                    <div class="row">
                        <div class="col-8">
                            <h6 class="cart-card-title">Demographics</h6>
                        </div>
                        <div *ngIf="typedisabled"
                             class="col-4">
                            <div (click)="changeToDemography()"
                                 style="text-align: right;color: #9090f7;margin-top: 2%;cursor: pointer;">Change
                            </div>
                        </div>
                    </div>
                    <div class="card demography">
                        <div *ngFor="let item of memberdata; let i=index"
                             class="">
                            <div class="plan-card-header apclnt-card-header "
                                 style="margin-left: 0.05rem;">
                                <h6 class="mb-0 py-2 f-600">
                                    <div class="header_t">
                                        {{ item.relation | titlecase }}:
                                        <span class="sub_txt">
                                            {{ item.firstname | titlecase }}
                                            {{ item?.middlename | titlecase }}
                                            {{ item.lastname | titlecase }}
                                        </span>
                                    </div>
                                </h6>
                            </div>
                            <div class="row">
                                <div *ngIf="item?.gender; else elseField"
                                     class="col-lg-3 col-xs-6">
                                    <div class="head_txt">Gender :
                                        <span class="cart_txt">{{ item.gender }}</span>
                                    </div>
                                    <ng-template #elseField>
                                        <div></div>
                                    </ng-template>
                                </div>
                                <div class="col-lg-4  col-xs-6">
                                    <div class="head_txt">SSN : <span class="cart_txt">{{ item.ssn4 }}</span></div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="head_txt"> DOB : <span class="cart_txt">{{
                                            item.dob |
                                                date:'MM/dd/yyyy'
                                        }}</span></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="head_txt">Phone : <span class="cart_txt">{{ item.phone }}</span></div>
                                </div>
                                <!-- <div class="col-lg-8"
                                     *ngIf="item?.height; else elseField">
                                    <div class="head_txt">Height & Weight :
                                        <span class="cart_txt">{{item.height}}
                                            &nbsp;&nbsp; {{item.weight}} lbs</span>
                                    </div>
                                    <ng-template #elseField>
                                        <div></div>
                                    </ng-template>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="head_txt">Email : <span class="cart_txt">{{ item.email }}</span></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="head_txt">Address :
                                        <span class="cart_txt">
                                            {{ item.address1 | titlecase }},
                                            <span *ngIf="item.address2">{{
                                                    item.address2 |
                                                        titlecase
                                                }},</span><br>
                                            {{ item.city | titlecase }},{{
                                                item.state |
                                                    titlecase
                                            }}
                                            {{ item.zip | titlecase }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="groupID">
                        <div class="row">
                            <div class="col-lg-6 col-xs-8">
                                <h6 class="cart-card-title">Payments Details</h6>
                            </div>
                        </div>
                        <div class="card demography">
                            <div class="plan-card-header apclnt-card-header "
                                 style="margin-left: 0.05rem;">
                                <div class="header_t">
                                    Group
                                </div>
                            </div>
                            <div class="head_txt">
                                Your group will be billed prior to start of the month.
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!groupID">
                        <div class="row">
                            <div class="col-8">
                                <h6 class="cart-card-title">Payments Details</h6>
                            </div>
                            <div *ngIf="typedisabled"
                                 class="col-4">
                                <div (click)="changeToPaymeny()"
                                     style="text-align: right;color: #9090f7;cursor: pointer;">Change
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cardType"
                             class="card demography">
                            <div class="">
                                <div class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    <h6 class="mb-0 py-2 f-600">
                                        <div class="header_t">
                                            BANK ACH
                                        </div>
                                    </h6>
                                </div>
                            </div>
                            <div *ngFor="let item of paymentData">
                                <div>
                                    <div class="head_txt">Name on the Account : <span
                                            class="cart_txt">{{ item.name }}</span>
                                    </div>

                                    <div class="head_txt">Routing Number : <span
                                            class="cart_txt">{{ item.routing }}</span>
                                    </div>
                                    <div class="head_txt">Account Number : <span
                                            class="cart_txt">{{ item.account }}</span>
                                    </div>
                                    <div class="head_txt">Account Type : <span
                                            class="cart_txt">{{ account_type }}</span>
                                    </div>
                                </div>
                                <div class="account_type1">
                                    <div style="color: red;text-align: center;">
                                        {{ errMsg }}
                                    </div>
                                    <div class="account_type1">
                                        <div style="color: red;text-align: center;">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!cardType"
                             class="card demography">
                            <div class="">
                                <div class="plan-card-header apclnt-card-header "
                                     style="margin-left: 0.05rem;">
                                    <h6 class="mb-0 py-2 f-600">
                                        <div class="header_t">
                                            {{ selectedCardType }}
                                        </div>
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="head_txt">Card Number: <span class="cart_txt">{{ cardData }}</span>
                                    </div>
                                    <p *ngIf="paymentData"
                                       class="head_txt">
                                        Name on the card : {{ paymentData[0].name }}
                                    </p>
                                    <!-- <div class="head_txt">Expiry Date: <span class="cart_txt">{{month}}/{{year}}</span></div> -->
                                </div>
                                <div class="account_type1">
                                    <div style="color: red;text-align: center;">
                                        {{ errorMessage }}
                                    </div>
                                    <div style="color: red;text-align: center;">
                                        {{ errMsg }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-xs-8">
                            <h6 class="cart-card-title"> Electronic Signature</h6>
                        </div>
                    </div>
                    <div class="card mobilecard">


                        <div class="signature">
                            <div class="row">
                                <div class="col-12">
                                    <label>
                                        <input (click)="onCheckbox($event)"
                                               formControlName="authorized"
                                               type="checkbox"><small>
                                        By checking this box, you
                                        acknowledge that
                                        your electronic signature as
                                        authorized
                                        representative for this application will have the same legal effect as a
                                        handwritten
                                        signature <span class="fieldMan">*</span>
                                    </small>
                                        <div *ngIf=" submitAttempt && formGroup.hasError('required', 'authorized')"
                                             class="errormsg">
                                            Checkbox is required
                                        </div>
                                    </label>
                                    <br>
                                    <label>
                                        <input formControlName="agree" type="checkbox">
                                        <small> I agree to the {{ companyName }} <a (click)="redirectToBeliefs()"
                                                                                    class="highlighttxt"> Terms
                                            and
                                            Conditions.</a> <span class="fieldMan">*</span>
                                        </small>

                                        <div *ngIf="submitAttempt && formGroup.hasError('required', 'agree')"
                                             class="errormsg">
                                            Checkbox is required
                                        </div>
                                    </label>

                                    <div class="col-md-6">
                                        <img [src]="member_signature" style="height: 80px; margin-bottom: 10px;">
                                        <input class="form-control"
                                               disabled
                                               type="text"
                                               value="{{applicantname}}">
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="browserInfo"
                             class="browserinfo">

                        <span *ngIf="browserInfo?.os_name">
                            {{ browserInfo.os_name }},
                        </span>
                            <span *ngIf="browserInfo?.os_version">
                            {{ browserInfo.os_version }},
                        </span>
                            <span *ngIf="browserInfo?.browser_name">
                            {{ browserInfo.browser_name }},
                        </span>
                            <!-- <span>
                                {{browserInfo.browser_version}}
                            </span> -->
                            <span *ngIf="browserInfo?.ip">
                            {{ browserInfo.ip }}
                        </span>


                        </div>


                    </div>
                </div>
                </div>
                <div class="col-md-5 col-lg-5 padding_demo">
                    <div class="right-column">
                    <div *ngIf="applicant.zipcode!==undefined "
                         class="card">
                        <div class="row">
                            <div class="healthflex-container">
                                <div class="planehead">Effective Start Date</div>
                                <div class="planehead"> {{ applicant.startdate | date:'MM/dd/yyyy' }}</div>
                            </div>

                        </div>

                    </div>
                    <div class="card">
                        <div class="row"
                             style="margin-bottom: 5%;">
                            <div class="col-lg-6 col-md-6 col-xs-6">
                                <div>Application Cart</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6"
                                 style=" text-align: right;">
                                <div (click)="backToPlanes()"
                                     *ngIf="typedisabled"
                                     class="returnPlane">
                                    <span style="color: white;">Return to Programs</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-8 col-md-8"
                                 style="font-weight: 600;">
                                Your Application Number
                            </div>
                            <div class="col-lg-4 col-md-4"
                                 style="text-align: right;font-weight: 600;">
                                {{ application_id }}
                            </div>
                        </div>
                    </div>
                    <app-applicationcart></app-applicationcart>

                    </div>
                </div>
            </div>

            <div class="row padding_demo mb-5">
                <div class="col-6 px-0">
                    <button (click)="changeToPaymeny()"
                            *ngIf="typedisabled"
                            class="btn btn-primary"
                            type="button">Previous
                    </button>
                </div>
                <div class="col-6 px-0"
                     style="text-align: right;">
                    <button (click)="submit()"
                            class="btn btn-success"
                            type="button">Submit Application
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="payment_view" class="container text-center mt-3">
    <div class="mb-4">
        <img src="assets/Sad.svg">
        <h5 class="mt-3 "><b>Ohhh.. Sorry, Your payment failed</b></h5>
        <h6 class=" mt-3">Would you like to try another payment method</h6>
        <button (click)="changeToPaymeny()" class="btn btn-success  mb-9" type="button">Yes Proceed</button>
    </div>
    <div class="">
        <hr class="failed_width mb-5">
        <div class="application_number ">
            Your Application Number : {{ application_id }}
            <i (click)="copy(application_id)" class="ri-file-copy-line td-icon-link "
               pTooltip="Copy Application Number"></i>
        </div>
        <div class="mt-3">
            Use the above application number if you decide to return to enrollment later
        </div>
        <div class="mt-3">
            The application number is also emailed to you.
        </div>
    </div>

</div>
