<div *ngIf="orderDetails==undefined">
    <p class="empty_page">Error:404</p>
</div>
<br>
<div *ngIf="orderDetails !=undefined"
     class="container mb-2 ">
    <div class="download_text">
        <span (click)="downloadSectionAsPDF()">Download your copy</span>
    </div>
    <div #orderInvoice
         class="order-confirmation-container"
         id="order-invoice">
        <div>
            <div class="order_header ">
                <div class="header-text">
                    <i class="ri-checkbox-circle-line succcess-check"></i>
                    <span>Thank You For Your Payment</span>
                </div>

            </div>
            <div id="invoice">
                <div class="row">
                    <div class="col-md-12">
                        <div *ngFor="let item of orderDetails"
                             class="applicants-info">
                            <div class="order_name"> Hi, <span
                                    class="applicantName">{{ item.acknowledge_by | titlecase }}</span></div>
                            <p class="help_text mb-0">Receipt for your Enrollment in <span>{{ plannames }}
                                Program</span></p>
                        </div>
                    </div>
                </div>

                <div class="invoice-section">
                    <h6 class="order_title">
                        Billed To:
                    </h6>
                    <div class="row">
                        <div class="col-md-6">
                            <ul *ngFor="let item of orderDetails"
                                class="billing-to text_margin">
                                <li class="f-500">
                                    {{ item.firstname | titlecase }}
                                    {{ item?.middlename | titlecase }}
                                    {{ item.lastname | titlecase }}
                                </li>
                                <li>
                                    {{ item.billing_address1 }},
                                    {{ item.billing_address2 }}
                                </li>
                                <li>
                                    {{ item.billing_city }},
                                    {{ item.billing_state }} ,
                                    {{ item.billing_zip }}
                                </li>
                                <li>
                                    {{ item.email }}
                                </li>
                                <li>
                                    {{ item.mobile }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div *ngFor="let item of orderDetails">
                                <table class="billing-table">
                                    <tbody>
                                    <tr>
                                        <td class="">
                                            Order ID
                                        </td>
                                        <td class="">

                                            <span>{{ item.order_id }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            Date & Time
                                        </td>
                                        <td class="">

                                            {{ item.created_at | date: 'd-MMM-y, h:mm:ss a' }}

                                        </td>
                                    </tr>

                                    <tr *ngIf="paymentData !=undefined">
                                        <td class="">
                                            Payment Method
                                        </td>
                                        <td class="">
                                            {{ this.orderDetails[0].payment_type }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="paymentData[0].payment_type =='CARD' && paymentData !=undefined">
                                        <td class="">
                                            Card Number
                                        </td>
                                        <td class="">
                                            {{ paymentData[0].card }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="this.paymentData[0].payment_type !='CARD' && paymentData !=undefined">
                                        <td class="">
                                            Routing Number
                                        </td>
                                        <td class="">
                                            {{ paymentData[0].routing }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="this.paymentData[0].payment_type !='CARD' && paymentData !=undefined">
                                        <td class="">
                                            Account Number
                                        </td>
                                        <td class="">
                                            {{ paymentData[0].account }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="paymentData !=undefined">
                                        <td class="">
                                            Confirmation Number
                                        </td>
                                        <td>
                                            {{ orderDetails[0].confirmation_number }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <p *ngFor="let item of orderDetails"
                               class="order-terms"> Terms
                                &
                                Conditions Accepted By :<span style="font-weight: 600;">
                                    {{ item.acknowledge_by | titlecase }} </span></p>
                        </div>
                    </div>
                </div>

                <div class="invoice-bill-info">
                    <div class=" order_table_header">
                        <div class="order_table_header-column">
                            <h6>Item</h6>
                        </div>
                        <div class="order_table_header-column text-right">
                            <h6>Price</h6>
                        </div>
                    </div>
                    <div class="">
                        <div class="order_new_table">

                            <div class="order-subheader">
                                <h6>Monthly Contribution</h6>
                            </div>

                            <div class="plan-price">
                                <div class="total_month_box">
                                    <h6 *ngFor="let order of orderMembers" class="member-data">
                                        {{ order.firstname | titlecase }} {{ order?.middlename | titlecase }} {{ order.lastname | titlecase }}
                                    </h6>
                                </div>

                                <div *ngFor="let item of cartProducts"
                                     class=" total_month_box">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h6 class="mb-0">{{ item.name }} Program</h6>
                                        </div>
                                        <div class="col-md-6  text-right">
                                            <h6 class="mb-0">{{ item.total_price | currency:'USD' }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let item of cart_addon_products"
                                 class="plan-price">
                                <div class="row ">
                                    <div class="col-md-6">
                                        {{ item.Name }}
                                    </div>
                                    <div class="col-md-6  text-right">
                                        {{ item.total_amount | currency:'USD' }}
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let item of cartFee"
                                 class="plan-price border-0">
                                <div class="row total_month_box">
                                    <div *ngIf="item.fee_name=='Nicotine Fee'"
                                         class="col-6">
                                        {{ item.fee_name }}
                                    </div>
                                    <div *ngIf="item.fee_name=='Nicotine Fee'"
                                         class="col-6  text-right">
                                        {{ item.fee | currency:'USD' }}
                                    </div>
                                </div>
                            </div>
                            <div class="plan-price-footer">
                                <div class="row ">
                                    <div *ngIf="monthlyContributionStartDate"
                                         class="col-md-6 total_price_order">
                                        <h6>Total Monthly Contribution</h6>
                                    </div>
                                    <div *ngIf="monthlyContribution"
                                         class="col-md-6  text-right ">
                                        <h6>{{ monthlyContribution | currency:'USD' }}</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="order_new_table mt-0">
                            <div class="order-subheader">
                                <h6>
                                    One - Time Fee
                                </h6>
                            </div>
                            <div *ngFor="let item of cartFee"
                                 class="plan-price">
                                <div class="row total_month_box">
                                    <div *ngIf="item.fee_name !='Nicotine Fee'"
                                         class="col-6">
                                        {{ item.fee_name }}
                                    </div>
                                    <div *ngIf="item.fee_name !='Nicotine Fee'"
                                         class="col-6  text-right">
                                        {{ item.fee | currency:'USD' }}
                                    </div>
                                </div>

                            </div>

                            <div *ngFor="let item of cartDiscount"
                                 class="plan-price">
                                <div class="row total_month_box">
                                    <div class="col-6">
                                        Group Discount
                                    </div>
                                    <div class="col-6  text-right">
                                        {{ item.discount | currency:'USD' }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="memberDiscount?.length >0"
                                 class="plan-price">
                                <div class="row total_month_box">
                                    <div class="col-6">
                                        Member Discount
                                    </div>
                                    <div *ngFor="let item of cartData"
                                         class="col-6  text-right">
                                        {{ item.discount | currency:'USD' }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="plan-price border-0"
                                 *ngFor="let data of cart_group_discount">
                                <div class="row total_month_box">
                                    <div class="col-md-6">
                                        {{data.name}}
                                    </div>
                                    <div class="col-md-6  text-right">
                                        {{data.discount | currency:'USD'}}
                                    </div>
                                </div>
                            </div> -->
                            <div class="plan-price-footer">
                                <div class="row total_month_price">
                                    <div class="col-md-6 total_price_order">
                                        <h6>Total One-time Fee</h6>
                                    </div>
                                    <div *ngIf="!a2COneTimeFee"
                                         class="col-md-6 total_price_order  text-right">
                                        <h6>{{ oneTimeFee | currency:'USD' }}</h6>
                                    </div>
                                    <div *ngIf="a2COneTimeFee"
                                         class="col-md-6 total_price_order  text-right">
                                        <h6>{{ a2COneTimeFee | currency:'USD' }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="plan-price-footer footer-success">
                            <div class="row total_month_price">
                                <div class="col-md-6 total_price_order">
                                    <h6>Total Payments Today</h6>
                                </div>
                                <div *ngFor="let item of cartData"
                                     class="col-md-6  text-right total_price_order">
                                    <h6>{{ item.total | currency:'USD' }}</h6>
                                </div>
                            </div>

                        </div>
                        <div class="plan-price-footer bg-white footer-danger">
                            <div class="row">
                                <div class="col-md-6 new_ontribution">
                                    <h6>Monthly Contribution starting {{
                                            startDate |
                                                date:'MM/dd/yyyy'
                                        }}</h6>
                                </div>
                                <div *ngIf="monthlyContribution"
                                     class="col-md-6  text-right new_ontribution">
                                    <h6>{{ monthlyContribution | currency:'USD' }}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4 mt-5">
                            <div class="col-md-12  text-center order_thankyou">
                                <h6>Thank you!</h6>
                            </div>
                            <div class="col-md-12 text-center">
                                <p class="thanks_msg">Thank you! Your payment will
                                    appear on your statement as <b><span *ngIf="!isDemoPortal">Galilee 4</span> {{ companyname }}</b>.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-md-12">
            <p>
                <i>
                    Note : A copy of
                    your receipt has been emailed to you. You will also receive an email with instructions to
                    set up your Member account access.
                    If these emails do not arrive promptly,
                    please check your spam folder.
                </i>
            </p>
        </div>
    </div>

</div>
