import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fromEvent, interval} from 'rxjs';
import SignaturePad from 'signature_pad';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {SharingService} from '../../../services/sharing.service';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ActivatedRoute, Router} from '@angular/router';

import {TestService} from '../../../services/behaviourService';
import {SessionService} from '../../../services/session.service';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-statementofbeliefs',
    templateUrl: './statementofbeliefs.component.html',
    styleUrls: ['./statementofbeliefs.component.scss']
})
export class StatementofbeliefsComponent implements OnInit {
    @ViewChild("canvas", {static: true}) canvas: ElementRef;
    @ViewChild('canvas', {static: true}) canvasElement: ElementRef;

    Form !: any;
    submitAttempt: boolean = false;
    firstDiv: boolean = true;
    secondDiv: boolean = false;
    thirdDiv: boolean = false;
    onTabClickData: any = 'esignature';
    signature: any;
    applicantname: any;
    showSignatureVerifyError: string;
    showSignatureVerifyError1: string;
    date = new Date();
    uploadedFile: any;
    imageUrl: any;
    signaturePad: SignaturePad;
    signatureImg: string;
    ipAddress: any;
    uploadedFilelogo: any;
    uploadedFileattachment: any;
    imageUrlLogo: any;
    imageUrlAttachment: any;

    message: string;
    signatureVAlid: any;
    est_date: string;
    companyId: any;
    family_id: any;
    name: any;
    fullname: string;
    cmpId: any;
    memId: any;
    cartId: any;
    applicant: any;
    memberdata: any;
    cart_id: any;
    decrypt_id: any;
    primaryInfo: any;
    enrollment_type: any;
    typedisabled: boolean = true;
    navigatedFrom: any;
    retrievedEmail: any;
    browserOSInfo: import("ngx-device-detector").DeviceInfo;
    data: any[] = [];
    agreementContent: any[] = [];
    agreementDesc: any[] = [];

    constructor(private formBuilder: UntypedFormBuilder, private services: TestService, private sessionService: SessionService, private objectService: TestService, private router: Router, private deviceService: DeviceDetectorService, private http: HttpClient,
                private service: SharingService, private toastr: ToastrService, private route: ActivatedRoute) {
        var applicant_primary = JSON.parse(localStorage.getItem('applicantprimary'));
        this.decrypt_id = this.route.snapshot.paramMap.get('enc_id');
        this.browserOSInfo = this.deviceService.getDeviceInfo();
        this.navigatedFrom = this.route.snapshot.paramMap.get('navigatedfrom');
        if (this.navigatedFrom == "summary") {
            (<HTMLInputElement>document.getElementById("header-navbar")).style.display = "none";
            (<HTMLInputElement>document.getElementById("disclaimer-txt")).style.display = "none";
            (<HTMLInputElement>document.getElementById("footer")).style.display = "none";
        }
        console.log(this.navigatedFrom);
        if (applicant_primary != null) {
            this.family_id = applicant_primary[0].family_id;
            this.fullname = (applicant_primary[0].middlename != null) ? applicant_primary[0].firstname + ' ' + applicant_primary[0].middlename + ' ' + applicant_primary[0].lastname : applicant_primary[0].firstname + ' ' + applicant_primary[0].lastname;
            this.name = applicant_primary[0].firstname.slice(0, 1) + '' + applicant_primary[0].lastname.slice(0, 1);
        }
    }

    ngOnInit(): void {

        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;

                this.getClientInfo();
                if (this.decrypt_id != undefined) {
                    this.DecryptApplication();
                }
            }
        })

        this.objectService.demographyObject.subscribe(result => {
            if (result) {
                this.applicant = result;
                this.memberdata = this.applicant.member_personal_data;
                this.cart_id = this.applicant.cart_data[0].id;
            } else {
                // this.router.navigate(['demographic']);
            }

        })
        interval(1 * 60).subscribe(t => {
            const _date = new Date();
            const options: any = {
                timeZone: "America/New_York", year: "numeric", month: "2-digit", day: "2-digit",
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // Use 12-hour clock with AM/PM
            };
            this.est_date = _date.toLocaleString("en-US", options);
        });

        this.Form = this.formBuilder.group({
            fullname: ['', Validators.required],
            uploadImage: [''],

        })
    }

    getClientInfo() {

        var url = 'GetClientInfo';
        let body = {
            company_id: this.companyId
        }
        this.agreementContent = [];

        this.agreementDesc = [];
        this.service.postData(url, body).subscribe((Object: any) => {
            if (Object.status == true) {
                this.data = Object.data[2].membership_agreement;
                this.agreementContent = this.data[0].agreementcontent;
                this.agreementContent.forEach(element => {
                    element.planDetailList.forEach((ele, i) => {
                        if (ele.desc.length > 0) {
                            if (this.agreementDesc.indexOf(ele.desc[0].name) === -1) {
                                this.agreementDesc.push(ele.desc[0].name);
                            }
                            this.agreementDesc.forEach((element, j: any) => {
                                let index = j + 1;
                                ele.desc[0].controlname = "initial" + index
                                this.Form.addControl("initial" + index, new UntypedFormControl(''));

                            });
                        }
                    });
                });
                this.Form.controls.fullname.setValue(this.fullname);
                this.Form.controls.initial1.setValue(this.name);
                this.Form.controls.initial2.setValue(this.name);
                this.Form.controls.initial3.setValue(this.name);
                this.Form.controls.initial4.setValue(this.name);
                this.onFocusoutname();
            }
        }, err => {
            console.log(err)
        })
    }

    onFocusoutname() {
        setTimeout(() => {
            let value: any = (<HTMLInputElement>document.getElementById("fullnameinput")).value;
            if (value != undefined) {
                let x = document.getElementById("fullnameinput");
                x.style.width = ((value.length + 4) * 7) + 'px'
            }
        }, 200);

    }

    ngAfterViewInit() {
        const canvas = this.canvasElement.nativeElement;
        this.signaturePad = new SignaturePad(canvas);
        this.resizeCanvas();

        this.signaturePad.onBegin = () => {
            console.log('Started drawing on the canvas');
        };
        // Debounce the resize event
        fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(() => this.resizeCanvas());
    }

    checkCanvas() {
        if (this.signaturePad.isEmpty()) {
            console.log('Canvas is empty');
        } else {
            console.log('Canvas has been drawn on');
        }
    }

    clear() {
        this.signaturePad.clear();
        this.signatureImg = '';
    }

    removeLogo() {
        this.uploadedFilelogo = undefined;
        this.Form.controls.uploadImage.setValue('');
    }

    uploadlogo(event) {
        this.uploadedFilelogo = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrlLogo = event.target.result;
        }
        reader.readAsDataURL(this.uploadedFilelogo);
    }

    uploadDoc(event) {
        this.uploadedFileattachment = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrlAttachment = event.target.result;
        }
        reader.readAsDataURL(this.uploadedFileattachment);
    }

    upload(event) {
        for (var i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 20971520) {
                (<HTMLInputElement>document.getElementById('modalLimitExceeds')).click();
                event.target.value = null;
            } else {
                this.uploadedFile = event.target.files[0];
                let reader = new FileReader();

                reader.onload = (event: any) => {
                    const img = new Image();
                    img.onload = () => {
                        const maxWidth = 400; // Set your desired maximum width
                        const maxHeight = 150; // Set your desired maximum height

                        let newWidth = img.width;
                        let newHeight = img.height;

                        // Resize image if it exceeds maxWidth or maxHeight
                        if (newWidth > maxWidth) {
                            newWidth = maxWidth;
                            newHeight = (newWidth / img.width) * img.height;
                        }

                        if (newHeight > maxHeight) {
                            newHeight = maxHeight;
                            newWidth = (newHeight / img.height) * img.width;
                        }

                        const canvas: HTMLCanvasElement = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        // Convert canvas content to data URL and set it as the image source
                        this.imageUrl = canvas.toDataURL();
                        this.message = '';
                    };

                    img.src = event.target.result;
                };

                reader.readAsDataURL(this.uploadedFile);

            }
        }
    }

    DecryptApplication() {
        var url = 'DecryptApplicantionID';
        var body = {
            decrypted_applicantion_id: this.decrypt_id
        }
        this.service.postData(url, body).subscribe((Object: any) => {
            if (Object != null) {
                this.retrievedEmail = Object.data.email
                this.newapplication(Object.data.applicantion_id)
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }

    newapplication(value) {
        var url = 'GetApplicantByApplicationID';
        let body = {
            company_id: this.companyId,
            application_id: value,
            email: this.retrievedEmail
        }
        this.service.postData(url, body).subscribe((newObj: any) => {
            this.applicant = newObj;
            if (this.applicant != null || this.applicant != "" || this.applicant != undefined) {
                this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Primary");
                this.family_id = this.primaryInfo[0].family_id;
                this.fullname = (this.primaryInfo[0].middlename != null) ? this.primaryInfo[0].firstname + ' ' + this.primaryInfo[0].middlename + ' ' + this.primaryInfo[0].lastname : this.primaryInfo[0].firstname + ' ' + this.primaryInfo[0].lastname;
                this.name = this.primaryInfo[0].firstname.slice(0, 1) + '' + this.primaryInfo[0].lastname.slice(0, 1);
                this.enrollment_type = this.primaryInfo[0].enrollment_type;
                if (this.enrollment_type == 'A2C') {
                    this.typedisabled = false;
                }
                this.Form.controls.fullname.setValue(this.fullname);
                this.onFocusoutname();
                this.Form.controls.initial1.setValue(this.name);
                this.Form.controls.initial2.setValue(this.name);
                this.Form.controls.initial3.setValue(this.name);
                this.Form.controls.initial4.setValue(this.name);
                this.sessionService.setData('member_id', this.primaryInfo[0].member_id);
                this.sessionService.setData('cart_id', this.applicant.cart_data[0].id);
                this.sessionService.setData("company", this.companyId);
                this.applicationCart();
            }

        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })

    }

    applicationCart() {
        var url = 'GetCartPlan/' + this.companyId + '/' + this.applicant.cart_data[0].id + '/' + this.applicant.member_personal_data[0].family_id;
        this.service.getData(url).subscribe((Object: any) => {
            if (!!Object) {
                this.services.appCartInfo(Object);
            }
        }, err => {
            console.log(err);
            this.toastr.error(err?.error?.message);
        })
    }

    onTabClick(data: any) {
        this.onTabClickData = data;
        if (data === 'text') {
            this.firstDiv = false;
            this.secondDiv = true;
            this.thirdDiv = false;
            this.uploadedFile = '';
            this.onfocusoutSignature();
            this.Form.controls.uploadImage.setValue('');
            this.signaturePad.clear();
        } else if (data === 'esignature') {
            this.firstDiv = true;
            this.secondDiv = false;
            this.thirdDiv = false;
            this.imageUrl = '';
            this.uploadedFile = '';
            this.Form.controls.enterText?.setValue('');
            this.Form.controls.uploadImage?.setValue('');
        }
    }

    onFocusoutInput(feature, formgroup) {
        this.Form.controls[formgroup].value.family_type = feature;
    }

    onfocusoutSignature() {
        this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
        this.message = '';
    }

    signatureField(value) {
        if (this.signaturePad.toData().length > 0) {
            this.message = "";
            this.signatureVAlid = value;
        } else {
            this.message = "Signature is required";
        }
    }

    submit(value) {
        this.submitAttempt = true;

        if (!this.Form.valid) {
            this.toastr.error("Name And Initials are required");
            return; // Exit the function if the form is not valid
        }

        // Check for signature validity using combined conditions
        const isSignatureValid = (this.signaturePad && !this.signaturePad.isEmpty()) ||
            (typeof this.signature !== 'undefined' && this.signature !== '') ||
            (typeof this.uploadedFile !== 'undefined' && this.uploadedFile !== '') ||
            (this.signatureVAlid === 'signatureValid' && this.signaturePad.toData().length > 0);


        if (!isSignatureValid) {
            this.message = "Signature is required";
            this.toastr.error(this.message);
            return; // Exit the function if the signature is not valid
        }

        // If the form is valid and the signature pad has a signature, proceed with submission
        this.submitsignature(value);

        // Resetting the form and other variables as necessary
        this.uploadedFile = '';
        this.Form.controls.enterText.setValue('');
    }

    submitsignature(value) {
        let formData: FormData = new FormData();
        if (this.signature) {
            formData.append('esignature', this.signature);
        } else if (this.onTabClickData == 'esignature') {
            this.signatureImg = this.signaturePad.toDataURL();
            formData.append('esignature', this.signatureImg);
            formData.append('esignature_type', 'esignature');
        } else if (this.onTabClickData === 'image') {
            formData.append('esignature', this.uploadedFile);
            formData.append('esignature_type', 'image');
        }
        if (this.uploadedFilelogo) {
            formData.append('logo', this.uploadedFilelogo);
        }
        formData.append('company_id', this.companyId);
        formData.append('browser_name', this.deviceService.browser);
        formData.append('browser_version', this.deviceService.browser_version);
        formData.append('os_name', this.browserOSInfo.os);
        formData.append('os_version', this.browserOSInfo.os_version);
        if (this.ipAddress != undefined) {
            formData.append('ip', this.ipAddress);
        } else {
            formData.append('ip', '');
        }

        formData.append('family_id', this.family_id);
        formData.append('name', value.fullname);
        formData.append('initial1', value.initial1);
        formData.append('initial2', value.initial2);
        formData.append('initial3', value.initial3);
        formData.append('initial4', value.initial4);
        var url = 'SaveSignature '
        this.service.postData(url, formData).subscribe((Object: any) => {
            if (Object != null) {
                this.Form.reset();
                this.uploadedFile = '';
                this.uploadedFilelogo = '';
                this.signatureImg = '';
                this.submitAttempt = false;
                this.clear();
                if (this.enrollment_type == 'A2C') {
                    this.router.navigate(['summary']);
                } else {
                    this.router.navigate(['health-basedquestions']);
                }
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
        console.log('Form submitted', value);
    }

    /* back(){
      this.router.navigate(['demographictab/'+this.companyId+'/'+this.memId+'/'+ this.cartId]);
    } */
    backto() {
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.service.getData(url).subscribe((Object: any) => {
            this.objectService.addCartPlan(Object);
            sessionStorage.setItem("cartplan", JSON.stringify(Object));
            this.router.navigate(['demographic']);
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }

    private resizeCanvas() {
        const canvas = this.canvasElement.nativeElement;
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const newWidth = canvas.offsetWidth * ratio;
        const newHeight = canvas.offsetHeight * ratio;

        if (canvas.width !== newWidth || canvas.height !== newHeight) {
            canvas.width = newWidth;
            canvas.height = newHeight;
            canvas.getContext('2d').scale(ratio, ratio);
            this.signaturePad.clear();
        }
    }

    // submit(value) {
    //   this.submitAttempt = true;
    //   if (!this.Form.valid) {
    //     this.toastr.error("Name And Initilas are required");
    //   }
    //   else if (this.signature != undefined && this.signature != '') {
    //     this.submitsignature(value)
    //     this.uploadedFile = '';
    //   }
    //   else if (this.uploadedFile != undefined && this.uploadedFile != '') {
    //     this.submitsignature(value);
    //     this.Form.controls.enterText.setValue('');
    //   }
    //   else if (this.signatureVAlid == 'signatureValid' && this.signaturePad.toData().length > 0) {
    //     this.submitsignature(value)
    //   }
    //   else {
    //     this.message = "Signature is required";
    //   }
    // }

    // submit(value) {
    //   this.submitAttempt = true;

    //   if (!this.Form.valid) {
    //     this.toastr.error("Name And Initials are required");
    //     return; // Exit the function if the form is not valid
    //   }

    //   if (
    //     (typeof this.signature !== 'undefined' && this.signature !== '') ||
    //     (typeof this.uploadedFile !== 'undefined' && this.uploadedFile !== '') ||
    //     (this.signatureVAlid === 'signatureValid' && this.signaturePad.toData().length > 0)
    //   ) {

    //    }
    //   else {
    //     this.message = "Signature is required";
    //     this.toastr.error(this.message);
    //     return; // Exit the function if the signature pad is empty
    //   }
    //   // If the form is valid and the signature pad has a signature, proceed with submission
    //   this.submitsignature(value);

    //   // Resetting the form and other variables as necessary
    //   this.uploadedFile = '';
    //   this.Form.controls.enterText.setValue('');
    //   // ... any other reset logic
    // }

    private handleTouchStart(event: TouchEvent): void {
        if (event.cancelable) {
            event.preventDefault();
        }
        const touch = event.touches[0];
        const mouseEvent = new MouseEvent('mousedown', {
            clientX: touch.clientX,
            clientY: touch.clientY
        });
        this.canvas.nativeElement.dispatchEvent(mouseEvent);
    }

    private handleTouchMove(event: TouchEvent): void {
        if (event.cancelable) {
            event.preventDefault();
        }
        const touch = event.touches[0];
        const mouseEvent = new MouseEvent('mousemove', {
            clientX: touch.clientX,
            clientY: touch.clientY
        });
        this.canvas.nativeElement.dispatchEvent(mouseEvent);
    }

    private handleTouchEnd(event: TouchEvent): void {
        if (event.cancelable) {
            event.preventDefault();
        }
        const mouseEvent = new MouseEvent('mouseup', {});
        this.canvas.nativeElement.dispatchEvent(mouseEvent);
    }
}
