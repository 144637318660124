<div class="container m-top"
     style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep "
             id="app2"
             style="z-index:6; padding-left: 30px;"><span>Demographics &amp; Dependents</span>
        </div>
        <div class="appProgStep "
             id="app1"
             style="z-index:5; padding-left: 40px;"><span>Member Agreement </span></div>
        <div class="appProgStep "
             id="app3"
             style="z-index:3;"><span>Health-Based Questions</span></div>
        <div class="appProgStep active"
             id="app4"
             style="z-index:2;"><span>Payment Details</span></div>
        <div class="appProgStep appProgStep-last"
             id="app5"
             style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <div class="row demomargin">
            <div class=" col-md-4 padding_demo">
                <div class="card">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <p class="cart-card-data">Effective Start Date</p>
                        </div>
                        <div class="col-4 pl-0">
                            <p class="cart-card-value "> {{ entryStartdate | date:'MM/dd/yyyy' }}</p>
                        </div>
                    </div>
                </div>
                <div class="card  ">
                    <div class="row">
                        <div class="col-md-6">
                            <p class="cart-card-title">Application Cart</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <p class="cart-card-data">Your Application Number</p>

                        </div>
                        <div class="col-md-4">
                            <p class="cart-card-value"> {{ application_id }}</p>
                        </div>
                    </div>
                </div>
                <app-applicationcart></app-applicationcart>
            </div>
            <div class=" col-md-8 padding_demo1">
                <div class="card">
                    <div class="plan-card-header apclnt-card-header "
                         style="margin-left: 0.05rem;">
                        <h6 class="mb-0 py-2 f-600"> Payment Details</h6>
                    </div>
                    <div class="cardsection form_margin">
                        <label *ngFor="let item of dynamic_paymentmethod; let i=index" class="margin_text">
                            <input (click)="changeCard(item.name)" (click)="setradio(item.type)"
                                   [(ngModel)]="typeOfCard" [checked]="i===0"
                                   name="cardtype" type="radio" value="{{item.name}}">
                            <span> {{ item.name }}</span>
                        </label>
                        <div *ngIf="base_form" class="mt-3">
                            <div class="cardsection">
                                <label>
                                    <input (click)="onHandleChange('Checking')"
                                           [(ngModel)]="paymentType"
                                           [checked]='true'
                                           name="checking"
                                           type="radio"
                                           value="Checking">
                                    <span> Checking</span>
                                </label>
                                <label style="margin-left: 7%;">
                                    <input (click)="onHandleChange('Savings')"
                                           [(ngModel)]="paymentType"
                                           name="savings"
                                           type="radio"
                                           value="Savings">
                                    <span> Savings</span>
                                </label>
                                <!--   <label style="margin-left: 7%;">
                                     <input type="radio"
                                            name="credit"
                                            value="creditinfo"
                                            (click)="setradio('creditinfo')"
                                            [checked]='true'
                                            [(ngModel)]="selectedLink"><span>
                                         Credit/Debit Card</span>
                                 </label>-->
                            </div>
                            <form [formGroup]="Banking_Form">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input class="form-control"
                                                   formControlName="name"
                                                   type="text">
                                            <div *ngIf="!Banking_Form.controls.name.valid && (Banking_Form.controls.name.touched || submitBankingAttempt)"
                                                 class="errormsg">
                                                Card Holder Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input #routingNumber
                                                   (keypress)="numberOnly($event)"
                                                   (keyup)=onKeyUpEnterRoutingNumber(routingNumber.value)
                                                   class="form-control"
                                                   formControlName="routingNumber"
                                                   placeholder="Routing Number"
                                                   type="text">
                                            <div *ngIf="!Banking_Form.controls.routingNumber.valid && (Banking_Form.controls.routingNumber.touched || submitBankingAttempt)"
                                                 class="errormsg">
                                                Routing Number is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input #selectListConfirmRoutingNumber
                                                   (copy)="onCopy($event)"
                                                   (keypress)="numberOnly($event)"
                                                   (keyup)="onKeyUpRoutingNumberMismatch(selectListConfirmRoutingNumber.value)"
                                                   (paste)="onPaste($event)"
                                                   class="form-control"
                                                   formControlName="reenterRouting"
                                                   placeholder="Re-enter Routing Number"
                                                   type="text">
                                            <div *ngIf="(!Banking_Form.controls.reenterRouting.dirty && !Banking_Form.controls.reenterRouting.valid) && (Banking_Form.controls.reenterRouting.touched || submitBankingAttempt)"
                                                 class="errormsg">
                                                Re-enter Routing Number is required
                                            </div>
                                            <div *ngIf="Banking_Form.controls.reenterRouting.dirty && routingNumberMismatch"
                                                 class="errormsg">
                                                Routing Number and Re-enter Routing Number do not match
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input #accountNumber
                                                   (keypress)="numberOnly($event)"
                                                   (keyup)=onKeyUpEnterAccountNumber(accountNumber.value)
                                                   class="form-control"
                                                   formControlName="accountNumber"
                                                   minlength="4"
                                                   placeholder="Account Number"
                                                   type="text">
                                            <div *ngIf="!Banking_Form.controls.accountNumber.valid && (Banking_Form.controls.accountNumber.touched || submitBankingAttempt)"
                                                 class="errormsg">
                                                Account Number is required minimum length 4 digits
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input #selectListConfirmAccountNumber
                                                   (copy)="onCopy($event)"
                                                   (keypress)="numberOnly($event)"
                                                   (keyup)="onKeyUpAccountNumberMismatch(selectListConfirmAccountNumber.value)"
                                                   (paste)="onPaste($event)"
                                                   class="form-control"
                                                   formControlName="reenterAccount"
                                                   minlength="4"
                                                   placeholder="Re-enter Account Number"
                                                   type="text">
                                            <div *ngIf="(!Banking_Form.controls.reenterAccount.dirty && !Banking_Form.controls.reenterAccount.valid) && (Banking_Form.controls.reenterAccount.touched || submitBankingAttempt)"
                                                 class="errormsg">
                                                Re enter Account Number is required
                                            </div>
                                            <div *ngIf="Banking_Form.controls.reenterAccount.dirty && accountNumberMismatch"
                                                 class="errormsg">
                                                Account Number and Re-enter Account Number do not match
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="!base_form" class="mt-3">
                            <!-- <div class="cardsection">
                                <label>
                                    <input type="radio"
                                           name="cardtype"
                                           (click)="changeCard('CreditCard')"
                                           value="CreditCard"
                                           [checked]='true'
                                           [(ngModel)]="typeOfCard">
                                    <span> Credit Card</span>
                                </label>
                                <label style="margin-left: 7%;">
                                    <input type="radio"
                                           name="cardtype"
                                           (click)="changeCard('DebitCard')"
                                           value="DebitCard"
                                           [(ngModel)]="typeOfCard">
                                    <span> Debit Card</span>
                                </label>
                                 <label style="    margin-left: 7%;">
                                    <input type="radio"
                                           name="bank"
                                           value="bankinfo"
                                           (click)="setradio('bankinfo')"
                                           [(ngModel)]="selectedLink"><span> Bank ACH</span>
                                </label>
                            </div> -->
                            <form [formGroup]="Credit_Form">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input class="form-control"
                                                   formControlName="name"
                                                   placeholder="Card Holder Name"
                                                   type="text">
                                            <div *ngIf="!Credit_Form.controls.name.valid && (Credit_Form.controls.name.touched || submitCardAttempt)"
                                                 class="errormsg">
                                                Card Holder Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input #SelectList
                                                   (keypress)="numberOnly($event)"
                                                   (keyup)="onKeyup(SelectList.value)"
                                                   class="form-control"
                                                   formControlName="cardNumber"
                                                   maxlength="16"
                                                   placeholder="Card Number"
                                                   type="text"
                                                   (paste)="removeSpaces($event)">
                                            <div *ngIf="!Credit_Form.controls.cardNumber.valid && (Credit_Form.controls.cardNumber.touched || submitCardAttempt)"
                                                 class="errormsg">
                                                Card Number is required
                                            </div>
                                            <div *ngIf="(Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && notValidCardNumber) || (Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && !checkCardValid)"
                                                 class="errormsg">
                                                Please enter a Valid Card Number
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="form-group mb-0">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <input #selectListExpiryMonth
                                                                   (keypress)="numberOnly($event)"
                                                                   (keyup)="onKeyupExpiryMonth(selectListExpiryMonth.value)"
                                                                   class="form-control"
                                                                   formControlName="expiryMonth"
                                                                   maxlength="2"
                                                                   name="month"
                                                                   placeholder="MM"
                                                                   size="2"
                                                                   type="text"/>
                                                        </div>
                                                        <div class="col-2">
                                                            <span class="slash_symbol">/</span>
                                                        </div>
                                                        <div class="col-5 pl-0">
                                                            <input #selectListExpiryYear
                                                                   (keypress)="numberOnly($event)"
                                                                   (keyup)="onKeyupExpiryYear(selectListExpiryYear.value)"
                                                                   class="form-control"
                                                                   formControlName="expiryYear"
                                                                   maxlength="2"
                                                                   name="year"
                                                                   placeholder="YY"
                                                                   size="2"
                                                                   type="text"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="(!Credit_Form.controls.expiryMonth.dirty && !Credit_Form.controls.expiryMonth.valid) && (Credit_Form.controls.expiryMonth.touched || submitCardAttempt) || (!Credit_Form.controls.expiryYear.dirty && !Credit_Form.controls.expiryYear.valid) && (Credit_Form.controls.expiryYear.touched || submitCardAttempt)"
                                                     class="errormsg">
                                                    Expiration Date is required
                                                </div>
                                                <div *ngIf="(Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && enterTwoDigitMonth)"
                                                     class="errormsg">
                                                    Please Use 'MM/YY' format
                                                </div>
                                                <div *ngIf="((Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && enterValidMonth) || (Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && invalidMonthEnteredValidation) || (Credit_Form.controls.expiryYear.dirty && !enterValidYear)) && (!enterTwoDigitMonth) "
                                                     class="errormsg">
                                                    Please enter Valid Expiration Date
                                                </div>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <div class="form-group">
                                                    <input (focusout)="oncvvfocusout()"
                                                           (keypress)="numberOnly($event)"
                                                           class="form-control"
                                                           formControlName="cvv"
                                                           id="cvvvalue"
                                                           maxlength="4"
                                                           placeholder="CVV"
                                                           type="text">
                                                </div>
                                                <div *ngIf="!Credit_Form.controls.cvv.valid && (Credit_Form.controls.cvv.touched || submitCardAttempt)"
                                                     class="errormsg">
                                                    CVV is required
                                                </div>
                                                <div class="errormsg"
                                                     id="cvverror">
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </form>
                        </div>
                        <div class="row">
                            <div class="col-sm-12"
                                 style=" margin-top: -2px;">
                                <i style="font-size: 12px;color: #c1c1c1;">Transactions are secure and encrypted.</i>
                            </div>
                        </div>
                        <div style="margin-top:15px">
                            <h6 class="billinghdng">Billing Address</h6>
                            <label>
                                <input (change)="handleChange($event)"
                                       [checked]="checkedCondition"
                                       type="checkbox">
                                <span class="useshipping"> Use Shipping Address</span>
                            </label>
                            <form *ngIf="showShippingAddress"
                                  [formGroup]="Billing_Form">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select class="form-control"
                                                    formControlName="country_billing"
                                                    style="margin-bottom: 15px;">
                                                <option *ngFor="let item of country_arr">{{ item }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input (keypress)="numberOnly($event)"
                                                   class="form-control"
                                                   formControlName="zipCode_billing"
                                                   maxlength="5"
                                                   placeholder="ZIP Code"
                                                   type="text">
                                            <div *ngIf="!Billing_Form.controls.zipCode_billing.valid && (Billing_Form.controls.zipCode_billing.touched || submitPaymentAttempt)"
                                                 class="errormsg">
                                                ZIP Code is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control"
                                           formControlName="address1_billing"
                                           placeholder="Address 1"
                                           type="text">
                                    <div *ngIf="!Billing_Form.controls.address1_billing.valid && (Billing_Form.controls.address1_billing.touched || submitPaymentAttempt)"
                                         class="errormsg">
                                        Address 1 is required
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control"
                                           formControlName="address2_billing"
                                           placeholder="Address 2"
                                           type="text">
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input class="form-control"
                                                   formControlName="city_billing"
                                                   placeholder="City"
                                                   type="text">
                                            <div *ngIf="!Billing_Form.controls.city_billing.valid && (Billing_Form.controls.city_billing.touched || submitPaymentAttempt)"
                                                 class="errormsg">
                                                City is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control"
                                                    formControlName="state_billing">
                                                <option [ngValue]="null"
                                                        disabled
                                                        selected
                                                        value="">Select Your State
                                                </option>
                                                <option *ngFor="let item of states"
                                                        value={{item.name}}>{{ item.name }}
                                                </option>
                                            </select>
                                            <div *ngIf="!Billing_Form.controls.state_billing.valid && (Billing_Form.controls.state_billing.touched || submitPaymentAttempt)"
                                                 class="errormsg">
                                                State is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="backtoQutions()"
                                    class="btn btn-primary"
                                    type="button">Previous
                            </button>
                        </div>
                        <!------*ngIf="!submitTermsAttempt"-->
                        <div class="col-6"
                             style="text-align: right;">
                            <button (click)="onPayment()"
                                    class="btn btn-success"
                                    type="button">Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
