import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-cartconfirmation',
  templateUrl: './cartconfirmation.component.html',
  styleUrl: './cartconfirmation.component.scss'
})
export class CartconfirmationComponent {
  companyId: any;
  agent_text: any;
  route_company_name: any;
  orderDetails: any;
  paymentData: any;
  cartFee: any;
  cartData: any;
  memberDiscount: any;
  additionalMember: any;
  oneTimeFee: any;
  cartProducts: any;
  monthly_contribution: any;
  agentInfo: any;

  constructor(private service: SharingService, private router: Router,) {
    var data = JSON.parse(localStorage.getItem('order_details'));
    if (data != undefined && data != '') {
      this.orderDetails = data?.order_details;
      this.paymentData = data?.paymentdata
      this.cartFee = data?.cart_fee;
      this.cartData = data?.cart_data;
      this.memberDiscount = data?.agent_cart_discount;
      this.additionalMember = data?.additional_member_fee;
      this.oneTimeFee = data?.a2c_total_one_time_fee
      this.cartProducts = data?.cart_products;
      this.monthly_contribution = data?.monthly_contribution;
      if (data?.agent_info) {
        this.agentInfo = data?.agent_info;
      }
    }
  }

  ngOnInit(): void {
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyId = data.company_id;
        this.agent_text = data.agent_text
        this.route_company_name = data.route_company
      }
    });
  }
  backToEnrollment(){
    this.router.navigate(['/d2c/enrollment']);
  }
}
