<div class="container">
    <div class="row user-information-card">
        <div class="container">
            <div class="enrlmnt-demographic">
                <div class="stepper">
                    <div class="step active"></div>
                    <div class="step active"></div>
                    <div class="step active"></div>
                    <div class="step active"></div>
                    <div class="step active"></div>
                </div>
            </div>
            <div class="progress_bar">
                <ul class="progess-bar mb-7">
                    <li class="active">Enrollment/Product Pricing</li>
                    <li>Payment Details</li>
                    <li>Terms & Condition</li>
                    <li>Summary</li>
                    <li>Confirmation</li>
                </ul>
            </div>
        </div>

        <div *ngIf="hasSubscribedProduct" class="col-md-12 mb-3">
            <div class="card-body">
                <h6 class="title-mainhead">
                    <span>Product & Pricing</span>
                </h6>
                <table class="table table-bordered">
                    <thead class="thead-light product_table">
                        <tr>

                            <th scope="col">Product/Package</th>
                            <th scope="col">Family Type</th>
                            <th scope="col" *ngIf="selectedRecordForProducts && selectedRecordForProducts[0]?.member_age_min >= 18">Age Group</th>
                            <th scope="col">Price</th>
                            <th scope="col">Package/Feature</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of selectedRecordForProducts; let i=index">

                            <td class="product_txt">{{ item.product_name }} <br><small *ngIf="item.short_description">({{ item.short_description }})</small></td>
                            <td class="product_txt">{{ item.family_type_name }}</td>
                            <td class="product_txt" *ngIf="item.member_age_min >= 18">{{ item.member_age_min }}-{{item.member_age_max}}</td>
                            <td class="product_txt">${{ item.price }}</td>
                            <td class="product_txt"><a href="{{this.public_url+'#planDetails'}}"
                                    target="_blank">Details</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12 mb-3">
            <form [formGroup]="newForm" class="primengDateNTable">

                <!--Agent code box, Display once code applied via URL -->
                <div *ngIf="(showAgentCodeSection && !hideAgentSection) && !agent_id_from_url_param" class="card shadow-sm mb-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 form-group code-field">
                                <label class=""><span>{{ agent_text }} Code </span><span
                                        class="optional">(Optional)</span></label>
                                <div class="input-group">
                                    <input (keyup)="showApplyBtn()" [readonly]="agentCodeParam"
                                        class="form-control marginTop" formControlName="agentCode"
                                        placeholder="{{agent_text}} Code" type="text">

                                    <button *ngIf="isAgentCodeApplied" class="input-group-text applied">Applied</button>
                                    <button (click)="applyAgentCode()" *ngIf="!isAgentCodeApplied"
                                        class="input-group-text">{{ applyBtnText }}
                                    </button>
                                </div>
                                <small class="errormsg">
                                    {{ agentCodeErrorMsg }}
                                </small>
                            </div>
                            <div class="col-md-6">
                                <div *ngIf="agentInfo?.firstname" class="info-box">
                                    <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname
                                            }}</span>
                                        <span *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span>
                                    </p>
                                    <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                                    <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card shadow-sm">
                    <div class="card-body ">
                        <h6 class="title-mainhead">
                            <span>User Information</span>
                        </h6>

                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class=" "><span>First Name</span> <span class="fieldMan">*</span>
                                </label>
                                <input class="form-control marginTop" formControlName="firstname" maxlength="25"
                                    placeholder="First Name" type="text">
                                <small
                                    *ngIf="!newForm.controls['firstname'].valid && (newForm.controls['firstname'].touched || submitAttempt)"
                                    class="errormsg">
                                    First Name is required.
                                </small>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class=" "><span>Last Name</span> <span class="fieldMan">*</span>
                                </label>
                                <input class="form-control marginTop" formControlName="lastname" maxlength="25"
                                    placeholder="Last Name" type="text">
                                <small
                                    *ngIf="!newForm.controls['lastname'].valid && (newForm.controls['lastname'].touched || submitAttempt)"
                                    class="errormsg">
                                    Last Name is required.
                                </small>
                            </div>
                        </div>
                            <div class="row">
                            <div class="col-md-6 form-group">
                                <label class=" "><span>Email</span> <span class="fieldMan">*</span></label>
                                <input class="form-control marginTop" formControlName="email" placeholder="Email"
                                    type="text">
                                <small *ngIf="errorMsgShow" class="errormsg">
                                    {{ emailError }}
                                </small>
                                <small
                                    *ngIf="!newForm.controls['email'].valid && (newForm.controls['email'].touched || submitAttempt)"
                                    class="errormsg">
                                    Email is required.
                                </small>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class=" "><span>Phone </span> <span class="fieldMan">*</span> 
                                     <span class="check_otp mt-2" *ngIf="otp_notification=='1'"><input type="checkbox"  formControlName='otp_notification_check'> 
                                   <span class="otp_text"> Please check this box to receive OTP & notifications via SMS.</span></span> </label>
                                <input (focusout)="onleavePhonenumber()"
                                    (keypress)="emailValidation($event.target.value);numberOnly($event)"
                                    autocomplete="off" class="form-control marginTop" formControlName="phone"
                                    id="phone-number" maxlength="12" appPhonenumberValidation placeholder="Phone"
                                    type="text">
                                <small *ngIf="errorMsgShow" class="errormsg"> {{ phoneerrmsg }}</small>
                                <small
                                    *ngIf="!newForm.controls['phone'].valid && (newForm.controls['phone'].touched || submitAttempt)"
                                    class="errormsg">
                                    Phone is required.
                                </small>
                                <small class="errormsg">
                                    {{ phnumbererrmsg }}
                                </small>           
                            </div>
                        </div>
                        <h6 class="title-head">
                            <span>Primary Applicant Information</span>
                        </h6>
                        <div class="personalInfo">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col-md-9 form-group">
                                            <label class="">DOB <span class="fieldMan">*</span> (MM/DD/YYYY)</label>
                                            <p-calendar (onInput)="onSearchChangeT(newForm.value)"
                                                (onSelect)="onSearchChange($event)" [disabledDates]="invalidDates1"
                                                [maxDate]="minimumDate" [monthNavigator]="true" [showIcon]="true"
                                                [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                [yearNavigator]="true" [yearRange]="'1952:' + maxDate.getFullYear()"
                                                formControlName="dob" inputId="navigators" inputId="icon"
                                                placeholder="mm/dd/yyyy">
                                            </p-calendar>
                                            <small
                                                *ngIf="!newForm.controls['dob'].valid && (newForm.controls['dob'].touched || submitAttempt)"
                                                class="errormsg" style="margin-top: 10px;">
                                                DOB is required
                                            </small>
                                            <small class="errormsg mt-2">{{ parentAge }}</small>
                                            <small class="errormsg">{{ parentAge1 }}</small>
                                            <small class="text_info mb-0 mt-2">
                                                <i class="fa fa-question-circle-o" data-bs-placement="top"
                                                    data-bs-toggle="tooltip"
                                                    title="Age is automatically calculated from the DOB."></i> Age is
                                                automatically calculated
                                                from the DOB.
                                            </small>
                                            <small class="errormsg"
                                                *ngIf="newForm.controls.age.errors?.ageOutOfRange && (newForm.controls['dob'].touched || submitAttempt)">
                                                Age must be between {{selectedRecordForProducts[0]?.member_age_min}} and
                                                {{selectedRecordForProducts[0]?.member_age_max}}.
                                            </small>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class=" alignCenter">Age</label>
                                            <input [readonly]="true" class="form-control" formControlName="age"
                                                placeholder="0">

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <label class="d-block">Gender <span class="fieldMan">*</span></label>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                            value="Male">
                                        <label class="form-check-label"> Male</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2">
                                        <input class="form-check-input" formControlName="gender" type="radio"
                                            value="Female">
                                        <label class="form-check-label"> Female</label>
                                    </div>
                                    <small
                                        *ngIf="!newForm.controls['gender'].valid && (newForm.controls['gender'].touched || submitAttempt)"
                                        class="errormsg">
                                        Gender is required
                                    </small>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 form-group has-float-label">
                                        <label class="  form-control-placeholder" for="address1">Address1<span
                                                class="fieldMan">*</span></label>
                                        <input (blur)="InputChange('address1')" autocomplete="off" class="form-control"
                                            formControlName="address1" id="address1" placeholder="Address1" type="text">

                                        <div *ngIf="!newForm.controls['address1'].valid && (newForm.controls['address1'].touched || submitAttempt)"
                                            class="errormsg">
                                            Address is required
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group has-float-label">
                                        <label class="  form-control-placeholder" for="address2">Apt/Suite
                                        </label>
                                        <input autocomplete="off" class="form-control" formControlName="address2"
                                            id="address2" placeholder="Apt/Suite" type="text">

                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <label class="required form-control-placeholder" for="city">City
                                        </label>
                                        <input autocomplete="off" class="form-control" formControlName="city" id="city"
                                            placeholder="City" type="text">
                                        <div *ngIf="!newForm.controls['city'].valid && (newForm.controls['city'].touched || submitAttempt)"
                                            class="errormsg">
                                            City is required
                                        </div>
                                    </div>
                                    <div class="col-md-4 form-group has-float-label">
                                        <label class="required form-label" for="state"> State </label>
                                        <select (change)="checkIfActiveState($event)"
                                            aria-label="Default select example" class="form-control form-select"
                                            formControlName="state" id="state">
                                            <option Value="" disabled>Select Your State</option>
                                            <option *ngFor="let item of usaStates" [value]=item.name>{{ item.name }}
                                            </option>
                                        </select>

                                        <div *ngIf="!newForm.controls['state'].valid && (newForm.controls['state'].touched || submitAttempt)"
                                            class="errormsg">
                                            State is required
                                        </div>

                                    </div>
                                    <div class="col-md-2 form-group has-float-label">
                                        <label class="required form-label" for="zip"> Zip Code </label>
                                        <input class="form-control" formControlName="zip" id="zip" maxlength="5"
                                            numbersOnly placeholder="Zip Code" type="text">

                                        <div *ngIf="!newForm.controls['zip'].valid && (newForm.controls['zip'].touched || submitAttempt)"
                                            class="errormsg">
                                            Zip Code is required
                                        </div>
                                        <span
                                            *ngIf="newForm.controls['zip'].hasError('pattern') && (newForm.controls['zip'].dirty )"
                                            class="errormsg">Please
                                            enter valid 5 digit zip code</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="enableSpouse()">
                        <h6 class="title-head" *ngIf="enableSpouse()">
                            <div class="form-check">
                                <input
                                    (change)="addEmployee($event,'spouse', newForm.value);accenrollment(newForm.value)"
                                    [checked]="spouse_check" class="form-check-input" id="spousecheckbox"
                                    type="checkbox">
                                <label class="form-check-label"> Spouse/Domestic Partner</label>
                            </div>
                            <div class="sub_text">Please select the check box to add spouse</div>
                        </h6>

                        <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index" class=" ">


                            <div class="" formArrayName="spouse_Array">
                                <div [formGroupName]="empIndex1" class="">
                                    <label class="d-flex gap-2 mb-3">
                                        <input (change)="checkBoXChange(empIndex1,'spouse_Array')"
                                            formControlName="checkBox" type="checkbox">
                                        <span>Check if Spouse address is same as Primary address</span>
                                    </label>
                                    <div class="row">
                                        <div class="col-md-4 form-group mb-2">
                                            <label class="">First Name <span class="fieldMan">*</span></label>
                                            <input autocomplete="off" class="form-control"
                                                formControlName="spouseFirstname" maxlength="25"
                                                placeholder="First Name" type="text">
                                            <small
                                                *ngIf="!spouseForm.get('spouseFirstname').valid && (spouseForm.controls.spouseFirstname.touched || submitAttempt)"
                                                class="errormsg">
                                                First Name is required
                                            </small>
                                        </div>
                                        <div class="col-md-4 form-group mb-2">
                                            <label class="">Last Name <span class="fieldMan">*</span></label>
                                            <input autocomplete="off" class="form-control"
                                                formControlName="spouseLastname" maxlength="25" placeholder="Last Name"
                                                type="text">
                                            <small
                                                *ngIf="!spouseForm.controls.spouseLastname.valid && (spouseForm.controls.spouseLastname.touched || submitAttempt)"
                                                class="errormsg">
                                                Last Name is required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">

                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 form-group mb-0">
                                            <div class="row">
                                                <div class="form-group col-md-9 ">
                                                    <label class="">DOB <span class="fieldMan">*</span>
                                                        (MM/DD/YYYY)
                                                    </label>
                                                    <p-calendar (onInput)="spouseAgeCalT(newForm.value)"
                                                        (onSelect)="spouseAgeCal($event,empIndex1,'spouseDob' + empIndex1)"
                                                        [disabledDates]="invalidDates1" [maxDate]="minimumDate"
                                                        [monthNavigator]="true" [showIcon]="true"
                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                        [yearNavigator]="true"
                                                        [yearRange]="'1952:'+ maxDate.getFullYear()"
                                                        formControlName="spouseDob" inputId="navigators" inputId="icon"
                                                        inputId="{{'spouseDob' + empIndex1}}" placeholder="mm/dd/yyyy">
                                                    </p-calendar>
                                                    <small
                                                        *ngIf="!spouseForm.controls.spouseDob.valid && (spouseForm.controls.spouseDob.touched || submitAttempt)"
                                                        class="errormsg mt-2">
                                                        DOB is required
                                                    </small>
                                                    <small class="errormsg mt-2">{{ spouseAgeMsg }}</small>
                                                    <small class="text_info mb-0">
                                                        <i class="fa fa-question-circle-o" data-bs-placement="top"
                                                            data-bs-toggle="tooltip"
                                                            title="Age is automatically calculated from the DOB."></i>
                                                        Age is automatically
                                                        calculated
                                                        from the DOB.
                                                    </small>
                                                </div>
                                                <div class="col-md-3 form-group ">
                                                    <label class=" alignCenter">Age</label>
                                                    <input [readonly]="true" class="form-control"
                                                        formControlName="spouseAge" placeholder="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4 ">
                                            <label class="d-block">Gender <span class="fieldMan">*</span></label>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="spouseGender"
                                                    id="spousemale" type="radio" value="Male">
                                                <label class="form-check-label" for="spousemale"> Male</label>
                                            </div>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="spouseGender"
                                                    id="spousefemale" type="radio" value="Female">
                                                <label class="form-check-label" for="spousefemale"> Female</label>
                                            </div>
                                            <small
                                                *ngIf="!spouseForm.controls.spouseGender.valid && (spouseForm.controls.spouseGender.touched || submitAttempt)"
                                                class="errormsg">
                                                Gender is required
                                            </small>
                                        </div>
                                        <div class="col-md-5 form-group mb-0">
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group has-float-label">
                                                <label class="  form-control-placeholder" for="address1">Address1<span
                                                        class="fieldMan">*</span>
                                                </label>
                                                <input [class.stateclass]="spouseForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="address1"
                                                    placeholder="Address1" type="text">

                                                <div *ngIf="!spouseForm.controls.address1.valid && (spouseForm.controls.address1.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Address is required
                                                </div>
                                            </div>
                                            <div class="col-md-6 form-group has-float-label">
                                                <label class="  form-control-placeholder" for="address1">Apt/Suite
                                                </label>
                                                <input [class.stateclass]="spouseForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="address2"
                                                    id="address2" placeholder="Apt/Suite" type="text">

                                            </div>
                                            <div class="col-md-4 form-group has-float-label">
                                                <label class="required form-control-placeholder" for="city">City
                                                </label>
                                                <input [class.stateclass]="spouseForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="city"
                                                    id="city" placeholder="City" type="text">
                                                <div *ngIf="!spouseForm.controls.city.valid && (spouseForm.controls.city.touched || submitAttempt)"
                                                    class="errormsg">
                                                    City is required
                                                </div>
                                            </div>
                                            <div class="col-md-4 form-group has-float-label">
                                                <label class="required form-label" for="state"> State </label>
                                                <select (change)="checkIfActiveState($event)"
                                                    [class.stateclass]="spouseForm.controls.disabled.value? true:''"
                                                    aria-label="Default select example" class="form-control form-select"
                                                    formControlName="state" id="state">
                                                    <option Value="" disabled>Select Your State</option>
                                                    <option *ngFor="let item of usaStates" [value]=item.name>{{
                                                        item.name }}
                                                    </option>
                                                </select>

                                                <div *ngIf="!spouseForm.controls.state.valid && (spouseForm.controls.state.touched || submitAttempt)"
                                                    class="errormsg">
                                                    State is required
                                                </div>

                                            </div>
                                            <div class="col-md-2 form-group has-float-label">
                                                <label class="required form-label" for="zip"> Zip Code </label>
                                                <input [class.stateclass]="spouseForm.controls.disabled.value? true:''"
                                                    class="form-control" formControlName="zip" id="zip" maxlength="5"
                                                    numbersOnly placeholder="Zip Code" type="text">

                                                <div *ngIf="!spouseForm.controls.zip.valid && (spouseForm.controls.zip.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Zip Code is required
                                                </div>
                                                <span
                                                    *ngIf="spouseForm.controls['zip'].hasError('pattern') && (spouseForm.controls['zip'].dirty )"
                                                    class="errormsg">Please
                                                    enter valid 5 digit zip code</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="enableChild()">
                        <h6 class="title-head" *ngIf="enableChild()">
                            <div class="form-check">
                                <input (change)="addChild($event,'child')" [checked]="child_check"
                                    [disabled]="addchilddisabled" class="form-check-input" id="childcheckbox"
                                    type="checkbox">
                                <label class="form-check-label" for="childcheckbox"><span *ngIf="!hasSubscribedProduct">Child Information</span> <span *ngIf="hasSubscribedProduct">{{dependentLabel}} Information</span> </label>
                            </div>
                            <div class="sub_text">Please select the check box to add <span *ngIf="!hasSubscribedProduct">child</span><span *ngIf="hasSubscribedProduct">{{dependentLabel}}</span> </div>
                        </h6>

                        <div formArrayName="child_Array" *ngIf="child_Array().controls.length > 0">


                            <div *ngFor="let childForm of child_Array().controls; let empIndex=index" class="">
                                <div class="form-card-header px-3 py-1 mb-1">
                                    <h6 class="title"><span *ngIf="!hasSubscribedProduct">child</span><span *ngIf="hasSubscribedProduct">{{dependentLabel}}</span> - {{ empIndex + 1 }}</h6>

                                    <span>
                                        <button
                                            (click)="removeEmployee(empIndex,childForm.value);accenrollment(newForm.value)"
                                            class="btn-remove mt-3" type="submit">
                                            Remove </button>
                                        <!-- <i class="fa fa-trash-o delete_btn"
                                            (click)="removeEmployee(empIndex,childForm.value)"></i> -->
                                    </span>

                                </div>
                                <div [formGroupName]="empIndex" class="card-body">
                                    <label class="d-flex gap-2 mb-3">
                                        <input (change)="checkBoXChange(empIndex,'child_Array')"
                                            formControlName="checkBox" type="checkbox">
                                        <span>Check if <span *ngIf="!hasSubscribedProduct">child</span><span *ngIf="hasSubscribedProduct">{{dependentLabel}}</span> address is same as Primary address</span>
                                    </label>
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <label class="">First Name <span class="fieldMan">*</span></label>
                                            <input autocomplete="off" class="form-control"
                                                formControlName="childFirstname" maxlength="25" placeholder="Firstname"
                                                type="text">
                                            <small
                                                *ngIf="!childForm.controls.childFirstname.valid && (childForm.controls.childFirstname.touched || submitAttempt)"
                                                class="errormsg">
                                                First Name is required
                                            </small>
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class=" "><span>Last Name</span> <span class="fieldMan">*</span>
                                            </label>
                                            <input class="form-control marginTop" formControlName="childLastname"
                                                maxlength="25" placeholder="Last Name" type="text">
                                            <small
                                                *ngIf="!childForm.controls.childLastname.valid && (childForm.controls.childLastname.touched || submitAttempt)"
                                                class="errormsg">
                                                Last Name is required.
                                            </small>
                                        </div>
                                        <div class="col-md-4 form-group has-float-label" *ngIf="hasSubscribedProduct">
                                            <label class="required form-label" for="state"> Relation </label>
                                            <select
                                                aria-label="Default select example" class="form-control form-select"
                                                formControlName="relation" id="relation">
                                                <option Value="" disabled>Select Relation</option>
                                                <option Value="Spouse"
                                                    *ngIf="this.relationSpouseIndex >= 0 ? empIndex === this.relationSpouseIndex : true">
                                                    {{spouseLabel}}</option>
                                                <option Value="Child">Child</option>

                                            </select>

                                            <div *ngIf="!childForm.controls.relation.valid && (childForm.controls.relation.touched || submitAttempt)"
                                                class="errormsg">
                                                Relation is required
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 form-group mb-0">
                                            <div class="row">
                                                <div class="col-md-9 form-group">
                                                    <label class="">DOB <span class="fieldMan">*</span>
                                                        (MM/DD/YYYY)
                                                    </label>
                                                    <p-calendar
                                                        (onInput)="childAgeCalT($event,empIndex,'dob' + empIndex, newForm.value)"
                                                        (onSelect)="childAgeCal($event,empIndex,'dob' + empIndex)"
                                                        [disabledDates]="invalidDates1" [maxDate]="minimumDate"
                                                        [monthNavigator]="true" [showIcon]="true"
                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                        [yearNavigator]="true"
                                                        [yearRange]="'1994:'+ maxDate.getFullYear()"
                                                        formControlName="childDob" inputId="navigators"
                                                        inputId="{{ 'dob' + empIndex}}" placeholder="mm/dd/yyyy">
                                                    </p-calendar>
                                                    <small
                                                        *ngIf="!childForm.controls.childDob.valid && (childForm.controls.childDob.touched || submitAttempt)"
                                                        class="errormsg mt-2">
                                                        DOB is required
                                                    </small>
                                                    <small class="errormsg mt-2" id="{{ 'childDOBerror' + empIndex}}">
                                                    </small>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Age</label>
                                                    <input [readonly]="true" class="form-control"
                                                        formControlName="childAge" placeholder="0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="d-block">Gender <span class="fieldMan">*</span></label>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="childGender"
                                                    id="Child - {{empIndex +1}}male" type="radio" value="Male">
                                                <label class="form-check-label"
                                                    for="Child - {{empIndex +1}}male">Male</label>
                                            </div>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="childGender"
                                                    id="Child - {{empIndex +1}}female" type="radio" value="Female">
                                                <label class="form-check-label"
                                                    for="Child - {{empIndex +1}}Female">Female</label>
                                            </div>
                                            <small
                                                *ngIf="!childForm.controls.childGender.valid && (childForm.controls.childGender.touched || submitAttempt)"
                                                class="errormsg">
                                                Gender is required
                                            </small>
                                        </div>
                                        <div class="col-md-5 form-group mb-0">

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group has-float-label">
                                                <label class="  form-control-placeholder" for="address1">Address1<span
                                                        class="fieldMan">*</span>
                                                </label>
                                                <input [class.stateclass]="childForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="address1"
                                                    id="address1" placeholder="Address1 " type="text">

                                                <div *ngIf="!childForm.controls.address1.valid && (childForm.controls.address1.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Address is required
                                                </div>
                                            </div>
                                            <div class="col-md-6 form-group has-float-label">
                                                <label class="  form-control-placeholder" for="address1">Apt/Suite
                                                </label>
                                                <input [class.stateclass]="childForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="address2"
                                                    id="address2" placeholder="Apt/Suite" type="text">

                                            </div>
                                            <div class="col-md-4 form-group has-float-label">
                                                <label class="required form-control-placeholder" for="city">City
                                                </label>
                                                <input [class.stateclass]="childForm.controls.disabled.value? true:''"
                                                    autocomplete="off" class="form-control" formControlName="city"
                                                    id="city" placeholder="City" type="text">
                                                <div *ngIf="!childForm.controls.city.valid && (childForm.controls.city.touched || submitAttempt)"
                                                    class="errormsg">
                                                    City is required
                                                </div>
                                            </div>
                                            <div class="col-md-4 form-group has-float-label">
                                                <label class="required form-label" for="state"> State </label>
                                                <select (change)="checkIfActiveState($event)"
                                                    [class.stateclass]="childForm.controls.disabled.value? true:''"
                                                    aria-label="Default select example" class="form-control form-select"
                                                    formControlName="state" id="state">
                                                    <option Value="" disabled>Select Your State</option>
                                                    <option *ngFor="let item of usaStates" [value]=item.name>{{
                                                        item.name }}
                                                    </option>
                                                </select>

                                                <div *ngIf="!childForm.controls.state.valid && (childForm.controls.state.touched || submitAttempt)"
                                                    class="errormsg">
                                                    State is required
                                                </div>

                                            </div>

                                            <div class="col-md-2 form-group has-float-label">
                                                <label class="required form-label" for="zip"> Zip Code </label>
                                                <input [class.stateclass]="childForm.controls.disabled.value? true:''"
                                                    class="form-control" formControlName="zip" id="zip" maxlength="5"
                                                    numbersOnly placeholder="Zip Code" type="text">

                                                <div *ngIf="!childForm.controls.zip.valid && (childForm.controls.zip.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Zip Code is required
                                                </div>
                                                <span
                                                    *ngIf="childForm.controls['zip'].hasError('pattern') && (childForm.controls['zip'].dirty )"
                                                    class="errormsg">Please
                                                    enter valid 5 digit zip code</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="enableAddDependent()">
                            <div (click)="add_child()" *ngIf="displayText" class="add_child" id="addchild">
                                + Add another <span *ngIf="!hasSubscribedProduct">child</span><span *ngIf="hasSubscribedProduct">{{dependentLabel}}</span>
                            </div></ng-container>


                        <div class="row">
                            <div class="col-md-12 mb-2 mt-2 membercount">
                                {{ new_text }}
                            </div>
                        </div>
                        <div class="card-grid-footer grid-end mt-3 text-center" *ngIf="hasSubscribedProduct">
                          If assistance is needed please contact <a href="tel:{{supportPhone}}"><i class="bi bi-telephone"></i> {{ supportPhone }}</a> and press 0
                       </div>
                    </div>
                </div>


                <div class="card-grid-footer grid-end mt-3 text-center" *ngIf="!hasSubscribedProduct">
                    <button (click)="accenrollment(newForm.value);submit(newForm.value)" [disabled]="!isActiveState"
                        class="btn add-btn" type="button">
                        {{ btn_text }}
                    </button>
                </div>


                <div class="card shadow-sm d-none">
                    <div class="card-body">
                        <h6 *ngIf="displayText" class="title-hr">
                            <span>Spouse and child Information</span>
                        </h6>
                        <!-------------------start Spouse------------------->
                        <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index"
                            class="card border-primary shadow-sm border-primary">
                            <div class="form-card-header px-3 py-1 mb-1">
                                <h6 class="title">Spouse</h6>
                                <button (click)="removeEmployee(empIndex1,spouseForm.value)"
                                    class="btn  btn-outline-danger btn-sm" type="button"><i
                                        class="ri-user-unfollow-line"></i>
                                    Remove
                                </button>
                            </div>
                            <div class="card-body" formArrayName="spouse_Array">
                                <div [formGroupName]="empIndex1" class="row">
                                    <div class="col-md-4 form-group">
                                        <label class="">First Name <span class="fieldMan">*</span></label>
                                        <input autocomplete="off" class="form-control" formControlName="spouseFirstname"
                                            maxlength="25" placeholder="First name" type="text">
                                        <small
                                            *ngIf="!spouseForm.controls.spouseFirstname.valid && (spouseForm.controls.spouseFirstname.touched || submitAttempt)"
                                            class="errormsg">
                                            First Name is required
                                        </small>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class=" "><span>Last Name</span> <span class="fieldMan">*</span>
                                        </label>
                                        <input class="form-control marginTop" formControlName="spouseLastname"
                                            maxlength="25" placeholder="Last Name" type="text">
                                        <small
                                            *ngIf="!spouseForm.controls.spouseLastname.valid && (spouseForm.controls.spouseLastname.touched || submitAttempt)"
                                            class="errormsg">
                                            Last Name is required.
                                        </small>
                                    </div>
                                    <div class="col-md-5 form-group mb-0">
                                        <div class="row">
                                            <div class="form-group col-md-8 ">
                                                <label class="">DOB <span class="fieldMan">*</span>
                                                    (MM/DD/YYYY)
                                                </label>
                                                <p-calendar (onInput)="spouseAgeCalT(newForm.value)"
                                                    (onSelect)="spouseAgeCal($event,empIndex1,'spouseDob' + empIndex1)"
                                                    [disabledDates]="invalidDates1" [maxDate]="minimumDate"
                                                    [monthNavigator]="true" [showIcon]="true"
                                                    [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                    [yearNavigator]="true" [yearRange]="'1956:'+ maxDate.getFullYear()"
                                                    formControlName="spouseDob" inputId="navigators" inputId="icon"
                                                    inputId="{{'spouseDob' + empIndex1}}" placeholder="mm/dd/yyyy">
                                                </p-calendar>
                                                <small
                                                    *ngIf="!spouseForm.controls.spouseDob.valid && (spouseForm.controls.spouseDob.touched || submitAttempt)"
                                                    class="errormsg">
                                                    DOB is required
                                                </small>
                                            </div>
                                            <div class="col-md-4 form-group ">
                                                <label class=" alignCenter">Age</label>
                                                <input [readonly]="true" class="form-control"
                                                    formControlName="spouseAge" placeholder="0" type="text">
                                            </div>
                                        </div>
                                        <small class="errormsg">{{ spouseAgeMsg }}</small>
                                    </div>
                                    <div class="form-group col-md-3 ">
                                        <label class="d-block">Gender <span class="fieldMan">*</span></label>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="spouseGender"
                                                id="spousemale" type="radio" value="Male">
                                            <label class="form-check-label" for="spousemale"> Male</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-2">
                                            <input class="form-check-input" formControlName="spouseGender"
                                                id="spousefemale" type="radio" value="Female">
                                            <label class="form-check-label" for="spousefemale"> Female</label>
                                        </div>
                                        <small
                                            *ngIf="!spouseForm.controls.spouseGender.valid && (spouseForm.controls.spouseGender.touched || submitAttempt)"
                                            class="errormsg">
                                            Gender is required
                                        </small>
                                    </div>
                                    <div class="col-md-5 form-group mb-0">
                                        <div class="row">
                                            <div class="form-group col-md-7">
                                                <label class=""> Height <span class="fieldMan">*</span>
                                                </label>
                                                <div class="row">
                                                    <div class="col-6 ">
                                                        <div class="row align-item-center">
                                                            <div class="col-8 ">
                                                                <select class="form-select form-control"
                                                                    formControlName="spouseFeet">
                                                                    <option disabled value="">Feet</option>
                                                                    <option *ngFor="let item of feetArray| slice:1"
                                                                        value={{item.feet}}>
                                                                        {{ item.feet }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-4 "> Ft</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 ">
                                                        <div class="row align-item-center">
                                                            <div class="col-8 ">
                                                                <select class="form-select form-control"
                                                                    formControlName="spouseInch">
                                                                    <option disabled value="">Inches</option>
                                                                    <option *ngFor="let item of inchArray; let i=index"
                                                                        value={{item.inchValue}}>
                                                                        {{ item.inch }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-4 "> In</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small
                                                    *ngIf="!spouseForm.controls.spouseFeet.valid && (spouseForm.controls.spouseFeet.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Height is required
                                                </small>

                                            </div>
                                            <div class="col-md-5 form-group ">
                                                <label class="">Weight <span class="fieldMan">*</span>
                                                </label>
                                                <div class="input-group">
                                                    <input (input)="onSearch($event.target.value, 'spouse')"
                                                        autocomplete="off" class="form-control"
                                                        formControlName="spouseWeight" maxlength="3" numbersOnly
                                                        placeholder="Weight" type="text"><span
                                                        class="input-group-text">lb</span>
                                                </div>
                                                <small
                                                    *ngIf="!spouseForm.controls.spouseWeight.valid && (spouseForm.controls.spouseWeight.touched || submitAttempt)"
                                                    class="errormsg">
                                                    Weight is required
                                                </small>
                                                <small class="errormsg">
                                                    {{ spouseWtError }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-------------------end Spouse------------------->
                        <!-----------------Start Child------------->
                        <div formArrayName="child_Array">
                            <div *ngFor="let childForm of child_Array().controls; let empIndex=index"
                                class="card border-primary shadow-sm border-primary">
                                <div class="form-card-header px-3 py-1 mb-1">
                                    <h6 class="title">Child - {{ empIndex + 1 }}</h6>
                                    <button (click)="removeEmployee(empIndex,childForm.value)"
                                        class="btn  btn-outline-danger btn-sm" type="button"><i
                                            class="ri-user-unfollow-line"></i>
                                        Remove
                                    </button>
                                </div>
                                <div [formGroupName]="empIndex" class="card-body">
                                    <div class="row">
                                        <div class="col-md-4 form-group">
                                            <label class="">First Name <span class="fieldMan">*</span></label>
                                            <input autocomplete="off" class="form-control"
                                                formControlName="childFirstname" maxlength="25" placeholder="Firstname"
                                                type="text">
                                            <small
                                                *ngIf="!childForm.controls.childFirstname.valid && (childForm.controls.childFirstname.touched || submitAttempt)"
                                                class="errormsg">
                                                First Name is required
                                            </small>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class=" "><span>Last Name</span> <span class="fieldMan">*</span>
                                            </label>
                                            <input class="form-control marginTop" formControlName="childLastname"
                                                maxlength="25" placeholder="Last Name" type="text">
                                            <small
                                                *ngIf="!childForm.controls.childLastname.valid && (childForm.controls.childLastname.touched || submitAttempt)"
                                                class="errormsg">
                                                Last Name is required.
                                            </small>
                                        </div>
                                        <div class="col-md-5 form-group mb-0">
                                            <div class="row">
                                                <div class="col-md-8 form-group">
                                                    <label class="">DOB <span class="fieldMan">*</span>
                                                        (MM/DD/YYYY)
                                                    </label>
                                                    <p-calendar
                                                        (onInput)="childAgeCalT($event,empIndex,'dob' + empIndex, newForm.value)"
                                                        (onSelect)="childAgeCal($event,empIndex,'dob' + empIndex)"
                                                        [disabledDates]="invalidDates1" [maxDate]="minimumDate"
                                                        [monthNavigator]="true" [showIcon]="true"
                                                        [style]="{'width': '100%','display': 'inline-flex','height':'50px'}"
                                                        [yearNavigator]="true"
                                                        [yearRange]="'1994:'+ maxDate.getFullYear()"
                                                        formControlName="childDob" inputId="navigators"
                                                        inputId="{{ 'dob' + empIndex}}" placeholder="mm/dd/yyyy">
                                                    </p-calendar>
                                                    <small
                                                        *ngIf="!childForm.controls.childDob.valid && (childForm.controls.childDob.touched || submitAttempt)"
                                                        class="errormsg mt-2">
                                                        DOB is required
                                                    </small>
                                                    <small class="errormsg mt-2" id="{{ 'childDOBerror' + empIndex}}">
                                                    </small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label>Age</label>
                                                    <input [readonly]="true" class="form-control"
                                                        formControlName="childAge" placeholder="0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class="d-block">Gender <span class="fieldMan">*</span></label>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="childGender"
                                                    id="Child - {{empIndex +1}}male" type="radio" value="Male">
                                                <label class="form-check-label"
                                                    for="Child - {{empIndex +1}}male">Male</label>
                                            </div>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" formControlName="childGender"
                                                    id="Child - {{empIndex +1}}female" type="radio" value="Female">
                                                <label class="form-check-label"
                                                    for="Child - {{empIndex +1}}Female">Female</label>
                                            </div>
                                            <small
                                                *ngIf="!childForm.controls.childGender.valid && (childForm.controls.childGender.touched || submitAttempt)"
                                                class="errormsg">
                                                Gender is required
                                            </small>
                                        </div>
                                        <div class="col-md-5 form-group mb-0">
                                            <div class="row">
                                                <div class="form-group col-md-7">
                                                    <label class="">
                                                        Height <span class="fieldMan">*</span>
                                                    </label>
                                                    <div class="row ">
                                                        <div class="col-6 ">
                                                            <div class="row align-item-center">
                                                                <div class="col-8 ">
                                                                    <select class="form-select form-control"
                                                                        formControlName="childFeet">
                                                                        <option disabled value="">Feet</option>
                                                                        <option *ngFor="let item of feetArray"
                                                                            value={{item.feet}}>
                                                                            {{ item.feet }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-4 "> Ft</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 ">
                                                            <div class="row align-item-center">
                                                                <div class="col-8 ">
                                                                    <select class="form-select form-control"
                                                                        formControlName="childInch">
                                                                        <option disabled value="">Inches</option>
                                                                        <option
                                                                            *ngFor="let item of inchArray; let i=index"
                                                                            value={{item.inchValue}}>
                                                                            {{ item.inch }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-4 "> In</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small
                                                        *ngIf="!childForm.controls.childFeet.valid && (childForm.controls.childFeet.touched || submitAttempt)"
                                                        class="errormsg">
                                                        Height is required
                                                    </small>

                                                </div>
                                                <div class="col-md-5 form-group">
                                                    <label class="">Weight <span class="fieldMan">*</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <input (keyup)="onselectChildWeight(empIndex,$event)"
                                                            autocomplete="off" class="form-control"
                                                            formControlName="childWeight"
                                                            id="{{ 'weightfield' + empIndex }}" maxlength="3"
                                                            numbersOnly placeholder="Weight" type="text"><span
                                                            class="input-group-text">lb</span>
                                                    </div>
                                                    <small
                                                        *ngIf="!childForm.controls.childWeight.valid && (childForm.controls.childWeight.touched || submitAttempt)"
                                                        class="errormsg">
                                                        Weight is required
                                                    </small>
                                                    <small class="errormsg childerrorhtml"
                                                        id="{{ 'childerror' + empIndex}}">
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-----------------end Child------------->
                    </div>
                    <div class="card-footer mb-0 form-card-footer">
                        <p></p>
                        <div class="d-flex gap-2">
                            <button (click)="addEmployee('spouse')" *ngIf="spouseForm" class="btn add-btn"
                                type="button">
                                <i class="ri-user-add-line"></i> Add Spouse
                            </button>
                            <button (click)="add_child()" *ngIf="test" class="btn add-btn" id="addchild" type="button">
                                <i class="ri-user-add-line"></i> Add Child
                            </button>
                        </div>
                    </div>

                </div>
                <div class="px-0 mb-0 form-card-footer  bg-transparent border-top-0 d-none">
                    <button (click)="Reset_form()" class="btn btn-outline-danger" tabindex="-1" type="button">
                        <i class="ri-close-fill"></i> Reset
                    </button>&nbsp;
                    <button (click)="createNewMembership(newForm.value)" *ngIf="status?.is_edit == '1'"
                        class="btn add-btn" type="button">
                        <i class="ri-check-line"></i> Apply
                    </button>
                </div>

            </form>
        </div>
    </div>
    <form *ngIf="!hasSubscribedProduct" [formGroup]="newForm" class="primengDateNTable">
        <div *ngIf="data?.length>0" class="card shadow-sm mb-3">
            <div class="card-body">
                <h6 class="title-mainhead">
                    <span>Product & Pricing</span>
                </h6>
                <table class="table table-bordered">
                    <thead class="thead-light product_table">
                        <tr>
                            <th scope="col" *ngIf="display_select_radio"></th>
                            <!-- <th scope="col">Vendor Name</th> -->
                            <th scope="col">Product/Package</th>
                            <th scope="col">Family Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Total Price</th>
                            <th scope="col" *ngIf="display_select_radio">Package/Feature</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data; let i=index">
                            <td *ngIf="display_select_radio"> <input id="i" [checked]="item.selected"
                                    class="form-check-input" type="radio" [value]="item" formControlName="package"
                                    (change)="selectRecord($event.target.checked , item)"></td>
                            <!-- <td class="product_txt">{{ item.vendor_name }}</td> -->
                            <td class="product_txt">{{ item.product_name }} <br><small *ngIf="item.short_description">({{ item.short_description }})</small></td>
                            <td class="product_txt">{{ item.family_type_name }}</td>
                            <td class="product_txt">${{ item.price }}</td>
                            <td class="product_txt">${{ item.total_price }}</td>
                            <td class="product_txt" *ngIf="display_select_radio"><a href="{{item.redirection_value}}"
                                    target="_blank">Details</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    <div *ngIf="data?.length>0" class="row">
        <div class="col-md-12 text-end" *ngIf="display_select_btn">
            <button (click)="addProductsToCart();updateData(newForm.value)" class="btn add-btn" type="button">
                Next
            </button>
        </div>
    </div>
    <div *ngIf="hasSubscribedProduct" class="row">
        <div class="col-md-12 text-end">
            <button (click)="accenrollment(newForm.value);submit(newForm.value)" class="btn add-btn" type="button">
                Next
            </button>
        </div>
    </div>
</div>
<div *ngIf="loader" class="loader">
    <div id="loading"></div>
</div>
