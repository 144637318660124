import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TestService} from '../../../services/behaviourService';
import {SharingService} from '../../../services/sharing.service';
import {DatePipe} from '@angular/common'
import {ToastrService} from 'ngx-toastr';
import {SessionService} from '../../../services/session.service';

@Component({
    selector: 'app-demographics-dependents',
    templateUrl: './demographics-dependents.component.html',
    styleUrls: ['./demographics-dependents.component.scss'],
    providers: [DatePipe]
})
export class DemographicsDependentsComponent implements OnInit {
    primaryForm: UntypedFormGroup;
    submitAttempt: boolean = false;
    spouseArray: UntypedFormArray;
    childArray: UntypedFormArray;
    applicant: any = {}
    objectData: any = {}
    cartProducts: any;
    memberdata: any;
    test: UntypedFormGroup;
    primaryApplicant: any;
    spouseApplicant: any;
    childApplicant: any;
    newForm: UntypedFormGroup;
    form: UntypedFormGroup;
    auxConfigform: UntypedFormGroup;
    applicants: any = {};
    states: any;
    theCheckbox = true;
    isDisabled: boolean = true;
    CheckBoxVisible: boolean = false;
    submitButton: boolean = false;
    txt: string;
    height: string;
    number = "";
    numberSpouse = "";
    childArrayLength: any;
    spouseArrayLength: any;
    isDisabled1: boolean = true;
    data = ""
    phnumbervalue: any;
    transformedInput: string;
    zip: any;
    startdate: any;
    fromA2Cdata: boolean = true
    A2CflowData: any;
    d2cflow: any;
    d2c: any;
    companyId: any
    memberDiscount: any;
    additionalMember: any;
    prSsn: string;
    zipFormArray: any[] = [];
    companyInfo: any;
    groupID: any;
    disableGFields: boolean = true;
    planeData: any;
    applicant_primary: any;
    cmpId: any;
    memId: any;
    cartId: any;
    apiURL: any;
    api_Key: any;
    application_id: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private objectService: TestService,
        private services: SharingService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private sessionService: SessionService
    ) {
        this.cmpId = this.sessionService.getData("company");
        this.memId = this.sessionService.getData("member_id");
        this.cartId = this.sessionService.getData("cart_id");
        this.services.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = data.company_id;
                if (this.cmpId != null && this.cmpId != "") {
                    var url = 'GetMemberDependents/' + this.cmpId + '/' + this.memId + '/' + this.cartId;
                    this.services.getDataFromUrl(url).subscribe((Object: any) => {

                        this.applicationCartInit(Object.member_personal_data[0].family_id);
                        sessionStorage.setItem('d2cFlow', 'd2c');
                        this.services.pushD2c('D2C');
                        this.objectService.addCartPlan(Object);
                        this.objectService.appCartmemberInfoInfo(this.companyId, this.memId, this.cartId);

                    }, err => {
                        console.log(err)
                    })
                }

            }
        })


        localStorage.removeItem('planupdate');
        this.objectService.cartPlan.subscribe(result => {
            if (result != '') {
                this.application_id = result.application_id
                this.sessionService.setData('company', this.companyId);
                this.sessionService.setData('member_id', result.member_personal_data[0].member_id);
                this.sessionService.setData('cart_id', result.cart_data[0].id);
                this.cmpId = this.sessionService.getData("company");
                this.memId = this.sessionService.getData("member_id");
                this.cartId = this.sessionService.getData("cart_id");
                this.d2cflow = sessionStorage.getItem('d2cFlow');
                this.data = JSON.parse(sessionStorage.getItem('applicantData'));
                this.applicant = result;
                if (this.applicant.guardian == true) {
                    this.disableGFields = false;
                }
                this.groupID = this.applicant.member_personal_data[0].group_id;//G2C flow
                this.d2c = this.services.getD2c();
                this.memberDiscount = this.applicant.agent_cart_discount;
                this.additionalMember = this.applicant.additional_member_fee
                if (this.applicant !== null) {
                    if (this.applicant.startdate !== undefined) {
                        this.startdate = this.applicant.startdate;
                        this.zip = this.applicant.zipcode
                    } else {
                        this.startdate = this.applicant.startDate;
                        this.zip = this.applicant.zipcode
                    }
                    if (this.applicant.member_personal_data[0].zip !== undefined) {
                        this.zip = this.applicant.member_personal_data[0].zip
                        this.startdate = this.applicant.startdate;
                    } else {
                        this.zip = this.applicant.zipcode
                        this.zip = this.applicant.member_personal_data[0].zip
                    }
                }
                this.cartProducts = this.applicant.cart_products;
                this.memberdata = this.applicant.member_personal_data;
                if (this.memberdata[0].ssn == null) {
                    this.CheckBoxVisible = true
                    this.submitButton = true;
                    this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
                    this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
                    this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");

                    this.primaryApplicant[0].zip = this.applicant.zipcode;
                    this.zip = this.applicant.zipcode
                    if (this.spouseApplicant !== undefined && this.spouseApplicant.length !== 0) {
                        this.spouseApplicant[0].zip = this.applicant.zipcode;
                    }
                    if (this.childApplicant !== undefined && this.childApplicant.length !== 0) {
                        for (var i = 0; i < this.childApplicant.length; i++) {
                            this.childApplicant[i].zip = this.applicant.zipcode;
                        }
                    }
                    this.theCheckbox = false;
                    this.childArrayLength = this.childApplicant.length;
                    this.spouseArrayLength = this.spouseApplicant.length;
                } else if (this.memberdata[0].ssn == "") {
                    this.CheckBoxVisible = true
                    this.submitButton = true;
                    this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
                    this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
                    this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
                    this.primaryApplicant[0].zip = this.applicant.zipcode;
                    this.zip = this.applicant.zipcode
                    if (this.spouseApplicant !== undefined && this.spouseApplicant.length !== 0) {
                        this.spouseApplicant[0].zip = this.applicant.zipcode;
                    }
                    if (this.childApplicant !== undefined && this.childApplicant.length !== 0) {
                        for (var i = 0; i < this.childApplicant.length; i++) {
                            this.childApplicant[i].zip = this.applicant.zipcode;
                        }
                    }
                    this.theCheckbox = false;
                    this.childArrayLength = this.childApplicant.length;
                    this.spouseArrayLength = this.spouseApplicant.length;
                } else {
                    this.CheckBoxVisible = false;
                    this.theCheckbox = true;
                    this.submitButton = false;
                    this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
                    this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
                    this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
                }
                const arryOfPrimaryGroup = this.primaryApplicant.map(item => {
                    // if (item.height != undefined) {
                    //   var height = item.height;
                    //   var n = height.toString().includes(".");
                    //   if (n == false) {
                    //     var str2 = ".0";
                    //     var height = height.toString().concat(str2);
                    //   }
                    //   const Height = height.toString();
                    //   var inch = Height.substring(Height.lastIndexOf('.') + 1);
                    //   var ft = Height.substring(0, Height.lastIndexOf('.'));
                    //   this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
                    // }
                    var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
                    if (this.primaryApplicant[0].phone != null || this.primaryApplicant[0].phone != undefined) {
                        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
                    } else {
                        var phone: string = item.phone;
                    }
                    if (this.primaryApplicant[0].ssn != null && this.primaryApplicant[0].ssn != undefined && this.primaryApplicant[0].ssn != '') {
                        const ssn = item.ssn.toString();
                        let str = "***-**-"
                        var ssN = str + ssn.slice(ssn.length - 4);
                    } else {
                        var ssN: string = item.ssn;
                    }
                    return this.createChildGroup(
                        D, item.gender, item.relation, item.age, item.smoker, item.firstname, item.middlename, item.lastname, item.email,
                        phone, item.city, item.state, item.zip, ssN, item.address1, item.address2, item.member_id,
                    );
                });
                const arryOfSpouseGroup = this.spouseApplicant.map(item => {
                    // this.txt = item.height;
                    // var height = item.height;
                    // var n = height.toString().includes(".");
                    // if (n == false) {
                    //   var str2 = ".0";
                    //   var height = height.toString().concat(str2);
                    // }
                    // const Height = height.toString();
                    // var inch = Height.substring(Height.lastIndexOf('.') + 1);
                    // var ft = Height.substring(0, Height.lastIndexOf('.'));
                    // this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
                    var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
                    if (this.spouseApplicant[0].phone !== null) {
                        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
                    } else {
                        var phone: string = item.phone;
                    }
                    if (this.spouseApplicant[0].ssn != null && this.spouseApplicant[0].ssn != undefined && this.spouseApplicant[0].ssn != '') {
                        const ssn = item.ssn.toString();
                        let str = "***-**-"
                        var ssN = str + ssn.slice(ssn.length - 4);
                    } else {
                        var ssN: string = item.ssn;
                    }
                    let address1;
                    let address2;
                    let city;
                    if (this.primaryApplicant) {
                        var state = this.primaryApplicant[0].state;
                        address1 = this.primaryApplicant[0].address1;
                        address2 = this.primaryApplicant[0].address2;
                        city = this.primaryApplicant[0].city;
                    }
                    return this.createChildGroup(
                        D, item.gender, item.relation, item.age, item.smoker, item.firstname, item.middlename, item.lastname, item.email,
                        phone, city, state, item.zip, ssN, address1, address2, item.member_id,
                    );
                });
                const arryOfChildGroup = this.childApplicant.map(item => {
                    // this.txt = item.height;
                    // var height = item.height;
                    // var n = height.toString().includes(".");
                    // if (n == false) {
                    //   var str2 = ".0";
                    //   var height = height.toString().concat(str2);
                    // }
                    // const Height = height.toString();
                    // var inch = Height.substring(Height.lastIndexOf('.') + 1);
                    // var ft = Height.substring(0, Height.lastIndexOf('.'));
                    // this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
                    var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
                    if (item.phone !== undefined && item.phone !== null) {
                        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
                    } else {
                        var phone: string = item.phone;
                    }
                    if (this.childApplicant[0].ssn != null && this.childApplicant[0].ssn != undefined && this.childApplicant[0].ssn != '') {
                        const ssn = item.ssn.toString();
                        let str = "***-**-"
                        var ssN = str + ssn.slice(ssn.length - 4);
                    } else {
                        var ssN: string = item.ssn;
                    }
                    let address1;
                    let address2;
                    let city;
                    if (this.primaryApplicant) {
                        var state = this.primaryApplicant[0].state;
                        address1 = this.primaryApplicant[0].address1;
                        address2 = this.primaryApplicant[0].address2;
                        city = this.primaryApplicant[0].city;
                    }
                    return this.createChildGroup(
                        D, item.gender, item.relation, item.age, item.smoker, item.firstname, item.middlename, item.lastname, item.email,
                        phone, city, state, item.zip, ssN, address1, address2, item.member_id,
                    );
                });
                this.auxConfigform = this.formBuilder.group({
                    childArray: this.formBuilder.array(arryOfChildGroup),
                    primaryArray: this.formBuilder.array(arryOfPrimaryGroup),
                    SpouseArray: this.formBuilder.array(arryOfSpouseGroup)
                });
            } else {
                //this.router.navigate(['DirectMemberEnrollment']);
            }
        });
    }

    get demgChildArray() {
        return this.auxConfigform.get("childArray")["controls"] as UntypedFormArray;
    }

    get demgPrimaryArray() {
        return this.auxConfigform.get("primaryArray")["controls"] as UntypedFormArray;
    }

    get demgSpouseArray() {
        return this.auxConfigform.get("SpouseArray")["controls"] as UntypedFormArray;
    }

    ngOnInit(): void {
        //this.getState();
        this.getUsaState();


        this.objectService.pushheaderCondition("showheader");
    }

    child_Array(): UntypedFormArray {
        return this.auxConfigform.get("childArray") as UntypedFormArray
    }

    applicationCartInit(family_id) {
        var url = 'GetCartPlan/' + this.cmpId + '/' + this.cartId + '/' + family_id;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            if (!!Object) {
                this.objectService.appCartInfo(Object);
                sessionStorage.setItem("cartplan", JSON.stringify(Object));
            }
        }, err => {
            console.log(err);
            this.toastr.error('Something went wrong. Please try again later.');
        })
    }

    createChildGroup(dob, gender, relation, age, smoker, firstname, middlename, lastname,
                     email, phone, city, state, zip, ssn, address1, address2, member_id) {
        return this.formBuilder.group({
            relation: [relation],
            age: [age],
            smoker: [smoker],
            firstname: [firstname, Validators.required],
            middlename: [middlename],
            lastname: [lastname, Validators.required],
            phone: [phone, Validators.required],
            email: [email, Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
            dob: [dob, Validators.required],
            //height: [height,],
            // weight: [weight,],
            gender: [gender,],
            ssn: [ssn,],
            address1: [address1, Validators.required],
            address2: [address2],
            city: [city, Validators.required],
            state: [state, Validators.required],
            zip: [zip, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]],
            member_id: [member_id],
            checkBox: [this.CheckBoxVisible],
            disabled: [this.CheckBoxVisible]
        });
    }

    InputChange(ControlName) {
        var ControlValue = this.auxConfigform.controls.primaryArray['controls'][0].controls[ControlName].value
        for (var i = 0; i < this.demgChildArray.length; i++) {
            if (this.auxConfigform.controls.childArray['controls'][i].controls.checkBox.value) {
                this.auxConfigform.controls.childArray['controls'][i].controls[ControlName].setValue(ControlValue)
            }
        }
        for (var i = 0; i < this.demgSpouseArray.length; i++) {
            if (this.auxConfigform.controls.SpouseArray['controls'][i].controls.checkBox.value) {
                this.auxConfigform.controls.SpouseArray['controls'][i].controls[ControlName].setValue(ControlValue)
            }
        }

    }

    checkBoXChange(index, arrayName) {
        if (this.auxConfigform.controls[arrayName]['controls'][index].controls.checkBox.value) {
            this.auxConfigform.controls[arrayName]['controls'][index].patchValue({
                disabled: true,
                address1: this.auxConfigform.controls.primaryArray['controls'][0].controls['address1'].value,
                address2: this.auxConfigform.controls.primaryArray['controls'][0].controls['address2'].value,
                city: this.auxConfigform.controls.primaryArray['controls'][0].controls['city'].value,
                state: this.auxConfigform.controls.primaryArray['controls'][0].controls['state'].value,
                zip: this.auxConfigform.controls.primaryArray['controls'][0].controls['zip'].value,
            })
        } else {
            this.auxConfigform.controls[arrayName]['controls'][index].patchValue({
                disabled: false,
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            })
        }
    }

    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    scrollToError(): void {
        const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
        this.scrollTo(firstElementWithError);
    }

    backtoD2C() {
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.demoToA2c(Object);
            if (this.groupID != null) {
                this.router.navigate(['DirectMemberEnrollment/' + localStorage.getItem('urlGroupName')]);
            } else {
                this.router.navigate(['DirectMemberEnrollment']);
            }
            localStorage.setItem('planupdate', 'updateplan')
        }, err => {
            console.log(err)
        })

    }

    //================state dropdown===============//
    getState() {
        var url = 'ListSalableUsaStates/'+this.companyId;
        this.services.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.states = Object.data;
            }
        })
    }

    getUsaState() {
        var url = 'ListSalableUsaStates/'+this.companyId;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.states = Object.data;
            }
        })
    }

    submitPrimaryForm(values) {
        this.zipFormArray = [];
        if (values.primaryArray.length != 0) {
            if ((values.primaryArray[0].firstname != null && values.primaryArray[0].firstname != "") &&
                (values.primaryArray[0].lastname != null && values.primaryArray[0].lastname != "") &&
                (values.primaryArray[0].email != null && values.primaryArray[0].email != "") &&
                (values.primaryArray[0].phone != null && values.primaryArray[0].phone != "") &&
                (values.primaryArray[0].address1 != null && values.primaryArray[0].address1 != "") &&
                (values.primaryArray[0].city != null && values.primaryArray[0].city != "") &&
                (values.primaryArray[0].state != null && values.primaryArray[0].state != "") &&
                (values.primaryArray[0].zip != null && values.primaryArray[0].zip != "")) {
                this.submitAttempt = true
                if (this.applicant.guardian == true) {
                    if (values.primaryArray[0].ssn != null) {
                        if (values.primaryArray[0].ssn.length == 11 || values.primaryArray[0].ssn.length == 0) {
                            this.zipFormArray.push(true);
                        } else
                            this.zipFormArray.push(false);
                    } else {
                        this.zipFormArray.push(true);
                    }
                } else if (values.primaryArray[0].ssn != null && values.primaryArray[0].ssn != "") {
                    if (values.primaryArray[0].ssn.length == 11) {
                        this.zipFormArray.push(true);
                    } else {
                        this.zipFormArray.push(false);
                    }
                }
                const z = values.primaryArray[0].zip.toString();
                if (z.length == 5) {
                    this.zipFormArray.push(true);
                } else {
                    this.zipFormArray.push(false);
                }
            } else {
                this.submitAttempt = true;
                this.zipFormArray.push(false);
            }
        }

        if (values.SpouseArray.length != 0) {
            this.submitAttempt = true;
            if ((values.SpouseArray[0].firstname != null && values.SpouseArray[0].firstname != "") &&
                (values.SpouseArray[0].lastname != null && values.SpouseArray[0].lastname != "") &&
                //  (values.SpouseArray[0].email != null && values.SpouseArray[0].email != "") &&
                // (values.SpouseArray[0].phone != null && values.SpouseArray[0].phone != "") &&
                // (values.SpouseArray[0].ssn != null && values.SpouseArray[0].ssn != "") &&
                (values.SpouseArray[0].address1 != null && values.SpouseArray[0].address1 != "") &&
                (values.SpouseArray[0].city != null && values.SpouseArray[0].city != "") &&
                (values.SpouseArray[0].state != null && values.SpouseArray[0].state != "") &&
                (values.SpouseArray[0].zip != null && values.SpouseArray[0].zip != "")) {
                this.submitAttempt = true;
                if (values.SpouseArray[0].ssn == '' || values.SpouseArray[0].ssn == null || values.SpouseArray[0].ssn.replace(/[^0-9\.]+/g, "").length == 9) {
                    this.zipFormArray.push(true);
                } else {
                    if (values.SpouseArray[0].ssn.includes('*') && values.SpouseArray[0].ssn.length == 11) {
                        this.zipFormArray.push(true);
                    } else {
                        this.zipFormArray.push(false);
                    }
                }
                const z = values.SpouseArray[0].zip.toString();
                if (z.length == 5) {
                    this.zipFormArray.push(true);
                } else {
                    this.zipFormArray.push(false);
                }
            } else {
                this.submitAttempt = true;
                this.zipFormArray.push(false);
            }
        }

        if (values.childArray.length != 0) {
            for (let i = 0; i < values.childArray.length; i++) {
                if ((values.childArray[i].firstname != null && values.childArray[i].firstname != "") &&
                    (values.childArray[i].lastname != null && values.childArray[i].lastname != "") &&
                    // (values.childArray[i].ssn != null && values.childArray[i].ssn != "") &&
                    (values.childArray[i].address1 != null && values.childArray[i].address1 != "") &&
                    (values.childArray[i].city != null && values.childArray[i].city != "") &&
                    (values.childArray[i].state != null && values.childArray[i].state != "") &&
                    (values.childArray[i].zip != null && values.childArray[i].zip != "")) {
                    this.submitAttempt = true;
                    if (values.childArray[i].ssn == '' || values.childArray[i].ssn == null || values.childArray[i].ssn.replace(/[^0-9\.]+/g, "").length == 9) {
                        this.zipFormArray.push(true);
                    } else {
                        if (values.childArray[i].ssn.includes('*') && values.childArray[i].ssn.length == 11) {
                            this.zipFormArray.push(true);
                        } else {
                            this.zipFormArray.push(false);
                        }
                    }
                    const z = values.childArray[i].zip.toString();
                    if (z.length == 5) {
                        this.zipFormArray.push(true);
                    } else {
                        this.zipFormArray.push(false);
                    }
                } else {
                    this.submitAttempt = true;
                    this.zipFormArray.push(false);
                }
            }
        }
        if (this.zipFormArray.includes(false) == false) {
            this.submitPrimaryForm1(values);
        } else {
            this.submitAttempt = true;
            this.scrollToError();
        }
    }

    //============*** submit demographic info============//
    submitPrimaryForm1(values) {
        this.submitAttempt = true;
        var memberId = this.applicant.member_personal_data[0].member_id;
        // if (memberId != null && this.applicant.order_details == null ) {
        if (values.primaryArray) {
            this.objectData.company_id = this.companyId;
            this.objectData.cart_id = this.applicant.cart_data[0].id
            this.objectData.zipcode = this.applicant.zipcode;
            this.objectData.startdate = this.applicant.startDate;
            values.primaryArray[0].height;
            if (values.primaryArray[0].ssn == '') {
                values.primaryArray[0].ssn = '';
            } else if (values.primaryArray[0].ssn) {
                if (sessionStorage.getItem("social-security") != null) {
                    values.primaryArray[0].ssn = sessionStorage.getItem("social-security").replace(/[^0-9\.]+/g, "");
                }
            }
            if (values.SpouseArray.length > 0) {
                if (values.SpouseArray[0].ssn == '') {
                    values.SpouseArray[0].ssn = '';
                } else {
                    if (sessionStorage.getItem("social-security-spouse") != null) {
                        values.SpouseArray[0].ssn = sessionStorage.getItem("social-security-spouse").replace(/[^0-9\.]+/g, "");
                    }
                }
            }
            if (values.childArray.length > 0) {
                for (let i = 0; i < values.childArray.length; i++) {
                    if (values.childArray[i].ssn == '') {
                        values.childArray[i].ssn = ''
                    } else {
                        if (sessionStorage.getItem("social-security-child-" + [i]) != null) {
                            values.childArray[i].ssn = sessionStorage.getItem("social-security-child-" + [i]).replace(/[^0-9\.]+/g, "");

                        }
                    }
                }
            }
            let resultArr = [];
            resultArr = resultArr.concat(values.primaryArray);
            resultArr = resultArr.concat(values.childArray);
            resultArr = resultArr.concat(values.SpouseArray);
            for (let i = 0; i < resultArr.length; i++) {
                if (resultArr[i].height == null || resultArr[i].height == undefined) {
                    resultArr[i].height = '';
                } else {
                    resultArr[i].height = resultArr[i].height.replace(/[^0-9\.-]+/g, "");
                }
                resultArr[i].ssn = resultArr[i].ssn.replace(/[^0-9\.]+/g, "");
                if (resultArr[i].phone == null) {
                    resultArr[i].phone = resultArr[i].phone;
                } else {
                    resultArr[i].phone = resultArr[i].phone.replace(/[^0-9\.]+/g, "");
                }
            }
            var applicantObj: any = {}
            applicantObj.member_personal_data = resultArr;
            var guardianInfo: any = {}
            guardianInfo.guardian = this.applicant.guardian;
            var body: any = {}
            body = Object.assign({}, this.objectData, guardianInfo, applicantObj)
            var url = 'UpdateMemberAddress';
            this.services.putData(url, body).subscribe((Object: any) => {
                if (Object.status == true) {
                    this.applicant_primary = Object.member_personal_data.filter(x => x['relation'] == "Primary");
                    localStorage.setItem('applicantprimary', JSON.stringify(this.applicant_primary))
                    this.planeData = Object;
                    var demographicsDepdnt = Object;
                    this.applicationCart();
                    this.objectService.demographyH(demographicsDepdnt);
                    this.router.navigate(['statementofbeliefs']);
                    this.objectService.questionsback("");
                    this.auxConfigform.reset();
                }
            }, err => {
                console.log(err);
                this.toastr.error(err.error.email);
            })
        }
        // }
        // else if (memberId != null && this.applicant.cart_data[0].id != null ) {
        //   this.objectData.company_id = this.companyId;
        //   this.objectData.cart_id = this.applicant.cart_data[0].id;
        //   if (values.primaryArray[0].ssn == '') {
        //     values.primaryArray[0].ssn = '';
        //   } else if (values.primaryArray[0].ssn) {
        //     values.primaryArray[0].ssn = sessionStorage.getItem("social-security").replace(/[^0-9\.]+/g, "");
        //   }
        //   if (values.SpouseArray.length > 0) {
        //     if (values.SpouseArray[0].ssn == '') {
        //       values.SpouseArray[0].ssn = '';
        //     } else {
        //       values.SpouseArray[0].ssn = sessionStorage.getItem("social-security-spouse").replace(/[^0-9\.]+/g, "");
        //     }
        //   }
        //   if (values.childArray.length > 0) {
        //     for (let i = 0; i < values.childArray.length; i++) {
        //       if (values.childArray[i].ssn == '') {
        //         values.childArray[i].ssn = ''
        //       } else {
        //         values.childArray[i].ssn = sessionStorage.getItem("social-security-child-" + [i]).replace(/[^0-9\.]+/g, "");
        //       }
        //     }
        //   }
        //   let resultArr = [];
        //   resultArr = resultArr.concat(values.primaryArray);
        //   resultArr = resultArr.concat(values.childArray);
        //   resultArr = resultArr.concat(values.SpouseArray);
        //   for (let i = 0; i < resultArr.length; i++) {
        //     if (resultArr[i].height == null || resultArr[i].height == undefined) {
        //       resultArr[i].height = '';
        //     } else {
        //       resultArr[i].height = resultArr[i].height.replace(/[^0-9\.-]+/g, "");
        //     }
        //     resultArr[i].ssn = resultArr[i].ssn.replace(/[^0-9\.]+/g, "");
        //     if (resultArr[i].phone == null) {
        //       resultArr[i].phone = resultArr[i].phone;
        //     } else {
        //       resultArr[i].phone = resultArr[i].phone.replace(/[^0-9\.]+/g, "");
        //     }
        //   }
        //   var applicantObj: any = {}
        //   applicantObj.member_personal_data = resultArr;
        //   var guardianInfo: any = {}
        //   guardianInfo.guardian = this.applicant.guardian
        //   var body: any = {}
        //   body = Object.assign({}, this.objectData, guardianInfo, applicantObj)
        //   var url = 'UpdateMemberAddress';
        //   this.services.putData(url, body).subscribe((Object: any) => {
        //     if (Object.status == 'success') {
        //       this.planeData = Object;
        //       this.applicationCart();
        //       this.objectService.paymentMethod(Object);
        //       this.router.navigate(['statementofbeliefs']);
        //       this.auxConfigform.reset();
        //     }
        //   }, err => {
        //     console.log(err);
        //     this.toastr.error(err.error.email);
        //   })
        // }
    }


    applicationCart() {
        var url = 'GetCartPlan/' + this.companyId + '/' + this.planeData.cart_data[0].id + '/' + this.planeData.member_personal_data[0].family_id;
        this.services.getData(url).subscribe((Object: any) => {
            if (!!Object) {
                this.objectService.appCartInfo(Object)
                sessionStorage.setItem("cartplan", JSON.stringify(Object));
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }


    onTypingSocialSecurity() {
        let primary_value = (<HTMLInputElement>document.getElementById("social-security")).value;
        if (primary_value.length < 11 && primary_value.length > 0) {
            this.number = "Please enter a 9 digit number"
        }
        if (primary_value.length >= 11 || primary_value.length == 0) {
            this.number = ""
        }
        if (this.spouseArrayLength != "") {
            let spouse_value = (<HTMLInputElement>document.getElementById("social-security-spouse")).value;
            if (spouse_value.length < 11 && spouse_value.length > 0) {
                this.numberSpouse = "Please enter a 9 digit number"
            }
            if (spouse_value.length >= 11 || spouse_value.length == 0) {
                this.numberSpouse = ""
            }
        }
    }

    onTypingSocialSecuritychild(i, event) {
        let id = "social-security-child-" + i;
        if (event.target.id == id) {
            let child_value = (<HTMLInputElement>document.getElementById("social-security-child-" + [i])).value;
            if (child_value.length < 11 && child_value.length > 0) {
                let error = "error" + i;
                (<HTMLInputElement>document.getElementById("error" + i)).innerHTML = "Please enter a valid 9 digit number";
            }
            if (child_value.length >= 11 || child_value.length == 0) {
                let error = "error" + i;
                (<HTMLInputElement>document.getElementById("error" + i)).innerHTML = "";

            }
        }
    }

    //================number validation==============//
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onkeyupPhone(e) {
        this.phnumbervalue = (<HTMLInputElement>document.getElementById("phnumber")).value;
        this.transformedInput = this.phnumbervalue.replace(/^0*/g, '');
        (<HTMLInputElement>document.getElementById("phnumber")).value = this.transformedInput;
        if (this.phnumbervalue.length == 12) {
            (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
        }
    }

    onfocusoutphnumber(feature, i, event) {
        if (feature == "primary") {
            let text = (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML;
            let value = (<HTMLInputElement>document.getElementById("phnumber")).value;
            if (value.length < 12) {
                (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "Please enter a valid Phone number";
            }
            if (value.length >= 12) {
                (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
            }
        }
        if (feature == "spouse") {
            let value = (<HTMLInputElement>document.getElementById("spousephnumber")).value;
            if (value.length < 12) {
                (<HTMLInputElement>document.getElementById("spousephnumbererrmsg")).innerHTML = "Please enter a valid Phone number";
            }
            if (value.length >= 12) {
                (<HTMLInputElement>document.getElementById("spousephnumbererrmsg")).innerHTML = "";
            }
        }
        if (feature == "child") {
            let id = "childphnumber" + i
            if (event.target.id == id) {
                let child_phnbumber = (<HTMLInputElement>document.getElementById("childphnumber" + [i])).value;
                if (child_phnbumber.length < 12 && child_phnbumber.length > 0) {
                    (<HTMLInputElement>document.getElementById("childpherror" + i)).innerHTML = "Please enter a valid Phone number";
                }
                if (child_phnbumber.length >= 12) {
                    (<HTMLInputElement>document.getElementById("childpherror" + i)).innerHTML = "";
                }
            }
        }
    }

    onchildssnFocusout(e) {
        let chilsssnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
        if (chilsssnvalue.length == 11) {
            var substr = chilsssnvalue.substring(chilsssnvalue.length - 4, chilsssnvalue.length);
            let str = "***-**-"
            str = str + substr;
            (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
        }
    }

    onprimaryssnFocusout(e) {
        let primaryssnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
        if (primaryssnvalue.length == 11) {
            var substr = primaryssnvalue.substring(primaryssnvalue.length - 4, primaryssnvalue.length);
            let str = "***-**-"
            str = str + substr;
            (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
        }
    }

    onspousessnFocusout(e) {
        let spousessnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
        if (spousessnvalue.length == 11) {
            var substr = spousessnvalue.substring(spousessnvalue.length - 4, spousessnvalue.length);
            let str = "***-**-"
            str = str + substr;
            (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
        }
    }

    onFocusChild(event) {
        let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
        if (ssn.length == 11) {
            let sessionstoragessn = sessionStorage.getItem(event.target.id);
            (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
        }
    }

    onFocusPrimary(event) {
        let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
        if (ssn.length == 11) {
            let sessionstoragessn = sessionStorage.getItem(event.target.id);
            (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
        }
    }

    onFocusSpouse(event) {
        let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
        if (ssn.length == 11) {
            let sessionstoragessn = sessionStorage.getItem(event.target.id);
            (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
        }
    }

    omit_special_char(e) {
        var key = e.which || e.keyCode || 0;
        return ((key >= 65 && key <= 92) || (key >= 97 && key <= 124))

    }
}
