import {Component} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ToastrService} from 'ngx-toastr';
import {DatePipe, Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SharingService} from 'src/app/services/sharing.service';

@Component({
    selector: 'app-beneficiary',
    // standalone: true,
    // imports: [],
    templateUrl: './beneficiary.component.html',
    styleUrl: './beneficiary.component.scss',
    providers: [DatePipe]
})
export class BeneficiaryComponent {
    companyId: any;
    agreementData: any[] = [];
    code: any;
    memberId: any = 0;
    documentId: any;
    pdfSrc: any | ArrayBuffer | null = null;
    role: string;
    sample: any;
    pdfViewer: any;
    pdfBytes: ArrayBuffer | null = null;
    dateFormat: any;
    dateTimeFormat: any;
    decryptedMemberId: any;
    decryptData: any;
    agencyName: any;
    route_company_name: any;
    isRiderAvailable: boolean = false
    member_document_id: string;
    mainMemberId: any;
    family_id: string;
    newForm: UntypedFormGroup;
    finalObj = {
        family_id: '',
        member_personal_data: []
    };
    member_medicationsPercentage: any = 0;
    spouse_member_medicationsPercentage: any = 0;
    child_member_medicationsPercentage: any = 0;
    cn_member_medicationsPercentage: any = 0;
    new_cn_member_medicationsPercentage: any = 0;
    child_cn_member_medicationsPercentage: any = 0;
    submitAttempt: boolean = false;
    primaryData: any[] = []
    spouseData: any[] = []
    A2Ccode: any;
    cartData: any[] = [];
    todaydate: any;
    childData: any[] = [];
    primaryCartdata: any[] = [];
    spouseCartData: any[] = [];
    childCartData: any[] = [];
    recurring_billing_date: any;
    start_date: any;
    primaryMemberData: any[] = [];
    sposeMemberData: any[] = [];
    childmemberData: any[] = [];
    agentInfo: any = null;
    new_members: any[] = [];
    cart_products: any[] = [];
    primary_info: any[];
    child_info: any[] = [];
    spouse_info: any[];
    beneficiary: any[] = [];
    main_index: any;
    child_index: any;
    filteredChildData: any[] = [];
    myForm: any;
    addProductForm: any;
    child_totalPercentage: any;
    child_contigent_totalPercentage: any;
    private shouldReload = true;

    constructor(private formBuilder: UntypedFormBuilder,
                private deviceService: DeviceDetectorService,
                private service: SharingService,
                private datePipe: DatePipe,
                private toastr: ToastrService,
                private route: ActivatedRoute,
                private router: Router,
                private http: HttpClient,
                private location: Location
    ) {
        this.A2Ccode = this.route.snapshot.paramMap.get('code');
        this.mainMemberId = this.route.snapshot.paramMap.get('id');
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        localStorage.removeItem('from_Beneficiary');
    }

    get member_medications(): UntypedFormArray {
        return this.addProductForm.get("member_medications") as UntypedFormArray
    }

    get spouse_member_medications(): UntypedFormArray {
        return this.addProductForm.get("spouse_member_medications") as UntypedFormArray
    }

    get contingent_member_medications(): UntypedFormArray {
        return this.addProductForm.get("contingent_member_medications") as UntypedFormArray
    }

    get spouse_contingent_member_medications(): UntypedFormArray {
        return this.addProductForm.get("spouse_contingent_member_medications") as UntypedFormArray
    }

    ngOnInit(): void {
        const now = new Date(); // Get current date
        const nextYear = now.getFullYear() + 1; // Get next year
        this.todaydate = new Date(nextYear, 0, 1); // Create a new Date object for January 1st of next year
        this.addProductForm = this.formBuilder.group({
            member_medications: this.formBuilder.array([]),
            spouse_member_medications: this.formBuilder.array([]),
            contingent_member_medications: this.formBuilder.array([]),
            spouse_contingent_member_medications: this.formBuilder.array([]),
            child_info: this.formBuilder.array([]),
        });
        this.pdfViewer = document.querySelector('#pdfViewer');
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;
                this.route_company_name = data.route_company;
                if (this.A2Ccode != undefined && this.A2Ccode != null) {
                    this.getDecryptMemberIdA2C();
                    // this.addPrimary();

                } else {
                    this.getBeneficiaryInfoD2C();
                    // this.addPrimary();
                    // this.ContingentaddSpouse();
                }
            }
        })

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
        }
    }

    createProjectGroup() {
        return this.formBuilder.group({
            projectName: [''],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: [''],
            category: 'child',
        })
    }

    createProjectGroup1() {
        return this.formBuilder.group({
            projectName1: [''],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: [''],
            category: 'child_contingent',
        })
    }

    addNewCompany() {
        let control = <UntypedFormArray>this.addProductForm.controls.child_info;
        control.push(
            this.formBuilder.group({
                projects: this.formBuilder.array([this.createProjectGroup()]),
                projects1: this.formBuilder.array([this.createProjectGroup1()])
            })
        )
    }

    addNewProject(control) {
        control.push(this.createProjectGroup())
    }

    deleteProject(control, index) {
        control.removeAt(index)
    }

    setCompanies() {
        let control = <UntypedFormArray>this.addProductForm.controls.child_info;
        this.child_info.forEach(x => {
            control.push(this.formBuilder.group({
                company: x.company,
                memberId: x.member_id,
                fullname: x.firstname + ' ' + x.lastname,
                relation: x.relation,
                created_at: x.created_at,
                product_name: x.product_name,
                policy_amount: x.policy_amount,
                percentageCalculation: '',
                contigent_percentageCalculation: '',
                child_count: 0,
                contigent_count: 0,
                projects: this.setProjects(x),
                projects1: this.setProjects1(x)
            }))

        })
    }

    setProjects(x) {
        let arr = new UntypedFormArray([]);
        if (x.beneficiary?.length > 0) {
            x.beneficiary.forEach((y) => {
                if (y.category == 'child') {
                    arr.push(
                        this.formBuilder.group({
                            projectName: '',
                            firstname: y.firstname,
                            lastname: y.lastname,
                            gender: y.gender,
                            percentage: y.percentage,
                            member_id: y.member_id,
                            category: y.category,
                        })
                    );
                }
            });
            return arr;
        } else {
            x.projects.forEach((y) => {
                arr.push(
                    this.formBuilder.group({
                        projectName: '',
                        firstname: y.firstname,
                        lastname: y.lastname,
                        gender: y.gender,
                        percentage: y.percentage,
                        member_id: y.member_id,
                        category: 'child',
                    })
                );
            });
            return arr;
        }
    }

    setProjects1(x) {
        let arr = new UntypedFormArray([]);
        if (x.beneficiary?.length > 0) {
            x.beneficiary.forEach((y) => {
                if (y.category == 'child_contingent') {
                    arr.push(
                        this.formBuilder.group({
                            projectName1: '',
                            firstname: y.firstname,
                            lastname: y.lastname,
                            gender: y.gender,
                            percentage: y.percentage,
                            member_id: y.member_id,
                            category: y.category,
                        })
                    );
                }
            });
            return arr;

        } else {
            x.projects1.forEach((y) => {
                if (y.default != 0) {
                    arr.push(
                        this.formBuilder.group({
                            projectName1: '',
                            firstname: y.firstname,
                            lastname: y.lastname,
                            gender: y.gender,
                            percentage: y.percentage,
                            member_id: y.member_id,
                            category: 'child_contingent',
                        })
                    );
                }
            });
            return arr;
        }
    }

    removememberFamily(i, feature) {
        const itemsArray = this.addProductForm.get('member_medications') as UntypedFormArray;
        itemsArray.removeAt(i);
        this.onFocusPercentage(feature, 1)
    }

    removespousememberFamily(i, feature) {
        const itemsArray = this.addProductForm.get('spouse_member_medications') as UntypedFormArray;
        itemsArray.removeAt(i);
        this.onFocusPercentage(feature, 1)
    }

    removeContingentFamily(i, feature) {
        const itemsArray = this.addProductForm.get('contingent_member_medications') as UntypedFormArray;
        itemsArray.removeAt(i);
        this.onFocusPercentage(feature, 1)
    }

    newremoveContingentFamily(i, feature) {
        const itemsArray = this.addProductForm.get('spouse_contingent_member_medications') as UntypedFormArray;
        itemsArray.removeAt(i);
        this.onFocusPercentage(feature, 1)
    }

    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            category: ['primary'],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: ['']
        })
    }

    newEmployee2(): UntypedFormGroup {
        return this.formBuilder.group({
            category: ['contingent'],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: ['']
        })
    }

    newEmployee3(): UntypedFormGroup {
        return this.formBuilder.group({
            category: ['spouse'],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: ['']
        })
    }

    newEmployee4(): UntypedFormGroup {
        return this.formBuilder.group({
            category: ['spouse_contingent'],
            firstname: [''],
            lastname: [''],
            gender: [''],
            percentage: [''],
            member_id: ['']
        })
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onFocusPercentage(feature, e) {
        if (e != '') {
            if (feature == 'member_medications') {
                this.member_medications.value.forEach(element => {
                    if (isNaN(element.percentage) || element.percentage == "") {
                        element.percentage = 0;
                    }
                    element.percentage = parseInt(element.percentage)
                });
                const propertyToSum = 'percentage';
                const sum = this.addProductForm.value[feature].reduce((accumulator, currentValue) => {
                    return accumulator + currentValue[propertyToSum];
                }, 0);
                this.member_medicationsPercentage = sum
                this.validationCheck();
            }
            if (feature == 'spouse_member_medications') {
                this.spouse_member_medications.value.forEach(element => {
                    if (isNaN(element.percentage) || element.percentage == "") {
                        element.percentage = 0;
                    }
                    element.percentage = parseInt(element.percentage)
                });

                const propertyToSum = 'percentage';

                // Use reduce to calculate the sum of the specified property
                const summ = this.addProductForm.value[feature].reduce((accumulator, currentValue) => {
                    return accumulator + currentValue[propertyToSum];
                }, 0);
                this.spouse_member_medicationsPercentage = summ
                this.validationCheck();
            }
            if (feature == 'contingent_member_medications') {
                this.contingent_member_medications.value.forEach(element => {
                    if (isNaN(element.percentage) || element.percentage == "") {
                        element.percentage = 0;
                    }
                    element.percentage = parseInt(element.percentage)
                });
                const propertyToSum = 'percentage';

                // Use reduce to calculate the sum of the specified property
                const summm = this.addProductForm.value[feature].reduce((accumulator, currentValue) => {
                    return accumulator + currentValue[propertyToSum];
                }, 0);
                this.cn_member_medicationsPercentage = summm
                this.validationCheck();

            }

            if (feature == 'spouse_contingent_member_medications') {
                this.spouse_contingent_member_medications.value.forEach(element => {
                    if (isNaN(element.percentage) || element.percentage == "") {
                        element.percentage = 0;
                    }
                    element.percentage = parseInt(element.percentage)
                });

                const propertyToSum = 'percentage';

                // Use reduce to calculate the sum of the specified property
                const summmm = this.addProductForm.value[feature].reduce((accumulator, currentValue) => {
                    return accumulator + currentValue[propertyToSum];
                }, 0);
                this.new_cn_member_medicationsPercentage = summmm
                this.validationCheck();
            }
        } else {
            if (feature == 'contingent_member_medications') {
                this.cn_member_medicationsPercentage = ''
            }
            if (feature == 'spouse_contingent_member_medications') {
                this.new_cn_member_medicationsPercentage = ''
            }
        }
        this.validationCheck();

    }

    addPrimary() {
        this.member_medications.push(this.newEmployee1());
    }

    addspouse() {
        this.spouse_member_medications.push(this.newEmployee3());
    }


    ContingentaddSpouse() {
        this.contingent_member_medications.push(this.newEmployee2());
    }

    newContingentaddSpouse() {
        this.spouse_contingent_member_medications.push(this.newEmployee4());
    }

    getBeneficiaryInfoD2C() {
        var url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
            this.cartData = Object.cart_products;
            this.start_date = Object?.order_details.start_date;
            this.recurring_billing_date = Object?.order_details.recurring_billing_date;
            this.cart_products = Object.cart_products;
            this.new_members = Object.members.filter(x => x['is_active'] == "yes");
            this.new_members.forEach(value => {
                value.product_name = '';
                value.is_add = ''
                let index = this.cart_products.findIndex(x => parseInt(x.member_id) === parseInt(value.member_id))
                if (index != -1) {
                    value.product_name = this.cart_products[index].product_name
                }
            })
            this.primary_info = this.new_members.filter(x => x['relation'] == "Primary");
            this.spouse_info = this.new_members.filter(x => x['relation'] == "Spouse");
            this.child_info = this.new_members.filter(x => x['relation'] == "Child");
            if (this.primary_info?.length > 0) {
                this.beneficiary = this.primary_info[0]?.beneficiary;
                if (this.beneficiary?.length > 0) {
                    var primary = []
                    var contingent = []
                    contingent = this.beneficiary.filter(x => x['category'] == "contingent");
                    primary = this.beneficiary.filter(x => x['category'] == "primary");

                    for (var i = 0; i < primary.length; i++) {
                        this.addPrimary();
                        this.addProductForm.controls['member_medications']['controls'][i].patchValue({
                            firstname: primary[i]?.firstname,
                            lastname: primary[i]?.lastname,
                            gender: primary[i]?.gender,
                            percentage: primary[i]?.percentage,
                            member_id: primary[i]?.member_id,
                        })
                    }
                    for (var i = 0; i < contingent.length; i++) {
                        this.ContingentaddSpouse();
                        this.addProductForm.controls['contingent_member_medications']['controls'][i].patchValue({
                            firstname: contingent[i]?.firstname,
                            lastname: contingent[i]?.lastname,
                            gender: contingent[i]?.gender,
                            percentage: contingent[i]?.percentage,
                            member_id: contingent[i]?.member_id,
                        })
                    }
                } else {
                    this.addPrimary();
                }

            }
            if (this.spouse_info?.length > 0) {
                this.beneficiary = this.spouse_info[0]?.beneficiary;
                if (this.beneficiary?.length > 0) {
                    var spouse = []
                    var spouse_contingent = []
                    spouse = this.beneficiary?.filter(x => x['category'] == "spouse");
                    spouse_contingent = this.beneficiary?.filter(x => x['category'] == "spouse_contingent");
                    for (var i = 0; i < spouse?.length; i++) {
                        this.addspouse();
                        this.addProductForm.controls['spouse_member_medications']['controls'][i].patchValue({
                            firstname: spouse[i]?.firstname,
                            lastname: spouse[i]?.lastname,
                            gender: spouse[i]?.gender,
                            percentage: spouse[i]?.percentage,
                            member_id: spouse[i]?.member_id,
                        })
                    }
                    for (var i = 0; i < spouse_contingent?.length; i++) {
                        this.newContingentaddSpouse();
                        this.addProductForm.controls['spouse_contingent_member_medications']['controls'][i].patchValue({
                            firstname: spouse_contingent[i]?.firstname,
                            lastname: spouse_contingent[i]?.lastname,
                            gender: spouse_contingent[i]?.gender,
                            percentage: spouse_contingent[i]?.percentage,
                            member_id: spouse_contingent[i]?.member_id,
                        })
                    }
                } else {
                    this.addspouse();
                }

            }
            if (this.child_info?.length > 0) {
                this.child_info.forEach(element => {

                    element.projects = [];
                    element.projects1 = [];
                    var projects = {
                        projectName: '',
                        category: 'child',
                        firstname: '',
                        lastname: '',
                        gender: '',
                        percentage: '',
                    }
                    var projects1 = {
                        projectName: '',
                        category: 'child_contingent',
                        firstname: '',
                        lastname: '',
                        gender: '',
                        percentage: '',
                        member_id: element.member_id,
                        default: '0'
                    }

                    element.projects.push(projects)
                    element.projects1.push(projects1)
                });
                this.setCompanies();
            }


        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }

    getDecryptMemberIdA2C() {
        var url = 'DecryptMemberIdA2C/' + this.A2Ccode;
        this.service.getData(url).subscribe((Object: any) => {
            this.decryptedMemberId = Object.data[0].member_id;
            this.decryptData = Object.data;
            this.mainMemberId = Object.data[0].member_id;
            this.family_id = Object.data[0].family_id;
            localStorage.setItem("decrypted_member_id", Object.data[0].member_id);
            this.getBeneficiaryInfoD2C()

            // this.listEsignMemberDocumentA2C(Object.data[0].member_id)
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }


    validationCheck() {

        if (this.spouse_info.length > 0) {
            if (this.spouse_member_medicationsPercentage != 100 && this.spouse_member_medicationsPercentage != '') {
                const itemsArray = this.addProductForm.get('spouse_member_medications') as UntypedFormArray;
                this.addProductForm.addControl('spouse_percentage_cal', new UntypedFormControl(''));
                this.addProductForm.controls['spouse_percentage_cal'].setErrors({'invalid-Percentage': true});
            } else if (this.spouse_member_medicationsPercentage == 100) {
                this.addProductForm.controls['spouse_percentage_cal']?.setErrors(null);
            }
            if (this.new_cn_member_medicationsPercentage != 100 && this.new_cn_member_medicationsPercentage != '') {
                const itemsArray = this.addProductForm.get('spouse_contingent_member_medications') as UntypedFormArray;
                for (var i = 0; i < itemsArray.length; i++) {
                    itemsArray.controls[i]['controls'].firstname.setValidators([Validators.required]);
                    itemsArray.controls[i]['controls'].firstname
                        .updateValueAndValidity({emitEvent: false});
                }
                this.addProductForm.addControl('newcontingent', new UntypedFormControl(''));
                this.addProductForm.controls['newcontingent'].setErrors({'invalid-Percentage': true});
            } else if (this.new_cn_member_medicationsPercentage == 100) {
                this.addProductForm.controls['newcontingent']?.setErrors(null);
                const itemsArray = this.addProductForm.get('spouse_contingent_member_medications') as UntypedFormArray;
                for (var i = 0; i < itemsArray.length; i++) {
                    itemsArray.controls[i]['controls'].firstname.clearValidators([Validators.required]);
                    itemsArray.controls[i]['controls'].firstname
                        .updateValueAndValidity({emitEvent: false});
                }
            } else if (this.new_cn_member_medicationsPercentage == '') {
                this.addProductForm.controls['newcontingent']?.setErrors(null);
                const itemsArray = this.addProductForm.get('spouse_contingent_member_medications') as UntypedFormArray;
                for (var i = 0; i < itemsArray.length; i++) {
                    itemsArray.controls[i]['controls'].firstname.clearValidators([Validators.required]);
                    itemsArray.controls[i]['controls'].firstname
                        .updateValueAndValidity({emitEvent: false});
                }
            }

        }

        // Primary----------------------------------------
        if (this.member_medicationsPercentage != 100 && this.member_medicationsPercentage != '') {
            const itemsArray = this.addProductForm.get('member_medications') as UntypedFormArray;
            this.addProductForm.addControl('member_percentage_cal', new UntypedFormControl(''));
            this.addProductForm.controls['member_percentage_cal'].setErrors({'invalid-Percentage': true});
        } else if (this.member_medicationsPercentage == 100) {
            this.addProductForm.controls['member_percentage_cal']?.setErrors(null);

        }

        if (this.cn_member_medicationsPercentage != 100 && this.cn_member_medicationsPercentage != '') {
            const itemsArray = this.addProductForm.get('contingent_member_medications') as UntypedFormArray;
            for (var i = 0; i < itemsArray.length; i++) {
                itemsArray.controls[i]['controls'].firstname.setValidators([Validators.required]);
                itemsArray.controls[i]['controls'].firstname
                    .updateValueAndValidity({emitEvent: false});
            }
            this.addProductForm.addControl('contingent', new UntypedFormControl(''));
            this.addProductForm.controls['contingent'].setErrors({'invalid-Percentage': true});

        } else if (this.cn_member_medicationsPercentage == 100) {
            this.addProductForm.controls['contingent']?.setErrors(null);
            const itemsArray = this.addProductForm.get('contingent_member_medications') as UntypedFormArray;
            for (var i = 0; i < itemsArray.length; i++) {
                itemsArray.controls[i]['controls'].firstname.clearValidators([Validators.required]);
                itemsArray.controls[i]['controls'].firstname
                    .updateValueAndValidity({emitEvent: false});
            }
        } else if (this.cn_member_medicationsPercentage == '') {
            const itemsArray = this.addProductForm.get('contingent_member_medications') as UntypedFormArray;
            for (var i = 0; i < itemsArray.length; i++) {
                itemsArray.controls[i]['controls'].firstname.clearValidators([Validators.required]);
                itemsArray.controls[i]['controls'].firstname
                    .updateValueAndValidity({emitEvent: false});
            }
        }


    }

    // Function to handle input change
    onInputChange(value: string) {
        // Update spouse_member_medicationsPercentage when input is cleared manually
        if (value === '') {
            this.spouse_member_medicationsPercentage = 0;
        }
    }

    onMemberChange(value: string) {
        if (value === '') {
            this.member_medicationsPercentage = 0;
        }
    }

    onCNMemberChange(value: string) {
        if (value === '') {
            this.cn_member_medicationsPercentage = 0;
        }
    }

    onNewCNMemberChange(value: string) {
        if (value === '') {
            this.new_cn_member_medicationsPercentage = 0;
        }
    }

    onFocusoutChildPercentage(feature, item, j, comp) {
        this.child_totalPercentage = 0;
        // let x = (<HTMLInputElement>document.getElementById('count'+j))
        // x.classList.add('child_total')

        comp.controls.projects.controls.forEach(element => {
            this.child_totalPercentage += Number(element.controls.percentage.value)
            comp.controls.child_count = this.child_totalPercentage
        });
        if (this.child_totalPercentage != 100) {
            comp.controls.percentageCalculation.setErrors({'invalid-Percentage': true});
        } else {
            comp.controls.percentageCalculation?.setErrors(null);
        }

        // Remove the error if no child Beneficiaries added
        if (comp.controls.projects.controls.length == 0) {
            comp.controls.percentageCalculation?.setErrors(null);
        }

        let control = <UntypedFormArray>this.addProductForm.controls.child_form;
    }

    onFocusoutChildContigent(feature, item, j, comp) {
        this.child_contigent_totalPercentage = 0;
        comp.controls.projects1.controls.forEach(element => {
            this.child_contigent_totalPercentage += Number(element.controls.percentage.value);
            comp.controls.contigent_count = this.child_contigent_totalPercentage
        });
        if (this.child_contigent_totalPercentage != 100) {
            comp.controls.contigent_percentageCalculation.setErrors({'invalid-Percentage': true});
        } else {
            comp.controls.contigent_percentageCalculation?.setErrors(null);
        }

        // Remove the error if no child Beneficiaries added
        if (comp.controls.projects1.controls.length == 0) {
            comp.controls.contigent_percentageCalculation?.setErrors(null);
        }

    }

    export() {
        this.submitAttempt = true;
        this.finalObj.family_id = this.family_id;
        this.finalObj.member_personal_data = [];
        if (this.addProductForm.valid) {
            this.addProductForm.value?.child_info.forEach(element => {
                element.projects?.forEach(e => {
                    let obj = {
                        category: e.category,
                        firstname: e.firstname,
                        lastname: e.lastname,
                        gender: e.gender,
                        percentage: e.percentage,
                        member_id: element.memberId
                    }
                    this.finalObj.member_personal_data.push(obj);
                })
            });
            this.addProductForm.value?.child_info.forEach(element => {
                element.projects1?.forEach(e => {
                    let obj = {
                        category: 'child_contingent',
                        firstname: e.firstname,
                        lastname: e.lastname,
                        gender: e.gender,
                        percentage: e.percentage,
                        member_id: element.memberId
                    }
                    this.finalObj.member_personal_data.push(obj);
                })
            });


            this.addProductForm.value?.contingent_member_medications?.forEach(element => {
                let obj = {
                    category: element.category,
                    firstname: element.firstname,
                    lastname: element.lastname,
                    gender: element.gender,
                    percentage: element.percentage,
                    member_id: this.primary_info[0]?.member_id
                }
                this.finalObj.member_personal_data.push(obj);
            });
            this.addProductForm.value?.spouse_contingent_member_medications?.forEach(element => {
                let obj = {
                    category: element.category,
                    firstname: element.firstname,
                    lastname: element.lastname,
                    gender: element.gender,
                    percentage: element.percentage,
                    member_id: this.spouse_info[0]?.member_id
                }
                this.finalObj.member_personal_data.push(obj);
            });

            this.addProductForm.value.member_medications?.forEach(element => {
                let obj = {
                    category: element.category,
                    firstname: element.firstname,
                    lastname: element.lastname,
                    gender: element.gender,
                    percentage: element.percentage,
                    member_id: this.primary_info[0]?.member_id
                }
                this.finalObj.member_personal_data.push(obj);
            });
            this.addProductForm.value?.spouse_member_medications?.forEach(element => {
                let obj = {
                    category: element.category,
                    firstname: element.firstname,
                    lastname: element.lastname,
                    gender: element.gender,
                    percentage: element.percentage,
                    member_id: this.spouse_info[0]?.member_id
                }
                this.finalObj.member_personal_data.push(obj);
            });
            if (this.finalObj) {
                [...new Set(this.finalObj.member_personal_data)]
            }
            let body = {family_id: this.family_id, category: "PDF_Enrollment_Form"}
            var url = 'CreateBeneficiaryD2C'
            this.service.postData(url, this.finalObj).subscribe((Object: any) => {
                this.router.navigate(['/agrement', this.mainMemberId, this.family_id]);

                // if (this.A2Ccode != undefined && this.A2Ccode != null) {
                //   this.router.navigate([this.route_company_name + '/A2C/memberagreement', this.mainMemberId, this.family_id, this.A2Ccode]);

                // } else {
                //   if (this.location.path().includes('d2c')) {
                //     this.router.navigate([this.route_company_name + '/d2c/memberagreement', this.mainMemberId, this.family_id]);
                //   }
                //   if (this.location.path().includes('acc')) {
                //     this.router.navigate([this.route_company_name + '/acc/memberagreement', this.mainMemberId, this.family_id]);
                //   }
                // }
            }, err => {
                this.toastr.error(err.error.message);
            })
        }
    }

    onPrevious() {
        if (this.A2Ccode != undefined && this.A2Ccode != null) {
            this.router.navigate(['/payments', this.family_id, this.mainMemberId, this.A2Ccode]);
        } else {
            this.router.navigate(['/payments', this.family_id, this.mainMemberId]);
        }
        localStorage.setItem('from_Beneficiary', 'Beneficiary');
    }
}
