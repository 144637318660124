import { DatePipe, Location, ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SharingService } from '../../../services/sharing.service';

@Component({
    selector: 'app-d2c-enrollment',
    templateUrl: './d2c-enrollment.component.html',
    styleUrls: ['./d2c-enrollment.component.scss']
})

export class D2cEnrollmentComponent implements OnInit {

    agent_id_from_url_param: any;
    Form: UntypedFormGroup;
    submitAttempt: boolean;
    newForm: UntypedFormGroup;
    test = true;
    spouseForm = true;
    applicants: any = {};
    objectData: any = {};
    GetProducts: any = {};
    cartProductDetails = [];
    member_personal_data: any = {};
    Age: any;
    spouseAge: any;
    childAge: any;
    AGE: any = [];
    groupList: any = [];
    username: string;
    er: any;
    er1: any;
    weight: any;
    zipcode: any;
    ziperr: any;
    applicant: any;
    primaryWeight: any;
    primaryDate: any;
    primaryApplicant: any;
    spouseApplicant: any;
    spouseWeight: any;
    spouseDate: any;
    childError: string;
    spouseWtError: string;
    ageError: string;
    parentAge: string;
    parentAge1: string;
    date: Date;
    minDate: string;
    minimumDate: Date;
    invalidDates: Date[];
    invalidDates1: Date[];
    spouseAgeMsg: string;
    value: any;
    inValue: any;
    errtxt = '';
    spouceerrtxt = '';
    childerrtxt = '';
    error: any;
    childApplicant: any;
    spouseWgt: any;
    spouseGe: any;
    spouseSmk: any;
    spouseHe: any;
    spouseInc: any;
    childWgt: any;
    childG: any;
    childDob: Date;
    childSmk: any;
    inchesAllowmode = true;
    phnumbererrmsg = '';
    spouse_Id: any;
    arr: any[] = [];
    spousearr: any[] = [];
    primaryInfo: any;
    spouseInfo: any;
    childInfo: any;
    agent_Id: any;
    emailError: any;
    emailerrmsg = false;
    phoneerrmsg: any;
    errorMsgShow = true;
    public data: any[] = [];
    result = '';
    effectiveDate: any;
    companyName: any;
    membershipUrl: any;
    eternity = false;
    usaStates: any;
    fellowship = false;
    companyId: any;
    openError = false;
    displayText = false;
    maxDate: Date;
    spouse_check: boolean;
    child_check: boolean;
    addchilddisabled: boolean;
    isActiveState = true;
    loader = false;
    agentCodeErrorMsg = '';
    agentCodeParam: any;
    isAgentCodeApplied = false;
    applyBtnText = 'Apply';
    agentInfo: any = null;
    showAgentCodeSection = true;

    subscriptions: Subscription[] = [];
    public isSameAddressControl: UntypedFormControl = new UntypedFormControl(false);
    public isSpouseAddressControl: UntypedFormControl = new UntypedFormControl(false);
    public isChildAddressControl: UntypedFormControl = new UntypedFormControl(false);
    status: any;
    dateFormat: string;
    ischeckedAddress = false;
    dateTimeFormat: string;
    toasterTimeout: any;
    isa2c = false;
    isacc = false;
    managersList: any[];
    group_id: any;
    stateStatus: any[] = [];
    routeMemberId: any;
    routeFamlyId: any;
    memberPersonalData: any[] = [];
    primary_info: any;
    spouse_info: any;
    child_info: any;
    primaryAddressobj: any;
    spouseAddressobj: any;
    childAddressobj: any;
    parentCompanyId: any;
    limit_age: number;
    agent_text: any;
    new_text: any;
    btn_text: any;
    selectedRecordForProducts: any = [];
    productList: any;
    allcheckboxchecked: boolean;
    family_id: any;
    user_company_id: any;
    company_key: any;
    display_select_radio: boolean;
    display_select_btn: boolean;
    hasSubscribedProduct: boolean = false;
    packageNameExist: boolean = false;
    product_name: any;
    family_type_name: any;
    public_url: any;
    hideAgentSection: boolean = false;

    // Labels
    dependentLabel: string = 'Dependent';
    spouseLabel: string = 'Spouse';

    // Spouse Validation
    relationSpouseIndex: number = -1;
    private valueSubscriptions: Subscription[] = [];
  supportPhone: any;
    otp_notification: any;

    constructor(
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private service: SharingService,
        private location: Location,
        private viewportScroller: ViewportScroller
    ) {
        this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
        this.routeFamlyId = this.route.snapshot.paramMap.get('family_id');
        this.agentCodeParam = this.route.snapshot.paramMap.get('agent_code');
        localStorage.removeItem('order_details');
        this.route.queryParams.subscribe(params => {
            if (Object.keys(params).length !== 0 && params.hasOwnProperty('utm_aff')) {
                this.agentCodeParam = params['utm_aff'];
            }

            if (Object.keys(params).length !== 0 && params.hasOwnProperty('affiliate_code')) {
                this.agentCodeParam = params['affiliate_code'];
            }

            if (Object.keys(params).length !== 0 && params.hasOwnProperty('agent_id')) {
                this.agent_id_from_url_param = params['agent_id'];
            }

            if (Object.keys(params).length !== 0 && params.hasOwnProperty('product_prices_id')) {
                this.hasSubscribedProduct = true;
                localStorage.setItem('product_prices_id', params['product_prices_id'])
                this.getCompanyProductInfo(params['product_prices_id']);
            }
            if (Object.keys(params).length !== 0 && params.hasOwnProperty('product_name')) {
                this.packageNameExist = true;
                this.product_name = params['product_name'];
                localStorage.removeItem('product_prices_id')
                localStorage.setItem('package_product_name', this.product_name)
            }

        });
        console.log('Agent code from URL: ' + this.agentCodeParam);
        this.subscriptions.push(this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.companyId = data.company_id;
                this.otp_notification=data?.sms_notification
                this.parentCompanyId = data.parent_company;
                this.agent_text = data.agent_text;
                this.public_url = data.public_url;
                this.supportPhone = data?.support_phone;
                if (data.hasOwnProperty('show_agent_info')) {
                    this.hideAgentSection = true;
                } else {
                    this.hideAgentSection = false;
                }
                this.getNoSaleProductUsaStates().then(() => {
                    if (this.routeFamlyId !== null) {
                        this.previousEnrollment();
                    }
                });

                this.limit_age = 120;
                this.btn_text = 'Get Pricing';

                if (this.agentCodeParam) {
                    console.log('Applying agent code');
                    this.applyAgentCode(this.agentCodeParam);
                }
            }
        })
        );
        this.labelConfig();
    }

    ngOnInit(): void {
        // ==============past and future dates display===========//
        this.minimumDate = new Date();
        this.maxDate = new Date();
        const today = new Date();
        const invalidDate = new Date();
        const invalidDate1 = new Date();
        invalidDate.setDate(today.getDate() - 1);
        invalidDate1.setDate(today.getDate() + 3);
        this.invalidDates = [today, invalidDate];
        this.invalidDates1 = [today, invalidDate1];
        // *********effective start data**************//
        this.newForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            manager: [''],
            email: ['',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                ]),
            ],
            phone: ['', Validators.required],
            dob: ['', Validators.required,],
            age: ['', [Validators.required, Validators.min(18), Validators.max(this.limit_age - 1)]],
            feet: ['', ''],
            inch: ['',],
            startDate: [''],
            weight: ['', ''],
            address1: ['', Validators.required],
            address2: [''],
            active: [1],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            gender: ['', Validators.required],
            relation: ['Primary'],
            member_id: [''],
            child_Array: this.formBuilder.array([]),
            spouse_Array: this.formBuilder.array([]),
            agentCode: [this.agentCodeParam],
            package: [''],
            otp_notification_check: [],
        });

    }

    ageRangeValidator(max: number, min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value !== null && (value < min || value > max)) {
                return { ageOutOfRange: true };
            }
            return null;
        };
    }

    enableSpouse(): boolean {
        let enable = true;
        if (this.hasSubscribedProduct) {
            enable = false;
        }
        return enable;
    }
    enableChild(): boolean {
        let enable = true;
        if (this.hasSubscribedProduct) {
            enable = false;
            if (this.family_type_name == 'Primary + 1' || this.family_type_name == 'Primary + 2' || this.family_type_name == 'Family' || this.family_type_name == 'Primary + Spouse' || this.family_type_name == 'Primary + Children') {
                enable = true;

            }
        }

        return enable;
    }
    enableAddDependent(): boolean {
        let enable = true;
        if (this.hasSubscribedProduct) {
            if (this.family_type_name == 'Primary + 1' || this.family_type_name == 'Primary + Spouse') {
                enable = false;
            }
            if (this.family_type_name == 'Primary + 2' || this.family_type_name == 'Primary + Children') {
                if (this.child_Array().length > 1) {
                    enable = false
                }
            }
        }
        return enable;
    }
    getCompanyProductInfo(product_prices_id) {
        this.loader = true
        const url = 'GetCompanyProductInfo/' + product_prices_id;
        this.subscriptions.push(this.service.getData(url).subscribe((Object: any) => {
            this.loader = false
            if (Object.status) {
                this.selectedRecordForProducts = Object.data;
                let max_age = this.selectedRecordForProducts[0]?.member_age_max
                let min_age = this.selectedRecordForProducts[0]?.member_age_min
                this.family_type_name = this.selectedRecordForProducts[0]?.family_type_name
                if (this.hasSubscribedProduct) {
                    this.newForm.controls.age.setValidators([Validators.required, this.ageRangeValidator(max_age, min_age)]);
                } else {
                    this.newForm.controls.age.clearValidators();
                }

                this.newForm.controls.age.updateValueAndValidity();
            }
        }, (err) => {
            this.loader = false
            if (err.error.message === 'Unauthenticated.') {
                this.openError = true;
            } else {
                console.log(err);
            }
        })
        );
    }
    sameAddressControl(value, feature, index?): void {
        if (value && feature === 'child' && this.child_Array().length > 0) {
            for (let i = 0; i < this.child_Array().length; i++) {
                this.child_Array().controls[index]['controls'].address1.setValue(this.newForm.controls.address1.value);
                this.child_Array().controls[index]['controls'].address2.setValue(this.newForm.controls.address2.value);
                this.child_Array().controls[index]['controls'].city.setValue(this.newForm.controls.city.value);
                this.child_Array().controls[index]['controls'].zip.setValue(this.newForm.controls.zip.value);
                this.child_Array().controls[index]['controls'].state.setValue(this.newForm.controls.state.value);
            }
        }

        if (!value && feature === 'child' && this.child_Array().length > 0) {
            for (let i = 0; i < this.child_Array().length; i++) {
                this.child_Array().controls[index]['controls'].address1.setValue('');
                this.child_Array().controls[index]['controls'].address2.setValue('');
                this.child_Array().controls[index]['controls'].city.setValue('');
                this.child_Array().controls[index]['controls'].zip.setValue('');
                this.child_Array().controls[index]['controls'].state.setValue('');

            }
        }

        if (value && feature === 'spouse' && this.spouse_Array().length > 0) {
            this.newForm.controls['spouse_Array']['controls'][0].get('address1').setValue(this.newForm.controls.address1.value),
                this.newForm.controls['spouse_Array']['controls'][0].get('address2').setValue(this.newForm.controls.address2.value),
                this.newForm.controls['spouse_Array']['controls'][0].get('city').setValue(this.newForm.controls.city.value),
                this.newForm.controls['spouse_Array']['controls'][0].get('zip').setValue(this.newForm.controls.zip.value);
            this.newForm.controls['spouse_Array']['controls'][0].get('state').setValue(this.newForm.controls.state.value);
        }

        if (!value && feature === 'spouse' && this.spouse_Array().length > 0) {
            this.newForm.controls['spouse_Array']['controls'][0].get('address1').setValue(''),
                this.newForm.controls['spouse_Array']['controls'][0].get('address2').setValue(''),
                this.newForm.controls['spouse_Array']['controls'][0].get('city').setValue(''),
                this.newForm.controls['spouse_Array']['controls'][0].get('zip').setValue('');
            this.newForm.controls['spouse_Array']['controls'][0].get('state').setValue('');
        }
    }


    async getNoSaleProductUsaStates(): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = 'ListNoSaleProductUsaStatesD2C/' + this.companyId;
            this.subscriptions.push(this.service.getData(url).subscribe((Object: any) => {
                if (Object.data.length > 0) {
                    this.usaStates = Object.data;
                    resolve();
                } else {
                    this.toastr.error(Object.message);
                    reject();
                }
            }, (err) => {
                if (err.error.message === 'Unauthenticated.') {
                    this.openError = true;
                } else {
                    console.log(err);
                    reject();
                }
            })
            );
        });
    }

    checkIfActiveState(val): void {
        this.usaStates.forEach(state => {
            if (state.name === val.target.value) {
                if (state.active === 0) {
                    this.isActiveState = false;
                    this.toastr.clear();
                    this.toastr.error('Thank you for your interest. At this time, Product is not available to the residents of this state.', '', {
                        closeButton: true,
                        timeOut: this.toasterTimeout,
                    });
                } else {
                    this.isActiveState = true;
                }

            }
        });
    }

    previousEnrollment(): void {
        const url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.routeFamlyId;
        this.service.getData(url).subscribe((Object: any) => {
            localStorage.setItem('enrollment_type', Object.members[0].enrollment_type);
            localStorage.setItem('family_id', Object.members[0].family_id);
            localStorage.setItem('primary_member_id', Object.members[0].member_id);
            localStorage.setItem('start_date', Object.startdate);

            this.memberPersonalData = Object.members;
            this.family_id = this.memberPersonalData[0]?.family_id;
            this.primary_info = this.memberPersonalData.filter(x => x.relation === 'Primary');
            if (this.hasSubscribedProduct) {
                this.spouse_info = [];
                this.child_info = this.memberPersonalData.filter(x => (this.hasSubscribedProduct ? (x.relation === 'Spouse' || x.relation === 'Child') : x.relation === 'Child') && !this.child_info?.some(y => y.member_id === x.member_id));
            } else {
                this.spouse_info = this.memberPersonalData.filter(x => x.relation === 'Spouse' && !this.spouse_info?.some(y => y.member_id === x.member_id));
                this.child_info = this.memberPersonalData.filter(x => x.relation === 'Child' && !this.child_info?.some(y => y.member_id === x.member_id));
            }
            this.user_company_id = Object?.user_company_id;
            this.memberPersonalData[0].memberupdate = 'update';
            this.primaryAddressobj = {
                address1: this.primary_info[0]?.address1,
                address2: this.primary_info[0]?.address2,
                city: this.primary_info[0]?.city,
                state: this.primary_info[0]?.state,
                zip: this.primary_info[0]?.zip
            };

            this.spouseAddressobj = {
                address1: this.spouse_info[0]?.address1,
                address2: this.spouse_info[0]?.address2,
                city: this.spouse_info[0]?.city,
                state: this.spouse_info[0]?.state,
                zip: this.spouse_info[0]?.zip
            };

            localStorage.setItem('family_type', this.primary_info[0]?.applicanttype);
            this.usaStates.forEach((state: { name: any; id: string; }) => {
                if (state.name === this.primary_info[0]?.state) {
                    localStorage.setItem('state', state.id);
                }
            });
            if (!this.hasSubscribedProduct) {
                this.getProductData();
            }

            const result = JSON.stringify(this.primaryAddressobj) === JSON.stringify(this.spouseAddressobj);

            if (result) {
                this.isSpouseAddressControl.setValue(true);
            }

            this.newForm.controls.firstname.setValue(this.primary_info[0]?.firstname);
            this.newForm.controls.lastname.setValue(this.primary_info[0]?.lastname);
            this.newForm.controls.email.setValue(this.primary_info[0]?.email);
            this.newForm.controls.phone.setValue(this.primary_info[0]?.phone);
            this.newForm.controls.dob.setValue(this.datePipe.transform(this.primary_info[0]?.dob, 'MM/dd/yyyy'));
            this.newForm.controls.age.setValue(this.primary_info[0]?.age);
            this.newForm.controls.gender.setValue(this.primary_info[0]?.gender);
            this.newForm.controls.address1.setValue(this.primary_info[0]?.address1);
            this.newForm.controls.address1.setValue(this.primary_info[0]?.address1);
            this.newForm.controls.address2.setValue(this.primary_info[0]?.address2);
            this.newForm.controls.city.setValue(this.primary_info[0]?.city);
            this.newForm.controls.state.setValue(this.primary_info[0]?.state);
            this.newForm.controls.zip.setValue(this.primary_info[0]?.zip);
            this.newForm.controls.member_id.setValue(this.primary_info[0]?.member_id);
            if (this.spouse_info.length > 0) {
                (document.getElementById('spousecheckbox') as HTMLInputElement).click();

                (document.getElementById('spousecheckbox') as HTMLInputElement).checked = true;
                for (let i = 0; i < this.spouse_Array().controls.length; i++) {
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseFirstname').setValue(this.spouse_info[0]?.firstname);
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseLastname').setValue(this.spouse_info[0]?.lastname);
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseDob').setValue(this.datePipe.transform(this.spouse_info[0]?.dob, 'MM/dd/yyyy'));
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseAge').setValue(this.spouse_info[0]?.age);
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseGender').setValue(this.spouse_info[0]?.gender);
                    this.newForm.controls['spouse_Array']['controls'][i].get('address1').setValue(this.spouse_info[0]?.address1);
                    this.newForm.controls['spouse_Array']['controls'][i].get('address2').setValue(this.spouse_info[0]?.address2);
                    this.newForm.controls['spouse_Array']['controls'][i].get('city').setValue(this.spouse_info[0]?.city);
                    this.newForm.controls['spouse_Array']['controls'][i].get('state').setValue(this.spouse_info[0]?.state);
                    this.newForm.controls['spouse_Array']['controls'][i].get('zip').setValue(this.spouse_info[0]?.zip);
                    this.newForm.controls['spouse_Array']['controls'][i].get('checkBox').setValue('true');
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouse_id').setValue(this.spouse_info[0]?.member_id);
                }
            }


            if (this.child_info.length > 0) {
                this.displayText = true;
                (document.getElementById('childcheckbox') as HTMLInputElement).checked = true;
                for (let i = 0; i < this.child_info.length; i++) {
                    console.log(this.child_info[i]);
                    this.child_Array().push(this.newChild());
                    this.subscribeToRelationChanges(this.child_Array().length - 1);
                    this.newForm.controls['child_Array']['controls'][i].get('childFirstname').setValue(this.child_info[i].firstname);
                    this.newForm.controls['child_Array']['controls'][i].get('childLastname').setValue(this.child_info[i].lastname);
                    this.newForm.controls['child_Array']['controls'][i].get('childDob').setValue(this.datePipe.transform(this.child_info[i].dob, 'MM/dd/yyyy'));
                    this.newForm.controls['child_Array']['controls'][i].get('relation').setValue(this.child_info[i].relation);
                    this.newForm.controls['child_Array']['controls'][i].get('childAge').setValue(this.child_info[i].age);
                    this.newForm.controls['child_Array']['controls'][i].get('childGender').setValue(this.child_info[i].gender);
                    this.newForm.controls['child_Array']['controls'][i].get('address1').setValue(this.child_info[i].address1);
                    this.newForm.controls['child_Array']['controls'][i].get('address2').setValue(this.child_info[i].address2);
                    this.newForm.controls['child_Array']['controls'][i].get('city').setValue(this.child_info[i].city);
                    this.newForm.controls['child_Array']['controls'][i].get('state').setValue(this.child_info[i].state);
                    this.newForm.controls['child_Array']['controls'][i].get('zip').setValue(this.child_info[i].zip);
                    this.newForm.controls['child_Array']['controls'][i].get('checkBox').setValue('true');
                    this.newForm.controls['child_Array']['controls'][i].get('child_id').setValue(this.child_info[i].member_id);
                }
            }
            this.cdr.detectChanges();
            this.child_info.forEach((element, i) => {
                this.childAddressobj = {
                    address1: element.address1,
                    address2: element.address2,
                    city: element.city,
                    state: element.state,
                    zip: element.zip
                };

                const childAddressresult = JSON.stringify(this.primaryAddressobj) === JSON.stringify(this.childAddressobj);
                if (childAddressresult) {
                    setTimeout(() => {
                        const x = (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement);
                        if (x !== null) {
                            (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement)?.click();
                            (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement).checked = true;
                        }
                    }, 50);
                }
            });

            // If Agent info is available in the response
            if (Object.agent_info) {
                this.agentInfo = Object.agent_info;
                if (this.agentInfo.code) {
                    this.isAgentCodeApplied = true;
                    this.agentCodeParam = this.agentInfo.code;
                    this.agent_Id = this.agentInfo.id;
                    this.newForm.controls.agentCode.setValue(this.agentCodeParam);
                }
            }
        }, err => {
            console.log(err);
        });
    }

    newChild(): FormGroup {
        return this.formBuilder.group({
            childFirstname: ['', Validators.required],
            childLastname: ['', Validators.required],
            childDob: ['', Validators.required],
            childGender: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            active: [1],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            childFeet: ['', ''],
            childInch: ['',],
            childAge: ['', [Validators.required, Validators.min(0), Validators.max(26)]],
            child_id: [''],
            childWeight: ['', ''],
            relation: ['Child', [Validators.required]],
            checkBox: [],
            disabled: ['']
        });
    }

    onItemSelect(e) {
        this.group_id = e.value.value;

    }

    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            spouseFirstname: ['', Validators.required],
            spouseLastname: ['', Validators.required],
            spouseDob: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            spouseGender: ['', Validators.required],
            spouseFeet: ['', ''],
            spouseInch: ['',],
            spouseAge: ['', [Validators.required, Validators.min(18), Validators.max(this.limit_age - 1)]],
            spouse_id: ['',],
            spouseWeight: ['', ''],
            relation: ['Spouse'],
            checkBox: [],
            disabled: ['']
        });
    }


    child_Array(): FormArray {
        return this.newForm.get('child_Array') as FormArray;
    }

    spouse_Array(): UntypedFormArray {
        return this.newForm.get('spouse_Array') as UntypedFormArray;
    }

    subscribeToRelationChanges(index: number): void {
        const item = this.child_Array().at(index) as FormGroup;
        const valueSub = item.get('relation').valueChanges.subscribe((value) => {
            console.log(`Relation of item at index ${index} changed to: ${value}`);
            if (value === 'Spouse') {
                this.relationSpouseIndex = index;
                item.controls.childAge.clearValidators();
                item.controls.childAge.setValidators([Validators.required, Validators.min(0)]);
                item.controls.childAge.updateValueAndValidity();
            } else if (value !== 'Spouse' && this.relationSpouseIndex === index) {
                this.relationSpouseIndex = -1;
                item.controls.childAge.clearValidators();
                item.controls.childAge.setValidators([Validators.required, Validators.min(0), Validators.max(26)]);
                item.controls.childAge.updateValueAndValidity();
            }
        });
        this.valueSubscriptions.push(valueSub);
    }

    unSubscribeToRelationChanges(index: number): void {
        this.valueSubscriptions[index].unsubscribe();
        this.valueSubscriptions.splice(index, 1);
    }

    // ================Add and remove spouse and child================//
    addEmployee(event, va, values) {
        if (event.target.checked === true) {
            if (va === 'child') {
                this.child_Array().push(this.newChild());
                this.subscribeToRelationChanges(this.child_Array().length - 1);
                this.data = [];
            } else {
                if (this.spouse_Array().length <= 0) {
                    this.spouse_Array().push(this.newEmployee1());
                    this.newEmployee1();
                    this.spouseForm = false;
                    this.data = [];
                    this.spousearr = [];
                }
            }
        } else {
            if (values.spouse_Array[0]?.spouse_id !== '') {
                values.spouse_Array = []
                this.spousearr = values.spouse_Array;
                this.data = [];
            }
            this.spouse_Array().removeAt(0);
            this.spouse_check = false;
            this.data = [];

        }
    }

    addChild(event, va): void {
        if (event.target.checked === true) {
            this.displayText = true;
            this.addchilddisabled = true;
            this.child_Array().push(this.newChild());
            this.subscribeToRelationChanges(this.child_Array().length - 1);
            this.data = [];
        } else {
            this.child_check = false;
            this.addchilddisabled = false;
            this.data = [];
        }
    }

    add_child() {
        this.child_Array().push(this.newChild());
        this.subscribeToRelationChanges(this.child_Array().length - 1);
        this.data = [];
        if (this.isChildAddressControl.value) {
            this.sameAddressControl(this.isChildAddressControl.value, 'child');
        }
    }

    // ==================Remove form Array==================//
    removeEmployee(empIndex, childValue) {
        this.test = true;
        if (childValue.child_id !== '') {
            this.arr.push(childValue);
            this.data = [];
        }
        this.newForm.value.child_Array.splice(empIndex, 1);
        this.child_Array().removeAt(empIndex);
        this.unSubscribeToRelationChanges(empIndex);
        if (this.child_Array().length === 0) {
            this.child_check = false;
            this.addchilddisabled = false;
            this.displayText = false;
            this.data = [];
        }
    }

    emailValidation(value): void {
        if (this.emailError !== null || this.phoneerrmsg !== null) {
            this.errorMsgShow = false;
        }
    }

    InputChange(ControlName): void {
        const ControlValue = this.newForm.controls[ControlName].value;
        for (let i = 0; i < this.spouse_Array.length; i++) {
            if (this.newForm.controls['child_Array']['controls'][i].controls.checkBox.value) {
                this.newForm.controls['child_Array']['controls'][i].controls[ControlName].setValue(ControlValue);
            }
        }
        for (let i = 0; i < this.child_Array.length; i++) {
            if (this.newForm.controls['spouse_Array']['controls'][i].controls.checkBox.value) {
                this.newForm.controls['spouse_Array']['controls'][i].controls[ControlName].setValue(ControlValue);
            }
        }

    }

    checkBoXChange(index, arrayName) {
        if (this.newForm.controls[arrayName]['controls'][index].controls.checkBox.value) {
            this.newForm.controls[arrayName]['controls'][index].patchValue({
                disabled: true,
                address1: this.newForm.controls.address1.value,
                address2: this.newForm.controls.address2.value,
                city: this.newForm.controls.city.value,
                state: this.newForm.controls.state.value,
                zip: this.newForm.controls.zip.value,
            });
        } else {
            this.newForm.controls[arrayName]['controls'][index].patchValue({
                disabled: false,
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            });
        }
    }

    accenrollment(values) {
        const count = values.spouse_Array.length + values.child_Array.length + 1;
        if (count > 7) {
            this.new_text = 'Maximum people per Network subscription is 7. To subscribe additional family members, please enroll them in another subscription';
            this.submitAttempt = false;
            this.isActiveState = false;
        } else if (count <= 7) {
            this.isActiveState = true;
            this.new_text = '';
            this.submitAttempt = false;
        }
    }

    updateData(values): void {
        if (this.newForm.touched && this.memberPersonalData[0]?.memberupdate === 'update') {
            this.new_text = '';
            this.submit(values);
        }
    }

    submit(values) {
        this.submitAttempt = true;
        const state = this.usaStates.filter(x => x.name === values.state);
        localStorage.removeItem('state');
        localStorage.setItem('state', state[0]?.id);
        if (this.newForm.valid && this.new_text === '') {
            this.loader = true;
            this.submitAttempt = false;
            this.objectData.company_id = this.companyId;
            this.objectData.product_company_id = this.parentCompanyId;
            this.objectData.group_id = this.group_id;
            this.objectData.group_name = this.location.path().split('/')[1];
            // this.objectData.request_type = 'acc'
            if (this.hideAgentSection && this.agent_id_from_url_param) {
                this.objectData.agent_id = this.agent_id_from_url_param;
            } else {
                this.objectData.agent_id = this.agent_Id;
            }
            this.objectData.member_personal_data = new Array<any>();
            this.member_personal_data.firstname = values.firstname;
            this.member_personal_data.lastname = values.lastname;
            this.member_personal_data.smoker = '';
            this.member_personal_data.member_id = values.member_id;
            this.member_personal_data.relation = 'Primary';
            this.member_personal_data.weight = values.weight;
            this.member_personal_data.address1 = values.address1;
            this.member_personal_data.address2 = values.address2;
            this.member_personal_data.city = values.city;
            this.member_personal_data.state = values.state;
            this.member_personal_data.zip = values.zip;
            this.member_personal_data.dob = this.datePipe.transform(values.dob, 'yyyy-MM-dd');
            this.member_personal_data.age = values.age;
            this.member_personal_data.gender = values.gender;
            this.member_personal_data.email = values.email;
            if(values.otp_notification_check !==null ){
            this.member_personal_data.sms_enabled = values.otp_notification_check ? 1:0;
            }
            this.member_personal_data.phone = values.phone.replace(/[^0-9\.]+/g, '');
            this.member_personal_data.height = values.feet + '.' + values.inch;
            this.objectData.member_personal_data.push(this.member_personal_data);
            for (let k = 0; k < values.spouse_Array.length; k++) { // insert
                this.member_personal_data = {};
                this.member_personal_data.firstname = values.spouse_Array[k].spouseFirstname;
                this.member_personal_data.lastname = values.spouse_Array[k].spouseLastname;
                this.member_personal_data.smoker = '';
                this.member_personal_data.member_id = values.spouse_Array[k].spouse_id;
                this.member_personal_data.relation = 'Spouse';
                this.member_personal_data.weight = values.spouse_Array[k].spouseWeight;
                this.member_personal_data.dob = this.datePipe.transform(values.spouse_Array[k].spouseDob, 'yyyy-MM-dd');
                this.member_personal_data.age = values.spouse_Array[k].spouseAge;
                this.member_personal_data.gender = values.spouse_Array[k].spouseGender;
                this.member_personal_data.active = 1;
                this.member_personal_data.address1 = values.spouse_Array[k].address1;
                this.member_personal_data.address2 = values.spouse_Array[k].address2;
                this.member_personal_data.city = values.spouse_Array[k].city;
                this.member_personal_data.zip = values.spouse_Array[k].zip;
                this.member_personal_data.state = values.spouse_Array[k].state;
                this.member_personal_data.height = values.spouse_Array[k].spouseFeet + '.' + values.spouse_Array[k].spouseInch;
                this.objectData.member_personal_data.push(this.member_personal_data);
            }
            if (this.spousearr.length > 0) {// for remove option
                for (let z = 0; z < this.spousearr.length; z++) {
                    this.member_personal_data = {};
                    this.member_personal_data.firstname = this.spousearr[z].spouseFirstname;
                    this.member_personal_data.lastname = this.spousearr[z].spouseLastname;
                    this.member_personal_data.smoker = '';
                    this.member_personal_data.member_id = this.spousearr[z].spouse_id;
                    this.member_personal_data.relation = 'Spouse';
                    this.member_personal_data.weight = this.spousearr[z].spouseWeight;
                    this.member_personal_data.dob = this.datePipe.transform(this.spousearr[z].spouseDob, 'yyyy-MM-dd');
                    this.member_personal_data.age = this.spousearr[z].spouseAge;

                    this.member_personal_data.gender = this.spousearr[z].spouseGender;
                    this.member_personal_data.active = 0;
                    this.member_personal_data.height = this.spousearr[z].spouseFeet + '.' + this.spousearr[z].spouseInch;
                    this.objectData.member_personal_data.push(this.member_personal_data);
                }
            }
            for (let i = 0; i < values.child_Array.length; i++) {
                this.member_personal_data = {};
                this.member_personal_data.firstname = values.child_Array[i].childFirstname;
                this.member_personal_data.lastname = values.child_Array[i].childLastname;
                this.member_personal_data.smoker = '';
                this.member_personal_data.relation = values.child_Array[i].relation ?? 'Child';
                this.member_personal_data.member_id = values.child_Array[i].child_id;
                this.member_personal_data.weight = values.child_Array[i].childWeight;
                this.member_personal_data.dob = this.datePipe.transform(values.child_Array[i].childDob, 'yyyy-MM-dd');
                this.member_personal_data.age = values.child_Array[i].childAge;
                this.member_personal_data.gender = values.child_Array[i].childGender;
                this.member_personal_data.active = 1;
                this.member_personal_data.address1 = values.child_Array[i]?.address1;
                this.member_personal_data.address2 = values.child_Array[i]?.address2;
                this.member_personal_data.city = values.child_Array[i]?.city;
                this.member_personal_data.state = values.child_Array[i]?.state;
                this.member_personal_data.zip = values.child_Array[i]?.zip;
                this.member_personal_data.height = values.child_Array[i].childFeet + '.' + values.child_Array[i].childInch;
                this.objectData.member_personal_data.push(this.member_personal_data);
            }
            if (this.arr.length > 0) {// for remove option
                for (let j = 0; j < this.arr.length; j++) {
                    this.member_personal_data = {};
                    this.member_personal_data.firstname = this.arr[j].childFirstname;
                    this.member_personal_data.lastname = this.arr[j].childLastname;
                    this.member_personal_data.smoker = '';
                    this.member_personal_data.relation = this.arr[j].relation ?? 'Child';
                    this.member_personal_data.member_id = this.arr[j].child_id;
                    this.member_personal_data.weight = this.arr[j].childWeight;
                    this.member_personal_data.dob = this.datePipe.transform(this.arr[j].childDob, 'yyyy-MM-dd');
                    this.member_personal_data.age = this.arr[j].childAge;
                    this.member_personal_data.gender = this.arr[j].childGender;
                    this.member_personal_data.active = 0;
                    this.member_personal_data.height = this.arr[j].childFeet + '.' + this.arr[j].childInch;
                    this.objectData.member_personal_data.push(this.member_personal_data);
                }
            }
            let finalObject: any = {};
            finalObject = this.objectData;
            const url = 'AddMemberD2C';
            this.service.postData(url, finalObject).subscribe((Object: any) => {
                localStorage.setItem('acc_member_id', Object.member_personal_data[0].member_id);
                this.loader = false;
                this.family_id = Object.member_personal_data[0]?.family_id;
                this.user_company_id = Object.user_company_id;
                localStorage.setItem('family_type', Object.family_type);
                localStorage.setItem('user_company_id', Object.user_company_id);
                localStorage.setItem('family_id', Object.member_personal_data[0]?.family_id);
                localStorage.setItem('enrollment_type', Object.member_personal_data[0]?.enrollment_type);
                localStorage.setItem('primary_member_id', Object.member_personal_data[0]?.member_id);
                localStorage.setItem('start_date', Object.startdate);
                this.primary_info = Object.member_personal_data.filter(x => x.relation === 'Primary');
                this.child_info = Object.member_personal_data.filter(x => x.relation === 'Child');
                this.spouse_info = Object.member_personal_data.filter(x => x.relation === 'Spouse');
                if (this.primary_info !== null) {
                    this.newForm.controls.member_id.setValue(this.primary_info[0]?.member_id);
                }
                if (this.spouse_info.length > 0) {
                    this.newForm.controls['spouse_Array']['controls'][0]?.get('spouse_id').setValue(this.spouse_info[0]?.member_id);
                }
                if (this.child_info.length > 0) {
                    for (let i = 0; i < this.child_info.length; i++) {
                        this.newForm.controls['child_Array']['controls'][i].get('child_id').setValue(this.child_info[i].member_id);
                    }
                }
                if (!this.hasSubscribedProduct) {
                    this.getProductData();
                    this.scrollToBottom();
                } else {
                    this.addProductsToCart();
                    this.updateData(this.newForm.value)
                }
            }, err => {
                if (err.error.message === 'Unauthenticated.') {
                    this.openError = true;
                    this.loader = false;
                    this.toastr.clear();
                    this.toastr.error(err.error.message, '', {
                        closeButton: true,
                        timeOut: this.toasterTimeout,
                    });
                } else {
                    console.log(err);
                    this.loader = false;
                    this.errorMsgShow = true;
                    this.emailError = err.error.email;
                    this.phoneerrmsg = err.error.phone;
                    this.toastr.clear();
                    this.toastr.error(err.error.message, '', {
                        closeButton: true,
                        timeOut: this.toasterTimeout,
                    });
                }
            });
        }

    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.viewportScroller.scrollToPosition([0, document.body.scrollHeight]);
        }, 500);
    }

    Reset_form() {
        this.applicant = '';
        this.newForm.reset();
        this.isSpouseAddressControl.setValue(false);
        this.isChildAddressControl.setValue(false);
        localStorage.removeItem('cartData_Id');
        localStorage.removeItem('cartProducts_productId');
    }

    // ===============Age Calculation==========//
    onSearchChange(searchValue: string): void {

        if (searchValue !== '') {
            const convertAge = new Date(searchValue);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (this.Age <= 17) {
                this.parentAge = 'Applicant  can not be younger than 18 years of age';
            }
            if (this.Age > this.limit_age) {
                this.parentAge = 'Applicant  can not be older than age' + this.limit_age;
            }
            if (this.Age < this.limit_age + 1 && this.Age > 18) {
                this.parentAge = '';
            }
        }
    }

    spouseAgeCal(searchValue, Index: number, dob): void {
        if (searchValue !== '') {
            const convertAge = new Date(searchValue);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            const formcontrol = this.spouse_Array();
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = 'Applicant  can not be younger than 18 years of age';
            }
            if (this.spouseAge > this.limit_age) {
                this.spouseAgeMsg = 'Applicant  can not be older than age' + this.limit_age;
            }
            if (this.spouseAge < this.limit_age + 1 && this.spouseAge > 18) {
                this.spouseAgeMsg = '';
            }
        }
    }

    childAgeCal(searchValue, Index: number, dob): void {
        const id = 'dob' + Index;
        if (id === dob) {
            if (searchValue !== '') {
                {
                    const convertAge = new Date(searchValue);
                    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                    this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                    const formValue = this.newForm.value;
                    if (formValue.child_Array[Index].relation === 'Spouse') {
                        if (this.childAge <= 17) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = `${this.spouseLabel} can not be younger than 18 years of age`;
                        }
                        if (this.childAge > this.limit_age - 1) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = `${this.spouseLabel} can not be older than ${this.limit_age} years of age`;
                        }
                        if (this.childAge < this.limit_age && this.childAge > 18) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                        }
                    } else if (formValue.child_Array[Index].relation === 'Child') {
                        if (this.childAge > 26) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = 'Child over 26 years age should buy their own plan.';
                        }
                        if (this.childAge < 26) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                        }
                    }
                }
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = '';
                this.ageError = '';
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);

            }
        }
    }

    getManagers() {
        const url = 'GetAgentGroups/' + this.agent_Id;
        this.managersList = [];
        this.service.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.managersList = Object.data;
                this.managersList.forEach(el => {
                    el.expanded = false;
                });
                this.data = this.managersList.map(value => {

                    return {
                        label: value.group_name,
                        value: value.group_id

                    };
                });


            }
        }, err => {
            this.toastr.clear();
            this.toastr.error(err.error.message, '', {
                closeButton: true,
                timeOut: this.toasterTimeout,
            });


        });
    }


    // ================number validation==============//
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }



    onleavePhonenumber() {
        const mobile = (document.getElementById('phone-number') as HTMLInputElement).value;
        if (mobile.length < 10 && mobile.length > 0) {
            this.phnumbererrmsg = 'Please enter a 10 digit number';
        }
        if (mobile.length >= 10) {
            this.phnumbererrmsg = '';
        }
    }




    // ===============Age Calculation==========//
    onSearchChangeT(value) {
        if (value.dob !== null) {
            const convertAge = new Date(value.dob);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (value.guardianCheckbox === true) {
                if (this.Age <= 26) {
                    this.parentAge = 'Applicant can not be younger than 26 years of age';
                }
            } else {
                if (this.Age <= 17) {
                    this.parentAge = 'Applicant  can not be younger than 18 years of age';
                }
            }
            if (this.Age > this.limit_age - 1) {
                this.parentAge = 'Applicant can not be older than ' + this.limit_age + ' years of age';
            }
            if (this.Age < this.limit_age && this.Age > 18) {
                this.parentAge = '';
            }
        }
    }

    spouseAgeCalT(value, Index = 0): void {
        if (value.spouse_Array[0].spouseDob) {
            const convertAge = new Date(value.spouse_Array[0].spouseDob);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            const formcontrol = this.spouse_Array();
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = 'Applicant   can not be younger than 18 years of age';
            }
            if (this.spouseAge > this.limit_age - 1) {
                this.parentAge = 'Applicant can not be older than ' + this.limit_age + ' years of age';
            }
            if (this.spouseAge < this.limit_age && this.spouseAge > 18) {
                this.spouseAgeMsg = '';
            }
        }
    }

    childAgeCalT(searchValue, Index: number, dob, va): void {
        const id = 'dob' + Index;
        console.log('Value:', va);
        if (id === dob) {
            if (va.child_Array.length > 0) {
                {
                    for (let x = 0; x < va.child_Array.length; x++) {
                        const convertAge = new Date(va.child_Array[x].childDob);
                        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                        this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

                        if (va.child_Array[x].relation === 'Spouse') {
                            if (this.childAge <= 17) {
                                (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = `${this.spouseLabel} can not be younger than 18 years of age`;
                            }
                            if (this.childAge > this.limit_age - 1) {
                                (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = `${this.spouseLabel} can not be older than ${this.limit_age} years of age`;
                            }
                            if (this.childAge < this.limit_age && this.childAge > 18) {
                                (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                            }
                        } else if (va.child_Array[x].relation === 'Child') {
                            {
                                if (this.childAge > 26) {
                                    (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = 'Child over 26 years age should buy their own plan.';
                                }
                                if (this.childAge < 26) {
                                    (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                                }
                            }
                        }
                    }
                }
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = '';
                this.ageError = '';
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            }
        }
    }

    // Function, called when agent code gets changed
    showApplyBtn() {
        if (!this.agentCodeParam) {
            this.agentCodeErrorMsg = '';
            this.isAgentCodeApplied = false;

            this.agent_Id = undefined;
            this.agentInfo = null;
        }
    }

    // Function to apply agent code
    applyAgentCode(code = null) {
        // If code is not recevied in the parameter, then read from FORM
        if (!code) {
            code = this.newForm.value.agentCode;
        }

        if (code) {
            this.agent_Id = undefined;
            this.applyBtnText = 'Applying...';
            this.agentCodeErrorMsg = '';
            this.loader = true;

            const formData: FormData = new FormData();
            formData.append('agent_code', code);

            const url = 'ApplyAgentCode/' + code;
            this.subscriptions.push(this.service.getData(url).subscribe((response: any) => {
                this.loader = false;
                this.applyBtnText = 'Apply';
                if (response.status === true) {
                    this.isAgentCodeApplied = true;
                    this.agent_Id = response.data.id;
                    this.agentInfo = response.data;
                    localStorage.setItem('agentInfo', JSON.stringify(this.agentInfo));
                } else {
                    this.isAgentCodeApplied = false;
                    this.agentCodeErrorMsg = response.message;
                    this.agent_Id = undefined;
                    this.agentInfo = null;
                }
            }, (err) => {
                this.loader = false;
                if (err.error.message === 'Unauthenticated.') {
                    this.openError = true;
                } else {
                    console.log(err);
                }
            }));
        } else {
            this.isAgentCodeApplied = false;
        }
    }

    ngOnDestroy() {
        this.newForm.reset();
        this.valueSubscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
        this.subscriptions.forEach(element => {
            element.unsubscribe();
        });
    }

    getProductData(): void {
        this.loader = true;
        const url = 'ListCompanyProductD2C';
        let body: any = {
            family_id: localStorage.getItem('family_id'),
            company_id: this.companyId,
        };
        if (this.packageNameExist) {
            body.package_name = this.product_name
        }
        this.service.postData(url, body).subscribe((Object: any) => {
            this.loader = false;
            if (Object.status === true) {
                this.scrollToBottom();
                this.data = Object.data;
                if (this.data.length > 1) {
                    this.display_select_radio = true;
                    this.display_select_btn = false;
                } else {
                    this.display_select_radio = false;
                    this.display_select_btn = true;
                }
                this.selectedRecordForProducts = [];
                this.data.forEach((el) => {
                    this.selectedRecordForProducts = el.product_info;
                });
                if (this.routeMemberId !== null) {
                    const products_info = localStorage.getItem('product_info');
                    const parsed_product_info = JSON.parse(products_info);
                    if (parsed_product_info !== null) {
                        const slno = parsed_product_info[0].slno;
                        this.data.forEach(element => {
                            if (element.slno === slno) {
                                element.selected = true;
                                element.isDisabled = false;
                                this.selectRecord(true, element);
                            }
                        });
                    }
                }
            }
        }, (err) => {
            this.loader = false;
            this.toastr.clear();
            this.toastr.error(err.error.message, '', {
                closeButton: true,
                timeOut: this.toasterTimeout,
            });

        });
    }

    selectRecord(checked, data): void {
        console.log(checked);
        console.log(data);
        if (this.allcheckboxchecked) {
            (document.getElementById('seletedallmembers') as HTMLInputElement).checked = false;
        }
        this.display_select_btn = true;
        this.data.forEach(element => {
            if (element.slno !== null) {
                element.isDisabled = element.slno !== data.slno;
            }
        });

        if (checked) {
            data.selected = true;
            this.selectedRecordForProducts = data.product_info;
        } else {
            this.selectedRecordForProducts = [];
            const index = this.selectedRecordForProducts.findIndex(
                (el) => el.product_id === data.product_id
            );
            console.log('Index of selectedRecordForProducts: ' + index);
            if (index > -1) {
                this.selectedRecordForProducts.splice(index, 1);
            }
            console.log('In select products');
            console.log(this.selectedRecordForProducts);
        }
    }

    addProductsToCart(): void {
        console.log('In add cart products');
        console.log(this.selectedRecordForProducts);
        this.loader = true;
        const url = 'AddCartProductD2C';
        const body = {
            family_id: this.family_id,
            company_id: this.companyId,
            cart_type: 'enrollment',
            products: this.selectedRecordForProducts,
            user_company_id: this.user_company_id,
            enrollment_type: localStorage.getItem('enrollment_type')
        };

        localStorage.setItem('product_info', JSON.stringify(this.selectedRecordForProducts));
        this.service.postData(url, body).subscribe((Object: any) => {
            this.loader = false;
            if (Object.recurring_dates && Object.recurring_dates.length) {
                localStorage.setItem('recurring_start_date', Object.recurring_dates[0]?.start);
                localStorage.setItem('recurring_end_date', Object.recurring_dates[0]?.end);
            }
            localStorage.setItem('cart_id', Object.cart_data[0]?.id);
            localStorage.setItem('cartdetails', JSON.stringify(Object));
            const member_id = Object.member_personal_data[0]?.member_id;
            if(!!this.agent_id_from_url_param){
              this.router.navigate(['/package/payment', Object.member_personal_data[0]?.family_id, member_id],{queryParams: {agent_id: this.agent_id_from_url_param}});
            }
            else{
              this.router.navigate(['/package/payment', Object.member_personal_data[0]?.family_id, member_id]);
            }
        }, err => {
            if (err.error.message === 'Unauthenticated.') {
                this.toastr.clear();
                this.toastr.error(err.error.message, '', {
                    closeButton: true,
                    timeOut: this.toasterTimeout,
                });
                this.loader = false;
            } else {
                console.log(err);
                this.toastr.clear();
                this.toastr.error(err.error.message, '', {
                    closeButton: true,
                    timeOut: this.toasterTimeout,
                });
                this.loader = false;
            }
        });
    }

    labelConfig = () => {
        const splitURL = window.location.host.split('.');
        const origin = window.location.origin;
        if (origin.includes('localhost')) {
            this.dependentLabel = 'Spouse/Domestic Partner/Dependent Child(ren)'; //  'Dependent'; //
            this.spouseLabel = 'Spouse/Domestic Partner'; // 'Spouse'; //
        } else {
            if (splitURL[0] === 'rita' || splitURL[0] === 'iconia' || splitURL[0] === 'emari' || splitURL[0] === 'wabs') {
                this.dependentLabel = 'Spouse/Domestic Partner/Dependent Child(ren)';
                this.spouseLabel = 'Spouse/Domestic Partner';
            } else {
                this.dependentLabel = 'Dependent';
                this.spouseLabel = 'Spouse';
            }
        }
    }

}
