import { DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
    selector: 'app-payment',
    // standalone: true,
    // imports: [],
    templateUrl: './payment.component.html',
    styleUrl: './payment.component.scss',
    providers: [DatePipe]
})
export class PaymentComponent {
    loader = false;
    paymentType = new FormGroup({
        type: new FormControl('credit')
    });
    choosenPayemntType: string;
    Credit_Form: any;
    Banking_Form: any;
    Ach_Form: any;
    shipping_address_form: any;
    submitCardAttempt: boolean;
    notValidCardNumber: boolean;
    checkCardValid: boolean;
    enterTwoDigitMonth: boolean;
    submitBankingAttempt: boolean;
    enterValidYear: boolean;
    enterValidMonth: boolean;
    invalidMonthEnteredValidation: boolean;
    enteredRoutingNumber: any;
    reEnteredRoutingNumber: any;
    enteredAccountNumber: any;
    reEnteredAccountNumber: any;
    routingNumberMismatch = false;
    routingNumberField: any = '';
    accountNumberMismatch = false;
    accountNumberField: any = '';
    routing_number: any;
    account_number: any;
    showShippingAddress = true;
    country_arr = ['UnitedStates'];
    cardType: string;
    enteredMonth: any;
    value: any;
    billing_date: any;
    minDate: Date;
    maxDate: Date;
    stateStatus: any[] = [];
    subscriptions: any[] = [];
    usaStates: any;
    orderDetails: any;
    companyId: any;
    obj: any = {};
    family_id: string;
    isShippingChecked = true;
    routeMemberId: any;
    cvvErr: any;
    card_number_Err: any;
    exp_date_err: any;
    selectedform: any;
    routeEntroll: any;
    public shippingAddressControl: FormControl = new FormControl(true);
    route_company_name: any;
    agentInfo: any = null;
    billingDate: Date;
    recurringBillingDate: Date;
    recurringBillingDay: any;
    disableDates: (date: Date) => boolean;
    billingMinDate: Date;
    billingMaxDate: Date;
    recurringMinDate: Date;
    recurringMaxDate: Date;
    disabledDates: any[];
    RecurringStartDate: any = 1;
    RecurringEndDate: any = 25;
    RecurringDates: any = [];
    billingDates: any = [];
    effectiveDate1: any;
    effectiveDate2: any;
    selectedEffectiveStartDate: any;
    paymentDate: any;
    selectedBillingDate: any;
    dynamic_paymentmethod: any;
    typeOfCard: any;
    base_form: boolean = false;
    selectedLink: string = "credit_card";
    card_type: any;
  agent_id_from_url_param: any;
  template: any;
  steps = [];
  primaryMember: any;
  cart_id: any;
  cart_products: any;
    constructor(
        private formBuilder: FormBuilder,
        private service: SharingService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {

        localStorage.removeItem('from_payment');
        this.routeEntroll = this.route.snapshot.queryParams.enrollment;
        this.Credit_Form = this.formBuilder.group({
          name: ['', Validators.required],
          cardNumber: ['', Validators.required,],
          expiryMonth: ['', Validators.required],
          expiryYear: ['', Validators.required],
          cvv: ['', Validators.required],


        });
        this.Banking_Form = this.formBuilder.group({
          type: ['', Validators.required],
          name: ['', Validators.required],
          routingNumber: ['', Validators.required],
          reenterRouting: ['', Validators.required],
          accountNumber: ['', Validators.required],
          reenterAccount: ['', Validators.required],
          addresscheckbox: [''],

        });
        this.shipping_address_form = this.formBuilder.group({

          address1: [''],
          address2: [''],
          city: [''],
          state: [''],
          recurringBillingDate: [''], // , Validators.required
          recurringBillingDay: ['', Validators.required],
          effectiveStartDate: ['', Validators.required],
          zip: ['']
        });

        // this.calculateEffectiveDates();
        // this.generateBillingDates();
        const now = new Date();
        this.billing_date = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());


        // this.maxDate = new Date(now);
        // this.maxDate.setDate(now.getDate() + 60);
        // this.minDate = now;
        const today = new Date();
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        // Get the last day of the current month
        this.billingMinDate = today;
        this.billingMaxDate = today; // Set max date to 25th of current month
        this.recurringMinDate = today;
        this.billingDate = new Date();
        this.recurringMaxDate = new Date(today.getFullYear(), today.getMonth(), 25); // Set max date to 25th of current month

        if (today.getDate() > 25) {
            // If today is after the 25th, set the min and max dates for the Recurring Billing Date calendar to April 1st to 25th
            const nextMonth = today.getMonth() + 2; // Add 2 to get to the next month (February is 1, so March is 2)
            const nextYear = today.getFullYear();
            this.recurringMinDate = new Date(nextYear, nextMonth, 1);
            this.recurringMaxDate = new Date(nextYear, nextMonth, 25);
        }
        this.route.queryParams.subscribe(params => {
          if (Object.keys(params).length !== 0 && params.hasOwnProperty('agent_id')) {
            this.agent_id_from_url_param = params['agent_id'];
        }
        })
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
              this.template = data?.template;
                this.companyId = data.company_id;
                this.route_company_name = data.route_company;
                this.dynamic_paymentmethod = data.payment_methods;
                this.typeOfCard = this.dynamic_paymentmethod[0]?.name;
                this.getUSstates();
                this.steps =  [
                  ...(this.template !== 'template_3' ? [{ step: 'Enrollment/Product Pricing', active: false }] : []),
                  { step: 'Payment Details', active: true },
                  { step: 'Terms & Condition', active: false },
                  { step: 'Summary', active: false },
                  { step: 'Confirmation', active: false }
                ];
                this.family_id = this.route.snapshot.paramMap.get('id');
                if(this.template == 'template_3'){
                  this.getMemberInfo()
                }else{
                  this.configurePackage()
                  this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
                }
                const element = localStorage.getItem('from_Beneficiary');
                if (element === 'Beneficiary') {
                    this.previousEnrollment();
                }
            }
        });

    }

    ngOnInit(): void {
        this.shippingAddressControl.valueChanges.subscribe(value => {

            if (!value) {
                this.shipping_address_form.controls.address1.setValidators(Validators.required);
                this.shipping_address_form.controls.address1.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.city.setValidators(Validators.required);
                this.shipping_address_form.controls.city.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.state.setValidators(Validators.required);
                this.shipping_address_form.controls.state.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.zip.setValidators([Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]);
                this.shipping_address_form.controls.zip.updateValueAndValidity({ emitEvent: false });
            } else {
                this.shipping_address_form.controls.address1.clearValidators(Validators.required);
                this.shipping_address_form.controls.address1.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.city.clearValidators(Validators.required);
                this.shipping_address_form.controls.city.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.state.clearValidators(Validators.required);
                this.shipping_address_form.controls.state.updateValueAndValidity({ emitEvent: false });

                this.shipping_address_form.controls.zip.clearValidators([Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]);
                this.shipping_address_form.controls.zip.updateValueAndValidity({ emitEvent: false });
            }
        });

    }

    configurePackage(){
      const primaryInfo = localStorage.getItem('cartdetails');
      if (primaryInfo !== null) {
        const userDetails = JSON.parse(primaryInfo);
        const userInfo = userDetails.member_personal_data.filter(x => x.relation === 'Primary');
        this.Credit_Form.controls.name.setValue(userInfo[0]?.firstname + ' ' + userInfo[0]?.lastname);
        this.Banking_Form.controls.name.setValue(userInfo[0]?.firstname + ' ' + userInfo[0]?.lastname);
      }

      const storedBillingDate = localStorage.getItem('billingDate');
      if (storedBillingDate !== null) {
        const parsedDate = new Date(storedBillingDate);
        if (!isNaN(parsedDate.getTime())) { // Check if the parsed date is valid
          this.billingDate = parsedDate;
          } else {
            this.billingDate = new Date();
          }
      } else {
        this.billingDate = new Date();
      }

      const storedrecurringDate = localStorage.getItem('recurringBillingDate');
      if (storedrecurringDate !== null) {
          const dateStringWithoutQuotes = storedrecurringDate.replace(/'/g, '');
          const year = Number(dateStringWithoutQuotes.substring(0, 4));
          const month = Number(dateStringWithoutQuotes.substring(5, 7)) - 1;
          const day = Number(dateStringWithoutQuotes.substring(8, 10));
          const hours = Number(dateStringWithoutQuotes.substring(11, 13));
          const minutes = Number(dateStringWithoutQuotes.substring(14, 16));
          const seconds = Number(dateStringWithoutQuotes.substring(17, 19));
          const milliseconds = Number(dateStringWithoutQuotes.substring(20, 23));
          this.recurringBillingDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      } else {
          this.recurringBillingDate = new Date();
      }



      // this.paymentType.value.type = 'credit';
      // this.choosenPayemntType = 'credit';

      // Get Agent info from localStorage
      if (localStorage.getItem('agentInfo')) {
          this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
      }

      // Get recurring state/end dates lmit from localStorage
      if (localStorage.getItem('recurring_start_date')) {
          this.RecurringStartDate = localStorage.getItem('recurring_start_date');
      }

      if (localStorage.getItem('recurring_end_date')) {
          this.RecurringEndDate = localStorage.getItem('recurring_end_date');
      }
    }

    getMemberInfo(): void {
      this.loader = true;
      const url = 'GetProductFamilyStagingInfo/' + this.companyId + '/' + this.family_id;
      this.service.getData(url).subscribe((res: any) => {
        this.primaryMember = res?.members.find(item => item.relation === "Primary");
        this.cart_id = res?.cart_data[0]?.id
        this.Credit_Form.controls.name.setValue(this.primaryMember?.firstname + ' ' + this.primaryMember?.lastname);
        this.Banking_Form.controls.name.setValue(this.primaryMember?.firstname + ' ' + this.primaryMember?.lastname);
        this.agentInfo = res?.agent_info
          this.loader = false;
          this.cart_products = res?.cart_products
      });
  }


    changeCard(e) {
        this.typeOfCard = e;
    }
    setradio(e: string): void {
        if (e == 'bank') {
            this.base_form = true;
        } else {
            this.base_form = false;
            this.Credit_Form.controls.cardNumber.setValue('');
            this.Credit_Form.controls.expiryMonth.setValue('');
            this.Credit_Form.controls.expiryYear.setValue('');
            this.Credit_Form.controls.cvv.setValue('');
            this.submitCardAttempt = false;
        }
        this.selectedLink = e;
    }

    previousEnrollment(): void {
        const url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
            this.orderDetails = Object;
            if (Object.status) {
                if (Object.payment_info.payment_type === 'Bank') {
                    this.paymentType.controls.type.setValue('ach');
                    (document.getElementById('paymentach') as HTMLInputElement).click();
                    this.Banking_Form.controls.routingNumber.setValue(Object.payment_info.routing_number);
                    this.Banking_Form.controls.reenterRouting.setValue(Object.payment_info.routing_number);
                    this.Banking_Form.controls.accountNumber.setValue(Object.payment_info.account_number);
                    this.Banking_Form.controls.reenterAccount.setValue(Object.payment_info.account_number);
                    this.Banking_Form.controls.type.setValue(Object.payment_info.account_type);
                    this.typeOfCard = Object.payment_info.account_type;

                } else {
                    const dateString = Object.payment_info.exp.toString();
                    const yy = dateString.substring(dateString.length - 2);
                    const mm = dateString.substring(0, 2);
                    this.Credit_Form.controls.cardNumber.setValue(Object.payment_info.card);
                    this.Credit_Form.controls.expiryMonth.setValue(mm);
                    this.Credit_Form.controls.expiryYear.setValue(yy);
                    this.typeOfCard = Object.payment_info.card_type;


                }

                // Payment Date
                if (this.orderDetails?.order_details?.payment_date) {
                    const d = new Date(this.orderDetails?.order_details?.payment_date);
                    const year = d.getFullYear();
                    const month = d.getMonth();
                    const day = d.getDate();
                    const pd = new Date(year, month, day);
                    this.selectedBillingDate = this.datePipe.transform(pd, 'mediumDate');
                }

                // Recurring Billing Date
                if (this.orderDetails?.order_details?.id) {
                    this.shipping_address_form.controls.recurringBillingDay.setValue(this.orderDetails?.order_details?.recurring_billing_day);
                }

                // Effective start date
                if (this.orderDetails?.order_details?.effective_start_date) {
                    this.selectedEffectiveStartDate = this.orderDetails?.order_details?.effective_start_date;
                    if (this.selectedEffectiveStartDate === this.effectiveDate1) {
                        this.updateEffectiveStartDate(1);
                    } else {
                        this.updateEffectiveStartDate(2);
                    }
                }
            }
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    getUSstates() {
        const url = 'ListUsaStatesACC';
        this.service.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.usaStates = Object.data;
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        });
    }

    checkIfActiveState(val) {
    }

    removeSpaces(event: ClipboardEvent) {
        event.preventDefault();
        const pastedText = event.clipboardData?.getData('text/plain').replace(/\s/g, '');
        document.execCommand('insertText', false, pastedText);
    }

    onKeyup(item) {
        let ccnum = item.replace(/\s/g, "");
        // this.checkCardValid=false;
        const ccCheckRegExp = /[^\d\s-]/;
        let isValid = !ccCheckRegExp.test(ccnum);
        let i;
        if (isValid) {
            const cardNumbersOnly = ccnum.replace(/[\s-]/g, '');
            const cardNumberLength = cardNumbersOnly.length;
            const arrCheckTypes = ['visa', 'mastercard', 'amex', 'discover', 'dinners', 'jcb'];
            for (i = 0; i < arrCheckTypes.length; i++) {
                let lengthIsValid = false;
                let prefixIsValid = false;
                let prefixRegExp;
                switch (arrCheckTypes[i]) {
                    case 'mastercard':
                        lengthIsValid = (cardNumberLength === 16);
                        prefixRegExp = /5[1-5][0-9]|(2(?:2[2-9][^0]|2[3-9]|[3-6]|22[1-9]|7[0-1]|72[0]))\d*/;
                        this.cardType = arrCheckTypes[i];
                        break;

                    case 'visa':
                        lengthIsValid = (cardNumberLength === 16 || cardNumberLength === 13);
                        prefixRegExp = /^4/;
                        this.cardType = arrCheckTypes[i];
                        break;

                    case 'amex':
                        lengthIsValid = (cardNumberLength === 15);
                        prefixRegExp = /^3([47])/;
                        this.cardType = arrCheckTypes[i];
                        break;

                    case 'discover':
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(6011|5)/;
                        this.cardType = arrCheckTypes[i];
                        break;

                    case 'dinners':
                        lengthIsValid = (cardNumberLength === 14);
                        prefixRegExp = /^(300|301|302|303|304|305|36|38)/;
                        break;

                    case 'jcb':
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(2131|1800|35)/;
                        break;

                    default:
                        prefixRegExp = /^$/;
                }
                prefixIsValid = prefixRegExp.test(cardNumbersOnly);
                isValid = prefixIsValid && lengthIsValid;
                // Check if we found a correct one
                if (isValid) {
                    this.notValidCardNumber = false;
                    break;
                }
            }
        }

        if (!isValid) {
            this.notValidCardNumber = true;
            return false;
        }

        // Remove all dashes for the checksum checks to eliminate negative numbers
        ccnum = ccnum.replace(/[\s-]/g, '');
        // Checksum ('Mod 10')
        // Add even digits in even length strings or odd digits in odd length strings.
        let checksum = 0;
        for (i = (2 - (ccnum.length % 2)); i <= ccnum.length; i += 2) {
            checksum += parseInt(ccnum.charAt(i - 1));
        }
        // Analyze odd digits in even length strings or even digits in odd length strings.
        for (i = (ccnum.length % 2) + 1; i < ccnum.length; i += 2) {
            const digit = parseInt(ccnum.charAt(i - 1)) * 2;
            if (digit < 10) {
                checksum += digit;
            } else {
                checksum += (digit - 9);
            }
        }
        if ((checksum % 10) === 0) {
            this.checkCardValid = true;
        }
        return (checksum % 10) === 0;
    }

  onPrevious(): void {
    if (!!localStorage.getItem('product_prices_id')) {
      if (!!this.agent_id_from_url_param) {

        this.router.navigate(['/package/DirectMemberEnrollment/' + this.routeMemberId + '/' + this.family_id], { queryParams: { product_prices_id: localStorage.getItem('product_prices_id') , agent_id:this.agent_id_from_url_param } });
      } else {

        this.router.navigate(['/package/DirectMemberEnrollment/' + this.routeMemberId + '/' + this.family_id], { queryParams: { product_prices_id: localStorage.getItem('product_prices_id') } });
      }
    } else {
      if (!!this.agent_id_from_url_param) {

        this.router.navigate(['/package/DirectMemberEnrollment/' + this.routeMemberId + '/' + this.family_id] , {queryParams:{agent_id:this.agent_id_from_url_param}});
      } else {
        if(!!localStorage.getItem('package_product_name')){
          this.router.navigate(['/package/DirectMemberEnrollment/' + this.routeMemberId + '/' + this.family_id] , {queryParams:{product_name:localStorage.getItem('package_product_name')}});

        }else{

          this.router.navigateByUrl('/package/DirectMemberEnrollment/' + this.routeMemberId + '/' + this.family_id);
        }
      }
    }
    localStorage.setItem('from_payment', 'Payment Details');
  }

    Next() {
        this.loader = true;
        this.cvvErr = undefined;
        this.card_number_Err = undefined;
        this.exp_date_err = undefined;
        let expiryMonth = this.Credit_Form.value.expiryMonth.toString();
        let expiryYear = this.Credit_Form.value.expiryYear.toString();
        let exp_date_card = expiryMonth + expiryYear;
        this.obj = {
            company_id: this.companyId,
            primary_member_id: this.template == 'template_3'? this.primaryMember?.member_id : localStorage.getItem("primary_member_id"),
            cart_id: this.template == 'template_3'? this.cart_id :localStorage.getItem("cart_id"),
            payment_option: "CARD",
            payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
            card_type: this.typeOfCard,
            card_number: this.Credit_Form.value.cardNumber,
            exp_date: exp_date_card,
            cvv: this.Credit_Form.value.cvv,
            name_on_card: this.Credit_Form.value.name,

        }

        if (this.selectedLink == "credit_card" || this.selectedLink == "debit_card") {
            if (!this.isShippingChecked) {
                this.obj['billing_as_shipping'] = 'N'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1
                this.obj['billing_address2'] = this.shipping_address_form.value.address2
                this.obj['billing_city'] = this.shipping_address_form.value.city
                this.obj['billing_state'] = this.shipping_address_form.value.state
                this.obj['billing_zip'] = this.shipping_address_form.value.zip
            }
            if (this.isShippingChecked) {
              if(this.template == 'template_3'){
                this.obj['billing_as_shipping'] = 'Y'
              }else{
                let cartDetails = localStorage.getItem("cartdetails");
                let parsedcartdetails = JSON.parse(cartDetails);
                parsedcartdetails.member_personal_data.forEach(ele => {
                    this.obj['billing_as_shipping'] = 'Y'
                    this.obj['billing_address1'] = ele.address1,
                    this.obj['billing_address2'] = ele.address2
                    this.obj['billing_city'] = ele.city
                    this.obj['billing_state'] = ele.state
                    this.obj['billing_zip'] = ele.zip
                });
              }
            }
        } else {
            this.obj = {
              primary_member_id: this.template == 'template_3'? this.primaryMember?.member_id : localStorage.getItem("primary_member_id"),
              cart_id: this.template == 'template_3'? this.cart_id :localStorage.getItem("cart_id"),
                payment_option: "BANK",
                payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
                routing_number: this.Banking_Form.value.routingNumber,
                account_number: this.Banking_Form.value.accountNumber,
                account_type: this.Banking_Form.value.type,
                name_on_card: this.Banking_Form.value.name,
            }
            if (!this.isShippingChecked) {
                this.obj['billing_as_shipping'] = 'N'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1
                this.obj['billing_address2'] = this.shipping_address_form.value.address2
                this.obj['billing_city'] = this.shipping_address_form.value.city
                this.obj['billing_state'] = this.shipping_address_form.value.state
                this.obj['billing_zip'] = this.shipping_address_form.value.zip
            }
            if (this.isShippingChecked) {
              if(this.template == 'template_3'){
                this.obj['billing_as_shipping'] = 'Y'
              }else{
                let cartDetails = localStorage.getItem("cartdetails");
                let parsedcartdetails = JSON.parse(cartDetails);
                this.obj['billing_as_shipping'] = 'Y'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1,
                    this.obj['billing_address2'] = this.shipping_address_form.value.address2,
                    this.obj['billing_city'] = this.shipping_address_form.value.city,
                    this.obj['billing_state'] = this.shipping_address_form.value.state,
                    this.obj['billing_zip'] = this.shipping_address_form.value.zip
              }
            }

        }
        if (this.orderDetails?.order_details?.id != undefined) {
            this.obj.order_id = this.orderDetails?.order_details?.id;
        }
        this.submitCardAttempt = true;
        this.selectedform = "Banking_Form";
        this.Banking_Form.get('name').markAsTouched();
        this.Banking_Form.get('routingNumber').markAsTouched();
        this.Banking_Form.get('reenterRouting').markAsTouched();
        this.Banking_Form.get('accountNumber').markAsTouched();
        this.Banking_Form.get('reenterAccount').markAsTouched();
        let url = "CreateMemberPaymentMethod";
        this.loader = true;
        this.service.postData(url, this.obj).subscribe(
            (Object: any) => {
                this.loader = false;
                if (Object.status) {
                  if(!!this.agent_id_from_url_param){
                    this.router.navigate(['/package/agreement', this.routeMemberId, this.family_id], {queryParams:{agent_id:this.agent_id_from_url_param}});
                  }else{
                    if(this.template == 'template_3'){
                      let origin = window.location.pathname.split('/')[1]
                      this.router.navigate(['/'+ origin+'/agreement', this.primaryMember?.member_id, this.family_id]);
                    }else{
                      this.router.navigate(['/package/agreement',this.routeMemberId, this.family_id]);
                    }

                  }
                }
            }, err => {

                this.loader = false;
                this.toastr.error(err.error.message);
                if (err.error?.errors?.cvv) {
                    this.cvvErr = err.error?.errors?.cvv
                }
                if (err.error?.errors?.card_number) {
                    this.card_number_Err = err.error?.errors?.card_number
                }
                if (err.error?.errors?.exp_date) {
                    this.exp_date_err = err.error?.errors?.exp_date
                }
            })
    }

    oncvvfocusout(): void {
        if (this.cardType !== '') {
            if (this.cardType === 'visa' || this.cardType === 'mastercard') {
                // this.cvvLength = 3;
                this.value = (document.getElementById('cvvvalue') as HTMLInputElement).value;
                if (this.value.length > 3) {
                    (document.getElementById('cvverror') as HTMLInputElement).innerHTML = 'Please enter valid 3 digit cvv number';
                }
                if (this.value.length <= 3) {
                    (document.getElementById('cvverror') as HTMLInputElement).innerHTML = '';
                }
                //  this.objectService.pushcardType(this.cardType)
                // this.objectService.pushcardCheckCondition(this.checkCardValid)
            } else if (this.cardType === 'amex') {
                this.value = (document.getElementById('cvvvalue') as HTMLInputElement).value;
                if (this.value.length > 4) {
                    (document.getElementById('cvverror') as HTMLInputElement).innerHTML = 'Please enter valid cvv number';
                }
                if (this.value.length <= 4) {
                    (document.getElementById('cvverror') as HTMLInputElement).innerHTML = '';
                }
            }
        }
    }

    onCheckboxChange() {
        const cartDetails = localStorage.getItem('cartdetails');
        const parsedcartdetails = JSON.parse(cartDetails);
        if (this.isShippingChecked) {
            parsedcartdetails.member_personal_data.forEach(ele => {
                this.Credit_Form.controls.billing_address1.setValue(ele.billing_address1);
                this.Credit_Form.controls.billing_city.setValue(ele.billing_city);
                this.Credit_Form.controls.billing_zip.setValue(ele.billing_zip);
                this.Credit_Form.controls.billing_state.setValue(ele.billing_state);
            });
        }
    }

    onKeyUpEnterRoutingNumber(value) {
        this.enteredRoutingNumber = value;
        if (!this.routingNumberMismatch) {
            if (this.enteredRoutingNumber !== this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = true;
            } else {
                this.routingNumberMismatch = false;
            }
        }
        if (this.routingNumberMismatch) {
            if (this.enteredRoutingNumber === this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpRoutingNumberMismatch(value) {
        this.reEnteredRoutingNumber = value;
        if (this.enteredRoutingNumber === value) {
            this.routingNumberMismatch = false;
        } else {
            this.routingNumberMismatch = true;
        }
        if (this.routing_number) {
            if (this.routing_number === value || this.enteredRoutingNumber === value) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpEnterAccountNumber(value) {
        this.enteredAccountNumber = value;
        if (!this.accountNumberMismatch) {
            if (this.enteredAccountNumber !== this.reEnteredAccountNumber) {
                this.accountNumberMismatch = true;
            } else {
                this.accountNumberMismatch = false;
            }
        }
        if (this.accountNumberMismatch) {
            if (this.enteredAccountNumber === this.reEnteredAccountNumber) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    onKeyUpAccountNumberMismatch(value) {
        this.reEnteredAccountNumber = value;
        if (this.enteredAccountNumber === value) {
            this.accountNumberMismatch = false;
        } else {
            this.accountNumberMismatch = true;
        }
        if (this.account_number) {
            if (this.account_number === value || this.enteredAccountNumber === value) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    onKeyupExpiryMonth(input) {
        if (input.length === 1) {
            this.enterTwoDigitMonth = true;
        }
        if (input.length === 2) {
            this.enterTwoDigitMonth = false;
        }
        this.enterValidMonth = false;
        //   let enteredYear = this.cartService.getYear();
        // if(input.length === 2  )
        // {
        const ccCheckRegExp = /^(0?[1-9]|1[012])$/;
        const isValid = ccCheckRegExp.test(input);
        this.enteredMonth = input;
        const currentDate = new Date();
        const cuurentMonth = currentDate.getMonth() + 1;
        const f = currentDate.getFullYear();
        const dateString = f.toString();
        const currentYear = dateString.substring(dateString.length - 2);
        if (!isValid) {
            this.enterValidMonth = true;
        }
    }

    onKeyupExpiryYear(input) {
        this.enterValidYear = false;
        const currentDate = new Date(); // this returns the current datetime
        const f = currentDate.getFullYear();
        const dateString = f.toString();
        const year = dateString.substring(dateString.length - 2);
        // this.cartService.pushYear(input);
        if (input >= year) {
            this.enterValidYear = true;
        }
        if (input === year) {
            const m = currentDate.getMonth() + 1;
            if (this.enteredMonth >= m) {
                this.invalidMonthEnteredValidation = false;
            } else {
                this.invalidMonthEnteredValidation = true;
            }
        }
        if (input > year) {
            this.invalidMonthEnteredValidation = false;
        }
    }

    onSelectBillingDate(): void {
        if (this.billingDate) {
            // Check if Billing Date is after the 25th
            if (this.billingDate.getDate() > 25) {
                // If yes, set the min and max dates for the Recurring Billing Date calendar to April 1st to 25th
                const nextMonth = this.billingDate.getMonth() + 1; // Add 2 to get to the next month (February is 1, so March is 2)
                const nextYear = this.billingDate.getFullYear();
                this.recurringMinDate = new Date(nextYear, nextMonth, 1);
                this.recurringMaxDate = new Date(nextYear, nextMonth, 25);
            } else {
                // If not, set the min and max dates back to the default (current month's 1st to 25th)
                const currentMonth = this.billingDate.getMonth() + 1;
                const currentYear = this.billingDate.getFullYear();
                this.recurringMinDate = new Date(currentYear, currentMonth, 1);
                this.recurringMaxDate = new Date(currentYear, currentMonth, 25);
            }

            // If the Recurring Billing Date is selected and it's not within the valid range, reset it
            if (this.recurringBillingDate &&
                (this.recurringBillingDate < this.recurringMinDate || this.recurringBillingDate > this.recurringMaxDate)) {
                this.recurringBillingDate = null;
            }

            if (this.billingDate instanceof Date && !isNaN(this.billingDate.getTime())) {
                localStorage.setItem('billingDate', JSON.stringify(this.billingDate));
            } else {
                console.error('Invalid billingDate:', this.billingDate);
            }
        }
    }

    updateEffectiveStartDate(opt): void {
        let date = this.effectiveDate1;
        if (opt === 2) {
            date = this.effectiveDate2;
        }

        const d = new Date(date);
        // Extract the components
        const year = d.getFullYear();
        const month = d.getMonth();
        const day = d.getDate();
        const esDate = new Date(year, month, day);
        this.shipping_address_form.controls.effectiveStartDate.setValue(esDate);
    }

    onSelectrecurringBillingDate(): void {
        if (this.recurringBillingDate instanceof Date && !isNaN(this.recurringBillingDate.getTime())) {
            const dateToStore = new Date(this.recurringBillingDate);
            dateToStore.setDate(dateToStore.getDate() + 1);
            localStorage.setItem('recurringBillingDate', JSON.stringify(dateToStore.toISOString()));
        } else {
            console.error('Invalid recurringBillingDate:', this.recurringBillingDate);
        }
    }

    /*calculateEffectiveDates(){
      let today = new Date();
      let currentMonth = today.getMonth();

      // Set the threshold day (the day of the month when we switch to the next month)
      let thresholdDay = 25;

      // Determine the threshold month based on the current date and threshold day
      let thresholdMonth = currentMonth;
      if (today.getDate() >= thresholdDay) {
          // If the current date is on or after the threshold day, increment the month
          thresholdMonth++;
      }

      // Check if the threshold month is December (11)
      // If yes, reset it to January (0) for the next year
      if (thresholdMonth === 12) {
          thresholdMonth = 0;
      }

      // Define the number of upcoming months to consider
      let numberOfUpcomingMonths = (today.getDate() < thresholdDay && currentMonth === thresholdMonth) ? 2 : 1;

      // Get the upcoming dates
      for (let i = 1; i <= numberOfUpcomingMonths; i++) {
          let upcomingMonth = (thresholdMonth + i) % 12; // Calculate the next month index (taking care of wrapping to the next year)
          let upcomingDate = new Date(today.getFullYear(), upcomingMonth, 1); // 1st day of the upcoming month
          let desiredDate = upcomingDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

          if (i === 1){
            this.effectiveDate1 = desiredDate;
          }
          else
            this.effectiveDate2 = desiredDate;
      }

      // Update the effective start date as selected, if only one date is available
      if(numberOfUpcomingMonths === 1) {
        this.updateEffectiveStartDate(1);
      }
    }

    generateBillingDates(){
      let today = new Date();
      let currentDay = today.getDate();
      let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
      let endDay = Math.min(25, lastDayOfMonth); // Limit end day to the 25th of the month
      this.billingDates = Array.from({ length: endDay - currentDay + 1 }, (_, index) => {
        const date = new Date(today.getFullYear(), today.getMonth(), currentDay + index);
        return this.datePipe.transform(date, 'mediumDate');
      });
      this.selectedBillingDate = this.billingDates[0];
    }*/

}

