import {Component} from '@angular/core';
import {SharingService} from 'src/app/services/sharing.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-directenrollment',
    templateUrl: './directenrollment.component.html',
    styleUrl: './directenrollment.component.scss'
})
export class DirectenrollmentComponent {
    template: any;
    routeMemberId: any;
    agentCodeParam: any;
    family_id: any;
    agent_id: string;

    constructor(private route: ActivatedRoute,
        private service: SharingService) {
        this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
        this.agentCodeParam = this.route.snapshot.paramMap.get('agent_code') || this.route.snapshot.paramMap.get('utm_aff');// to check the affiliate code also for few cases
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        this.service.clientData.subscribe((data) => {
            if (data !== '' || data !== undefined) {
                this.template = data?.template;
            }
        });
    }
}
