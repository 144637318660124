import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-table-pagination',
    templateUrl: './table-pagination.component.html',
    styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
