import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TestService} from '../../../services/behaviourService';
import {SharingService} from '../../../services/sharing.service';
import {ToastrService} from 'ngx-toastr';
import SignaturePad from 'signature_pad';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HttpClient} from '@angular/common/http';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {SessionService} from '../../../services/session.service';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
    signaturePad: SignaturePad;
    @ViewChild('canvas') canvasEl: ElementRef;
    @ViewChild('closebutton') closebutton;
    signatureImg: string;
    @Input() signatureData: any;
    applicant: any;
    cartProducts: any;
    memberdata: any;
    paymentData: any;
    orderDetails: any;
    loader: boolean = false;
    cardType: boolean = false;
    submitAttempt: boolean
    errMsg: any;
    memer: any;
    order_id: any;
    expdate: any;
    year: any;
    month: any;
    card: any;
    cardData: any;
    account_type: string;
    signature: any;
    date = new Date();
    applicant_type: any;
    errorMessage: any;
    data: any;
    displayplane: boolean = true;
    checkedSignature: boolean = false;
    applicantname: any;
    companyId: any;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    bmiinfo: any;
    additionalMember: any;
    selectedCardType: any;
    signatureverify: boolean;
    showCheckboxError = "";
    showSignatureVerifyError = "";
    showSignatureVerifyError1 = "";
    companyName: any;
    pendingStatus: any;
    companyInfo: any;
    oneTimeFee: any;
    groupID: any;
    dispalyGuardian: boolean;
    firstDiv: boolean = true;
    secondDiv: boolean = false;
    thirdDiv: boolean = false;
    onTabClickData: any = 'esignature';
    memberId: any;
    body: { family_id: any; type: any; esignature: any; browser_version: any; browser_name: any; ip: any };
    ipAddress: any;
    fileValue: any;
    uploadedFile: any;
    showModal: boolean = false;
    member_signature: any;
    order_details: any;
    formGroup: any;
    cmpId: any;
    memId: any;
    cartId: any;
    application_id: any;
    imagetest1: any;
    imageUrl: any;
    applicantData: any;
    plannames: string;
    monthlyContribution: any;
    monthlyContributionStartDate: any;
    monthly_contribution_day: any;
    a2COneTimeFee: any;
    typedisabled: boolean = true;
    browserInfo: any;
    payment_view: boolean = false

    constructor(
        private router: Router,
        private objectService: TestService,
        private services: SharingService,
        private service: TestService,
        private toastr: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private deviceService: DeviceDetectorService,
        private http: HttpClient,
        private sessionService: SessionService
    ) {
        this.cmpId = this.sessionService.getData("company");
        this.memId = this.sessionService.getData("member_id");
        this.cartId = this.sessionService.getData("cart_id");
        localStorage.removeItem('planupdate')
        localStorage.removeItem('summaryPlanupdate');
        this.services.clientData.subscribe((data) => {

            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.companyName = this.companyInfo.company_name;
            }
        })
        this.objectService.plansSObject.subscribe(result => {
            var data = result;
            this.data = data.cart_products;
            this.displayplane = false
        });
        var url = 'GetMemberDependents/' + this.cmpId + '/' + this.memId + '/' + this.cartId;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            this.applicant = Object;
            this.application_id = Object.application_id
            this.cartFee = this.applicant.cart_fee;
            this.cartData = this.applicant.cart_data;
            this.memberDiscount = this.applicant.agent_cart_discount;
            this.bmiinfo = this.applicant.cart_bmi;
            this.oneTimeFee = this.applicant.total_one_time_fee;
            this.additionalMember = this.applicant.additional_member_fee
            this.cartProducts = this.applicant.cart_products;
            this.displayplane = true
            this.orderDetails = this.applicant.order_details;
            this.memberdata = this.applicant.member_personal_data;
            if (this.memberdata[0].enrollment_type == 'A2C') {
                this.typedisabled = false;
            }
        }, err => {
            console.log(err)
        })
        this.objectService.paymentObject.subscribe(result => {
            this.applicant = result;

            this.cartFee = this.applicant.cart_fee;
            this.cartData = this.applicant.cart_data;
            this.memberDiscount = this.applicant.agent_cart_discount;
            this.bmiinfo = this.applicant.cart_bmi;
            this.oneTimeFee = this.applicant.total_one_time_fee;
            this.additionalMember = this.applicant.additional_member_fee
            this.cartProducts = this.applicant.cart_products;
            this.displayplane = true
            this.orderDetails = this.applicant.order_details;
            this.memberdata = this.applicant.member_personal_data;
            //  this.applicantname = this.memberdata[0].firstname + " " + this.memberdata[0].lastname;
            this.getSummeryDetails();
        })
    }

    ngOnInit(): void {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
        this.formGroup = this.formBuilder.group({
            authorized: [false, Validators.requiredTrue],
            agree: [false, Validators.requiredTrue]
        });
    }

    ngAfterViewInit() {
    }


    backToPlanes() {
        localStorage.setItem('planupdate', 'updateplan')
        localStorage.setItem('summaryPlanupdate', 'summarytoPlan')
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.demoToA2c(Object);
            if (this.groupID != null) {
                this.router.navigate(['DirectMemberEnrollment/' + localStorage.getItem('urlGroupName')]);
            } else {
                this.router.navigate(['DirectMemberEnrollment']);
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }


    getSummeryDetails() {

        var url = 'GetCartSummary/' + this.cmpId + '/' + this.memId;
        let body = {
            cart_id: this.cartId
        }
        this.services.postDataUrl(url, body).subscribe((Object: any) => {
            if (Object.status == true) {
                this.browserInfo = Object.member_broswer_info
                this.memberdata = Object.member_personal_data;
                this.memberId = Object.member_personal_data[0].family_id;
                this.member_signature = Object.member_signature;
                this.order_details = Object.order_details;
                if (this.order_details != undefined) {
                    this.order_id = this.order_details[0].order_id;
                }
                this.applicantname = (this.memberdata[0].middlename != null) ? this.memberdata[0].firstname + " " + this.memberdata[0].middlename + " " + this.memberdata[0].lastname : this.memberdata[0].firstname + " " + this.memberdata[0].lastname;
                for (let i = 0; i < this.memberdata.length; i++) {
                    var ssn = "***" + '-' + '**' + '-';
                    if (this.memberdata[i].ssn4) {
                        this.memberdata[i].ssn4 = ssn + this.memberdata[i].ssn4;
                    }
                    if (this.memberdata[i].phone) {
                        this.memberdata[i].phone = this.memberdata[i].phone.substring(0, 3) + '-' + this.memberdata[i].phone.substring(3, 6) + '-' + this.memberdata[i].phone.substring(6, 10);
                    }
                    if (this.memberdata[i].height != undefined) {
                        var height = this.memberdata[i].height.toString();
                        var n = height.toString().includes(".");
                        if (n == false) {
                            var str2 = ".0";
                            var height = height.toString().concat(str2);
                        }
                        const Height = height.toString();
                        var inch = height.substring(Height.lastIndexOf('.') + 1);
                        var ft = height.substring(0, Height.lastIndexOf('.'));
                        this.memberdata[i].height = ft + "'" + " " + inch + "''";
                    }
                }
                this.paymentData = Object.paymentdata;
                if (this.paymentData != null || this.paymentData != undefined) {
                    if (this.paymentData[0].payment_type == "CARD") {
                        this.cardType = false;
                        this.paymentData[0].card_type == "C" ? this.selectedCardType = "Credit Card" : this.selectedCardType = "Debit Card"
                        this.cardData = this.paymentData[0].card;
                        // this.expdate = this.paymentData[0].exp;
                        // const dateString = this.expdate.toString();
                        // this.year = dateString.substring(dateString.length - 2);
                        // this.month = dateString.substring(0, 2);
                    }
                    if (this.paymentData[0].payment_type == "BANK") {
                        this.cardType = true;
                        if (this.paymentData[0].account_type == "S") {
                            this.account_type = "Savings";
                        } else {
                            this.account_type = "Checking";
                        }
                    }
                }
            }
        }, err => {
            this.toastr.error(err.message);
            console.log(err)
        })
    }

    onfocusoutSignature() {
        this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
        if (this.applicantname.toUpperCase() === this.signature.toUpperCase()) {
            this.showSignatureVerifyError = '';
            this.showSignatureVerifyError1 = '';
        }
    }

    finalSubmit() {
        this.showSignatureVerifyError = "";
        var orderId = this.order_id;
        let body = {
            order_id: orderId,
            company_id: this.companyId,
            company_name: this.companyName
        };
        this.loader = true;
        var url = 'ProcessOrderSharing';
        this.services.postData(url, body).subscribe((Object: any) => {
            this.services.pushOrderDetails(Object)
            if (Object.status == true) {
                this.loader = false;
                sessionStorage.removeItem('questionList');
                sessionStorage.removeItem('cartplan');
                sessionStorage.removeItem('cart_id');
                sessionStorage.removeItem('member_id');
                sessionStorage.removeItem('company');
                this.router.navigate(['thankyou']);
                var plansAndProducts = "";
                this.objectService.changeUsername(plansAndProducts);
                this.signatureImg = '';
                this.signature = '';

                // localStorage.removeItem('order_id');
            }
            if (Object.status == 'failure') {
                this.loader = false;
                this.errMsg = Object.order_details[0].message;
                this.toastr.error(this.errMsg);
            }
            if (Object.status == 'pending') {
                this.pendingStatus = Object.order_details[0].message;
                this.toastr.warning(this.pendingStatus);
            }
        }, err => {
            console.log(err);
            this.loader = false;
            this.toastr.error(err.message);
            // this.errorMessage = err.error.acknowledge_by[0];
            setTimeout(() => {
                this.errorMessage = ""
            }, 3000);
        })
    }

    onCheckbox(e) {
        if (e.target.checked == true) {
            this.checkedSignature = true;
            this.showCheckboxError = "";
        } else {
            this.checkedSignature = false;
        }
    }

    changeToDemography() {
        sessionStorage.setItem('applicantData', '1');
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.addCartPlan(Object);
            this.router.navigate(['demographic']);
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }

    changeToPaymeny() {
        var url = 'GetPayment/' + this.companyId + '/' + this.memberdata[0].member_id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.demographyH(Object);
            this.router.navigate(['paymentdetails']);
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        })
    }

    redirectToBeliefs() {
        var navURl = '/statementofbeliefs/1/summary'
        window.open(navURl, '_blank', 'location=yes,height=1000,width=1500,scrollbars=yes,status=yes');
    }

    submit() {

        this.submitAttempt = true;
        if (this.formGroup.value.authorized == true && this.formGroup.value.agree == true) {
            this.loader = true;
            let body = {
                order_id: this.order_id,
                acknowledge_by: this.applicantname,
                company_id: this.companyId
            }
            var url = 'ProcessOrder';
            this.services.postData(url, body).subscribe((Object: any) => {
                if (Object.status) {
                    this.toastr.success(Object.message);
                    var url = 'CreateMembership';
                    let body = {
                        order_id: this.order_id,
                        company_id: this.companyId
                    }
                    this.services.postData(url, body).subscribe((Object: any) => {
                        if (Object.status == true) {
                            this.loader = false;
                            this.services.pushOrderDetails(Object);
                            this.services.pushOrderMembers(Object);
                            this.service.appCartInfo(Object);
                            sessionStorage.removeItem('questionList');
                            sessionStorage.removeItem('cartplan');
                            sessionStorage.removeItem('cart_id');
                            sessionStorage.removeItem('member_id');
                            sessionStorage.removeItem('company');
                            sessionStorage.removeItem('healthQUestions');
                            this.router.navigate(['thankyou']);
                        }
                    }, err => {
                        console.log(err);
                        this.loader = false;
                        this.toastr.error(err.error.message);
                    })
                }
            }, err => {
                if (err.error.status == false) {
                    this.payment_view = true;
                    window.scrollTo(0, 0)
                }
                console.log(err);
                this.loader = false;
                this.toastr.error(err.error.message);
            })
        }
    }

    copy(code) {
        var application_number = code;
        let textData = document.createElement('input');
        textData.value = application_number;
        document.body.appendChild(textData);
        textData.select();
        document.execCommand('copy');
        document.body.removeChild(textData);
        this.toastr.success("Application number copied", '');
    }


}
