import {Component, OnInit} from '@angular/core';
import {SharingService} from '../services/sharing.service';
import {TestService} from '../services/behaviourService';

//import { environment } from '../../environments/environment';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    slideIndex = 1;
    memberPersonalInfo: any;
    companyId: any;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    data = [
        {number: '1/3', img: 'assets/images/banner20.jpg', category: 'education'},
        {number: '2/3', img: 'assets/images/banner-22.jpg', category: 'education'},
        {number: '3/3', img: 'assets/images/Banner-3.png', category: 'education'}
    ]
    companyName: string;
    companyInfo: any;

    constructor(
        private objectService: TestService,
        private service: SharingService,
    ) {
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.companyName = this.companyInfo.company_name;
            }
        })
    }

    ngOnInit(): void {
    }
}
