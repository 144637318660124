import {NavigationEnd, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SharingService} from './services/sharing.service';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import { AnalyticsService } from './services/analytics/analytics-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Hope Healthshare';
    lastUrlSegment: any;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    company_path: string;
    template: any;

    constructor(public router: Router, private http: HttpClient, private service: SharingService,
                private titleService: Title, private toastr: ToastrService,
                private analyticsService: AnalyticsService
    ) {
        this.companyVariables();
    }

    ngOnInit(): void {
        // localStorage.clear();
        this.companyVariables();
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    decodeCompanySettings(res): object {
        res = JSON.parse(atob(atob(res)));
        res.api_Key = atob(res.api_Key);
        res.client_secret = atob(res.client_secret);
        return res;
    }

    // *********based on url get company settings *******************//
    companyVariables(): void {
        let url: string;
        const splitURL = window.location.host.split('.');
        this.company_path = splitURL[0];
        if (origin.includes('localhost')) {
            // url = 'https://healthfreedom.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/wabsapi';
            // url = 'https://healthfreedom.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/healthfreedom';
            // url = 'https://healthfreedom.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/rita';
            // url = 'https://allstate.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/allstate';
            url = 'https://revolthealth.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/revolthealth';
            // url = 'https://allstate-sandbox.galaxyhealthadmin.com/vasquez-api/api/GetAllCompanySettings/allstate';
            //  url = 'https://iconia.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/iconia';
            //  url = 'https://hope.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/hope';
            // url = 'https://joppa.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/joppa';
            // url = 'http://127.0.0.1:8000/api/GetCompanySettings/healthfreedom';
            // url = 'https://rita.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/rita';
            // url = 'http://127.0.0.1:8000/api/GetCompanySettings/rita';
        } else {
            url = window.location.origin + '/vasquez-api/api/GetCompanySettings';
        }

        this.http.get(url).subscribe((obj: any) => {
            obj.data = this.decodeCompanySettings(obj.data);
            if (origin.includes('localhost')) {
                //obj.data.apiURL = 'http://127.0.0.1:8000/api/';
            }
            this.service.getClientData(obj.data);
            const imgURL = obj.data?.imgURL;
            // let faviconUrl = imgURL + obj.data?.companyIcon;
            // this.setFavicon(faviconUrl);
            this.titleService.setTitle(obj.data.company_name);

            if (obj.data?.analytics_config) {
              obj.data.analytics_config = JSON.parse(obj.data?.analytics_config);
              if (obj.data?.analytics_config && obj.data?.analytics_config.provider && obj.data?.analytics_config.id) {
                if (obj.data?.analytics_config.provider == 'gtm') {
                  this.analyticsService.appendGtmScript(obj.data?.analytics_config.id);
                } else if (obj.data.analytics_config.provider == 'pixel') {
                  // Future implementation for Facebook Pixel
                }
              } else {
                console.log('Invalid analytics config');
              }
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.message);
        });
    }

    // setFavicon(url: string) {
    //   let link = document.querySelector(`link[rel*='icon']`) as HTMLLinkElement;
    //   if (!link) {
    //     link = document.createElement('link');
    //     link.rel = 'shortcut icon';
    //     document.getElementsByTagName('head')[0].appendChild(link);
    //   }
    //   link.href = url;
    // }

    ngOnDestroy(): void {
        localStorage.clear();
    }
}
