import { AbstractControl } from '@angular/forms';

export function isValidMobileNumber(control: AbstractControl): { [key: string]: boolean } {

    if (control.value != null && control.value.replace(/-/g, "")?.length > 0 && control.value.replace(/-/g, "")?.length < 10) {
        return { invalidmobile: true }
    }
    else {
        return null!;
    }
}