import {Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DatePipe, Location} from '@angular/common';
import {SharingService} from 'src/app/services/sharing.service';

@Component({
    selector: 'app-products',
    // standalone: true,
    templateUrl: './products.component.html',
    styleUrl: './products.component.scss',
    providers: [DatePipe]

})
export class ProductsComponent {
    toasterTimeout: any;
    productList: any;

    data: any;
    productinfo: any;
    selectedRecordForProducts: any = [];
    allcheckboxchecked: boolean;
    seletedAllfromCheckbox: any;
    companyId: any;
    start_date: any;
    family_id: any;
    binaryData: any[] = [];
    routeMemberId: any;
    user_company_id: any;
    route_company_name: any;
    mainprice: number;
    memberdata: any;
    total: any;
    plan: void;
    plan_name: any;
    product: any;
    grand_total = 0;
    from_products: any;
    cart_products: any;
    cart_data: any;
    cart_id: any;
    agentInfo: any = null;
    limit: number;
    primary_info: any;
    child_info: any;
    spouse_info: any;
    child_products: any = [];
    child_price: any;
    uniqueArray: any[] = [];
    hoverActive: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private _route: Router,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private service: SharingService,
        private location: Location
    ) {
        //this.start_date = this.datePipe.transform(localStorage.getItem('start_date'), "yyyy-MM-dd"),
        this.family_id = localStorage.getItem('family_id');
        this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
        this.user_company_id = localStorage.getItem('user_company_id');
        localStorage.removeItem('cartdetails');
        localStorage.removeItem('from_Beneficiary');
        localStorage.removeItem('billingDate');
        localStorage.removeItem('recurringBillingDate');
        this.from_products = localStorage.getItem('from_payment')
    }

    ngOnInit(): void {
        this.product = this.formBuilder.group({
            newvalue: [''],
        })
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.toasterTimeout = data.toasterTimeout;
                this.companyId = data.company_id;
                this.route_company_name = data.company_key;
                this.memberdata = JSON.parse(localStorage.getItem('memberdata'));
                if (this.from_products == 'Payment Details') {
                    this.previousEnrollment();
                } else {
                    this.getnewproducta()
                }
            }
        })


    }


    async previousEnrollment() {
        var url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
            this.cart_products = Object.cart_products;
            this.grand_total = Object.cart_data[0]?.total - Object.cart_data[0]?.fee_amount;
            this.cart_id = Object.cart_data[0]?.id;

            this.getnewproducta()
        });
    }

    getnewproducta() {
        let body = {
            company_id: this.companyId, is_check: 'false', member_personal_data: this.memberdata.member_personal_data
        }
        var url = 'CompanyPlanList'
        this.service.postData(url, body).subscribe((Object: any) => {
            if (Object.status == true) {

                if (Object.data.agent_info) {
                    this.agentInfo = Object.data.agent_info;
                    localStorage.setItem('agentInfo', JSON.stringify(this.agentInfo));
                }

                this.total = 0;
                this.data = Object.data.plans;
                this.plan_name = Object.data.plan_name;
                this.primary_info = this.data.filter(x => x['relation'] == "Primary");
                this.spouse_info = this.data.filter(x => x['relation'] == "Spouse");
                this.child_info = this.data.filter(x => x['relation'] == "Child");
                if (this.primary_info) {
                    this.limit = this.primary_info.length + this.spouse_info.length
                }
                if (this.cart_products) {
                    this.grand_total = this.grand_total;
                    this.data.forEach(value => {
                        let index = this.cart_products.findIndex(x => parseInt(x.member_id) === parseInt(value.member_id))

                        if (index != -1) {
                            value.scale = this.cart_products[index].policy_amount
                            value.total_price = this.cart_products[index].total_amount
                            value.total_price_duplicate = '',
                                value.isshowtotalprice = true
                            value.selected = true;
                            value.child_length = this.child_info.length,
                                value.col_length = this.limit;
                        } else {
                            value.total_price_duplicate = '',
                                value.isshowtotalprice = false
                            value.selected = false;
                            value.child_length = this.child_info.length,
                                value.col_length = this.limit;
                        }
                    })
                } else {
                    this.grand_total = Object.data.grand_total;
                    this.data.forEach(x => {
                        x.scale = x.scale_list[0]
                        x.total_price_duplicate = '',
                            x.isshowtotalprice = true
                        x.selected = true;
                        x.child_length = this.child_info.length,
                            x.col_length = this.limit;
                    })
                }
            }
        }, (err) => {
            this.toastr.clear()
            this.toastr.error(err.error.message, '', {
                closeButton: true,
                timeOut: this.toasterTimeout,
            });

        })
    }

    // handleChange1(e, data) {
    //   data.scale = e.target.value;
    //   for (var i = 0; i < this.data.length; i++) {
    //     if (this.data[i].member_id == data.member_id) {
    //       this.data[i].total_price = (this.data[i].price * e.target.value / this.data[i].price_change_per);
    //       this.grand_total += this.data[i].total_price;
    //     }
    //   }

    // }

    onPrevious() {

        this._route.navigateByUrl('/DirectMemberEnrollment/' + this.memberdata.member_personal_data[0].member_id + '/' + this.family_id);

        // if (this.location.path().includes('d2c')) {
        //   this._route.navigateByUrl(this.route_company_name + '/d2c/memberenrollment/' + this.memberdata.member_personal_data[0].member_id + '/' + this.family_id);
        // }
        // if (this.location.path().includes('acc')) {
        //   this._route.navigateByUrl(this.route_company_name + '/acc/memberenrollment/' + this.memberdata.member_personal_data[0].member_id + '/' + this.family_id);

        // }
    }

    checkIfActiveState(e, data) {
        data.scale = e.target.value;
        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].member_id == data.member_id) {
                let tp = (this.data[i].price * e.target.value / this.data[i].price_change_per);
                this.data[i].total_price = parseFloat(tp.toFixed(2));
            }
        }

        let filtereddata = this.data.filter(element => {
            return element.isshowtotalprice == true;
        });

        const specifiedProperty = 'total_price';
        let childTotalCalculated = false;

        const total = filtereddata.reduce((accumulator, currentObject) => {
            if (specifiedProperty in currentObject) {

                if (childTotalCalculated && currentObject.relation.toLowerCase() === 'child') {
                    return accumulator;
                }

                if (currentObject.relation.toLowerCase() === 'child') {
                    childTotalCalculated = true;
                }

                return accumulator + currentObject[specifiedProperty];
            } else {
                return accumulator;
            }
        }, 0); // Initialize accumulator with 0

        this.grand_total = parseFloat(total.toFixed(2));

    }

    selectRecord(checked, data,) {
        data.isshowtotalprice = !data.isshowtotalprice;
        //this.child_products=[];
        if (data.relation == 'Child') {
            this.child_products.push(data);
            this.child_price = this.child_products[0]?.total_price;
            if (this.child_products.length > this.child_info.length) {
                let index = this.child_products.findIndex(
                    (el) => el.member_id == data.member_id
                )
                if (index > -1) {
                    this.child_products.splice(index, 1);
                }
            }
            if (checked) {
                if (this.child_info.length == this.child_products.length) {
                    this.grand_total = this.grand_total + this.child_price
                }
            } else {
                let index = this.child_products.findIndex(
                    (el) => el.member_id == data.member_id
                )
                if (this.child_info.length == this.child_products.length) {
                    this.grand_total = this.grand_total - this.child_price;
                }
            }
        } else {
            if (!data.isshowtotalprice) {//unchecked
                this.grand_total = this.grand_total - data.total_price
            }
            if (data.isshowtotalprice) {//checked
                this.grand_total = this.grand_total + data.total_price
            }
        }
        if (checked) {
            // data.selected = true;
            this.selectedRecordForProducts.push(data);
        } else {
            this.selectedRecordForProducts = [];
            let index = this.selectedRecordForProducts.findIndex(
                (el) => el.member_id == data.member_id
            );
            if (index > -1) {
                this.selectedRecordForProducts.splice(index, 1);
            }

        }
    }

    sendMultipleEmail() {
        let filtereddata = this.data.filter(element => {
            return element.isshowtotalprice;
        });
        let body;
        const url = 'AddCompanyCartPlan';
        const enrollment_type = localStorage.getItem('enrollment_type');
        body = {
            family_id: this.family_id,
            company_id: this.companyId,
            total: this.grand_total,
            enrollment_type,
            plans: filtereddata,
            cart_id: this.cart_id,
        };
        localStorage.setItem('product_info', body);
        localStorage.setItem('product_info', JSON.stringify(this.selectedRecordForProducts));
        this.service.postData(url, body).subscribe((Object: any) => {
            localStorage.setItem('acc_cartId', Object.cart_data[0].id);
            if (Object) {
                localStorage.setItem('cartdetails', JSON.stringify(this.memberdata));
                localStorage.setItem('cart_id', Object.cart_data[0].id);
                localStorage.setItem('primary_member_id', this.memberdata.member_personal_data[0].member_id);

                if (Object.recurring_dates && Object.recurring_dates.length) {
                    localStorage.setItem('recurring_start_date', Object.recurring_dates[0].start);
                    localStorage.setItem('recurring_end_date', Object.recurring_dates[0].end);
                }
                this.router.navigate(['/payments', this.family_id, this.memberdata.member_personal_data[0]?.member_id]);

                // if (this.location.path().includes('d2c')) {
                //   this.router.navigate([this.route_company_name + '/d2c/memberpayment', this.family_id, this.memberdata.member_personal_data[0].member_id]);
                // }
                // if (this.location.path().includes('acc')) {
                //   this.router.navigate([this.route_company_name + '/acc/memberpayment', this.family_id, this.memberdata.member_personal_data[0].member_id]);
                // }
            }
        }, err => {
            if (err.error.message == "Unauthenticated.") {
                this.toastr.clear();
                this.toastr.error(err.error.message, '', {
                    closeButton: true,
                    timeOut: this.toasterTimeout,
                });
            } else {
                console.log(err);
                this.toastr.clear();
                this.toastr.error(err.error.message, '', {
                    closeButton: true,
                    timeOut: this.toasterTimeout,
                });
            }
        })

    }

    applyHoverEffect(hover: boolean) {
        this.hoverActive = hover;
    }
}
