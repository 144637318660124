<div *ngIf="template === 'template_1'">
    <nav class="navbar navbar-expand-lg bg-white sticky-top shadow-sm py-0" id="header-navbar">
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img [src]="logo" alt="" height="auto" width="150px">
            </a>
            <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
                    data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
                <i class="bi bi-list"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <ul class="navbar-nav ms-auto">
                    <ng-container *ngIf="isDemoPortal">
                        <li *ngFor="let item of header_array" class="nav-item">
                            <a href="javascript:void(0);" class="nav-link"> {{ item.name }} </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!isDemoPortal">
                        <li *ngFor="let item of header_array" class="nav-item">
                            <a (click)="route(item.name)" *ngIf="item.name !='RESOURCES'" class="nav-link" href="{{routepath}}"
                                routerLinkActive="active" target="_blank">{{ item.name }} <span class="sr-only">(current)</span></a>
                            <span *ngIf="item.name =='RESOURCES'" class="nav-item dropdown">
                                <a aria-expanded="false" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button">
                                    {{ item.name }}
                                </a>
                                <div class="dropdown-menu">
                                    <a *ngFor="let item of resource_plans" [attr.href]="item.url" class="dropdown-item" target="_blank"> {{
                                        item.name }}
                                    </a>
                                </div>
                            </span>
                        </li>
                    </ng-container>

                    <li class="nav-item">
                        <div class="nav-btn-link">
                            <button class="btn btn-sm btn-primary member mr-3" routerLink="/DirectMemberEnrollment">
                                <i class="bi bi-person-plus mr-2"></i> Become A Member
                            </button>
                            <a class="btn btn-sm btn-primary member" href="{{MemberLogin}}" target="_blank">
                                <i class="bi bi-person-badge mr-2"></i>
                                Member Login
                            </a>
                        </div>
                    </li>
                
                </ul>

                <ul class="navbar-nav ms-auto d-none">
                    <li class="nav-item">
                        <a class="nav-link" href="https://hopehealthshare.com" routerLinkActive="active"
                           target="_blank">HOME <span
                                class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://hopehealthshare.com/programs/" routerLinkActive="active"
                           target="_blank">PROGRAMS</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://hopehealthshare.com/about/" routerLinkActive="active"
                           target="_blank">ABOUT</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://hopehealthshare.com/faq/" routerLinkActive="active"
                           target="_blank">FAQ</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link " href="https://hopehealthshare.com/contact/" routerLinkActive="active"
                           target="_blank">CONTACT US</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a aria-expanded="false" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                           role="button">
                            RESOURCES
                        </a>
                        <div class="dropdown-menu">
                            <a *ngFor="let item of resource_plans" class="dropdown-item" href="{{item.url}}"
                               routerLinkActive="active"
                               target="_blank"> {{ item.name }}
                            </a>
                            <!-- <a class="dropdown-item" routerLinkActive="active" routerLink="/program-brochure">Program Brochure</a>
                            <a class="dropdown-item" routerLinkActive="active" routerLink="/form-document">Forms and Documents</a>
                            <a class="dropdown-item" routerLinkActive="active" routerLink="/blog">Blog</a> -->
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-btn-link">
                            <button class="btn btn-sm btn-primary member mr-3" routerLink="/DirectMemberEnrollment">
                                <i class="bi bi-person-plus mr-2"></i> Become A Member
                            </button>
                            <a class="btn btn-sm btn-primary member" href="{{MemberLogin}}" target="_blank">
                                <i class="bi bi-person-badge mr-2"></i>
                                Member Login
                            </a>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </nav>

    <div *ngIf="disclaimer" class="disclaimer-txt" id="disclaimer-txt">
        <h6 class="">
            {{ disclaimer }}
        </h6>
    </div>
</div>


<header *ngIf="template === 'template_2' || template === 'package' || template == 'template_3' || template === 'single-page-template' || displayHeaderSection" id="enrollment-header">
    <div class="top-header">
        <div class="container">
            <div class="header-left">
                <!-- <p>Affordable Health Insurance  <a href="">Learnmore</a></p> -->
            </div>
            <div class="header-right">
                <ul>
                    <li *ngIf="supportEmail"><a href="mailto:{{supportEmail}}"><i class="bi bi-envelope"></i> {{ supportEmail }}</a>
                    </li>
                    <li *ngIf="supportPhone"><a href="tel:{{supportPhone}}"><i class="bi bi-telephone"></i> {{ supportPhone }}</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="enrollment-header">
        <div class="container">
            <div class="header-left" *ngIf="display_enrollment">
                Member Enrollment
            </div>
            <!-- <div class="header-left" *ngIf="groupLogo !=null">
                <img src='data:image/png;base64,{{groupLogo}}' alt="" class="brand-logo">
            </div> -->
            <div class="header-right" (click)="navigateToPublic()">
                <img alt="" class="brand-logo" src="{{logo}}">
            </div>
        </div>
    </div>
</header>
