<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    <div class="progress_bar">
        <ul [ngClass]="{'progessbar': A2Ccode, 'progess-bar': !A2Ccode}" class=" mb-7">
            <li *ngIf="!A2Ccode">Enrollment</li>
            <li *ngIf="!A2Ccode">Products</li>
            <li *ngIf="!A2Ccode ">Payment Details</li>
            <li>Beneficiary</li>
            <li>Agreement</li>
            <li>Disclosure 1</li>
            <li>Disclosure 2</li>
            <li>Summary</li>
            <li class="active">Confirmation</li>
        </ul>
    </div>
</div>
<div class="container">
    <!--Agent Info Section-->
    <div *ngIf="agentInfo?.id>0" class="card shadow-sm mb-3">
        <div class="card-body">
            <h6 class="title-mainhead">
                <span>Agent Information</span>
            </h6>

            <div class="row">
                <div class="col-md-6">
                    <div class="info-box">
                        <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span> <span
                                *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                        <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                        <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="orderDetails !=undefined" class="column col-lg-12" id="content">
        <div class="confirmation">
            <h4 style="margin: 0;">
                <i class="bi bi-check-circle check-icon"></i>
                Thank You For Your Payment
                <!-- <span class="downloadorder d-none" style="cursor: pointer;
                text-decoration: underline;
                font-size: 20px;  color: #ED1C24;" (click)="exportPdf()"><a>Download your copy</a>
            </span> -->
            </h4>
        </div>
        <div class="my-invoices" id="orderslist">
            <div class="inner-container clearfix" id="invoice"
                 style=" background-color: white !important;padding: 4px;">
                <div class="container-fluid pdf_cs">
                    <div class="row">
                        <div class="col-lg-10">
                            <h2 *ngFor="let item of orderDetails">Hi, {{ item.acknowledge_by }}</h2>
                            <!-- <p>Receipt for your Enrollment in <span>{{plannames}}
                                Program</span></p> -->
                        </div>

                    </div>
                    <div class="invoice-box">
                        <div class="row">
                            <div class="column  col-md-4 col-sm-12 col-xs-12">
                                <div>
                                    <h3>Billed To:</h3>
                                    <ul *ngFor="let item of orderDetails" class="invoice-info">
                                        <li>
                                            {{ item.firstname | titlecase }}
                                            {{ item.lastname | titlecase }}
                                        </li>
                                        <li>
                                            <span class="addres-td">
                                                <span>{{ item.billing_address1 }}, </span>
                                                <span *ngIf="item?.billing_address2"> {{ item.billing_address2 }},
                                                </span>
                                                <span>{{ item.billing_city }}, {{ item.billing_state }}, {{
                                                        item.billing_zip
                                                    }} </span>
                                            </span>

                                        </li>

                                        <li>
                                            {{ item.email }}
                                        </li>
                                        <li>
                                            {{ item.mobile }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="column   col-md-5 col-sm-12 col-xs-12">
                                <div *ngFor="let item of orderDetails">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td class="width50percent">
                                                Order ID
                                            </td>
                                            <td class="width50percent">
                                                <span class="rightspace">:</span> {{ item.order_id }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50percent">
                                                Date & Time
                                            </td>
                                            <td class="width50percent">
                                                <span class="rightspace">:</span>
                                                {{ item.created_at | date: 'd-MMM-y, h:mm:ss a' }}

                                            </td>
                                        </tr>
                                        <!-- <tr *ngIf="enrolmantType=='G2C'">
                                        <td class="width50percent">
                                            Payment Method
                                            <td class="width50percent">
                                        <td>
                                            <span class="rightspace">:</span>
                                                Group
                                        </td>
                                    </tr> -->
                                        <tr *ngIf="paymentData !=undefined">
                                            <td class="width50percent">
                                                Payment Method
                                            </td>
                                            <td class="width50percent">
                                                <span class="rightspace">:</span>
                                                {{ this.orderDetails[0].payment_type }}
                                            </td>
                                        </tr>
                                        <tr *ngIf="paymentData[0].payment_type =='CARD' && paymentData !=undefined">
                                            <td class="width50percent">
                                                Card Number
                                            </td>
                                            <td class="width50percent">
                                                <span class="rightspace">:</span> {{ paymentData[0].card }}
                                            </td>
                                        </tr>
                                        <tr
                                                *ngIf="this.paymentData[0].payment_type !='CARD' && paymentData !=undefined">
                                            <td class="width50percent">
                                                <div>Routing Number</div>
                                                <div>Account Number</div>
                                            </td>
                                            <td class="width50percent">
                                                <div>
                                                    <span class="rightspace">:</span> {{ paymentData[0].routing }}
                                                </div>
                                                <div>
                                                    <span class="rightspace">:</span> {{ paymentData[0].account }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="paymentData !=undefined">
                                            <td class="width50percent">
                                                Confirmation Number
                                            </td>
                                            <td>
                                                <span class="rightspace">:</span>
                                                {{ orderDetails[0].confirmation_number }}

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <p *ngFor="let item of orderDetails" style="margin-bottom: 2px;"> <span
                                        style="font-size: 19px; margin-right: 2%;"> Terms
                                        &
                                        Conditions</span> <span><span>Accepted By :</span>
                                    {{ item.acknowledge_by }} </span></p>
                            </div>
                            <div *ngIf="cartProducts.length>0" class="table-responsive ">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th style="width: 20%; border-right: 2px solid white">
                                            <strong>Item</strong>
                                        </th>
                                        <th style="width: 15%; border-right: 2px solid white">
                                            <strong>Name</strong>
                                        </th>
                                        <th style="width: 10%; border-right: 2px solid white">
                                            <strong>Relation</strong>
                                        </th>
                                        <th style="width: 5%; border-right: 2px solid white">
                                            <strong>Age</strong>
                                        </th>
                                        <th style="width: 20%; border-right: 2px solid white">
                                            <strong>Policy Amount</strong>
                                        </th>
                                        <th style="width: 30%; text-align: end;"><strong>Price</strong></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of cartFee">
                                        <td class="font_bold" colspan="6">
                                            {{item.name | titlecase}}
                                            <span style="float: right;">
                                                    {{ item.amount | currency:'USD' }}
                                                </span>
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let item of cartProducts; let i=index">
                                        <tr *ngIf="item.relation=='Primary' || item.relation=='Spouse'">
                                            <td class="font_bold">{{ item.product_name }}
                                                <div *ngFor="let member of item.family_info" class="applicantName">
                                                    {{ member.firstname | titlecase }}
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.name | titlecase }}
                                            </td>
                                            <td>
                                                {{ item.relation }}
                                            </td>
                                            <td>
                                                {{ item.age }}
                                            </td>
                                            <td>
                                                {{ item.policy_amount | currency:'USD' }}
                                            </td>
                                            <td style="text-align: end;">{{ item.total_price | currency:'USD' }}
                                            </td>
                                        </tr>

                                        <tr *ngIf="item.relation=='Child'">
                                            <td class="font_bold">{{ item.product_name }}
                                                <div *ngFor="let member of item.family_info" class="applicantName">
                                                    {{ member.firstname | titlecase }}
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.name | titlecase }}
                                            </td>
                                            <td>
                                                {{ item.relation }}
                                            </td>
                                            <td>
                                                {{ item.age }}
                                            </td>
                                            <td>
                                                {{ item.policy_amount | currency:'USD' }}
                                            </td>
                                            <td *ngIf="i==item.col_length" [attr.rowspan]="item.child_length"
                                                style="text-align: end;"> {{ item.total_price | currency:'USD' }}
                                            </td>
                                        </tr>


                                    </ng-container>
                                    <!-- <tr *ngFor="let item of cart_addon_products">
                                <td>{{item.Name}} </td>
                                <td style="text-align: end;"> {{item.total_amount | currency:'USD'}}</td>
                            </tr> -->

                                    <!-- <tr style="font-weight: bold;" class="d-none">
                                <td> Total One-time  Fee </td>
                                <td style="text-align: end;" *ngIf="!a2COneTimeFee">{{oneTimeFee |
                                    currency:'USD'}} </td>
                                <td style="text-align: end;" *ngIf="a2COneTimeFee">{{a2COneTimeFee |
                                    currency:'USD'}} </td>
                            </tr> -->
                                    <!-- <tr style="font-weight: bold;" *ngFor="let item of cartDiscount">
                                        <td> Group Discount</td>
                                        <td style="text-align: end;" >{{item.discount | currency:'USD'}} </td>

                                    </tr> -->
                                    <tr *ngIf="memberDiscount?.length >0">
                                        <td> Member Discount</td>
                                        <td *ngFor="let item of cartData" style="text-align: end;">
                                            {{
                                                item.discount |
                                                    currency:'USD'
                                            }}
                                        </td>
                                    </tr>
                                    <!-- <tr *ngFor="let data of cart_group_discount">
                                <td style="font-weight: bold;">  {{data.name}}</td>
                                <td style="text-align: end; font-weight: bold;" >
                                    {{data.discount | currency:'USD'}}
                                </td>
                            </tr> -->
                                    <tr class="bgborder">
                                        <td colspan="6" style="font-weight: bold;"> Total Payments Today
                                            <span *ngFor="let item of cartData" style="float: right;">
                                                    {{ item.total | currency:'USD' }}
                                                </span>
                                        </td>
                                        <!-- <td style="text-align: end; font-weight: bold;"  *ngFor="let item of cartData">
                                        {{item.total | currency:'USD'}}
                                    </td> -->
                                    </tr>
                                    <!-- <tr>
                                    <td style="font-weight: bold;"> Monthly Contribution</td>
                                    <td style="text-align: end; font-weight: bold;">
                                        {{monthly_contribution | currency: "USD" }}
                                    </td>
                                </tr> -->

                                    <tr>
                                        <!-- <td style="font-weight: bold;" *ngIf="monthlyContributionStartDate">
                                    Monthly Contribution to be collected on or before {{monthly_contribution_day}}th of each month starting {{monthlyContributionStartDate|date:'MM/dd'}}
                                </td> -->
                                        <!-- <td style="text-align: end; font-weight: bold;" *ngIf="monthlyContribution">
                                    {{monthlyContribution | currency:'USD'}}
                                </td> -->
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="cartProducts.length>0" class="table-responsive ">
                                    <table class="table ">
                                        <tbody class="mt-4">
                                        <tr>
                                            <td colspan="2" style="font-weight: bold;"> Total Monthly Recurring
                                                Payment
                                                <span style="float: right; font-weight: bold;">
                                                        {{ monthly_contribution | currency: "USD" }}
                                                    </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="d-none" style="font-size: 19px;text-align: center;"
                        *ngIf="enrolmantType !='G2C'">Thank you! Your payment will appear on your statement as <b>Galilee for Hope</b>.</div> -->
                            <!-- <div class="d-none" *ngIf="enrolmantType =='G2C'">Thank you for Enrolling in {{companyName}} program
                    </div> -->
                            <div class="d-none"
                                 style="padding: 15px 0;font-size: 15px;text-align: center;font-weight: bold;">A copy
                                of your receipt has been emailed to you. You will also receive an email with
                                instructions to set up your Member account access.
                                If these emails do not arrive promptly,
                                please check your spam folder.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loader" class="loader">
        <div id="loading"></div>
    </div>
</div>
