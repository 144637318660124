import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {

    transform(value: string, showMask: boolean): string {
        if (value == null) {
            value = "";
        }
        let trimmed = value;
        if (trimmed.length > 12) {
            trimmed = trimmed.substr(0, 12);
        }
        trimmed = trimmed.replace(/-/g, "");
        let numbers = [];
        numbers.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 3) !== "") numbers.push(trimmed.substr(3, 3));
        if (trimmed.substr(6, 4) != "") numbers.push(trimmed.substr(6, 4));
        return value = numbers.join("-");
    }

}
