import {SharingService} from 'src/app/services/sharing.service';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';

@Component({
    selector: 'app-membersummary',
    templateUrl: './membersummary.component.html',
    styleUrl: './membersummary.component.scss'
})
export class MembersummaryComponent {

    billingDate: any;
    recurringBillingDate: any;
    companyId: any;
    family_id: any;
    code: any;
    payment_info: any;
    order_details: any;
    total_payment_today: any;
    product_price: any;
    order_id: any;
    product_name: any;
    cart_products: any;
    loader: boolean = false;
    cart_fee: any[] = [];
    dateFormat: any;
    members: any;
    monthly_contribution: any;
    agencyName: any;
    isSubmitted: boolean = false;
    groupCompanyId: any;
    route_company_name: any;
    parent_company_id: any;
    isRiderAvailable: boolean = false
    mamberid: string;
    A2Ccode: any;
    payment_date: any;
    agentInfo: any = null;
    futurePayment: any = 0;
    paymentMessage: any = "";
    knowledgement: boolean = false;
    display_div: boolean = false;
    child_info: any;
    child: any;
    child_total: any;
    healthQuestions: any = [];
    spouseHealthQuestions: any = [];

    constructor(
        private service: SharingService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private location: Location
    ) {
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        this.mamberid = this.route.snapshot.paramMap.get('id');
        this.A2Ccode = this.route.snapshot.paramMap.get('code');

    }

    ngOnInit(): void {
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;
                this.route_company_name = data.company_key
                this.parent_company_id = data.parent_company
                this.getMemberSummary();
            }
            // Get Agent info from localStorage
            if (localStorage.getItem('agentInfo')) {
                this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
            }
            console.log("agentINfo", this.agentInfo)
            if (this.location.path().includes('acc') || this.agentInfo?.id > 0) {
                this.display_div = true;
                this.knowledgement = true;
            } else {
                this.knowledgement = false;
                this.display_div = false;
            }
        });

        const billingDateStr = localStorage.getItem('billingDate');
        if (billingDateStr) {
            this.billingDate = new Date(JSON.parse(billingDateStr));
            const date = new Date(this.billingDate);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
            this.billingDate = formattedDate;
        }
        const recurringBillingDateStr = localStorage.getItem('recurringBillingDate');
        if (recurringBillingDateStr) {
            this.recurringBillingDate = new Date(JSON.parse(recurringBillingDateStr));
            const date = new Date(this.recurringBillingDate);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
            this.recurringBillingDate = formattedDate;
        } else {
            this.recurringBillingDate = this.billingDate;
        }

    }

    convertToFeetAndInches = (num: number): string => {
        const feet = Math.floor(num);
        const inches = Math.round((num - feet) * 100);

        // If there are no inches, return just feet.
        if (inches === 0) {
            return `${feet}ft`;
        }

        // Return feet and inches.
        return `${feet}ft ${inches}in`;
    }

    getMemberSummary() {
        const url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
            this.members = Object.members;

            const primaryMemberId = Object.members.find(member => member.relation === 'Primary')?.member_id;
            const spouseMemberId = Object.members.find(member => member.relation === 'Spouse')?.member_id;

            // Group health issue data by primary and spouse
            const primaryHealthIssues = Object.member_health_issue_data.filter(issue => issue.member_id === primaryMemberId);
            const spouseHealthIssues = Object.member_health_issue_data.filter(issue => issue.member_id === spouseMemberId);

            this.healthQuestions = primaryHealthIssues;
            this.spouseHealthQuestions = spouseHealthIssues;

            this.payment_info = Object?.payment_info;
            let mm = this.payment_info.exp?.substring(0, 2);
            let yy = this.payment_info.exp?.substring(2);
            this.payment_info.mm = mm;
            this.payment_info.yy = yy;
            this.order_details = Object?.order_details;
            this.order_id = this.order_details.id;
            this.total_payment_today = Object?.cart_data[0]?.total;
            this.product_price = Object?.cart_products[0]?.total_amount;
            this.cart_products = Object?.cart_products;
            this.child_info = this.cart_products.filter(x => x['relation'] == "Child");
            if (this.child_info?.length > 0) {
                this.child_total = this.child_info[0]?.total_amount;
                this.child = 'child(ren)';
            }
            this.cart_fee = Object.cart_fee;
            this.monthly_contribution = Object.monthly_contribution,
                this.futurePayment = Object.future_payment;
        });
    }

    onPrevious() {
        if (this.A2Ccode != undefined && this.A2Ccode != null) {
            this.router.navigate(['/disclosure_two', this.mamberid, this.family_id, this.A2Ccode]);
        } else {
            this.router.navigate(['/disclosure_two', this.mamberid, this.family_id]);
        }
    }

    submit() {
        this.loader = true;
        const url = 'CreateProductOrderA2C';
        let payload = {
            parent_company_id: this.parent_company_id,
            acknowledge_by: this.members[0]?.firstname + ' ' + this.members[0]?.lastname,
            order_id: this.order_details?.id,
            company_id: this.companyId
        };
        this.service.postData(url, payload).subscribe((res: any) => {
            this.loader = false;
            if (res.process_payment) {
                if (this.A2Ccode) {
                    this.router.navigate(['/confirmation', this.order_id, this.family_id, this.A2Ccode]);
                } else {
                    this.router.navigate(['/confirmation', this.order_id, this.family_id]);
                }
                localStorage.setItem('parent_company_id', this.companyId);
                localStorage.setItem('group_company_id', this.groupCompanyId);
            } else {
                this.isSubmitted = true;
                this.paymentMessage = res.message;
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
        });
    }

    knowledgement_check(checked): void {
        if (checked) {
            this.knowledgement = false;
        } else {
            this.knowledgement = true;
        }
    }

    backToEnrollment() {
        this.router.navigate(['/DirectMemberEnrollment']);


    }


}
