<!-- <div class="container m-top" style="padding: 0;background-color: #ffff;margin-bottom: 23%;">
    <div class="error">404</div>
    <div class="page"> Page Not Found</div>
</div> -->
<div class="container-fluid" style="text-align: center;">
    <img src="assets/comingsoon.jpg">
</div>
<style>
    .error {
        font-size: 100px;
        text-align: center;
        letter-spacing: .5px;
        color: red;
    }

    .page {
        font-size: 19px;
        text-align: center;
        padding: 10px;
        margin: 2% 0% 2% 0%;
    }
</style>