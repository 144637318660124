<div class="container">
  <div class="enrlmnt-demographic">
    <div class="stepper">
      <div class="step active" *ngFor="let item of steps"></div>
    </div>
  </div>
  <div class="progress_bar">
    <ul class="progess-bar mb-7">
      <li *ngFor="let item of steps" [ngClass]="item.active ? 'active' : '' ">{{item.step}}</li>
    </ul>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="card card-body shadow-sm">
        <h6 class="mb-3">Select the type of payment mode</h6>
        <div class="paymentType">
          <form [formGroup]="paymentType" class="d-flex ">
            <div class="form-check" *ngFor="let item of dynamic_paymentmethod; let i=index">
              <input (click)="changeCard(item.name)" (click)="setradio(item.type)" [(ngModel)]="typeOfCard"
                [ngModelOptions]="{standalone: true}" [checked]="i===0" name="cardtype" type="radio"
                class="form-check-input" value="{{item.name}}">
              <label class="form-check-label"> {{ item.name }}</label>
            </div>
          </form>
        </div>

        <div class="paymentinfo">
          <form *ngIf="!base_form" [formGroup]="Credit_Form" class="payment-form">
            <div class="row">
              <div class="form-group col-md-8">
                <input class="form-control" formControlName="name" placeholder="Name on Card" type="text">
                <div
                  *ngIf="!Credit_Form.controls.name.valid && (Credit_Form.controls.name.touched || submitCardAttempt)"
                  class="errormsg">
                  Name on card is required
                </div>
              </div>

            </div>
            <div class="row">
              <div class="form-group col-md-8">
                <input #SelectList (keypress)="numberOnly($event)" (keyup)="onKeyup(SelectList.value)"
                  class="form-control" formControlName="cardNumber" maxlength="16" placeholder="Card Number" type="text"
                  (paste)="removeSpaces($event)">
                <div
                  *ngIf="!Credit_Form.controls.cardNumber.valid && (Credit_Form.controls.cardNumber.touched || submitCardAttempt)"
                  class="errormsg">
                  Card Number is required
                </div>
                <div
                  *ngIf="(Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && notValidCardNumber) || (Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && !checkCardValid)"
                  class="errormsg">
                  Please enter a Valid Card Number
                </div>
                <div *ngIf="!!card_number_Err" class="errormsg">{{ card_number_Err }}</div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-5">
                <!-- <div class="form-group">
                        <input type="text" name="month_year" #selectListExpiryMonthYear formControlName="expiryDate"
                          placeholder="Expiry Date (mm/yy)" maxlength="5" size="5" class="form-control" />
                          <div class="errormsg"
                          *ngIf="!Credit_Form.controls.expiryDate.valid && (Credit_Form.controls.expiryDate.touched || submitCardAttempt)">
                          Expiry Date is required
                        </div>
                          <div class="errormsg" *ngIf="!!exp_date_err" >{{exp_date_err}}</div>
                      </div> -->
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-4 col-xs-5">
                      <input #selectListExpiryMonth (keyup)="onKeyupExpiryMonth(selectListExpiryMonth.value)"
                        class="form-control" formControlName="expiryMonth" maxlength="2" name="month" numbersOnly
                        placeholder="MM" size="2" type="text" />
                    </div>
                    <div class="col-md-2 col-xs-2 text-center slash">
                      <span class="slash_symbol">/</span>
                    </div>
                    <div class="col-md-4 col-xs-5">
                      <input #selectListExpiryYear (keyup)="onKeyupExpiryYear(selectListExpiryYear.value)"
                        class="form-control" formControlName="expiryYear" maxlength="2" name="year" numbersOnly
                        placeholder="YY" size="2" type="text" />
                    </div>
                  </div>
                  <span
                    *ngIf="(!Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.errors?.required) && (Credit_Form.controls.expiryMonth.touched || submitCardAttempt) || (!Credit_Form.controls.expiryYear.dirty && !Credit_Form.controls.expiryYear.valid) && (Credit_Form.controls.expiryYear.touched || submitCardAttempt)"
                    class="form-validate-error errormsg">
                    Expiration Date is required
                  </span>
                  <span
                    *ngIf="(Credit_Form.controls.expiryMonth.dirty && !Credit_Form.controls.expiryMonth.errors?.required && Credit_Form.controls.expiryMonth.touched && enterTwoDigitMonth)"
                    class="form-validate-error errormsg">
                    Please Use 'MM/YY' format
                  </span>
                  <span
                    *ngIf="((Credit_Form.controls.expiryMonth.dirty && !Credit_Form.controls.expiryMonth.errors?.required && Credit_Form.controls.expiryMonth.touched && enterValidMonth) || (Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && invalidMonthEnteredValidation) || (Credit_Form.controls.expiryYear.dirty && !enterValidYear)) && (!enterTwoDigitMonth) "
                    class="form-validate-error errormsg">
                    Please enter Valid Expiration Date
                  </span>
                </div>


              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <input (focusout)="oncvvfocusout()" (keypress)="numberOnly($event)" class="form-control"
                    formControlName="cvv" id="cvvvalue" maxlength="4" placeholder="CVV" type="text">
                  <div
                    *ngIf="!Credit_Form.controls.cvv.valid && (Credit_Form.controls.cvv.touched || submitCardAttempt)"
                    class="errormsg">
                    CVV is required
                  </div>
                  <div *ngIf="!!cvvErr" class="errormsg">{{ cvvErr }}</div>

                  <div class="errormsg" id="cvverror">
                  </div>
                </div>
              </div>
            </div>


          </form>
          <form *ngIf="base_form" [formGroup]="Banking_Form" class="payment-form">
            <div class="row">
              <div class="form-group">
                <input class="form-control" formControlName="name" placeholder="Banking Name" type="text">
                <div
                  *ngIf="!Banking_Form.controls.name.valid && (Banking_Form.controls.name.touched || submitCardAttempt)"
                  class="errormsg">
                  Banking Name is required
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input #routingNumber (keypress)="numberOnly($event)"
                    (keyup)=onKeyUpEnterRoutingNumber(routingNumber.value) class="form-control"
                    formControlName="routingNumber" placeholder="Routing Number" type="text">
                  <div
                    *ngIf="!Banking_Form.controls.routingNumber.valid && (Banking_Form.controls.routingNumber.touched || submitBankingAttempt)"
                    class="errormsg">
                    Routing Number is required
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input #selectListConfirmRoutingNumber (keypress)="numberOnly($event)"
                    (keyup)="onKeyUpRoutingNumberMismatch(selectListConfirmRoutingNumber.value)" class="form-control"
                    formControlName="reenterRouting" placeholder="Re-enter Routing Number" type="text">
                  <div
                    *ngIf="(!Banking_Form.controls.reenterRouting.dirty && !Banking_Form.controls.reenterRouting.valid) && (Banking_Form.controls.reenterRouting.touched || submitBankingAttempt)"
                    class="errormsg">
                    Re-enter Routing Number is required
                  </div>
                  <div *ngIf="Banking_Form.controls.reenterRouting.dirty && routingNumberMismatch" class="errormsg">
                    Routing Number and Re-enter Routing Number do not match
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input #accountNumber (keypress)="numberOnly($event)"
                    (keyup)=onKeyUpEnterAccountNumber(accountNumber.value) class="form-control"
                    formControlName="accountNumber" minlength="4" placeholder="Account Number" type="text">
                  <div
                    *ngIf="!Banking_Form.controls.accountNumber.valid && (Banking_Form.controls.accountNumber.touched || submitBankingAttempt)"
                    class="errormsg">
                    Account Number is required minimum length 4 digits
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input #selectListConfirmAccountNumber (keypress)="numberOnly($event)"
                    (keyup)="onKeyUpAccountNumberMismatch(selectListConfirmAccountNumber.value)" class="form-control"
                    formControlName="reenterAccount" minlength="4" placeholder="Re-enter Account Number" type="text">
                  <div
                    *ngIf="(!Banking_Form.controls.reenterAccount.dirty && !Banking_Form.controls.reenterAccount.valid) && (Banking_Form.controls.reenterAccount.touched || submitBankingAttempt)"
                    class="errormsg">
                    Re enter Account Number is required
                  </div>
                  <div *ngIf="Banking_Form.controls.reenterAccount.dirty && accountNumberMismatch" class="errormsg">
                    Account Number and Re-enter Account Number do not match
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex gap-5 mb-3">
              <label class="d-flex gap-2">
                <input formControlName="type" type="radio" value="C" />
                Checking
              </label>
              <label class="d-flex gap-2">
                <input formControlName="type" type="radio" value="S" />
                Saving
              </label>
            </div>

            <div
              *ngIf="!Banking_Form.controls.type.valid && (Banking_Form.controls.type.touched || submitBankingAttempt)"
              class="errormsg">
              Type is required
            </div>

          </form>
          <div class="payment-form">
            <form [formGroup]="shipping_address_form">
              <div class="row ">
                <small>Transactions are secure and encrypted.</small>
              </div>

              <div class="row mt-3">
                <h5>Billing Address</h5>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="shipping">
                    <input (change)="onCheckboxChange()" [(ngModel)]="isShippingChecked"
                      [formControl]="shippingAddressControl" type="checkbox">
                    <label for="">Use Shipping Address</label>
                  </div>
                  <div *ngIf="shippingAddressControl.value==false" class="col-md-12">
                    <div class="row">
                      <div class="col-md-8">
                        <form action="">
                          <div class="form-group">
                            <select class="form-control" style="margin-bottom: 15px;">
                              <option *ngFor="let item of country_arr">{{ item }}</option>
                            </select>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input class="form-control" formControlName="zip" maxlength="5" numbersOnly
                            placeholder="ZIP Code" type="text">
                          <div
                            *ngIf="!shipping_address_form.controls.zip.valid && (shipping_address_form.controls.zip.touched || submitCardAttempt)"
                            class="errormsg">
                            Zip Code is required
                          </div>
                          <span
                            *ngIf="shipping_address_form.controls['zip'].hasError('pattern') && (shipping_address_form.controls['zip'].dirty )"
                            class="errormsg">Please
                            enter valid 5 digit zip code</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input class="form-control" formControlName="address1" placeholder="Address 1" type="text">
                          <div
                            *ngIf="!shipping_address_form.controls.address1.valid && (shipping_address_form.controls.address1.touched || submitCardAttempt)"
                            class="errormsg">
                            Address is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input class="form-control" formControlName="address2" placeholder="Address 2" type="text">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input class="form-control" formControlName="city" placeholder="City" type="text">
                              <div
                                *ngIf="!shipping_address_form.controls.city.valid && (shipping_address_form.controls.city.touched || submitCardAttempt)"
                                class="errormsg">
                                City is required
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <select (change)="checkIfActiveState($event)" aria-label="Default select example"
                                class="form-control form-select" formControlName="state" id="state">
                                <option disabled value="">Select Your State</option>
                                <option *ngFor="let item of usaStates" [value]=item.name>
                                  {{ item.name }}
                                </option>
                              </select>
                              <div
                                *ngIf="!shipping_address_form.controls.state.valid && (shipping_address_form.controls.state.touched || submitCardAttempt)"
                                class="errormsg">
                                State is required
                              </div>
                              <!-- <div class="errormsg"
                                      *ngIf="!Billing_Form.controls.state_billing.valid && (Billing_Form.controls.state_billing.touched || submitPaymentAttempt)">
                                      State is required
                                  </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>

        <div></div>

        <div class="actions">
          <!-- <button class="btn btn-outline-primary">Previous</button> -->
          <button (click)="onPrevious()" class="btn btn-previous btn-outline-primary "
            *ngIf="template!= 'template_3'">Previous</button>
          <button (click)="Next()" class="btn btn-primary "
            [ngClass]="template == 'template_3' ? 'ml-auto' : ''">Next</button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <!--Agent Info Section-->
      <div class="card shadow-sm mb-3 product-card" *ngFor="let product of cart_products">
        <div class="row">
          <h6 class="title-mainhead text-nowrap">
            <span>Product Information</span>
          </h6>
          <div class="">
            <p class="text-nowrap"><b>Name:</b>&nbsp;<span class="text-nowrap"
                *ngIf="product?.product_name">{{ product?.product_name }}</span>
            </p>
            <p><span
                *ngIf="product?.total_amount"><b>Amount:</b>&nbsp;{{ product?.total_amount | currency : 'USD' }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mb-3 product-card" *ngIf="agentInfo?.id>0">
        <div class="row">
          <div>
            <h6 class="title-mainhead">
              <span>Agent Information</span>
            </h6>
            <div class="">
              <p class="text-nowrap"><b>Name:</b>&nbsp;<span class="text-nowrap"
                  *ngIf="agentInfo?.firstname">{{ agentInfo?.firstname }}</span>
                <span class="text-nowrap" *ngIf="agentInfo?.lastname">{{ agentInfo?.lastname }}</span></p>
              <p class="text-nowrap"><span *ngIf="agentInfo?.email"><b>Email:</b>&nbsp;{{ agentInfo?.email }}</span></p>
              <p class="text-nowrap"><span *ngIf="agentInfo?.mobile"><b>Phone:</b>&nbsp;{{ agentInfo?.mobile }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
<div *ngIf="loader" class="loader">
  <div id="loading"></div>
</div>
