import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class AddMemberConfirmationComponent implements OnInit {
  name: any;
  applicationNumber: string | null;
  successMessage: string;
  displyapplicationNumbar: boolean;

  constructor(
    private route: ActivatedRoute
  ) {
    this.name = localStorage.getItem('name');
    this.applicationNumber = this.route.snapshot.paramMap.get('id');
    if (this.applicationNumber == 'addgroup') {
      this.successMessage = 'Successfully enrolled group for';
    } else if (this.applicationNumber == 'applicant') {
      this.successMessage = 'Successfully enrolled application for';
    } else {
      this.successMessage = 'Member created successfully';
    }
  }

  ngOnInit(): void {
  }

}
