import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharingService} from '../../../services/sharing.service';

@Component({
    selector: 'app-d2c-confirmation',
    templateUrl: './d2c-confirmation.component.html',
    styleUrls: ['./d2c-confirmation.component.scss']
})

export class D2cConfirmationComponent implements OnInit {
    companyId: any;
    orderId: any;
    orderDetails: any[] = [];
    monthly_contribution: any;
    companyName: any;
    toasterTimeout: any;
    family_id: string;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    additionalMember: any;
    oneTimeFee: any;
    cartProducts: any[] = [];
    paymentData: any[] = [];
    members: any;
    loader = false;
    isRiderAvailable = false;
    agentInfo: any = null;
    agent_text: any;
  agent_id_from_url_param: any;
  hideAgentInfo: boolean = false;
  template: any;
  steps: { step: string; active: boolean; }[];

    constructor(private service: SharingService, private route: ActivatedRoute, private router: Router) {
        this.orderId = this.route.snapshot.paramMap.get('orderId');
        const isRider = localStorage.getItem('isRiderAvailable');
        if (!!isRider && isRider === 'yes') {
            this.isRiderAvailable = true;
        } else {
            this.isRiderAvailable = false;
        }
        const data = JSON.parse(localStorage.getItem('order_details'));
        if (data) {
            this.orderDetails = data?.order_details;
            this.paymentData = data?.paymentdata;
            this.cartFee = data?.cart_fee;
            this.cartData = data?.cart_data;
            this.memberDiscount = data?.agent_cart_discount;
            this.additionalMember = data?.additional_member_fee;
            this.oneTimeFee = data?.a2c_total_one_time_fee;
            this.cartProducts = data?.cart_products;
            this.monthly_contribution = data?.monthly_contribution;
            if (data?.agent_info) {
                this.agentInfo = data?.agent_info;
            }
        }

        this.route.queryParams.subscribe(params => {
          if (Object.keys(params).length !== 0 && params.hasOwnProperty('agent_id')) {
            this.agent_id_from_url_param = params['agent_id'];
            this.hideAgentInfo = true;
        }
        })
    }

    ngOnInit(): void {
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.companyId = data.company_id;
                this.agent_text = data.agent_text;
                this.template = data?.template;
                this.steps =  [
                  ...(this.template !== 'template_3' ? [{ step: 'Enrollment/Product Pricing', active: false }] : []),
                  { step: 'Payment Details', active: false },
                  { step: 'Terms & Condition', active: false },
                  { step: 'Summary', active: false },
                  { step: 'Confirmation', active: true }
                ];
            }
        });

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            localStorage.removeItem('agentInfo');
        }
    }

    backToEnrollment(): void {
      if(!!this.agent_id_from_url_param){
        if (!!localStorage.getItem('product_prices_id')) {
          this.router.navigate(['/package/DirectMemberEnrollment'], {queryParams:{product_prices_id:localStorage.getItem('product_prices_id') , agent_id:this.agent_id_from_url_param}});
        }
        else{
          this.router.navigate(['/package/DirectMemberEnrollment'], {queryParams:{agent_id:this.agent_id_from_url_param}});
        }
      }else{
        this.router.navigate(['/package/DirectMemberEnrollment']);
      }
    }
}
