<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    <div class="progress_bar">
        <ul [ngClass]="{'progessbar': A2Ccode, 'progess-bar': !A2Ccode}"
            class=" mb-7">

            <li *ngIf="!A2Ccode" [class.future]="futurePayment">Enrollment</li>
            <li *ngIf="!A2Ccode" [class.future]="futurePayment">Products</li>
            <li *ngIf="!A2Ccode" [class.future]="futurePayment">Payment Details</li>
            <li [class.future2]="A2Ccode" [class.future]="futurePayment">Beneficiary</li>
            <li [class.future2]="A2Ccode" [class.future]="futurePayment">Agreement</li>
            <li [class.future2]="A2Ccode" [class.future]="futurePayment">Disclosure 1</li>
            <li [class.future2]="A2Ccode" [class.future]="futurePayment">Disclosure 2</li>
            <li [class.future2]="A2Ccode" [class.future]="futurePayment" class="active">Summary</li>
            <li *ngIf="!futurePayment">Confirmation</li>
        </ul>
    </div>
</div>
<div class="container">
    <div *ngIf="!isSubmitted" class="row bg-white m-0 p-2 pt-3">
        <div class="col-md-8">
            <div *ngFor="let member of members"
                 class="mb-3">
                <div *ngIf="member?.is_active =='yes'" class="applicant card-body applicant-info">
                    <div class="applicant-header">
                        <div class="applicant-title row">
                            <div class="col-md-6">
                                <h6 class="card-subtitle">{{ member?.applicanttype }}</h6>
                                <h4 class="card-title">{{ member?.firstname  | titlecase }} {{ member?.lastname | titlecase }} </h4>
                            </div>
                            <div class="col-md-6 text-end">
                                <h6 class="card-subtitle mt-2">Policy
                                    Amount: {{ member?.policy_amount | currency:'USD': number:'1.0-0' }}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 info mt-2">
                        <div class="row m-0 p-0 mb-2">
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid">
                  <span class="text-muted">Gender
                  </span>
                                    <strong>{{ member?.gender }}</strong>
                                </div>
                            </div>
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid">
                                    <span class="text-muted justify-content-end">DOB </span>
                                    <strong>{{ member?.dob | date:dateFormat }} </strong>
                                </div>
                            </div>
                            <div *ngIf="member.ssn !=null"
                                 class="col-md-6 p-0">
                                <div class="summary-data-grid">
                                    <span class="text-muted">SSN </span>
                                    <strong>{{ member?.ssn }} </strong>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 p-0 mb-2">
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid">
                                    <span class="text-muted">Age </span>
                                    <strong>{{ member?.age }} </strong>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="member.phone !=null"
                             class="row m-0 p-0 mb-2">
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid">
                                    <span class="text-muted">Mobile Number </span>
                                    <strong>{{ member?.phone }} </strong>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="member.email !=null"
                             class="row m-0 p-0 mb-2">
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid">
                                    <span class="text-muted">Email address </span>
                                    <strong>{{ member?.email }} </strong>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="member.address1 !=null"
                             class="row m-0 p-0 mb-2">
                            <div class="col-md-6 p-0">
                                <div class="summary-data-grid flex-start">
                                    <span class="addres-td">USA Address </span>
                                    <span class="address-data">
                    <span><strong>{{ member?.address1 }},</strong> </span>
                    <span *ngIf="member?.address2"> <strong>{{ member.address2 }},</strong> </span>
                    <span><strong>{{ member.city }}, {{ member.state }}, {{ member.zip }}</strong> </span>
                  </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <h5 class="divider-title">
                Payment Details
            </h5>


            <div class="cart-info mb-3">
                <div class="applicant-header border-bottom-0 pb-0">
                    <div class="applicant-title">
                        <h6 class="card-subtitle mb-2">Payment Type : {{ payment_info?.payment_type }}</h6>
                        <h4 class="card-title">{{ payment_info?.payment_type }} Details</h4>
                    </div>
                </div>
                <div class="card-body pt-0">

                    <ng-container *ngIf="payment_info?.payment_type == 'CARD'">
                        <div class="row mb-2">
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Name </span>
                                <strong>{{ payment_info?.name | titlecase }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Card Number </span>
                                <strong>{{ payment_info?.card_number }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Expiry Date </span>
                                <strong>{{ payment_info?.mm }}/{{ payment_info?.yy }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Billing Date </span>
                                <strong>{{ payment_info?.payment_date | date:dateFormat }} </strong>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="payment_info?.payment_type == 'BANK'">
                        <div class="row mb-2 mt-2 p-2 ">
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Name</span>
                                <strong>{{ payment_info?.name  | titlecase }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Account Type </span>
                                <strong>{{ payment_info?.account_type == 'C' ? 'Checking' : (payment_info?.account_type == 'S' ? 'Saving' : '') }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Account Number </span>
                                <strong>{{ payment_info?.account_4 }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Routing Number </span>
                                <strong>{{ payment_info?.routing_number }} </strong>
                            </div>
                            <div class="summary-data-grid mb-1">
                                <span class="text-muted">Billing Date </span>
                                <strong>{{ payment_info?.payment_date | date:dateFormat }} </strong>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="healthQuestions.length" class="card shadow-sm mb-3">
                <div class="card-body">
                    <h6 class="title-mainhead">
                        <span>Employee Policy replacement questions <span class="fieldMan"> *</span></span>
                    </h6>
                    <div></div>

                    <div *ngFor="let list of healthQuestions;let i=index" class="row mb-3">
                        <div class="col-md-10">
                            <p class="health-qstn">Question  {{ i + 1 }}) {{ list.question }}</p>
                            <div class="row display mb-2">
                                <div *ngFor="let test_value of list.fields" class="col-md-4 form-group">
                          <span *ngIf="test_value.label !=undefined ">
                              <label class="">{{ test_value?.label }}:</label>
                              <p class="mb-0">{{ test_value?.value }}</p>
                          </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3 pl-4">
                                <span class="float-end">{{ list.answer }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="spouseHealthQuestions.length > 0" class="card shadow-sm mb-3">
                <div class="card-body">
                    <h6 class="title-mainhead">
                        <span>Spouse Policy replacement questions <span class="fieldMan"> *</span></span>
                    </h6>
                    <div></div>

                    <div *ngFor="let list of spouseHealthQuestions;let i=index" class="row mb-3">
                        <div class="col-md-10">
                            <p class="health-qstn">Question  {{ i + 1 }}) {{ list.question }}</p>
                            <div class="row display mb-2">
                                <div *ngFor="let test_value of list.fields" class="col-md-4 form-group">
                          <span *ngIf="test_value.label !=undefined ">
                              <label class="">{{ test_value?.label }}:</label>
                              <p class="mb-0">{{ test_value?.value }}</p>
                          </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3 pl-4">
                                <span class="float-end">{{ list.answer }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Agent Info Section-->
            <div *ngIf="agentInfo?.id>0" class="mt-4 mb-3">
                <h5>
                    Agent Information
                </h5>
            </div>

            <div *ngIf="agentInfo?.id>0" class="card cart-info">
                <div class="card-body pt-2">
                    <div *ngIf="agentInfo" class="info-box">
                        <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span> <span
                                *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                        <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                        <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-md-4">
            <div class="card cart-info mb-3">
                <div class="row m-0 p-0 border-btm">
                    <div class="info d-flex justify-content-between">
                        <span class="text-muted">Effective start date</span>
                        <span> <strong>{{ order_details?.start_date | date:dateFormat }} </strong> </span>
                    </div>
                </div>
                <div *ngIf="cart_fee.length" class="row m-0 p-0 border-btm">
                    <div *ngFor="let item of cart_fee" class="info d-flex justify-content-between">
                        <span class="text-muted">{{item.name | titlecase}}</span>
                        <span> {{ item.amount | currency: "USD" }} </span>
                    </div>
                </div>
                <div *ngFor="let item of cart_products"
                     class="row m-0 p-0">
                    <div *ngIf="item.relation=='Primary' || item.relation=='Spouse'"
                         class="info d-flex justify-content-between  border-btm">
                        <span><b> {{ item.firstname  | titlecase }}  {{ item.lastname | titlecase }} </b></span>
                        <span><b> {{ item.total_amount | currency: "USD" }} </b></span>
                    </div>

                </div>
                <div *ngIf="child_info?.length>0" class="row m-0 p-0 ">
                    <div class="info d-flex justify-content-between  border-btm">
                        <span><b> {{ child | titlecase }} </b></span>
                        <span><b> {{ child_total | currency: "USD" }} </b></span>
                    </div>
                </div>

                <!-- <div class="row m-0 p-0 border-btm">
                    <div class="info d-flex justify-content-between">
                      <span class="text-muted">Product Price</span>
                      <span> {{product_price | currency: "USD" }} </span>
                    </div>
                  </div> -->

                <div class="row m-0 p-0 border-btm">
                    <div class="info d-flex justify-content-between">
                        <span class="text-muted">Total Payment Today</span>
                        <span> {{ total_payment_today | currency: "USD" }} </span>
                    </div>
                </div>
                <div class="row m-0 p-0 border-btm">
                    <div class="info d-flex justify-content-between">
                        <span class="text-muted">Monthly Recurring Payment </span>
                        <span> <strong>{{ monthly_contribution | currency: "USD" }} </strong> </span>
                    </div>
                </div>
                <div class="row m-0 p-0 border-btm">
                    <div class="info d-flex justify-content-between">
                        <span class="text-muted">Recurring Billing Date: {{ order_details?.recurring_billing_date | date:dateFormat }}</span>
                    </div>
                </div>


            </div>
            <div *ngIf="display_div" class="row">
                <div class="col-md-12 mb-2 knowledge_text">
                    <input (change)="knowledgement_check($event.target.checked)" class="" type="checkbox">
                    I certify that to the best of my knowledge and belief the information on this form is complete,
                    accurate and correctly recorded.
                </div>
            </div>
            <div *ngIf="!isSubmitted" class="d-flex justify-content-between">
                <button (click)="onPrevious()"
                        class="btn btn-outline-primary ">Previous
                </button>
                <button (click)="submit()" [disabled]="knowledgement"
                        class="btn btn-primary">Submit Application
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="isSubmitted" class="row bg-white m-0 p-2 pt-3">
        <div class="col-md-12 text-center">
            <div class="confirmation">
                <h4>Thank You For Your Application</h4>
                <h5>{{ paymentMessage }}</h5>

                <button (click)="backToEnrollment()" class="btn btn-primary mt-5">New Enrollment</button>
            </div>
        </div>
    </div>


    <div *ngIf="loader"
         class="loader">
        <div id="loading"></div>
    </div>
</div>
