import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TestService} from '../../../services/behaviourService';
import {SharingService} from '../../../services/sharing.service';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from '../../../services/session.service';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
    applicant: any;
    cartProducts: any;
    memberdata: any;
    check: boolean = true;
    selectedLink: string = "credit_card";
    checked: boolean = true;
    Banking_Form: UntypedFormGroup;
    Billing_Form: UntypedFormGroup;
    Credit_Form: UntypedFormGroup;
    Shipping_Form: UntypedFormGroup;
    submitAttempt: boolean = false;
    Terms_Form: UntypedFormGroup;
    submitTermsAttempt: boolean = false;
    submitPaymentAttempt: boolean = false;
    loadedProductDetails: any = "";
    showreviewblock: boolean = true;
    showShippingAddress: boolean = false;
    country_arr = ['UnitedStates'];
    checkedCondition: boolean = true;
    cvvLength: any;
    failureMessage: boolean = false;
    failuretxt: any = "";
    stripeTest: UntypedFormGroup;
    banking_value: any;
    billing_value: any;
    submitBankingAttempt: boolean;
    submitCardAttempt: boolean;
    txtvalue: any;
    credit_value: any;
    cardType: string;
    notValidCardNumber: boolean;
    checkCardValid: boolean;
    expiryYear: any = "";
    enterValidYear: boolean;
    enterValidMonth: boolean;
    routingNumberMismatch: boolean = false;
    routingNumberField: any = "";
    accountNumberMismatch: boolean = false;
    accountNumberField: any = "";
    total;
    value_quantity: any;
    quantityValueChanged: boolean = false;
    subtotalValue: any;
    disablePurchaseButton: boolean;
    cardNumberMasked: any;
    routingNumberMasked: any;
    accountNumberMasked: any;
    typeOfPayment: string;
    currentMonth: number;
    enteredMonth: any;
    invalidMonthEnteredValidation: boolean;
    paymentType: string = "Checking";
    typeOfCard: string = "CreditCard";
    enteredRoutingNumber: any;
    reEnteredRoutingNumber: any;
    enteredAccountNumber: any;
    reEnteredAccountNumber: any;
    accountType: string;
    paymentCardType: string;
    enterTwoDigitMonth: boolean;
    payment_type: any;
    cart_id: any;
    states: any;
    paymentDetails: any;
    accountDe: any;
    zipcode: any;
    test: boolean = true
    address1: any;
    address2: any;
    city: any;
    state: any;
    card: any;
    expdate: any;
    year: any;
    month: any;
    routing_number: any;
    account_number: any;
    startdate: any;
    entryzipcode: any;
    entryZipcode: any;
    entryStartdate: any;
    order_id: any;
    account_type: any;
    paymentType1: string;
    card_type: any;
    value: any;
    companyId: any;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    bmiinfo: any;
    additionalMember: any;
    companyInfo: any;
    oneTimeFee: any;
    cmpId: any;
    memId: any;
    cartId: any;
    application_id: any;
    program_id: any;
    dynamic_paymentmethod: any;
    base_form: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private objectService: TestService,
        private services: SharingService,
        private toastr: ToastrService,
        private sessionService: SessionService
    ) {
        this.services.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.dynamic_paymentmethod = this.companyInfo.payment_methods;
                this.typeOfCard = this.dynamic_paymentmethod[0]?.name;
                // this.companyName = this.companyInfo.company_name;
            }
        })
        this.cmpId = this.sessionService.getData("company");
        this.memId = this.sessionService.getData("member_id");
        this.cartId = this.sessionService.getData("cart_id");
        var url = 'GetMemberDependents/' + this.cmpId + '/' + this.memId + '/' + this.cartId;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            this.applicant = Object;
            if (Object.application_id) {
                this.getPayment()
            }
            this.application_id = Object.application_id
            this.cartFee = this.applicant.cart_fee;
            this.cartData = this.applicant.cart_data;
            this.memberDiscount = this.applicant.agent_cart_discount;
            this.bmiinfo = this.applicant.cart_bmi;
            this.additionalMember = this.applicant.additional_member_fee
            this.oneTimeFee = this.applicant.total_one_time_fee;
            if (this.applicant.order_details == undefined) {
                this.entryZipcode = this.applicant.zipcode,
                    this.entryStartdate = this.applicant.startdate
            } else {
                this.entryZipcode = this.applicant.order_details[0].zipcode,
                    this.entryStartdate = this.applicant.order_details[0].startdate,
                    this.order_id = this.applicant.order_details[0].order_id
                if (this.applicant != null || this.applicant != undefined) {
                    this.entryZipcode = this.applicant.zipcode,
                        this.entryStartdate = this.applicant.startdate
                }
            }
            this.cartProducts = this.applicant.cart_products;
            this.memberdata = this.applicant.member_personal_data;
            let memberName = (this.memberdata[0].middlename != null) ? this.memberdata[0].firstname + ' ' + this.memberdata[0].middlename + ' ' + this.memberdata[0].lastname : this.memberdata[0].firstname + ' ' + this.memberdata[0].lastname;
            this.Credit_Form.controls.name.setValue(memberName)
            this.Banking_Form.controls.name.setValue(memberName)
            this.paymentDetails = this.applicant.paymentdata
            if (this.paymentDetails !== undefined) {
                this.submitTermsAttempt = true;
                //  this.showShippingAddress = true;
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.routing_number !== undefined) {
                    this.submitTermsAttempt = true;
                    if (this.applicant.order_details[0].billing_zip != this.applicant.order_details[0].shipping_zip) {
                        this.showShippingAddress = true;
                        this.checkedCondition = false;
                    } else {
                        this.showShippingAddress = false;
                        this.checkedCondition = true;
                    }
                    this.routing_number = this.applicant.paymentdata.routing_number;
                    this.account_number = this.applicant.paymentdata.account_number;
                    this.account_type = this.applicant.paymentdata.account_type;
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                    if (this.account_type == "PS") {
                        this.selectedLink = "bank";
                        this.paymentType = "Savings"
                        this.typeOfCard = 'Bank'
                    } else {
                        this.paymentType = "Checking"
                        this.selectedLink = "bank";
                        this.typeOfCard = 'Bank'
                    }
                }
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.card_number !== undefined) {
                    this.submitTermsAttempt = true;
                    if (this.applicant.order_details[0].billing_zip != this.applicant.order_details[0].shipping_zip) {
                        this.showShippingAddress = true;
                        this.checkedCondition = false;
                    } else {
                        this.showShippingAddress = false;
                        this.checkedCondition = true;
                    }
                    this.card = this.applicant.paymentdata.card_number;
                    this.payment_type = this.applicant.paymentdata.payment_type;
                    if (this.payment_type == "CARD") {
                        this.card_type = this.applicant.paymentdata.card_type;
                        if (this.card_type == "C") {
                            this.selectedLink = "credit_card";
                        }
                        if (this.card_type == "D") {
                            this.selectedLink = "debit_card";
                            this.typeOfCard = "Debit Card";
                        }
                    }
                    this.expdate = this.applicant.paymentdata.exp_date;
                    const dateString = this.expdate.toString();
                    this.year = dateString.substring(dateString.length - 2);
                    this.month = dateString.substring(0, 2);
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                }
            }
        }, err => {
            console.log(err)
        })
        this.objectService.demographyObject.subscribe(result => {
            this.applicant = result;
            this.cartFee = this.applicant.cart_fee;
            this.cartData = this.applicant.cart_data;
            this.memberDiscount = this.applicant.agent_cart_discount;
            this.bmiinfo = this.applicant.cart_bmi;
            this.additionalMember = this.applicant.additional_member_fee
            this.oneTimeFee = this.applicant.total_one_time_fee;
            if (this.applicant.order_details == undefined) {
                this.entryZipcode = this.applicant.zipcode,
                    this.entryStartdate = this.applicant.startdate
            } else {
                this.entryZipcode = this.applicant.order_details[0].zipcode,
                    this.entryStartdate = this.applicant.order_details[0].startdate,
                    this.order_id = this.applicant.order_details[0].order_id
                if (this.applicant != null || this.applicant != undefined) {
                    this.entryZipcode = this.applicant.zipcode,
                        this.entryStartdate = this.applicant.startdate
                }
            }
            this.cartProducts = this.applicant.cart_products;
            this.memberdata = this.applicant.member_personal_data;

            this.paymentDetails = this.applicant.paymentdata
            if (this.paymentDetails !== undefined) {
                this.submitTermsAttempt = true;
                //  this.showShippingAddress = true;
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.routing_number !== undefined) {
                    this.submitTermsAttempt = true;
                    if (this.applicant.order_details[0].billing_zip != this.applicant.order_details[0].shipping_zip) {
                        this.showShippingAddress = true;
                        this.checkedCondition = false;
                    } else {
                        this.showShippingAddress = false;
                        this.checkedCondition = true;
                    }
                    this.routing_number = this.applicant.paymentdata.routing_number;
                    this.account_number = this.applicant.paymentdata.account_number;
                    this.account_type = this.applicant.paymentdata.account_type;
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                    if (this.account_type == "PS") {
                        this.selectedLink = "bank";
                        this.paymentType = "Savings"
                    } else {
                        this.paymentType = "Checking"
                        this.selectedLink = "bank";
                    }
                }
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.card_number !== undefined) {
                    this.submitTermsAttempt = true;
                    if (this.applicant.order_details[0].billing_zip != this.applicant.order_details[0].shipping_zip) {
                        this.showShippingAddress = true;
                        this.checkedCondition = false;
                    } else {
                        this.showShippingAddress = false;
                        this.checkedCondition = true;
                    }
                    this.card = this.applicant.paymentdata.card_number;
                    this.payment_type = this.applicant.paymentdata.payment_type;
                    if (this.payment_type == "CARD") {
                        this.card_type = this.applicant.paymentdata.card_type;
                        if (this.card_type == "C") {
                            this.selectedLink = "credit_card";
                        }
                        if (this.card_type == "D") {
                            this.selectedLink = "credit_card";
                            this.typeOfCard = "Debit Card";
                        }
                    }
                    this.expdate = this.applicant.paymentdata.exp_date;
                    const dateString = this.expdate.toString();
                    this.year = dateString.substring(dateString.length - 2);
                    this.month = dateString.substring(0, 2);
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                }
            }
        })
    }

    ngOnInit(): void {
        this.cardType = this.objectService.getCardType();
        this.checkCardValid = this.objectService.getcardCheckCondition();
        this.getState();

        this.Banking_Form = this.formBuilder.group({
            routingNumber: [this.routing_number, Validators.required],
            reenterRouting: [this.routing_number, Validators.required],
            accountNumber: [this.account_number, Validators.required],
            reenterAccount: [this.account_number, Validators.required],
            name: ['', Validators.required]
        }),
            this.Billing_Form = this.formBuilder.group({
                country_billing: ['UnitedStates'],
                zipCode_billing: [this.zipcode, [Validators.required, Validators.pattern('[0-9]{5}'), Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]],
                address1_billing: [this.address1, Validators.required],
                address2_billing: [this.address2,],
                city_billing: [this.city, Validators.required],
                state_billing: [this.state, Validators.required]
            }),
            this.Credit_Form = this.formBuilder.group({
                cardNumber: [this.card, Validators.required,],
                expiryMonth: [this.month, Validators.required],
                expiryYear: [this.year, Validators.required],
                cvv: ['', Validators.required],
                name: ['', Validators.required]
            })

        if (this.memberdata != undefined) {
            let memberName = (this.memberdata[0].middlename != null) ? this.memberdata[0].firstname + ' ' + this.memberdata[0].middlename + ' ' + this.memberdata[0].lastname : this.memberdata[0].firstname + ' ' + this.memberdata[0].lastname;
            this.Credit_Form.controls.name.setValue(memberName)

            this.Banking_Form.controls.name.setValue(memberName)
        }

        this.Credit_Form.get("cardNumber").valueChanges.subscribe(value => {
            if (value != '') {
                this.onKeyup(value);
            }
        });
    }

    async getPayment() {
        var url = 'GetPayment/' + this.cmpId + '/' + this.memId;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            this.applicant = Object;
            this.paymentDetails = this.applicant.paymentdata
            if (this.paymentDetails != undefined) {
                this.submitTermsAttempt = true;


                this.Credit_Form.controls.name.setValue(this.paymentDetails.name_on_card)
                //  this.Billing_Form.controls.name.setValue(this.paymentDetails.name_on_card)

            }
            if (!!this.applicant.cart_products && this.applicant.cart_products?.length > 0) {
                this.program_id = this.applicant.cart_products[0]?.program_id;
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.routing_number !== undefined) {
                    this.submitTermsAttempt = true;
                    this.Banking_Form.controls.routingNumber.setValue(this.applicant.paymentdata.routing_number)
                    this.Banking_Form.controls.accountNumber.setValue(this.applicant.paymentdata.account_number)
                    this.routing_number = this.applicant.paymentdata.routing_number;
                    this.account_number = this.applicant.paymentdata.account_number;
                    this.account_type = this.applicant.paymentdata.account_type;
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                    if (this.account_type == "S") {
                        this.selectedLink = "bank";
                        this.paymentType = "Savings";
                        this.typeOfCard = "Bank";
                        this.base_form = true
                    } else {
                        this.paymentType = "Checking"
                        this.selectedLink = "bank";
                        this.typeOfCard = "Bank";
                        this.base_form = true
                    }
                }
            }
            if (this.applicant.paymentdata != null || this.applicant.paymentdata != undefined) {
                if (this.applicant.paymentdata.card_number !== undefined) {
                    this.submitTermsAttempt = true;
                    this.Credit_Form.controls.cardNumber.setValue(this.applicant.paymentdata.card_number)
                    this.card = this.applicant.paymentdata.card_number;
                    this.payment_type = this.applicant.paymentdata.payment_type;
                    this.Credit_Form.controls.name.setValue(this.applicant.paymentdata.name_on_card)
                    //   this.Billing_Form.controls.name.setValue(this.applicant.paymentdata.name_on_card)
                    if (this.payment_type == "CARD") {
                        this.card_type = this.applicant.paymentdata.card_type;
                        if (this.card_type == "C") {
                            this.selectedLink = "credit_card";
                        }
                        if (this.card_type == "D") {
                            this.selectedLink = "credit_card";
                            this.typeOfCard = "Debit Card";
                        }
                    }
                    this.expdate = this.applicant.paymentdata.exp_date;
                    const dateString = this.expdate.toString();
                    this.year = dateString.substring(dateString.length - 2);
                    this.month = dateString.substring(0, 2);
                    this.Credit_Form.controls.expiryMonth.setValue(this.month)
                    this.Credit_Form.controls.expiryYear.setValue(this.year)
                    this.zipcode = this.applicant.order_details[0].billing_zip;
                    this.address1 = this.applicant.order_details[0].billing_address1;
                    this.address2 = this.applicant.order_details[0].billing_address2;
                    this.city = this.applicant.order_details[0].billing_city;
                    this.state = this.applicant.order_details[0].billing_state;
                }
            }
        }, err => {
            console.log(err)
        })
    }

    getState() {
        var url = 'ListSalableUsaStates/'+this.companyId;
        this.services.getDataFromUrl(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.states = Object.data;
            }
        })
    }

    setradio(e: string): void {
        if (e == 'bank') {
            this.base_form = true;
        } else {
            this.base_form = false;
        }
        this.selectedLink = e;
    }

    onHandleChange(e) {
        this.paymentType = e;
    }

    changeCard(e) {
        this.typeOfCard = e;
    }

    isSelected(name: string): boolean {
        if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown
            return false;
        }
        return (this.selectedLink === name); // if current radio button is selected, return true, else return false
    }

    // <----------------------End of method when clicked on purchase button in 5th block---------------------->
    handleChange(e) {
        //   e.target.checked=false;
        this.checkedCondition = !this.checkedCondition;
        var eve = e.target.checked;
        if (eve == false) {
            this.showShippingAddress = true;
        } else {
            this.showShippingAddress = false;
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onKeyUpEnterRoutingNumber(value) {
        this.enteredRoutingNumber = value;
        if (!this.routingNumberMismatch) {
            if (this.enteredRoutingNumber != this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = true;
            } else {
                this.routingNumberMismatch = false;
            }
        }
        if (this.routingNumberMismatch) {
            if (this.enteredRoutingNumber == this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpRoutingNumberMismatch(value) {
        this.reEnteredRoutingNumber = value;
        if (this.enteredRoutingNumber == value) {
            this.routingNumberMismatch = false;
        } else {
            this.routingNumberMismatch = true;
        }
        if (this.routing_number) {
            if (this.routing_number == value || this.enteredRoutingNumber == value) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpEnterAccountNumber(value) {
        this.enteredAccountNumber = value;
        if (!this.accountNumberMismatch) {
            if (this.enteredAccountNumber != this.reEnteredAccountNumber) {
                this.accountNumberMismatch = true;
            } else {
                this.accountNumberMismatch = false;
            }
        }
        if (this.accountNumberMismatch) {
            if (this.enteredAccountNumber == this.reEnteredAccountNumber) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    onKeyUpAccountNumberMismatch(value) {
        this.reEnteredAccountNumber = value;
        if (this.enteredAccountNumber == value) {
            this.accountNumberMismatch = false;
        } else {
            this.accountNumberMismatch = true;
        }
        if (this.account_number) {
            if (this.account_number == value || this.enteredAccountNumber == value) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    removeSpaces(event: ClipboardEvent) {
        event.preventDefault();
        const pastedText = event.clipboardData?.getData('text/plain').replace(/\s/g, '');
        document.execCommand('insertText', false, pastedText);
    }

    onKeyup(item) {
        let ccnum = item.replace(/\s/g, "");
        // this.checkCardValid=false;
        var ccCheckRegExp = /[^\d\s-]/;
        var isValid = !ccCheckRegExp.test(ccnum);
        var i;
        if (isValid) {
            var cardNumbersOnly = ccnum.replace(/[\s-]/g, "");
            var cardNumberLength = cardNumbersOnly.length;
            var arrCheckTypes = ['visa', 'mastercard', 'amex', 'discover', 'dinners', 'jcb'];
            for (i = 0; i < arrCheckTypes.length; i++) {
                var lengthIsValid = false;
                var prefixIsValid = false;
                var prefixRegExp;
                switch (arrCheckTypes[i]) {
                    case "mastercard":
                        lengthIsValid = (cardNumberLength === 16);
                        prefixRegExp = /5[1-5][0-9]|(2(?:2[2-9][^0]|2[3-9]|[3-6]|22[1-9]|7[0-1]|72[0]))\d*/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "visa":
                        lengthIsValid = (cardNumberLength === 16 || cardNumberLength === 13);
                        prefixRegExp = /^4/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "amex":
                        lengthIsValid = (cardNumberLength === 15);
                        prefixRegExp = /^3([47])/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "discover":
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(6011|5)/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "dinners":
                        lengthIsValid = (cardNumberLength === 14);
                        prefixRegExp = /^(300|301|302|303|304|305|36|38)/;
                        break;

                    case "jcb":
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(2131|1800|35)/;
                        break;

                    default:
                        prefixRegExp = /^$/;
                }
                prefixIsValid = prefixRegExp.test(cardNumbersOnly);
                isValid = prefixIsValid && lengthIsValid;
                // Check if we found a correct one
                if (isValid) {
                    this.notValidCardNumber = false;
                    break;
                }
            }
        }

        if (!isValid) {
            this.notValidCardNumber = true;
            return false;
        }

        // Remove all dashes for the checksum checks to eliminate negative numbers
        ccnum = ccnum.replace(/[\s-]/g, "");
        // Checksum ("Mod 10")
        // Add even digits in even length strings or odd digits in odd length strings.
        var checksum = 0;
        for (i = (2 - (ccnum.length % 2)); i <= ccnum.length; i += 2) {
            checksum += parseInt(ccnum.charAt(i - 1));
        }
        // Analyze odd digits in even length strings or even digits in odd length strings.
        for (i = (ccnum.length % 2) + 1; i < ccnum.length; i += 2) {
            var digit = parseInt(ccnum.charAt(i - 1)) * 2;
            if (digit < 10) {
                checksum += digit;
            } else {
                checksum += (digit - 9);
            }
        }
        if ((checksum % 10) === 0) {
            this.checkCardValid = true;
        }
        return (checksum % 10) === 0;
    }

    oncvvfocusout() {
        if (this.cardType != '') {
            if (this.cardType == "visa" || this.cardType == "mastercard") {
                // this.cvvLength = 3;
                this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
                if (this.value.length > 3) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid 3 digit cvv number";
                }
                if (this.value.length <= 3) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
                }
                this.objectService.pushcardType(this.cardType)
                this.objectService.pushcardCheckCondition(this.checkCardValid)
            } else if (this.cardType == "amex") {
                this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
                if (this.value.length > 4) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid cvv number";
                }
                if (this.value.length <= 4) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
                }
                this.objectService.pushcardType(this.cardType)
                this.objectService.pushcardCheckCondition(this.checkCardValid)
            }
        }
    }

    onKeyupExpiryMonth(input) {
        if (input.length == 1) {
            this.enterTwoDigitMonth = true;
        }
        if (input.length == 2) {
            this.enterTwoDigitMonth = false;
        }
        this.enterValidMonth = false;
        //   let enteredYear = this.cartService.getYear();
        // if(input.length == 2  )
        // {
        var ccCheckRegExp = /^(0?[1-9]|1[012])$/;
        var isValid = ccCheckRegExp.test(input);
        this.enteredMonth = input;
        var currentDate = new Date();
        var cuurentMonth = currentDate.getMonth() + 1;
        var f = currentDate.getFullYear();
        const dateString = f.toString();
        const currentYear = dateString.substring(dateString.length - 2);
        if (!isValid) {
            this.enterValidMonth = true;
        }
    }

    onKeyupExpiryYear(input) {
        this.enterValidYear = false;
        var currentDate = new Date(); //this returns the current datetime
        var f = currentDate.getFullYear();
        const dateString = f.toString();
        const year = dateString.substring(dateString.length - 2);
        //this.cartService.pushYear(input);
        if (input >= year) {
            this.enterValidYear = true;
        }
        if (input == year) {
            var m = currentDate.getMonth() + 1;
            if (this.enteredMonth >= m) {
                this.invalidMonthEnteredValidation = false;
            } else {
                this.invalidMonthEnteredValidation = true;
            }
        }
        if (input > year) {
            this.invalidMonthEnteredValidation = false;
        }
    }

    onKeyUpQuantity(input) {
        // this.quantityValueChanged=true;
        this.value_quantity = input;
        this.subtotalValue = this.loadedProductDetails[0].price * this.value_quantity;
    }

    // <-------------------This method is called when clicked continue in payment block----------->
    onPayment() {
        if (this.selectedLink == 'bank') {
            this.submitBankingAttempt = true;
            if (this.checkedCondition == false) {
                this.submitPaymentAttempt = true;
                if (this.Billing_Form.valid && this.Banking_Form && (!this.routingNumberMismatch && !this.accountNumberMismatch)) {
                    this.banking_value = this.Banking_Form.value;
                    this.billing_value = this.Billing_Form.value;
                    this.routingNumberMasked = this.banking_value.routingNumber;
                    this.routingNumberMasked = this.routingNumberMasked.replace(/\d(?=\d{4})/g, "*");
                    this.accountNumberMasked = this.banking_value.accountNumber;
                    this.accountNumberMasked = this.accountNumberMasked.replace(/\d(?=\d{4})/g, "*");
                    this.payment();
                }
            } else if (this.checkedCondition == true && this.Banking_Form.valid && (!this.routingNumberMismatch && !this.accountNumberMismatch)) {
                this.banking_value = this.Banking_Form.value;
                this.routingNumberMasked = this.banking_value.routingNumber;
                this.routingNumberMasked = this.routingNumberMasked.replace(/\d(?=\d{4})/g, "*");
                this.accountNumberMasked = this.banking_value.accountNumber;
                this.accountNumberMasked = this.accountNumberMasked.replace(/\d(?=\d{4})/g, "*");
                this.payment();
            }
        } else {//  if (this.selectedLink =="debit_card" || "credit_card")
            let cvverror = (<HTMLInputElement>document.getElementById("cvverror")).innerHTML
            this.submitCardAttempt = true;
            if (this.checkedCondition == false) {
                this.submitPaymentAttempt = true;
                if ((this.Billing_Form.valid && this.Credit_Form) && (!this.notValidCardNumber && this.checkCardValid) && (!this.enterValidMonth && !this.invalidMonthEnteredValidation) && !this.enterTwoDigitMonth
                    && cvverror == "") {
                    this.credit_value = this.Credit_Form.value;
                    this.cardNumberMasked = this.credit_value.cardNumber;
                    this.cardNumberMasked = this.cardNumberMasked.replace(/\d(?=\d{4})/g, "*");
                    this.billing_value = this.Billing_Form.value;
                    this.payment();
                }
            } else if (this.checkedCondition == true && this.Credit_Form.valid && (!this.notValidCardNumber && this.checkCardValid) && (!this.enterValidMonth && !this.invalidMonthEnteredValidation) && !this.enterTwoDigitMonth
                && cvverror == "") {
                this.credit_value = this.Credit_Form.value;
                this.credit_value.cardNumber;
                this.cardNumberMasked = this.credit_value.cardNumber;
                this.cardNumberMasked = this.cardNumberMasked.replace(/\d(?=\d{4})/g, "*");
                this.payment();
            }
        }
    }

    payment() {
        if (this.selectedLink == ("bank")) {//this condition if for bank payments
            if (this.selectedLink == ("bank")) {
                this.typeOfPayment = "BANK";
                if (this.paymentType == "Checking") {
                    this.accountType = 'C';
                } else if (this.paymentType == "Savings") {
                    this.accountType = 'S'
                }
            }
            if (this.checkedCondition == true) {
                var url = 'CreatePayment';
                var meberId = this.applicant.member_personal_data[0].member_id
                let body = {
                    member_id: meberId,
                    cart_id: this.applicant.cart_data[0].id,
                    order_id: this.order_id,
                    zipcode: this.entryZipcode,
                    startdate: this.entryStartdate,
                    payment_option: this.typeOfPayment,
                    account_type: this.accountType,
                    billing_as_shipping: "Y",
                    name_on_account: this.banking_value.name,
                    routing_number: this.banking_value.routingNumber,
                    account_number: this.banking_value.accountNumber,
                }
                this.services.postData(url, body).subscribe((Object: any) => {
                    if (Object.status == true) {
                        var paymentDetails = Object;
                        this.objectService.paymentMethod(paymentDetails);
                        this.router.navigate(['summary']);
                    }
                }, err => {
                    console.log(err);
                    if (err.error.status = "failure") {
                        this.toastr.error(err.error.message);
                    } else {
                        this.toastr.error(err.error.message);
                    }
                })
            } else if (this.checkedCondition == false) {
                this.submitPaymentAttempt = true
                var url = 'CreatePayment';
                var meberId = this.applicant.member_personal_data[0].member_id
                let body = {
                    member_id: meberId,
                    cart_id: this.applicant.cart_data[0].id,
                    order_id: this.order_id,
                    zipcode: this.entryZipcode,
                    startdate: this.entryStartdate,
                    payment_option: this.typeOfPayment,
                    account_type: this.accountType,
                    billing_as_shipping: "N",
                    name_on_account: this.banking_value.name,
                    routing_number: this.banking_value.routingNumber,
                    account_number: this.banking_value.accountNumber,
                    billing_address1: this.billing_value.address1_billing,
                    billing_address2: this.billing_value.address2_billing,
                    billing_city: this.billing_value.city_billing,
                    billing_state: this.billing_value.state_billing,
                    billing_zip: this.billing_value.zipCode_billing,
                }
                this.services.postData(url, body).subscribe((Object: any) => {
                    if (Object.status == true) {
                        var paymentDetails = Object;
                        this.objectService.paymentMethod(paymentDetails);
                        this.router.navigate(['summary']);
                    }
                }, err => {
                    console.log(err);
                    if (err.error.status = "failure") {
                        this.toastr.error(err.error.message);
                    } else {
                        this.toastr.error(err.error.message);
                    }
                })
            }
        } else {
            if (this.selectedLink == "debit_card" || "credit_card") {
                this.paymentCardType = this.typeOfCard.charAt(0)
                this.typeOfPayment = "CARD";
                // if (this.typeOfCard == "CreditCard") {
                //   this.paymentCardType = 'C';
                // }
                // else if (this.typeOfCard == "DebitCard") {
                //   this.paymentCardType = 'D';
                // }
            }
            if (this.checkedCondition == true) {// this condition is for card payments and only when billing address checkbox is selected for same as shipping address
                let expiry = this.credit_value.expiryMonth + this.credit_value.expiryYear;
                var url = 'CreatePayment';
                this.submitPaymentAttempt = true;
                var meberId = this.applicant.member_personal_data[0].member_id
                let body = {
                    member_id: meberId,
                    zipcode: this.entryZipcode,
                    startdate: this.entryStartdate,
                    cart_id: this.applicant.cart_data[0].id,
                    order_id: this.order_id,
                    payment_option: this.typeOfPayment,
                    card_type: this.paymentCardType,
                    card_number: this.credit_value.cardNumber,
                    exp_date: expiry,
                    cvv: this.credit_value.cvv,
                    billing_as_shipping: "Y",
                    name_on_card: this.credit_value.name
                }
                this.services.postData(url, body).subscribe((Object: any) => {

                    if (Object.status == true) {
                        var paymentDetails = Object;
                        this.objectService.paymentMethod(paymentDetails);
                        this.router.navigate(['summary']);
                    }
                }, err => {
                    console.log(err);
                    if (err.error.status = "failure") {
                        this.toastr.error(err.error.name_on_card[0]);
                    } else {
                        this.toastr.error(err.error.message);
                    }
                })
            } else if (this.checkedCondition == false) {
                this.submitPaymentAttempt = true
                let expiry = this.credit_value.expiryMonth + this.credit_value.expiryYear;
                var url = 'CreatePayment';
                var meberId = this.applicant.member_personal_data[0].member_id
                let body = {
                    member_id: meberId,
                    cart_id: this.applicant.cart_data[0].id,
                    order_id: this.order_id,
                    zipcode: this.entryZipcode,
                    startdate: this.entryStartdate,
                    payment_option: this.typeOfPayment,
                    card_type: this.paymentCardType,
                    card_number: this.credit_value.cardNumber,
                    exp_date: expiry,
                    cvv: this.credit_value.cvv,
                    billing_as_shipping: "N",
                    name_on_card: this.credit_value.name,
                    billing_address1: this.billing_value.address1_billing,
                    billing_address2: this.billing_value.address2_billing,
                    billing_city: this.billing_value.city_billing,
                    billing_state: this.billing_value.state_billing,
                    billing_zip: this.billing_value.zipCode_billing,
                }
                this.services.postData(url, body).subscribe((Object: any) => {
                    if (Object.status == true) {
                        var paymentDetails = Object;
                        this.objectService.paymentMethod(paymentDetails);
                        this.router.navigate(['summary']);
                    }
                }, err => {
                    console.log(err);
                    if (err.error.status = "failure") {
                        this.toastr.error(err.error.message);
                    } else {
                        this.toastr.error(err.error.message);
                    }
                })
            }
        }
    }

    backtoQutions() {
        var url = 'GetMemberHealthIssue/' + this.companyId + '/' + this.memberdata[0].member_id;
        localStorage.setItem('healthQUestions', 'health_questions')
        this.sessionService.setData('healthQUestions', 'health_questions');
        this.services.getData(url).subscribe((obj: any) => {
            if (obj.status == true) {
                this.objectService.questionsback(obj);
                this.sessionService.setData('questionList', obj);
                this.router.navigate(['health-basedquestions'], {queryParams: {program_id: this.program_id}});
            } else {
                this.router.navigate(['health-basedquestions'], {queryParams: {program_id: this.program_id}});
            }
        }, err => {
            console.log(err);
        })
    }

}
