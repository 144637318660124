<div class="container m-top" style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep " id="app2" style="z-index:6; padding-left: 30px;">
            <span>Demographics &amp; Dependents</span>
        </div>
        <div class="appProgStep " id="app1" style="z-index:5; padding-left: 40px;"><span>Member Agreement</span>
        </div>
        <div class="appProgStep active" id="app3" style="z-index:3;"><span>Health-Based Questions</span></div>
        <div class="appProgStep" id="app4" style="z-index:2;"><span>Payment Details</span></div>
        <div class="appProgStep appProgStep-last" id="app5" style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>

    <div class="container m-top">
        <form [formGroup]="Form">
            <div class="row demomargin">
                <div class="col-lg-4  col-md-12 padding_demo">
                    <div class="card">
                        <div class="row">
                            <!-- <div class="healthflex-container">
                                <div class="planehead">Zip Code</div>
                                <div class="planeh">Effective Start Date</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6" style="text-align: right;">
                                <div class="planehead" *ngIf="applicant"> {{applicant.zipcode}}</div>
                                <div class="planeh" *ngIf="applicant"> {{applicant.startdate}}</div>

                            </div> -->

                            <div class="healthflex-container">
                                <div class="planehead">Effective Start Date</div>
                                <div *ngIf="applicant"
                                     class="planehead"> {{ applicant.startdate | date:'MM/dd/yyyy' }}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card  ">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="cart-card-title">Application Cart</p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-8 col-md-8">
                                <p class="cart-card-data">Your Application Number</p>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <p class="cart-card-value">{{ application_id }}</p>
                            </div>
                        </div>
                    </div>
                    <app-applicationcart></app-applicationcart>

                </div>
                <div class="col-lg-8  padding_demo1">
                    <div class="card questions_txt">
                        <span style="font-weight: 500;"> MEDICAL QUESTIONS</span>
                        You are enrolling in a Health Share Ministry Program, with Pre-Existing Conditions Limitations.
                        Medical Needs Sharing for any condition diagnosed, treated, shown signs or symptoms (whether
                        treated or not) within the past 24 months or 60 months for Cancer, are not eligible for sharing
                        during the first 24 or 36 months of Membership. In addition to the Pre-Existing Condition
                        limitation, some listed conditions may have sharing limitations or identified as ineligible for
                        sharing in the Member Guidelines Medical Conditions and Services Ineligible for Sharing Chapter
                    </div>
                    <div class="card">
                        <div *ngIf="Form.contains('healthIssues')" formGroupName="healthIssues">
                            <div *ngFor="let list of healthQuestions;let i=index">
                                <p class="health-qstn">{{ i + 1 }}) {{ list.name }} <span
                                        *ngIf="list?.required == 'yes'"
                                        class="fieldMan">*</span>
                                <div class="row">
                                    <div *ngFor="let item of list.health_issue" class="col-md-6">
                                        <div *ngIf="item.field_type=='Multiple-Checkbox'">
                                            <div class="form-check mb-3 pl-4">
                                                <input (change)="selectoptions(item, $event.target.checked, i)"
                                                       [checked]="item.Checked"
                                                       [formControlName]="'question_' + list.id"
                                                       class="form-check-input"
                                                       id="{{'question_'+item.health_issue_id}}"
                                                       name="{{item.health_id}}"
                                                       type="checkbox"
                                                       value="{{item.health_issue_id}}">

                                                <label class="form-check-label" for="">{{ item.value }}</label>
                                            </div>
                                        </div>
                                        <div *ngIf="item.field_type=='Checkbox'">
                                            <div class="form-check mb-3 pl-4">
                                                <input (change)="selectoptionsradio(item, $event.target, i)"
                                                       [checked]="item.Checked"
                                                       [formControlName]="'question_' + list.id"
                                                       [name]="'question_' + list.id"
                                                       class="form-check-input"
                                                       type="radio"
                                                       value="{{item.value}}">
                                                <label class="form-check-label" for="">{{ item.value }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="Form.controls.healthIssues.get('question_' + list.id).invalid && submitAttempt"
                                         class="mb-2 mt-2">
                                        <small class="text-danger">This field is required.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-14 form-group mt-3">
                            <label class="head_txt1"> If yes, to any question above or any other condition not listed
                                above complete the following information.
                            </label>
                        </div>
                        <div *ngIf="employeedetails">
                            <div formArrayName="spouse_Array">
                                <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index">
                                    <div [formGroupName]="empIndex1">
                                        <div class="row">
                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">
                                                    First Name</label>
                                                <input class="form-control" formControlName="name" maxlength="25"
                                                       placeholder="First Name" type="text">
                                                <small *ngIf="!spouseForm.controls.name.valid && (spouseForm.controls.name.touched || submitAttempt)"
                                                       class="errormsg">
                                                    First Name is required
                                                </small>


                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">Relation</label>
                                                <input class="form-control" formControlName="relationship"
                                                       maxlength="25"
                                                       placeholder="Relation"
                                                       type="text">
                                                <small *ngIf="!spouseForm.controls.relationship.valid && (spouseForm.controls.relationship.touched || submitAttempt)"
                                                       class="errormsg">
                                                    Relation is required
                                                </small>
                                            </div>

                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">
                                                    Date Of Birth </label>
                                                <p-calendar [disabledDates]="invalidDates1"
                                                            [maxDate]="minimumDate"
                                                            [monthNavigator]="true"
                                                            [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                                            [yearNavigator]="true"
                                                            [yearRange]="'1956:' + maxDate.getFullYear()"
                                                            formControlName="dob"
                                                            inputId="navigators"
                                                            placeholder="mm/dd/yyyy">
                                                </p-calendar>
                                                <small *ngIf="!spouseForm.controls.dob.valid && (spouseForm.controls.dob.touched || submitAttempt)"
                                                       class="errormsg">
                                                    Date Of Birth is required
                                                </small>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">Physician
                                                    Name</label>
                                                <input class="form-control" formControlName="physician_name"
                                                       maxlength="25"
                                                       placeholder="Physician Name"
                                                       type="text">
                                                <small *ngIf="!spouseForm.controls.physician_name.valid && (spouseForm.controls.physician_name.touched || submitAttempt)"
                                                       class="errormsg">
                                                    Physician Name is required
                                                </small>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">Diagnosis</label>
                                                <input class="form-control" formControlName="diagnosis"
                                                       placeholder="Diagnosis" type="text">
                                                <small *ngIf="!spouseForm.controls.diagnosis.valid && (spouseForm.controls.diagnosis.touched || submitAttempt)"
                                                       class="errormsg">
                                                    Diagnosis Name is required
                                                </small>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <label [ngClass]="{'required': isHealthquestions}" class="head_txt1">Date
                                                    of Diagnosis</label>
                                                <p-calendar [disabledDates]="invalidDates1"
                                                            [maxDate]="minimumDate"
                                                            [monthNavigator]="true"
                                                            [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                                            [yearNavigator]="true"
                                                            [yearRange]="'1956:' + maxDate.getFullYear()"
                                                            formControlName="date_of_diagnosis"
                                                            inputId="navigators"
                                                            placeholder="mm/dd/yyyy">
                                                </p-calendar>
                                                <small *ngIf="!spouseForm.controls.date_of_diagnosis.valid && (spouseForm.controls.date_of_diagnosis.touched || submitAttempt)"
                                                       class="errormsg">
                                                    Date of Diagnosis is required
                                                </small>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-lg-12 align">
                                                <button (click)="removeEmployee(empIndex1,spouseForm.value,Form.value);"
                                                        *ngIf="empIndex1>0"
                                                        class="btn btn-sm1 btn-danger btn-remove"
                                                        type="button">
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 align mt-4">
                                    <button (click)="addSpouse()" class="btn btn-sm btn-primary" type="button">
                                        <i aria-hidden="true" class="fa fa-user"></i> Add More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="head_op">
                        <label>
                            <input formControlName="acknowledgeCheck" type="checkbox"> By checking this box I
                            acknowledge
                            the above information is true and
                            accurate, any information that is determined to be inaccurate may result in a medical need
                            being
                            ineligible for sharing.</label>
                        <div *ngIf="!Form.controls.acknowledgeCheck.valid &&  submitAttempt" class="errormsg">
                            Acknowledge checkbox is required
                        </div>
                    </div>
                    <!-- <div class="head_op d-none">
                        <label> <input type="checkbox" formControlName="acknowledgeCheck1">&nbsp;
                            By checking this box I acknowledge that I understand the testing
                            requirements listed above based upon my current and future age.</label>
                            <div class="errormsg" *ngIf="!Form.controls.acknowledgeCheck1.valid &&  submitAttempt">
                            Acknowledge checkbox is required
                        </div>
                    </div> -->
                    <div class="head_op1">
                        By Clicking the <b> "Next" </b> button, you confirm that the above information is correct.
                        You will not be able to make changes to this information again in this application.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <button (click)="changeToDemography()" class="btn btn-primary"
                                    type="button">Previous
                            </button>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <button (click)=" submit(Form.value)" class="btn btn-success" type="button"> Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
