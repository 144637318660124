<section>
    <div class="footer-container footer pt-0" style="margin-top: 80px;">
        <div *ngIf="template !== 'template_2' &&  template !=='package'" class="container" style="padding-top: 80px;">
            <div class="row">
                <div class="col-sm-4">
                    <div class="widget">
                        <h5 class="widget-title">{{ companyName }} Program Disclaimer</h5>
                        <p [innerHTML]="disclaimer" class="pr"></p>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="widget">
                        <h5 class="widget-title">About</h5>
                        <ul id="menu-footer-quick-links">
                            <li *ngIf="companyName == 'Hope Health Share'">
                                <a class="hover" href="https://hopehealthshare.com/about" target="_blank">
                                    About Us
                                </a>
                            </li>
                            <ng-container *ngIf="isDemoPortal">
                                <li *ngFor="let item of footerMenus">
                                    <a href="javascript:void(0);" class="hover">{{ item.name }}</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!isDemoPortal">
                                <li *ngFor="let item of footerMenus">
                                    <a (click)="route(item.name)" *ngIf="item.name != 'RESOURCES'" class="hover" href="{{ routepath }}"
                                        routerLinkActive="active" target="_blank">{{ item.name }} <span class="sr-only">(current)</span></a>
                                    <span *ngIf="item.name == 'RESOURCES'" class="nav-item dropdown">
                                        <a aria-expanded="false" class="hover dropdown-toggle" data-toggle="dropdown" href="#" role="button">
                                            {{ item.name }}
                                        </a>
                                        <div class="dropdown-menu">
                                            <a *ngFor="let item of resource_plans" [attr.href]="item.url" class="dropdown-item" target="_blank">
                                                {{ item.name }}
                                            </a>
                                        </div>
                                    </span>
                                </li>
                            </ng-container>
                        
                            <!-- <li>
                                <a class="hover" routerLink="/privacy-policy">
                                    Privacy Policy
                                </a>
                            </li> -->
                            <!-- <li><a class="hover" routerLink="/stateSpecificNotices">
                                    State Specific Notices
                                </a> 
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="widget">
                        <h5 class="widget-title">Support</h5>
                        <ul id="menu-footer-quick-links">
                            <li *ngIf="companyName == 'Hope Health Share'">
                                <a
                                        class="hover"
                                        href="https://hopehealthshare.com/contact/"
                                        target="_blank"
                                >
                                    Contact Us
                                </a>
                            </li>
                            <li *ngIf="companyName == 'Joppa Health Share'">
                                <a
                                        class="hover"
                                        href="https://www.joppahealth.org/contact/"
                                        target="_blank"
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="widget">
                        <h5 class="widget-title">Collaboration</h5>
                        <ul id="menu-footer-quick-links">
                            <li>
                                <a class="hover" href="{{ url }}" target="_blank">
                                    Producers
                                </a>
                            </li>
                            <!-- <li>
                            <a class="hover" routerLink="/employer-group">
                              Employer Groups
                            </a>
                          </li> -->
                            <li>
                                <a class="hover" href="{{ url }}" target="_blank">
                                    Management Login
                                </a>
                            </li>
                            <!-- <li>
                            <a class="hover" routerLink="/appointmenttest">
                              Appointment Test
                            </a>
                          </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="widget">
                        <h5 class="widget-title">Follow US on</h5>
                        <div class="d-flex">
                            <ul
                                    *ngFor="let item of social_links"
                                    class="social d-flex align-items-center"
                                    id=""
                            >
                                <li *ngIf="item.facebook">
                                    <a href="{{ item.facebook }}" target="_blank"
                                    ><i class="fa fa-facebook m-0"></i>
                                    </a>
                                </li>
                                <li *ngIf="item.twitter">
                                    <a href="{{ item.twitter }}" target="_blank">
                                        <i>
                                            <img
                                                    alt=""
                                                    class="sm_twitter"
                                                    src="../../assets/images/twitter_logo.svg"
                                            />
                                        </i>
                                    </a>
                                </li>
                                <li *ngIf="item.instagram">
                                    <a href="{{ item.instagram }}" target="_blank"
                                    ><i class="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li *ngIf="item.linkedin">
                                    <a href="{{ item.linkedin }}" target="_blank">
                                        <i class="bi bi-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="companyName == 'Hope Health Share'" class="col-sm-12">
                    <hr/>
                    <div class="p-60">
                        <h5 class="widget-title">Additional Member Services Disclosure</h5>
                        <p>
                            Additional Member Services Included in Hope Health Share Programs
                            Hope Health RX, Telemedicine, Lensabl, Well Card Savings are
                            provided by valued partners of Hope Health Share and are not owned
                            or operated by Hope Health Share. The services provided are NOT
                            insurance and are not intended to replace insurance. Well Card
                            Savings,This is Not Insurance. It is a discount medical program.
                            It does not replace COBRA or any other medical insurance program
                            nor is it a Medicare Part D prescription drug plan. WellCard
                            Savings does not qualify for essential coverage under the
                            Affordable Care Act (ACA-ObamaCare). Cardholders are responsible
                            for paying the discounted cost at the time of service from
                            participating providers. WellCard Savings has no membership fee
                            nor is participation in any organization or purchase of any good
                            or service required to obtain or use WellCard Savings. WellCard
                            Savings will not share or sell your personal information. The
                            discount medical plan organization is Access One Consumer
                            Health,Inc. (not affiliated with AccessOne Medcard), 84 Villa
                            Road, Greenville, SC, 29615,
                            <a
                                    class="text-warning"
                                    href="https://accessonedmpo.com/"
                                    target="_blank"
                            >www.accessonedmpo.com</a
                            >. This program is not available to residents of Montana but may
                            be used by non-residents at participating Montana providers. Other
                            state residents: visit
                            <a
                                    class="text-warning"
                                    href="https://www.wellcardsavings.com/"
                                    target="_blank"
                            >Welcome to WellCard Savings</a
                            >
                            for full disclosure statement.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="footer-container">
                    <div class="copyright-footer-content">
                        <p class="mb-0">© {{ companyName }}. All rights reserved.</p>
                        <p class="mb-0">17-Sep-2024 | Version: 3.4.1</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
