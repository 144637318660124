import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnChanges {

    @Input() columns: Array<any>;
    @Output() search = new EventEmitter();
    @Output() filteredColumns = new EventEmitter();

    searchText: string;
    dropdownOpen = false;
    selectedColumns = [];
    availableColumns = [];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['columns'].firstChange) {
            this.availableColumns = [...this.columns];
        }
        if (changes['columns'].currentValue.length > 0) {
            this.selectedColumns = [...this.columns];
            changes['columns'].currentValue.forEach(col => {
                col.displayed = true;
            })
        }
    }

    searchTextValueEmit() {
        this.search.emit(this.searchText);
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    closeDropdown() {
        this.dropdownOpen = false;
    }

    onCheckboxChange(checked, col) {
        if (checked) {
            const originalIndex = this.availableColumns.findIndex(column => column === col);
            this.selectedColumns.splice(originalIndex, 0, col);
        } else {
            const index = this.selectedColumns.indexOf(col);
            if (index !== -1) {
                this.selectedColumns.splice(index, 1);
            }
        }

        this.filteredColumns.emit(this.selectedColumns);
    }


    toggleCheckbox(event) {
        const target = event.target;
        const checkbox = target.querySelector('input[type="checkbox"]');
        if (checkbox) {
            checkbox.checked = !checkbox.checked;
            this.onCheckboxChange(event, checkbox.id);
        }
    }

}
