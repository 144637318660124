import {BrowserModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {
    DemographicsDependentsComponent
} from './enrollment/template_1/demographics-dependents/demographics-dependents.component';
import {CalendarModule} from 'primeng/calendar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import {ToastrModule} from 'ngx-toastr';
import {SignUpComponent} from './enrollment/template_1/sign-up/sign-up.component';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {ScrollSpyDirective} from './scroll-spy.directive';
import {httpInterceptorproviders} from './dataservices/http-Interceptors';
import {ApplicationcartComponent} from './enrollment/template_1/applicationcart/applicationcart.component';
import {StatementofbeliefsComponent} from './enrollment/template_1/statementofbeliefs/statementofbeliefs.component';
import {PaymentDetailsComponent} from './enrollment/template_1/payment-details/payment-details.component';
import {SummaryComponent} from './enrollment/template_1/summary/summary.component';
import {
    HealthBasedQuestionsComponent
} from './enrollment/template_1/health-based-questions/health-based-questions.component';
import {ThankyouComponent} from './enrollment/template_1/thankyou/thankyou.component';
import {HealthquestionsComponent} from './healthquestions/healthquestions.component';
import {DialogModule} from 'primeng/dialog';
import {DirectenrollmentComponent} from './enrollment/directenrollment/directenrollment.component';
import {EnrollmentComponent} from './enrollment/template_2/enrollment/enrollment.component';
import {ProductsComponent} from './enrollment/template_2/products/products.component';
import {PaymentsComponent} from './enrollment/template_2/payments/payments.component';
import {BeneficiaryComponent} from './enrollment/template_2/beneficiary/beneficiary.component';
import {AgreementComponent} from './enrollment/template_2/agreement/agreement.component';
import {Disclosure1Component} from './enrollment/template_2/disclosure-1/disclosure-1.component';
import {ConfirmationComponent} from './enrollment/template_2/confirmation/confirmation.component';
import {MembersummaryComponent} from './enrollment/template_2/membersummary/membersummary.component';
import {NumberDirective} from './featuresModule/directives/numbers-only.directive';
import {SocialSecurityDirective} from './featuresModule/directives/social-security.directive';
import {DatePipe} from '@angular/common';
import {Disclosure2Component} from './enrollment/template_2/disclosure-2/disclosure-2.component';
import {D2cEnrollmentComponent} from './enrollment/package/d2c-enrollment/d2c-enrollment.component';
import {D2cagreementComponent} from './enrollment/package/d2cagreement/d2cagreement.component';
import {D2cConfirmationComponent} from './enrollment/package/d2c-confirmation/d2c-confirmation.component';
import {D2cSummaryComponent} from './enrollment/package/d2c-summary/d2c-summary.component';
import {PaymentComponent} from './enrollment/package/payment/payment.component';
import { GroupMemberComponent } from './group-member/group-member.component';
import { AddMemberConfirmationComponent } from './confirmation/confirmation.component';
import { SinglePageTemplateComponent } from './enrollment/single-page-template/single-page-template.component';
import { AccordionModule } from 'primeng/accordion';
import { CartconfirmationComponent } from './enrollment/single-page-template/cartconfirmation/cartconfirmation.component';
import { PaymentFailedComponent } from './enrollment/package/payment-failed/payment-failed.component';
import { AddGroupsComponent } from './add-groups/add-groups.component';
import { SharedModule } from './shared/shared.module';
import { ReusableTableModule } from './features-module/table/table.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        DemographicsDependentsComponent,
        PagenotfoundComponent,
        SignUpComponent,
        ScrollSpyDirective,
        ApplicationcartComponent,
        StatementofbeliefsComponent,
        PaymentDetailsComponent,
        PaymentFailedComponent,
        SummaryComponent,
        HealthBasedQuestionsComponent,
        ThankyouComponent,
        HealthquestionsComponent,
        DirectenrollmentComponent,
        EnrollmentComponent,
        ProductsComponent,
        PaymentsComponent,
        BeneficiaryComponent,
        AgreementComponent,
        Disclosure1Component,
        MembersummaryComponent,
        ConfirmationComponent,
        NumberDirective,
        SocialSecurityDirective,
        Disclosure2Component,
        D2cEnrollmentComponent,
        D2cagreementComponent,
        D2cConfirmationComponent,
        D2cSummaryComponent,
        PaymentComponent,
        GroupMemberComponent,
        AddMemberConfirmationComponent,
        SinglePageTemplateComponent,
        CartconfirmationComponent,
        AddGroupsComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        AccordionModule,
        NgxExtendedPdfViewerModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        DialogModule,
        SharedModule,
        ReusableTableModule,
        ToastrModule.forRoot(),
        BackButtonDisableModule.forRoot({
            preserveScroll: true
        })
        // BrowserModule,NgxAwesomePopupModule.forRoot(),

        // ConfirmBoxConfigModule.forRoot()
    ],
    providers: [
        DatePipe,
        httpInterceptorproviders,
        Title
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
