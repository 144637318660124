<section id="member-signup">
    <div *ngIf="loader"
         class="loader">
        <div id="loading"></div>
    </div>
    <p-dialog [(visible)]="display" [baseZIndex]="10000" [breakpoints]="{'900px': '50vw','600px': '100vw'}"
              [closable]="false" [modal]="true" [resizable]="false" [style]="{width: '40vw'}">

        <h6 *ngIf="textchange" class="pop_text">You are already registered in our system. Do you want to continue with
            the enrollment process?</h6>
        <h6 *ngIf="!textchange" class="pop_text">Please Click on the "Become a Member" button to start a New
            Enrollment</h6>

        <ng-template pTemplate="footer">
            <div class="btn_text">
                <button (click)="cancel()" *ngIf="textchange" class="btn btn-outline-danger">NO</button>
                <button (click)="process()" *ngIf="textchange" class="btn btn-success">Yes</button>
                <button (click)="becomeMember()" *ngIf="!textchange" class="btn btn-success">Become A Member</button>
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog #myDialog (onShow)="onDialogShow()" [(visible)]="plan_details_popup"
              [baseZIndex]="10000"
              [breakpoints]="{'900px': '50vw','600px': '100vw'}" [modal]="true" [resizable]="false"
              [style]="{width: '40vw'}">
        <ng-template pTemplate="header">
            <div class="plan-name text-center w-100">{{ service_data[0]?.Name }}</div>
        </ng-template>
        <table class="table table-borderless  program-features-table">
            <tbody>
            <ng-container *ngFor="let item of service_data">
                <tr *ngIf="item?.price">
                    <td>
                        <div class="contibution-details">
                            <p>CONTRIBUTIONS AS LOW AS</p>
                            <h6>${{ item?.price }}.00/month</h6>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="item?.tag">
                    <td>
                        {{ item?.tag }}
                    </td>
                </tr>
            </ng-container>
            <tr *ngFor="let item of service_newData">
                <td>
          <span class="feature-list">
            <i class="fa fa-check"></i>
            <span [innerHtml]="item.item"
                  class="feature-list-description"></span>
          </span>
                </td>
            </tr>
            <tr class="reference-links">
                <td *ngFor="let plan of service_data; let i = index">
                    <button (click)="downloadReferenceGuide(plan.Name.toLowerCase().includes('plus') ? 'plus' : 'premium' , plan)"
                            class="btn btn-primary my-2"
                            type="button">
                        <i aria-hidden="true" class="fa fa-link"></i> Quick Reference Guide
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </p-dialog>

    <div class="container mb-4">
        <form [formGroup]="newForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title-container pb-2">
                        <h5 class="mb-3">Become A Member</h5>
                        <p>Please provide some basic information below to determine a Membership level that best suits
                            your needs.
                        </p>
                    </div>
                    <div class="row ">
                        <div class="form-group col-auto">
                            <div class="return-user-check">
                                <label class="switch-label">Do you have a previously incomplete
                                    application? </label>
                                <label class="switch ml-4">
                                    <input (change)="applicationFields($event.target.checked)"
                                           [checked]="!!groupName && isSubmitAllowed && isD2C"
                                           [disabled]="!isSubmitAllowed"
                                           type="checkbox">
                                    <div class="slider-applicant round"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <form *ngIf="isSubmitAllowed " [formGroup]="applicationForm">
                        <div *ngIf="showApplicationForm"
                             class="row">
                            <div class="col-lg-3 form-group">
                                <div class="head_txt1"> Application ID <span class="fieldMan">*</span></div>
                                <input class="form-control"
                                       formControlName="applicationId"
                                       id="applicationId"
                                       maxlength="25"
                                       placeholder="Application ID"
                                       type="text">
                                <div *ngIf="!applicationForm.controls.applicationId.valid && (applicationForm.controls.applicationId.touched || applicationsubmitAttempt)"
                                     class="errormsg">
                                    Application ID is required
                                </div>
                            </div>
                            <div class="col-lg-3 form-group">
                                <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                                <input class="form-control"
                                       formControlName="applicationEmail"
                                       placeholder="Email"
                                       type="text">
                                <div *ngIf="!applicationForm.controls.applicationEmail.valid && (applicationForm.controls.applicationEmail.touched || applicationsubmitAttempt)"
                                     class="errormsg">
                                    Email is required
                                </div>
                            </div>
                            <div class="col-lg-3 form-group applicationBtn ">

                                <button (click)="newapplication(applicationForm.value)"
                                        class="btn btn-sm btn-primary btn-hope-two "
                                        type="button">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row ">

                        <div class="form-group col-auto ">
                            <div class="return-user-check">
                                <label class="switch-label">In the last 12 months have you or any family member
                                    enrolling
                                    used
                                    Nicotine, in any form?</label>
                                <label class="switch ml-4 nocotine-switch">
                                    <input (change)="tobacooFields($event)"
                                           [disabled]="!isSubmitAllowed"
                                           formControlName="nicotine"
                                           id="togBtn"
                                           type="checkbox">
                                    <div class="slider round"></div>
                                </label>
                            </div>
                            <div *ngIf="nicotinetextdisplay"
                                 class="alert-error">
                                <i class="ri-alert-fill"></i>
                                <span>Currently {{companyName}} is not available to users of Nicotine in any form</span>
                            </div>

                            <div *ngIf="nicotineChecked && companyName=='Joppa Health Share'"
                                 class="alert-error">
                                <i class="ri-alert-fill"></i>
                                <span> Programs include a Monthly Surcharge for Nicotine Use (All forms, including Vaping)
                </span>
                            </div>


                        </div>
                        <div class="col-md-4">

                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="head_txt1"> State of Residency <span class="fieldMan">*</span></label>

                            <select #mySelect
                                    (change)='onOptionsSelected(mySelect.value)'
                                    class="form-control  form-select"
                                    formControlName="state">
                                <option disabled
                                        value="">
                                    Select Your State
                                </option>
                                <option *ngFor="let item of states"
                                        value={{item.name}}>
                                    {{ item.name }}
                                </option>
                            </select>
                            <small *ngIf="!newForm.controls.state.valid && (submitAttempt)"
                                   class="errormsg">
                                State is required
                            </small>
                        </div>
                        <div *ngIf="statestextdisplay"
                             class="col-lg-6 form-group tobaco-error">
                            <p class="non-tabacco mt-1 mb-0">
                                Thank you for your interest in joining {{ companyName }}. At this
                                time, {{ companyName }}
                                Programs are
                                not available to the residents of your state.
                            </p>
                        </div>
                    </div>
                    <fieldset class="primaryinfo">
                        <legend class="legend">Personal Information</legend>
                        <div class="row">
                            <div class="col-lg-4 form-group">
                                <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                                <input class="form-control"
                                       formControlName="firstname"
                                       id="firstname"
                                       maxlength="25"
                                       placeholder="First Name"
                                       type="text">

                                <small *ngIf="newForm.controls.firstname.errors?.required && (newForm.controls.firstname.touched || submitAttempt)"
                                       class="errormsg">
                                    First Name is required</small>
                                <small *ngIf="newForm.controls.firstname.errors?.pattern && (newForm.controls.firstname.touched || submitAttempt)"
                                       class="errormsg">Please
                                    enter a valid
                                    Name
                                </small>
                            </div>
                            <div class="col-lg-4 form-group">
                                <div class="head_txt1"> Middle Name</div>
                                <input class="form-control"
                                       formControlName="middlename"
                                       maxlength="25"
                                       placeholder="Middle Name"
                                       type="text">
                                <small *ngIf="newForm.controls.middlename.errors?.pattern && (newForm.controls.middlename.touched || submitAttempt)"
                                       class="errormsg">
                                    Please enter a valid Name
                                </small>
                            </div>
                            <div class="col-lg-4 form-group">
                                <div class="head_txt1"> Last Name <span class="fieldMan">*</span></div>
                                <input class="form-control"
                                       formControlName="lastname"
                                       maxlength="25"
                                       placeholder="Last Name"
                                       type="text">

                                <small *ngIf="newForm.controls.lastname.errors?.required && (newForm.controls.lastname.touched || submitAttempt)"
                                       class="errormsg">
                                    Last Name is required</small>
                                <small *ngIf="newForm.controls.lastname.errors?.pattern && (newForm.controls.lastname.touched || submitAttempt)"
                                       class="errormsg">Please
                                    enter a valid
                                    Name
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                                <input (focusout)="enrollCancelMember($event.target.value)"
                                       (keypress)="emailValidation($event.target.value)"
                                       class="form-control"
                                       formControlName="email"
                                       placeholder="Email"
                                       type="text">
                                <div *ngIf="errorMsgShow"
                                     class="errormsg">
                                    {{ emailError }}
                                </div>
                                <small *ngIf="!newForm.controls.email.valid && (newForm.controls.email.touched || submitAttempt) && newForm.controls.email.errors?.required"
                                       class="errormsg">
                                    Email Address is required
                                </small>
                                <small *ngIf="newForm.controls.email.errors?.pattern"
                                       class="errormsg"> Please enter a valid Email
                                    Address</small>
                            </div>
                            <div class="col-lg-6 form-group">
                                <div class="head_txt1"> Phone <span class="fieldMan">*</span> <span class="check_otp mt-2" *ngIf="otp_notification=='1'"><input type="checkbox"  formControlName='otp_notification_check'> 
                                    <span class="otp_text"> Please check this box to receive OTP & notifications via SMS.</span></span></div>
                                <input (focusout)="onfocusoutphnumber('primary')"
                                       (focusout)="userTempData(newForm.value)"
                                       (keypress)="numberOnly($event)"
                                       (keypress)="emailValidation($event.target.value)"
                                       (keyup)="onkeyupPhone($event)"
                                       (paste)="onPastePhone($event)"
                                       appPhonenumberValidation
                                       class="form-control"
                                       class="form-control"
                                       formControlName="phone"
                                       id="phnumber"
                                       maxlength="12"
                                       placeholder="Phone"
                                       type="text">
                                <div *ngIf="errorMsgShow"
                                     class="errormsg"> {{ phoneerrmsg }}
                                </div>
                                <div *ngIf="!newForm.controls.phone.valid && (submitAttempt)"
                                     class="errormsg">
                                    Phone Number is required
                                </div>
                                <div class="errormsg"
                                     id="phnumbererrmsg">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <div class="head_txt1">Start Date <span class="fieldMan">*</span>
                                    <!-- <span style="font-size: 12px;">(Contact us, if you would like a different start
                                                      date.)</span> -->
                                </div>
                                <select #mySelec
                                        (change)='startDate(mySelec.value)'
                                        class="form-control  form-select"
                                        formControlName="startDate">
                                    <option disabled
                                            value="">
                                        Select Your Effective Start Date
                                    </option>
                                    <option *ngFor="let item of effectiveDate"
                                            value={{item.date}}>
                                        {{ item.date }}
                                    </option>
                                </select>
                                <div *ngIf="!newForm.controls.startDate.valid && (newForm.controls.startDate.touched || submitAttempt)"
                                     class="errormsg">
                                    Start Date is required
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-lg-5 form-group">
                                        <div class="head_txt1">Gender At Birth <span class="fieldMan">*</span></div>
                                        <div class="genderSpace">
                                            <label>
                                                <input formControlName="gender"
                                                       type="radio"
                                                       value="Male">
                                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                                            </label>
                                            <label>
                                                <input formControlName="gender"
                                                       type="radio"
                                                       value="Female">
                                                <span> &nbsp;Female</span>
                                            </label>
                                        </div>
                                        <div *ngIf="!newForm.controls.gender.valid && (newForm.controls.gender.touched || submitAttempt)"
                                             class="errormsg">
                                            Gender is required
                                        </div>
                                    </div>
                                    <div class="col-lg-5 form-group">

                                        <div class="head_txt1"> Date Of Birth <span class="fieldMan">*</span></div>
                                        <p-calendar (onInput)="onSearchChangeT(newForm.value)"
                                                    (onSelect)="onSearchChange($event,newForm.value)"
                                                    [disabledDates]="invalidDates1"
                                                    [maxDate]="minimumDate"
                                                    [monthNavigator]="true"
                                                    [showIcon]="true"
                                                    [style]="{'width': '100%','display': 'inline-flex','height':'48px'}"
                                                    [yearNavigator]="true"
                                                    [yearRange]="'1956:' + maxDate.getFullYear()"
                                                    appendTo="body"
                                                    formControlName="dob"
                                                    inputId="navigators"
                                                    placeholder="mm/dd/yyyy">
                                        </p-calendar>
                                        <div *ngIf="!newForm.controls.dob.valid && (newForm.controls.dob.touched || submitAttempt)"
                                             class="errormsg">
                                            DOB is required
                                        </div>
                                        <div class="errormsg">{{ parentAge }}</div>
                                    </div>

                                    <div class="col-lg-2 col-xs-4 form-group">
                                        <div class="head_txt1">Age
                                            <span data-placement="top"
                                                  data-toggle="tooltip"
                                                  tooltip="Age on Effective Start Month">(<i
                                                    class="fa fa-info"></i>)</span>
                                        </div>
                                        <input [readonly]="true"
                                               class="form-control readonly"
                                               formControlName="age"
                                               placeholder="0"
                                               type="text">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="row"
                                     *ngIf="spouseHide">
                                    <div class="col-lg-5 d-none">
                                        <div class="head_txt1 label-new"> Height <span class="fieldMan">*</span></div>
                                        <div class="row">
                                            <div class="col-6 paddingFt form-group">
                                                <div class="row">
                                                    <div class="col-8 paddingFt">
                                                        <select formControlName="feet"
                                                                class="form-control">
                                                            <option value=""
                                                                    disabled>Feet</option>
                                                            <option value={{item.feet}}
                                                                    *ngFor="let item of feetArray| slice:1">
                                                                {{item.feet}} </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-4 marginFt"> Ft</div>
                                                </div>
                                            </div>
                                            <div class="col-6 paddingFt form-group">
                                                <div class="row">
                                                    <div class="col-8 paddingFt">
                                                        <select formControlName="inch"
                                                                class="form-control">
                                                            <option value=""
                                                                    disabled>Inches</option>
                                                            <option value={{item.inchValue}}
                                                                    *ngFor="let item of inchArray; let i=index">
                                                                {{item.inch}} </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-4 marginFt"> In</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="errormsg"
                                             *ngIf="!newForm.controls.feet.valid && (newForm.controls.feet.touched || submitAttempt)">
                                            Height is required
                                        </div>
                                    </div>
                                    <div class="col-lg-3 form-group d-none">
                                        <div class="head_txt1">Weight <span class="fieldMan">*</span></div>
                                        <div class="input-group">
                                            <input class="form-control"

                                                   maxlength="3"
                                                   type="text"
                                                   placeholder="Weight"
                                                   (keypress)="numberOnly($event)"
                                                   (input)="onSearch($event.target.value,'primary')"
                                                   formControlName="weight"><span class="input-group-addon">lbs</span>
                                        </div>
                                        <div class="errormsg">
                                            {{er}}
                                        </div>
                                        <div class="errormsg"
                                             *ngIf="!newForm.controls.weight.valid && (newForm.controls.weight.touched || submitAttempt)">
                                            Weight is required
                                        </div>
                                    </div>

                                </div> -->
                    </fieldset>
                    <fieldset class="primaryinfo">
                        <legend class="legend">Group & Agent Identifier</legend>
                        <div *ngFor="let field of fields;let i=index"
                             class="row">
                            <div class="col-md-6 ">

                                <div class="form-group coupon-grid">
                                    <input (focusout)="onleaveGroupAgentcode($event.target.value,i)"
                                           [formControlName]="field.id"
                                           class="form-control"
                                           id="{{'agent-groupcode'+i}}"
                                           maxlength="25"
                                           placeholder="Code"
                                           type="text">
                                    <div class="button-group">
                                        <button (click)="groupInfoAgent(field.id,i)"
                                                [disabled]="disableTextbox"
                                                class="btn btn-sm btn-primary btn-hope-new"
                                                type="button">Apply Code
                                        </button>

                                        <button (click)=removeField(i,field.id)
                                                *ngIf="i>0"
                                                class="btn btn-outline-danger"
                                                type="button">Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 ">
                                <div *ngFor="let item of field.data"
                                     class="border-left pl-4 ml-2 mb-3">
                                    <h6 class="mb-0"><b>{{ item.firstname }} {{ item.lastname }}</b></h6>
                                    <p class="mb-0"><b>{{ item.email }}</b></p>
                                    <p class="mb-0"><b>{{ item.mobile }}</b></p>
                                    <p *ngIf="item.g2c_discount !=undefined"
                                       class="mb-0"><b>Group Discount : {{
                                            item.g2cdiscount |
                                                currency:'USD'
                                        }}</b></p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!display_newcode && fields.length <=1"
                             class="col-lg-4">
                            <div (click)="addGroupAndAgent()"
                                 [disabled]="!isSubmitAllowed"
                                 class="addcode">
                                + Add Code
                            </div>
                        </div>
                    </fieldset>
                    <div class="row">
                        <div *ngIf="spouseHide"
                             class="col-sm-12 details sub-title-group">
                            <h6 class="additionalmem">
                                ADDITIONAL MEMBERS:
                            </h6>
                            <p class="">
                                In addition to you, what other family members will be included in your membership.
                            </p>
                        </div>
                        <div *ngIf="!spouseHide"
                             class="col-sm-12 details">
                            <p class="additionalmem">
                                DEPENDENT INFORMATION :
                            </p>
                        </div>
                        <div class="button-group">
                            <div *ngIf="spouseHide">
                                <button (click)="addSpouse()"
                                        [disabled]="disableSpouseArray"
                                        [disabled]="!isSubmitAllowed"
                                        class="btn btn-sm btn-primary btn-hope-new"
                                        type="button">
                                    <i class="bi bi-person-plus-fill"></i> Add Spouse
                                </button>
                            </div>
                            <div *ngIf="spouseHide">
                                <button (click)="addChildren()"
                                        [disabled]="disableChildArray"
                                        [disabled]="!isSubmitAllowed"
                                        class="btn btn-sm btn-primary btn-hope-new"
                                        type="button">
                                    <i class="bi bi-people-fill"></i> Add Children
                                </button>
                            </div>

                        </div>
                        <div *ngIf="spouseHide"
                             class="col-sm-12 age-disclaimer">
                            <p>Spouse must be under the age of 65</p>
                            <p>Unmarried Child/Children must be under the age of 26</p>
                        </div>

                        <div *ngIf="showPlanButton"
                             class="mbmrshp-btn-container ">
                            <button (click)="formSubmit(newForm.value)"
                                    [disabled]='disableTextbox'
                                    [disabled]="!isSubmitAllowed"
                                    class="btn btn-sm btn-primary btn-hope-two mt-4"
                                    id="getmyplan1"
                                    type="button">
                                Click to continue to Membership Options
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12"
                             formArrayName="spouse_Array">
                            <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index">
                                <div [formGroupName]="empIndex1">
                                    <fieldset class="primaryinfo">

                                        <legend class="legend">Spouse details</legend>

                                        <div class="row">
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">
                                                    First Name <span class="fieldMan">*</span>
                                                </div>
                                                <input class="form-control"
                                                       formControlName="spouseFirstName"
                                                       maxlength="25"
                                                       placeholder="First Name"
                                                       type="text"/>
                                                <div *ngIf="
                                            !spouseForm.controls.spouseFirstName.valid &&
                                            (spouseForm.controls.spouseFirstName.touched ||
                                              submitAttempt)
                                          "
                                                     class="errormsg">
                                                    First Name is required
                                                </div>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">Middle Name</div>
                                                <input class="form-control"
                                                       formControlName="spouseMiddleName"
                                                       maxlength="25"
                                                       placeholder="Middle Name"
                                                       type="text"/>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">
                                                    Last Name <span class="fieldMan">*</span>
                                                </div>
                                                <input class="form-control"
                                                       formControlName="spouseLastName"
                                                       maxlength="25"
                                                       placeholder="Last Name"
                                                       type="text"/>
                                                <div *ngIf="
                                            !spouseForm.controls.spouseLastName.valid &&
                                            (spouseForm.controls.spouseLastName.touched ||
                                              submitAttempt)
                                          "
                                                     class="errormsg">
                                                    Last Name is required
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-xs-8 form-group">
                                                <div class="head_txt1">
                                                    DOB <span class="fieldMan">*</span>
                                                    (MM/DD/YYYY)
                                                </div>
                                                <p-calendar (onInput)="spouseAgeCalT(newForm.value)"
                                                            (onSelect)="
                                            spouseAgeCal(
                                              $event,
                                              empIndex1,
                                              'spouseDob' + empIndex1
                                            )
                                          "
                                                            [disabledDates]="invalidDates1"
                                                            [maxDate]="minimumDate"
                                                            [monthNavigator]="true"
                                                            [showIcon]="true"
                                                            [style]="{
                                            width: '100%',
                                            display: 'inline-flex',
                                            height: '48px'
                                          }"
                                                            [yearNavigator]="true"
                                                            [yearRange]="'1956:' + maxDate.getFullYear()"
                                                            formControlName="spouseDob"
                                                            inputId="navigators"
                                                            inputId="{{ 'spouseDob' + empIndex1 }}"
                                                            placeholder="mm/dd/yyyy">
                                                </p-calendar>
                                                <div *ngIf="
                                            !spouseForm.controls.spouseDob.valid &&
                                            (spouseForm.controls.spouseDob.touched ||
                                              submitAttempt)
                                          "
                                                     class="errormsg">
                                                    DOB is required
                                                </div>
                                                <div class="errormsg">{{ spouseAgeMsg }}</div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <div class="head_txt1">
                                                        Age
                                                        <span data-placement="top"
                                                              data-toggle="tooltip"
                                                              tooltip="Age on Effective Start Month">(<i
                                                                class="fa fa-info"></i>)</span>
                                                    </div>
                                                    <input [readonly]="true"
                                                           class="form-control readonly"
                                                           formControlName="spouseAge"
                                                           placeholder="0"/>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="enroll-action-row">
                                                    <div class="form-group">
                                                        <div class="head_txt1">
                                                            Gender At Birth <span class="fieldMan">*</span>
                                                        </div>
                                                        <div class="genderSpace">
                                                            <label>
                                                                <input formControlName="spouseGender"
                                                                       type="radio"
                                                                       value="Male"/>
                                                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                                                            </label>
                                                            <label>
                                                                <input formControlName="spouseGender"
                                                                       type="radio"
                                                                       value="Female"/>
                                                                <span> &nbsp;Female</span>
                                                            </label>
                                                        </div>
                                                        <div *ngIf="
                                              !spouseForm.controls.spouseGender.valid &&
                                              (spouseForm.controls.spouseGender.touched ||
                                                submitAttempt)
                                            "
                                                             class="errormsg">
                                                            Gender is required
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="enrollRemove">
                                                            <div class="form-check">
                                                                <input (change)="
                                            spouseCheckbox(
                                              $event.target.checked,
                                              spouseForm.value,
                                              newForm.value
                                            )
                                          "
                                                                       class="form-check-input"
                                                                       formControlName="spouseCheckBox"
                                                                       type="checkbox"/>
                                                            </div>
                                                            <button (click)="
                                          removeEmployee1(
                                            empIndex1,
                                            spouseForm.value,
                                            newForm.value
                                          )
                                        "
                                                                    class="btn btn-danger btn-remove"
                                                                    type="button">
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-------------------child and dependent------------------->
                    <div *ngIf="spouseHide"
                         style="height: 25px;"></div>
                    <div *ngIf="showChild"
                         class="row">
                        <div class="col-lg-12"
                             formArrayName="child_Array">
                            <div *ngFor="let childForm of child_Array().controls; let empIndex=index">
                                <div [formGroupName]="empIndex">
                                    <fieldset class="primaryinfo">
                                        <legend class="legend">Child - {{ empIndex + 1 }}</legend>

                                        <div class="row">
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">
                                                    First Name <span class="fieldMan">*</span>
                                                </div>
                                                <input class="form-control"
                                                       formControlName="childFirstName"
                                                       maxlength="25"
                                                       placeholder="First Name"
                                                       type="text"/>
                                                <div *ngIf="
                                            !childForm.controls.childFirstName.valid &&
                                            (childForm.controls.childFirstName.touched ||
                                              submitAttempt)
                                          "
                                                     class="errormsg">
                                                    First Name is required
                                                </div>
                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">Middle Name</div>
                                                <input class="form-control"
                                                       formControlName="childMiddleName"
                                                       maxlength="25"
                                                       placeholder="Middle Name"
                                                       type="text"/>

                                            </div>
                                            <div class="col-lg-4 form-group">
                                                <div class="head_txt1">
                                                    Last Name <span class="fieldMan">*</span>
                                                </div>
                                                <input class="form-control"
                                                       formControlName="childLastName"
                                                       maxlength="25"
                                                       placeholder="Last Name"
                                                       type="text"/>
                                                <div *ngIf="
                                            !childForm.controls.childLastName.valid &&
                                            (childForm.controls.childLastName.touched ||
                                              submitAttempt)
                                          "
                                                     class="errormsg">
                                                    Last Name is required
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-xs-8 form-group">
                                                <div class="head_txt1">
                                                    DOB <span class="fieldMan">*</span> (MM/DD/YYYY)
                                                </div>
                                                <p-calendar (onInput)="
                                                childAgeCalT(
                                                  $event,
                                                  empIndex,
                                                  'dob' + empIndex,
                                                  newForm.value
                                                )
                                              "
                                                            (onSelect)="
                                                childAgeCal($event, empIndex, 'dob' + empIndex)
                                              "
                                                            [disabledDates]="invalidDates1"
                                                            [maxDate]="minimumDate"
                                                            [monthNavigator]="true"
                                                            [showIcon]="true"
                                                            [style]="{
                                                width: '100%',
                                                display: 'inline-flex',
                                                height: '48px'
                                              }"
                                                            [yearNavigator]="true"
                                                            [yearRange]="'1994:' + maxDate.getFullYear()"
                                                            formControlName="childDob"
                                                            inputId="navigators"
                                                            inputId="{{ 'dob' + empIndex }}"
                                                            placeholder="mm/dd/yyyy">
                                                </p-calendar>
                                                <div *ngIf="
                                                !childForm.controls.childDob.valid &&
                                                (childForm.controls.childDob.touched ||
                                                  submitAttempt)
                                              "
                                                     class="errormsg">
                                                    DOB is required
                                                </div>
                                                <div class="errormsg"
                                                     id="{{ 'childDOBerror' + empIndex }}"></div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <div class="head_txt1">
                                                        Age
                                                        <span data-placement="top"
                                                              data-toggle="tooltip"
                                                              tooltip="Age on Effective Start Month">(<i
                                                                class="fa fa-info"></i>)</span>
                                                    </div>
                                                    <input [readonly]="true"
                                                           class="form-control readonly"
                                                           formControlName="childAge"
                                                           placeholder="0"/>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="enroll-action-row">


                                                    <div class="form-group">
                                                        <div class="head_txt1">
                                                            Gender At Birth
                                                            <span class="fieldMan">*</span>
                                                        </div>
                                                        <div class="genderSpace">
                                                            <label>
                                                                <input formControlName="childGender"
                                                                       type="radio"
                                                                       value="Male"/>
                                                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                                                            </label>
                                                            <label>
                                                                <input formControlName="childGender"
                                                                       type="radio"
                                                                       value="Female"/>
                                                                <span> &nbsp;Female</span>
                                                            </label>
                                                        </div>
                                                        <div *ngIf="
                                                    !childForm.controls.childGender.valid &&
                                                    (childForm.controls.childGender.touched ||
                                                      submitAttempt)
                                                  "
                                                             class="errormsg">
                                                            Gender is required
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-md-3 enroll-action-row">
                                                <div class="form-group">
                                                    <div class="disability-check">
                                                        <label class="switch-label">Disability</label>
                                                        <label class="switch">
                                                            <!-- Set checkbox checked state based on the childDisability value -->
                                                            <input formControlName="childDisability" type="checkbox">
                                                            <div class="slider-applicant round"></div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div *ngIf="!spouseHide && empIndex > 0"
                                                         class="enrollRemove">
                                                        <div class="form-check">
                                                            <input (change)="childCheckbox($event.target.checked,childForm.value,newForm.value)"
                                                                   class="form-check-input"
                                                                   formControlName="childCheckBox"
                                                                   type="checkbox"/>
                                                        </div>
                                                        <button (click)="removeEmployee(empIndex,childForm.value,newForm.value)"
                                                                class="btn btn-danger btn-remove"
                                                                type="button">
                                                            Remove
                                                        </button>
                                                    </div>
                                                    <div *ngIf="spouseHide"
                                                         class="enrollRemove">
                                                        <div class="form-check">
                                                            <input (change)="childCheckbox($event.target.checked,childForm.value,newForm.value)"
                                                                   class="form-check-input"
                                                                   formControlName="childCheckBox"
                                                                   type="checkbox"/>
                                                        </div>
                                                        <button (click)="removeEmployee(empIndex,childForm.value,newForm.value)"
                                                                class="btn btn-danger btn-remove"
                                                                type="button">
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 d-flex justify-content-between">
                            <div>
                                <div *ngIf="!spouseHide">
                                    <button (click)="addEmployee('child')"
                                            *ngIf="showChild"
                                            class="btn  btn-success btn-hope-new"
                                            type="button">
                                        <i class="bi bi-person-fill-add"></i> Add Dependent
                                    </button>&nbsp;
                                </div>
                                <div *ngIf="spouseHide">
                                    <button (click)="addEmployee('child')"
                                            *ngIf="showChild"
                                            class="btn  btn-success btn-hope-new"
                                            type="button">
                                        <i class="bi bi-people-fill"></i> Add Child
                                    </button>&nbsp;
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!showPlanButton"
                             class="mbmrshp-btn-container">
                            <button (click)="formSubmit(newForm.value)"
                                    [disabled]='disableTextbox'
                                    [disabled]="!isSubmitAllowed"
                                    class="btn  btn-primary btn-hope-two mt-4"
                                    id="getmyplan"
                                    type="button">
                                Click to continue to Membership Options
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="plandata.length>0 || programs !=undefined"
             class="bottomMargin">
            <div *ngIf="plandata.length !=0 || programs !=undefined"
                 class="basic">
                Your Application Number: {{ application_id }}
            </div>
            <div class="col-lg-12"
                 style="padding: 0;">
                <div *ngIf="plandata.length !=0"
                     class="basic">
                    Please click on the Program Name in the Blue Bar below to begin your Application
                </div>
                <div *ngIf="plandata.length !=0"
                     class="memberNames">
                    (<span>{{ firstname | titlecase }}</span>
                    <span *ngFor="let item of spouseInfo">, {{ item.firstname | titlecase }}</span>
                    <span *ngFor="let item of childInfo">, {{ item.firstname | titlecase }}</span>)
                </div>
            </div>
            <div *ngIf="plandata.length !=0"
                 class="col-lg-12"
                 style="padding: 0;text-align: center;">
                Rates may be based on the age of the oldest member and subject to change based on this information.
            </div>
            <br>
            <div *ngIf="displayPrograms">
                <div class="row">
                    <div *ngIf="display_programs"
                         class="col-md-12 mb-2">
                        <!-- <div class="new_header">Please choose between our Programs below to see available options</div> future use -->
                        <div class="new_header"> Please click on the Program Name in the Blue Bar below to begin your
                            Application
                        </div>
                        <div class="col-lg-12 mt-3"
                             style="padding: 0;text-align: center;">
                            Rates may be based on the age of the oldest member and subject to change based on this
                            information.
                        </div>
                    </div>
                    <div *ngIf="!display_programs"
                         class="col-md-12">
                        <!-- <div class="new_header"> Affordable Alternatives to Health Care for Every Budget and Lifestyle</div> future use -->
                        <div class="new_header"> Please click on the Program Name in the Blue Bar below to begin your
                            Application
                        </div>
                        <div class="col-lg-12 mt-3"
                             style="padding: 0;text-align: center;">
                            Rates may be based on the age of the oldest member and subject to change based on this
                            information.
                        </div>
                    </div>
                </div>
                <div class=" programs-list ">
                    <div (click)="planstabs(item.id, programs,item,i)"
                         *ngFor="let item of programs; let i=index"
                         [ngClass]="{'activeprogram': selectedPlan==item.name}"
                         class=" programstabs">
                        {{ item.name }}
                    </div>
                </div>
                <div *ngIf="display_programs"
                     class="row  plan-list-grid">
                    <div *ngFor="let element of programs_data" [class.col-md-4]="planDetails !== 'Internal'"
                         class="plan-list-grid-column">
                        <div class="mb-2  new_header text-left">
                            {{ element.sub_program_name }}
                        </div>

                        <div [class.row]="planDetails === 'Internal'" class="mt-1 ">
                            <div *ngFor="let item of element.data" [class.col-md-4]="planDetails === 'Internal'"
                                 class=" mb-4">
                                <div class="plan-details-card ">
                                    <div class="plan-info">
                                        <h6> {{ item.Name }} </h6>
                                    </div>
                                    <div class="applicants-info">
                                        <p>{{ item.family_info }}</p>
                                    </div>
                                    <div class="new_plan-pricing-info">
                                        <h6 [ngClass]="{'strike': item.discount}">
                                            {{ item.price | currency:'USD' }}<span>/Month</span>
                                        </h6>
                                        <p *ngIf="item.discount"
                                           class="text-primary">
                                            Group Discount: {{ item.discount | currency:'USD' }}
                                        </p>
                                        <p *ngIf="item.enrollment_fee"
                                           class="text-primary">
                                            <i class="ri-add-circle-line"></i>
                                            Enrollment Fee {{ item.enrollment_fee| currency:'USD' }}
                                        </p>
                                        <p *ngIf="item.nicotine_fee && textdisplay"
                                           class="text-primary">
                                            <i class="ri-add-circle-line"></i>
                                            Nicotine Fee {{ item.nicotine_fee| currency:'USD' }}
                                        </p>
                                        <h6 *ngIf="item.after_discount_price">
                                            {{ item.after_discount_price | currency:'USD' }}<span>/Month</span>
                                        </h6>
                                        <p>
                                            {{ item.fee?.item }}
                                        </p>
                                        <button (click)="addToCart(item)"
                                                class="btn btn-primary new_programbtn"
                                                type="button">
                                            Enroll Now <i class="ri-arrow-right-s-line"></i>
                                        </button>
                                        <button (click)="seeAllFeatures(item)"
                                                class="btn btn-primary program_btn mt-2"
                                                type="button">
                                            See all Features <i class="ri-arrow-right-s-line"></i>
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="!display_programs"
                     class="row plan-list-grid">
                    <div *ngFor="let item of plandata_new"
                         class="col-md-3 plan-list-grid-column ">
                        <div class="plan-details-card">
                            <div class="plan-info">
                                <h6> {{ item.Name }} </h6>
                            </div>
                            <div class="applicants-info">
                                <p>{{ item.family_info }}</p>
                            </div>
                            <div class="new_plan-pricing-info">
                                <h6 [ngClass]="{'strike': item.discount}">
                                    {{ item.price | currency:'USD' }}<span>/Month</span>
                                </h6>
                                <p *ngIf="item.discount"
                                   class="text-primary">
                                    Group Discount: {{ item.discount | currency:'USD' }}
                                </p>
                                <p *ngIf="item.enrollment_fee"
                                   class="text-primary">
                                    <i class="ri-add-circle-line"></i>
                                    Enrollment Fee {{ item.enrollment_fee| currency:'USD' }}
                                </p>
                                <p *ngIf="item.nicotine_fee && textdisplay"
                                   class="text-primary">
                                    <i class="ri-add-circle-line"></i>
                                    Nicotine Fee {{ item.nicotine_fee| currency:'USD' }}
                                </p>
                                <h6 *ngIf="item.after_discount_price">
                                    {{ item.after_discount_price | currency:'USD' }}<span>/Month</span>
                                </h6>
                                <p>
                                    {{ item.fee?.item }}
                                </p>
                                <button (click)="addToCart(item)"
                                        class="btn btn-primary new_programbtn"
                                        type="button">
                                    Enroll Now
                                </button>
                                <button (click)="seeAllFeatures(item)"
                                        class="btn btn-primary program_btn mt-2"
                                        type="button">
                                    See all Features <i class="ri-arrow-right-s-line"></i>
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!displayPrograms"
                 class="row plan-list-grid">
                <div *ngFor="let item of plandata"
                     class=" col-lg-3 plan-list-grid-column">
                    <div class="plan-details-card">
                        <div class="plan-info">
                            <h6> {{ item.Name }} </h6>
                        </div>
                        <div class="applicants-info">
                            <p>{{ item.family_info }}</p>
                        </div>
                        <div class="plan-pricing-info">
                            <h6 [ngClass]="{'strike': item.discount}">
                                {{ item.price | currency:'USD' }}<span>/Month</span>
                            </h6>
                            <p *ngIf="item.discount"
                               class="text-primary">
                                Group Discount: {{ item.discount | currency:'USD' }}
                            </p>
                            <p *ngIf="item.enrollment_fee"
                               class="text-primary">
                                Enrollment Fee {{ item.enrollment_fee| currency:'USD' }}
                            </p>
                            <p *ngIf="item.nicotine_fee && textdisplay"
                               class="text-primary">
                                Nicotine Fee {{ item.nicotine_fee| currency:'USD' }}
                            </p>
                            <h6 *ngIf="item.after_discount_price">
                                {{ item.after_discount_price | currency:'USD' }}<span>/Month</span>
                            </h6>
                            <p>
                                {{ item.fee?.item }}
                            </p>
                            <button (click)="addToCart(item)"
                                    class="btn btn-primary programbtn"
                                    type="button">
                                {{ item.Name }}
                            </button>
                        </div>

                        <div class="plan-description"
                             id="{{'applicantsinfo'+item.plan_id}}">
                            <p>{{ item.tag }}</p>
                        </div>

                        <ul class="plan-details-grid">
                            <li *ngFor="let service of item.formatedservice; let i=index;"
                                class="plan-list-item"
                                id="{{'list'+i+service.planId}}">
                                <i *ngIf="i !==0"
                                   class="ri-check-fill"></i>
                                <span *ngIf="i !==0"
                                      class="feature-list-description"
                                      innerHtml="{{service.item}}"></span>
                            </li>
                        </ul>
                        <!-- <div class="d-flex justify-content-center">
                          <button type="button"
                          class="btn btn-primary my-2" (click)="downloadReferenceGuide(item.Name)"
                          ><i aria-hidden="true"
                              class="fa fa-link"></i> Quick Reference Guide</button>
                        </div> -->
                    </div>
                </div>
            </div>
            <div *ngIf="plandata.length !=0"
                 class="col-lg-12 my-4 ">
                <p class="title-note">
                    Limitations & Waiting Periods apply. Please review Member Guidelines for full Program details
                </p>

            </div>
            <div *ngIf="display_programs">
                <div class="mt-2 mb-2 text-center new_header">
                    Enrollment fee is a One-time Non Refundable fee
                </div>
                <div *ngIf="nicotineChecked"
                     class="mt-2 mb-2 text-center new_header">
                    Programs include a $50 Monthly Surcharge for Nicotine Use (All forms, including Vaping)
                </div>
            </div>
        </div>
    </div>
</section>
