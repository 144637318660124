import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhoneMaskPipe} from './phonemask.filter';
import {NumberDirective} from './numbers-only.directive';


@NgModule({
    declarations: [PhoneMaskPipe, NumberDirective],
    // exports is required so you can access your component/pipe in other modules
    imports: [
        CommonModule
    ],
    exports: [PhoneMaskPipe, NumberDirective]

})
export class SharedModule {

}
