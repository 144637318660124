import {Directive, ElementRef, HostListener, Input, Renderer2} from "@angular/core";
import {Sort} from "../utils/sort";

@Directive({
    selector: '[appSort]'
})

export class SortDirective {
    @Input() appSort: Array<any>;

    constructor(private renderer: Renderer2, private targetElem: ElementRef) {
    }

    @HostListener('click')
    sortData() {
        if (!!this.appSort && this.appSort.length > 0) {
            //create object of sort class
            const sort = new Sort();
            // Get Reference of current clicked element
            const elem = this.targetElem.nativeElement;
            // Get in which order list should be sorted by default it should be set to desc on element attribute
            const order = elem.getAttribute("data-order");
            // Get the property specially set [data-type="date"] if it is date field
            const type = elem.getAttribute("data-type");
            // Get the property name from element attribute
            const property = elem.name;
            if (order === "desc") {
                this.appSort.sort(sort.startSort(property, order, type))
                elem.setAttribute("data-order", "asc");
            } else {
                this.appSort.sort(sort.startSort(property, order, type))
                elem.setAttribute("data-order", "desc");
            }
            // Not change the S.No even sort
            if (this.appSort.find(e => e?.index)) {
                this.appSort.forEach((data, index) => {
                    data.index = index + 1;
                })
            }
        }

    }
}
