import {animate, state, style, transition, trigger} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('contentExpansion', [
            state('expanded', style({display: 'table'})),
            state('collapsed', style({display: 'none'})),
            transition('expanded <=> collapsed',
                animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
        ])
    ]
})


export class TableComponent {
    columnName: any;
    columnValue: any;
    observer: any;
    @Input() columns: Array<any>;
    @Input() data: Array<any> = [];
    @Input() selectAll: boolean = false
    @Input() allSelected: boolean = false
    @Input() showTotal: boolean = false
    @Input() totalAmount: string

    @Input() isFiltered: boolean = false;
    @Input() isExpandable: boolean = false;
    @Input() isSticky: boolean = true;
    @Input() relativeData: string;
    @Input() relativeColumns: Array<any> = [];
    @Input() modalName: string;
    @Input() toggle: string;
    @Input() infiniteScroll: boolean = false;
    @Output() record = new EventEmitter();
    @Output() lastcontent = new EventEmitter();
    @Output() rowClicked = new EventEmitter();
    @Output() slctAll = new EventEmitter();
    @Input() FocusRow: any;
    @Input() hideRecords: boolean = false; // flag used to hideRecords
    @Input() noShowMessage: string = ''; // Flag used to display message when not showing records i.e. when hideRecords = true
    @Input() noShowTemplate: string = ''; // Flag used to display template when not showing records i.e. when hideRecords = true and noShowMessage = ''
    selectDropdownValue = '';
    @Output() selectedValue = new EventEmitter();
    @Output() triggersubmitbtn = new EventEmitter();
    public searchText: string;
    collapsing: boolean = true;
    expanded = new Set<number>();
    @Input() showCustomScroll: boolean = false;
    public initialData: Array<any> = [];

    public tableContainerOffsetTop: any = '30vh';
    public tableResponsiveOffsetTop: any = '35vh'
    @ViewChild('tableContainer') tableContainer: ElementRef;
    @ViewChild('tableResponsive') tableResponsive: ElementRef;

    @ViewChildren('getMoreContent')
    getMoreContent: QueryList<ElementRef>;

    constructor(
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {

        if (this.FocusRow) {
            if (this.FocusRow.length > 0) {
                this.columnName = this.FocusRow[0].column;
                this.columnValue = this.FocusRow[0].value;
            }
        }
        this.intersectionObserver();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['hideRecords']) {
            this.cdr.detectChanges();
        }
        if (changes['data']) {
            this.initialData = this.data;
        }
    }

    get hostStyles() {
        return {
            '--tableContainerOffsetTop': this.tableContainerOffsetTop + 'vh',
            '--tableResponsiveOffsetTop': this.tableResponsiveOffsetTop + 'vh'
        };
    }

    ngAfterViewChecked() {
        const tableResponsive = this.el.nativeElement.querySelector('.table-responsive').getBoundingClientRect();
        const tableContainer = this.el.nativeElement.querySelector('.table-container').getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.tableContainerOffsetTop = Math.ceil(this.convertPxToVh(tableContainer.top + scrollTop)) + 2;
        this.tableResponsiveOffsetTop = Math.ceil(this.convertPxToVh(tableResponsive.top + scrollTop)) + 2;
        this.setDynamicMinHeight();
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.infiniteScroll) {
                this.observer.observe(this.getMoreContent?.last?.nativeElement);
                this.getMoreContent.changes.subscribe(d => {
                    console.log('Query List changes subscription triggered');
                    if (d.last) this.observer.observe(d?.last.nativeElement);
                })
            }

            this.setDynamicMinHeight();
            console.log('tableContainerOffsetTop', this.tableContainerOffsetTop);
            console.log('tableResponsiveOffsetTop', this.tableResponsiveOffsetTop);
            // this.initialData=this.data;
        }, 3000);
    }

    filteredColumns(data) {
        this.columns = [...data]
    }

    private setDynamicMinHeight() {
        const tableContainer = this.el.nativeElement.querySelector('.table-container');
        const tableResponsive = this.el.nativeElement.querySelector('.table-responsive');
        const table = this.el.nativeElement.querySelector('.table')
        const minHeight = '10vh';
        const minHeightInPixels = window.innerHeight * 0.3;

        if (table.offsetHeight <= minHeightInPixels) {
            this.renderer.setStyle(tableContainer, 'min-height', table.offsetHeight + 'px');
            // this.renderer.setStyle(tableResponsive, 'min-height', tableContainer.offsetHeight + 'px');
        } else {
            this.renderer.setStyle(tableContainer, 'min-height', minHeight);
            // this.renderer.setStyle(tableResponsive, 'min-height', minHeight);
        }
    }

    convertPxToVh(pxValue: number): number {
        const viewportHeight = window.innerHeight;
        const vhValue = (pxValue / viewportHeight) * 100;
        return vhValue;
    }

    search(val) {
        if (this.initialData) {
            this.searchText = val;
            this.hideRecords = false;
            if (val == '' && this.noShowMessage != '' && this.noShowMessage != undefined) {
                this.hideRecords = true;
            }
            let fiteredItem = this.initialData.filter(obj => Object.keys(obj).some(key => String(obj[key]).toLowerCase().includes(this.searchText.toLowerCase())));
            if (fiteredItem.find(e => e?.index)) {
                fiteredItem.forEach((data, index) => {
                    data.index = index + 1;
                })
            }
            this.data = fiteredItem;
        }

    }

    selectAllData(e) {
        this.slctAll.emit(e.target.checked)
    }

    toggleState(index: number) {
        if (this.isExpandable) {
            if (this.expanded.has(index)) {
                this.expanded.delete(index);
            } else {

                this.expanded.add(index);
            }
        }
        this.rowClicked.emit(index);
    }

    viewDetails(record) {
        this.record.emit(record);
    }

    intersectionObserver() {
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };
        this.observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                this.lastcontent.emit();
            }
        }, options);
    }
}
