<div class="card  ">

    <div *ngFor="let item of cartProducts">
        <div class="row">
            <div class="cartHead  cartplaname">
                <div>
                    {{ item.name }} Program
                    <div *ngFor="let member of item.family_info" class="applicantName">
                        {{ member.firstname | titlecase }}
                    </div>
                </div>
                <div class="carthead">
                    {{ item.total_price | currency:'USD' }}
                </div>
            </div>

            <div *ngIf="nicotineData.length>0" class="cartHead  cartplaname">
                <div>

                    <div *ngFor="let data of nicotineData" class="applicantName">
                        {{ data.fee_name | titlecase }}
                    </div>
                </div>
                <div *ngFor="let data of nicotineData" class="carthead">
                    {{ data.fee | currency:'USD' }}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="supplementalProducts.length>0">
        <div style="font-weight: 600;">Supplemental Products</div>
        <div *ngFor="let item of supplementalProducts" class="row">
            <div class="col-lg-8 col-md-8 col-xs-8 lfthead">
                {{ item.Name }}
            </div>

            <div class="col-lg-4 col-md-4 col-xs-4 lfthead" style="text-align: right;">
                {{ item.retail_price | currency:'USD' }}
            </div>
        </div>
    </ng-container>


    <div *ngIf="addOnProducts.length>0" class="row">
        <div class="col-lg-12">
            <hr style=" border-top: 1px dashed  #c1c1c1;">
        </div>
    </div>
    <ng-container *ngIf="addOnProducts.length>0">
        <div style="font-weight: 600;">Add-on Products</div>
        <div *ngFor="let item of addOnProducts" class="row">
            <div class="col-lg-8 col-md-8 col-xs-8 lfthead">
                {{ item.Name }}
            </div>

            <div class="col-lg-4 col-md-4 col-xs-4 lfthead" style="text-align: right;">
                {{ item.retail_price | currency:'USD' }}
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-lg-12">
            <hr style=" border-top: 1px dashed  #c1c1c1;">
        </div>
        <div class="col-6">
            <p class="cart-card-data">Total Price</p>
        </div>
        <div class="col-6">
            <p class="cart-card-value text-right">{{ total_price | currency:'USD' }} </p>
        </div>
    </div>
</div>
<div class="card  ">
    <div style="font-weight: 600;">Onetime Fee<span class="fieldMan">*</span></div>
    <div *ngFor="let item of cartFee" class="row">
        <div *ngIf="item.fee_name !='Nicotine Fee'" class="col-6 lfthead">
            {{ item.fee_name }}
        </div>
        <div *ngIf="item.fee_name !='Nicotine Fee'" class="col-6 lfthead" style="text-align: right;">
            {{ item.fee | currency:'USD' }}
        </div>
    </div>
    <!-- <div *ngIf="cartFee?.length > 0">
      <hr style=" border-top: 1px dashed  #c1c1c1;">
      <div class="row" *ngFor="let item of cartFee">
        <div class="col-lg-8 col-md-8 col-xs-8 lfthead" *ngIf="item.discount !=0">
          {{item.fee_name}} <span>Discount</span>
        </div>
        <div class="col-lg-4 col-md-4 col-xs-4 lfthead" *ngIf="item.discount !=0" style="text-align: right;">
          {{item.discount | currency:'USD'}}
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <hr style=" border-top: 1px dashed  #c1c1c1;">
      </div>
      <div class="col-6" >

        <p class="cart-card-data">Total Onetime Fee</p>
      </div>
      <div class="col-6" >
        <p class="cart-card-value text-right">{{oneTimeFee | currency:'USD'}}</p>
      </div>
    </div> -->
</div>
<div *ngIf="cart_group_discount?.length > 0" class="card">
    <div *ngFor="let data of cart_group_discount">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-xs-8" style="font-weight: 600;">
                {{ data.name }}
            </div>
            <div class="col-lg-4 col-md-4 col-xs-4" style="text-align: right;font-weight: 600;">
                {{ data.discount | currency:'USD' }}
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div *ngIf="cartData?.length > 0">
        <div *ngFor="let data of cartData">
            <div class="row">
                <div class="col-8">
                    <p class="cart-card-data">Total Payments Today</p>
                </div>
                <div class="col-4">
                    <p class="cart-card-value text-right">{{ data.total | currency:'USD' }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="monthlyContributionStartDate" class="card">

    <div class="row">
        <div class="col-md-8">
            <p class="cart-card-data"> Monthly Contribution to be collected on or before {{ monthly_contribution_day }}
                th of each month starting {{ monthlyContributionStartDate|date:'MM/dd' }}</p>
        </div>
        <div *ngIf="monthlyContribution" class="col-md-4">
            <p class="cart-card-value "> {{ monthlyContribution | currency:'USD' }} </p>
        </div>
    </div>

</div>
<!-- <div class="card" *ngIf="groupInfo?.length > 0">
  <div *ngFor="let item of groupInfo">
    <div class="row">
      <div class="col-lg-12 cartHead">
        Group Information
      </div>
      <div class="col-lg-12">{{item.name}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-4" style="padding-right: 0;">
        <div class="planehead">Contact Name</div>
        <div class="planehead">Mobile &nbsp; </div>
        <div class="planeh">E-mail &nbsp; </div>
      </div>
      <div class="col-lg-8 col-xs-8">
        <div class="planehead">{{item.firstname}} {{item.lastname}}</div>
        <div class="planehead">{{item.mobile}}</div>
        <div class="planeh">{{item.email}}</div>
      </div>
    </div>
  </div>
</div> -->

<div *ngIf="groupInfo?.length > 0" class="card">
    <div *ngFor="let item of groupInfo">
        <div class="row">
            <div *ngIf="item.groupID != null" class="col-lg-12 cartHead">
                Group Information
            </div>
            <div *ngIf="item.agent_id != null" class="col-lg-12 cartHead">
                {{ item.type }} Information
            </div>
            <!-- <div class="col-lg-12">{{item.name}}</div> -->
        </div>
        <div class="r">
            <table class="table borderless">
                <tr>
                    <td style="width: 25%;">Name</td>
                    <td style="width: 75%;">: {{ item.firstname }} {{ item.lastname }}</td>
                </tr>
                <tr>
                    <td>Mobile</td>
                    <td>: {{ item.mobile }}</td>
                </tr>
                <tr>
                    <td>E-mail</td>
                    <td style="word-break: break-all;">: {{ item.email }}</td>
                </tr>
            </table>
            <!-- <div class="col-lg-4 col-xs-4" style="padding-right: 0;">
                      <div class="planehead">Contact Name</div>
                      <div class="planehead">Mobile &nbsp; </div>
                      <div class="planeh">E-mail &nbsp; </div>
                  </div>
                  <div class="col-lg-8 col-xs-8">
                      <div class="planehead">{{item.firstname}} {{item.lastname}}</div>
                      <div class="planehead">{{item.mobile}}</div>
                      <div class="planeh">{{item.email}}</div>
                  </div> -->
        </div>
    </div>
</div>
