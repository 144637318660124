<div class="container m-top" style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep " id="app2" style="z-index:6; padding-left: 30px;">
            <span>Demographics &amp; Dependents</span>
        </div>
        <div class="appProgStep " id="app1" style="z-index:5; padding-left: 40px;"><span>Member Agreement </span>
        </div>
        <div class="appProgStep active" id="app3" style="z-index:3;"><span>Health-Based Questions</span></div>
        <div class="appProgStep" id="app4" style="z-index:2;"><span>Payment Details</span></div>
        <div class="appProgStep appProgStep-last" id="app5" style="z-index:1;">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <form [formGroup]="Form">
            <div class="row">
                <div class="col-md-12 ">
                    <div *ngFor="let list of data;let i=index">
                        {{ i + 1 }}) {{ list.name }}
                        <div class="row">
                            <div *ngFor="let item of list.health_issue" class="col-md-6">
                                <div *ngIf="item.field_type=='Multiple-Checkbox'">
                                    <label> <input (change)="selectoptions(item, $event.target.checked, i)" name="{{item.health_issue_id}}"
                                                   type="checkbox"
                                                   value="{{item.health_issue_id}}"> {{ item.value }}
                                    </label>
                                </div>
                                <div *ngIf="item.field_type=='Checkbox'">
                                    <label>
                                        <input (change)="selectoptionsradio(item, $event.target, i)" name="{{item.health_id}}"
                                               type="radio"
                                               value="{{item.health_issue_id}}">
                                        {{ item.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="employeedetails" class="col-lg-12">
                    <div class="col-lg-12" formArrayName="spouse_Array">
                        <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index">
                            <div [formGroupName]="empIndex1">
                                <div class="row">
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1"> First Name</div>
                                        <input class="form-control" formControlName="name" maxlength="25"
                                               placeholder="First Name" type="text">
                                    </div>
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1">Relation</div>
                                        <input class="form-control" formControlName="relationship" maxlength="25"
                                               placeholder="Relation" type="text">
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="head_txt1"> Date Of Birth</div>
                                        <p-calendar [disabledDates]="invalidDates1"
                                                    [maxDate]="minimumDate"
                                                    [monthNavigator]="true" [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                                    [yearNavigator]="true" [yearRange]="'1956:' + maxDate.getFullYear()"
                                                    formControlName="dob" inputId="navigators"
                                                    placeholder="mm/dd/yyyy">
                                        </p-calendar>
                                    </div>
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1">Physician Name</div>
                                        <input class="form-control" formControlName="physician_name" maxlength="25"
                                               placeholder="Physician Name" type="text">
                                    </div>
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1">Diagnosis</div>
                                        <input class="form-control" formControlName="diagnosis"
                                               placeholder="Diagnosis" type="text">
                                    </div>
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1">Date of Diagnosis</div>
                                        <p-calendar [disabledDates]="invalidDates1"
                                                    [maxDate]="minimumDate"
                                                    [monthNavigator]="true" [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                                    [yearNavigator]="true" [yearRange]="'1956:' + maxDate.getFullYear()"
                                                    formControlName="date_of_diagnosis" inputId="navigators"
                                                    placeholder="mm/dd/yyyy">
                                        </p-calendar>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-12 align">
                                        <button (click)="removeEmployee(empIndex1,spouseForm.value,Form.value);" *ngIf="empIndex1>0"
                                                class="btn btn-sm1 btn-danger btn-remove"
                                                type="button">Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 align mt-4">
                            <button (click)="addSpouse()" class="btn btn-sm btn-primary" type="button">
                                <i aria-hidden="true" class="fa fa-user"></i> Add More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                </div>
                <div class="col-md-6" style="text-align: end;">
                    <button (click)="submit(Form.value)" class="btn btn-sm btn-primary" type="button">
                        <i aria-hidden="true" class="fa fa-user"></i> Next
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
