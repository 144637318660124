import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TestService {
    subject = new Subject();
    cardName = "";
    cardcheckCondition: boolean;
    private memberinfo = new BehaviorSubject<any>('');//step1
    memberInfoData = this.memberinfo.asObservable();//step1
    private memberinfostep2 = new BehaviorSubject<any>('');//step1
    memberInfostep2 = this.memberinfostep2.asObservable();//step1
    private memberbackinfo = new BehaviorSubject<any>('');//step1
    memberBackInfo = this.memberbackinfo.asObservable();//step1
    private appCartMemberInfo = new BehaviorSubject<any>('');
    cartMemberId = this.appCartMemberInfo.asObservable();
    private MemberData = new BehaviorSubject<any>('');
    memberInfo = this.MemberData.asObservable();
    private clientInfo = new BehaviorSubject<any>('');
    getClientData = this.clientInfo.asObservable();
    private usernameSource = new BehaviorSubject<any>('');
    username = this.usernameSource.asObservable();
    private AddCartPlan = new BehaviorSubject<any>('');
    cartPlan = this.AddCartPlan.asObservable();
    private demographyObj = new BehaviorSubject<any>('');
    demographyObject = this.demographyObj.asObservable();
    private paymentDetails = new BehaviorSubject<any>('');
    paymentObject = this.paymentDetails.asObservable();
    private searchDetails = new BehaviorSubject<any>('');
    searchObject = this.searchDetails.asObservable();
    private backQuestions = new BehaviorSubject<any>('');
    questionsObject = this.backQuestions.asObservable();
    private backtoPlans = new BehaviorSubject<any>('');
    plansObject = this.backtoPlans.asObservable();
    private backtoSummary = new BehaviorSubject<any>('');
    plansSObject = this.backtoSummary.asObservable();
    private demotoA2c = new BehaviorSubject<any>('');
    demotoA2C = this.demotoA2c.asObservable();
    //new
    private appCartData = new BehaviorSubject<any>('');
    appcartData = this.appCartData.asObservable();

    constructor() {
    }

    appCartmemberInfoInfo(company_id: any, member_id: any, cart_id: any) {
        this.appCartMemberInfo.next({company_id, member_id, cart_id})
    }

    memberInFo(memberInfoData: any) {//step1
        this.memberinfo.next(memberInfoData);
    }

    memberFullDetails(memberInfostep2: any) {//step2
        this.memberinfostep2.next(memberInfostep2);
    }

    backMemberInFo(memberbackinfo: any) {
        this.memberbackinfo.next(memberbackinfo)
    }

    appCartInfo(appcartData: any) {
        this.appCartData.next("");
        this.appCartData.next(appcartData);
    }

    demoToA2c(demotoA2C: any) {
        this.demotoA2c.next(demotoA2C)
    }

    backToHome(memberInfo) {
        this.MemberData.next(memberInfo);
    }

    changeUsername(username: any) {
        this.usernameSource.next(username);
    }

    addCartPlan(cartPlan: any) {
        this.AddCartPlan.next(cartPlan);
    }

    demographyH(demographyObject: any) {
        this.demographyObj.next(demographyObject);
    }

    searchFilter(searchObject: any) {
        this.searchDetails.next(searchObject);
    }

    paymentMethod(paymentObject: any) {
        this.paymentDetails.next(paymentObject);
    }

    questionsback(questionsObject: any) {
        this.backQuestions.next(questionsObject);
    }

    plansback(plansObject: any) {
        this.backtoPlans.next(plansObject);
    }

    planSsback(plansSObject: any) {
        this.backtoSummary.next(plansSObject);
    }

    pushcardType(cardType) {
        this.cardName = cardType;
    }

    getCardType() {
        return this.cardName;
    }

    pushcardCheckCondition(condition) {
        this.cardcheckCondition = condition;
    }

    getcardCheckCondition() {
        return this.cardcheckCondition
    }


    pushheaderCondition(data) {
        this.subject.next(data);
    }

    getheaderCondition(): Observable<any> {
        return this.subject.asObservable();
    }

    pushClientInfo(data) {
        this.clientInfo.next(data)
    }

    getClientInfo(): Observable<any> {
        return this.clientInfo.asObservable();
    }
}
