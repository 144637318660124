<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    <div class="progress_bar">
        <ul class="progess-bar mb-7">
            <li>Enrollment</li>
            <li class="active">Products</li>
            <li>Payment Details</li>
            <li>Beneficiary</li>
            <li>Agreement</li>
            <li>Disclosure 1</li>
            <li>Disclosure 2</li>
            <li>Summary</li>
            <li>Confirmation</li>
        </ul>
    </div>
</div>


<div class="card-primary " id="mobile-grid-card">
    <div class="container table-responsive pt-5 pb-3 ">
        <table class="table table-bordered">
            <thead class="">
            <tr>
                <th colspan="9">
                    <h6 class="table-title">Plan Name : {{ plan_name }}</h6>
                </th>
            </tr>
            </thead>
            <thead class="tableheader">

            <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Relation</th>
                <th scope="col">Age</th>
                <th scope="col">Unit price for <br> $1000</th>
                <th scope="col">Minimum</th>
                <th scope="col">Maximum</th>
                <th scope="col">Policy Amount</th>
                <th scope="col">Total Monthly <br> Cost</th>

            </tr>
            </thead>
            <tbody>

            <ng-container *ngFor="let item of data; let i=index">

                <tr *ngIf="item.relation=='Primary' || item.relation=='Spouse'">
                    <td>
                        <div class="form-check">
                            <input (change)="selectRecord($event.target.checked , item)" [checked]="item.selected"
                                   class="form-check-input"
                                   type="checkbox">
                        </div>
                    </td>
                    <td>{{ item.firstname | titlecase }} {{ item.lastname | titlecase }}</td>
                    <td>{{ item.relation }}</td>
                    <td>{{ item.age }}</td>
                    <td>{{ item.price | currency:'USD':'symbol':'1.2-4' }}</td>
                    <td> ${{ item.min | number:'1.0-0' }}</td>
                    <td> ${{ item.max | number:'1.0-0' }}</td>
                    <td>
                        <select (change)="checkIfActiveState($event,item)" [(ngModel)]="item.scale"
                                class="form-control form-select dropdownpadding p-2"
                                id="state">
                            <option Value="" disabled>Select Your Amount</option>
                            <option *ngFor="let scale of item.scale_list"
                                    [value]=scale>{{ scale | currency:'USD': number:'1.0-0' }}
                            </option>
                        </select>
                    </td>
                    <td>
                            <span *ngIf="item.isshowtotalprice">
                                {{ item.total_price | currency:'USD' }}
                            </span>
                        <span *ngIf="!item.isshowtotalprice">
    
                            </span>
                    </td>

                </tr>

                <tr (mouseenter)="applyHoverEffect(true)" (mouseleave)="applyHoverEffect(false)"
                    *ngIf="item.relation=='Child'"
                    [class.hover-effect]="hoverActive" class="child-row">
                    <td>
                        <div class="form-check">
                            <input (change)="selectRecord($event.target.checked , item)" [checked]="item.selected"
                                   class="form-check-input"
                                   type="checkbox">
                        </div>
                    </td>
                    <td>{{ item.firstname | titlecase }} {{ item.lastname | titlecase }}</td>
                    <td>{{ item.relation }}</td>
                    <td>{{ item.age }}</td>
                    <td *ngIf="i==item.col_length"
                        [attr.rowspan]="item.child_length">{{ item.price | currency:'USD':'symbol':'1.2-4' }}
                    </td>
                    <td *ngIf="i==item.col_length" [attr.rowspan]="item.child_length">
                        ${{ item.min | number:'1.0-0' }}
                    </td>
                    <td *ngIf="i==item.col_length" [attr.rowspan]="item.child_length">
                        ${{ item.max | number:'1.0-0' }}
                    </td>
                    <td *ngIf="i==item.col_length" [attr.rowspan]="item.child_length">
                        <select (change)="checkIfActiveState($event,item)" [(ngModel)]="item.scale"
                                class="form-control form-select dropdownpadding p-2"
                                id="state">
                            <option Value="" disabled>Select Your Amount</option>
                            <option *ngFor="let scale of item.scale_list"
                                    [value]=scale>{{ scale | currency:'USD': number:'1.0-0' }}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="i==item.col_length" [attr.rowspan]="item.child_length">
                        {{ item.total_price | currency:'USD' }}
                        <!-- <span *ngIf="item.isshowtotalprice">
                            {{item.total_price | currency:'USD'}}
                        </span>
                        <span *ngIf="!item.isshowtotalprice"> //future use
                        </span>-->
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="text-end" colspan="8">
                    <b class="fontsize">Total</b>
                </td>
                <td>
                    <b class="fontsize">
                        {{ grand_total | currency:'USD' }}

                    </b>
                </td>

            </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="container">
    <!--Agent Info Section-->
    <div *ngIf="agentInfo?.id>0" class="card shadow-sm mb-5">
        <div class="card-body">
            <h6 class="title-mainhead">
                <span>Agent Information</span>
            </h6>

            <div class="row">
                <div class="col-md-6">
                    <div class="info-box">
                        <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span> <span
                                *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                        <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                        <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-6">
            <button (click)="onPrevious()" class="btn btn-outline-primary ">
                Previous
            </button>
        </div>
        <div class="col-md-6 mb-2" style="text-align: end;">
            <button (click)="sendMultipleEmail()" class="btn btn-primary ">Next</button>
        </div>
    </div>
</div>
