import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    url: string;
    companyId: string;
    companyInfo: any;
    logo: any;
    MemberLogin: any;
    header_array: any;
    footer_array: any;
    disclaimer_array: any;
    companyName: any;
    resource_plans: any;
    routepath: string;
    plans: any;
    disclaimer: any;
    supportEmail: any;
    supportPhone: any;
    template: any;
    display_enrollment: boolean;
    public_url: any;
    groupLogo: any;
    displayHeaderSection: boolean = false;
    isDemoPortal: boolean = false;

    constructor(
        private behaviourservice: TestService,
        private service: SharingService
    ) {
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                this.logo = data.apiURL.slice(0, -4) + data.companyLogo;
                this.companyName = this.companyInfo.company_name;
                this.isDemoPortal = this.companyInfo.demo_portal === '1' ? true : false; //Boolean to use only for the demo portal(s)
                this.MemberLogin = this.companyInfo.MemberLogin;
                this.public_url = data.public_url;
                this.template = data?.template;
                if (this.template !== 'template_2' && this.template !== 'package') {
                    this.getMenu();
                }
                this.supportEmail = data?.support_email;
                this.supportPhone = data?.support_phone;
                localStorage.removeItem('social_media');
            }
        });
        if (window.location.href.includes('affiliate')) {
            this.display_enrollment = false
        } else {
            this.display_enrollment = false;
        }
        if (window.location.href.includes('/MemberEnrollment/')) {
            this.displayHeaderSection = true;
        }
        if (window.location.href.includes('/addgroup/')) {
          this.displayHeaderSection = true;
      } else {
            this.displayHeaderSection = false;
        }
    }

    ngOnInit(): void {
        this.service.grouplogo.subscribe((data: any) => {
            if (data != '') {
              this.groupLogo = data;
            }
        })
    }

    navigateToPublic() {
        if (!!this.public_url) {
            window.open(this.public_url, '_blank');
        }
    }

    getMenu(): void {
        var url = 'GetClientInfo';
        let body = {
            company_id: this.companyId
        }
        this.service.postData(url, body).subscribe((Object: any) => {
            if (Object.status) {
                if (Object.data) {
                    this.header_array = Object.data[0].headers[0].menus;
                    this.resource_plans = Object.data[0].headers[1].resource_plans;
                    this.disclaimer = Object.data[0].headers[2].disclaimer;

                    this.footer_array = Object.data[1].footer[0].social_media;
                    this.disclaimer_array = Object.data[1].footer[1].disclaimer;
                    localStorage.setItem('disclaimer', JSON.stringify(this.disclaimer_array))
                    localStorage.setItem('social_media', JSON.stringify(this.footer_array))
                    this.behaviourservice.pushClientInfo(Object.data)
                }
            }
        }, err => {
            console.log(err)
        })
    }

    route(value) {
        if (value == 'HOME') {
            this.routepath = 'https://hopehealthshare.com'
        } else if (value == 'PROGRAMS' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/programs/'
        } else if (value == 'ABOUT') {
            this.routepath = 'https://hopehealthshare.com/about/'
        } else if (value == 'FAQ') {
            this.routepath = 'https://hopehealthshare.com/faq/'
        } else if (value == 'CONTACT US' && origin.includes('hope')) {
            this.routepath = 'https://hopehealthshare.com/contact/'
        } else if (value == 'WHO WE ARE') {
            this.routepath = 'https://www.joppahealth.org/who-we-are/'
        } else if (value == 'HOW IT WORKS') {
            this.routepath = 'https://www.joppahealth.org/how-it-works/'
        } else if (value == 'PROGRAMS') {
            this.routepath = 'https://www.joppahealth.org/programs/ '
        } else if (value == 'CONTACT US') {
            this.routepath = 'https://www.joppahealth.org/contact/'
        } else if (value == 'STATE NOTICES') {
            this.routepath = 'https://www.joppahealth.org/state-specific-notices/'
        }


    }

    Brochure(value) {
        if (value == 'Hope Health Brochure Plus') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Brochure-Plus.pdf'
        } else if (value == 'Hope Health Guide Plus') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Guide-Plus.pdf'
        } else if (value == 'Hope Health Brochure Premium') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Brochure-Premium.pdf'
        } else if (value == 'Hope Health Guide Premium') {
            this.plans = 'https://thedivinitygroup.com/Hope-Health-Guide-Premium.pdf'
        }

    }
}
