import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { PDFDocument } from 'pdf-lib';
import { DatePipe, ViewportScroller } from '@angular/common';
import { SharingService } from 'src/app/services/sharing.service';
@Component({
  selector: 'app-single-page-template',
  templateUrl: './single-page-template.component.html',
  styleUrl: './single-page-template.component.scss'
})
export class SinglePageTemplateComponent {
  enrollment_form: any
  submitAttempt: boolean = false;
  maxDate: Date;
  minimumDate: Date;
  invalidDates: Date[];
  invalidDates1: Date[];
  display_dependents: boolean;
  phnumbererrmsg = "";
  emailError: any;
  phoneerrmsg: any;
  errorMsgShow: boolean = true;
  Age: any;
  display_payment_section: boolean = true;
  display_address_section: boolean = true;
  display_summary_section: boolean = true;
  display_dependents_section: boolean = true;
  active_index = 0;
  members = [{ value: '0 Dependent' }, { value: '1 Dependent' }, { value: '2 Dependents' }, { value: '3 Dependents', }, { value: '4 Dependents' }, { value: '5 Dependents' }, { value: '6 Dependents' }]
  companyId: any;
  value: any;
  parentCompanyId: any;
  route_company_name: any;
  member_personal_data: any = {}
  objectData: any = {}
  primary_info: any;
  paymentTypes = [
    { name: 'Credit Card', value: 'credit' },
    { name: 'Debit Card', value: 'debit' },
    { name: 'ACH', value: 'ach' },
  ]
  paymentType = new FormGroup({
    type: new FormControl('credit')
  });
  obj: any = {};
  Credit_Form: any;
  Banking_Form: any;
  shipping_address_form: any;
  choosenPayemntType: any;
  cardType: string;
  submitCardAttempt: boolean = false;
  notValidCardNumber: boolean;
  checkCardValid: boolean;
  enterTwoDigitMonth: boolean;
  submitBankingAttempt: boolean = false;
  enrollmentType: any;
  country_arr = ['UnitedStates'];
  usaStates: any;
  cvvErr: any;
  card_number_Err: any;
  exp_date_err: any;
  billing_date: Date;
  selectedform: any;
  isShippingChecked: boolean = false;
  family_id: any;
  agreementData: any;
  member_id: any;
  member_document_id: any;
  sample: any;
  agent_text: any;
  agentCodeErrorMsg = "";
  // agentCodeParam: any;
  isAgentCodeApplied: boolean = false;
  applyBtnText = "Apply";
  agentInfo: any = null;
  agent_Id: any;
  pdfSrc: any | ArrayBuffer | null = null;
  loader: boolean = false;
  toasterTimeout: any;
  data:any;
  selectedRecordForProducts: any[];
  member_count: any;
  family_type = 'Primary';
  enrollment_type: any;
  user_company_id: any;
  cart_fee: any;
  monthly_contribution: any;
  cart_products: any;
  total_payment_today: any;
  child_Info: any;
  cart_id: any;
  discountValue: any;
  discountType: any;
  discountError: any = "";
  showDiscountSection = false;
  btndisabled: boolean;
  onetimeAppFee = 0;
  affiliate_active_index: any;
  order_id: any;
  isSubmitted: boolean = false;
  minDate: Date;
  members_info: any;
  cart_data: any;
  settings: any;
  effectiveMinDate: Date;
  effectiveMaxDate: Date;
  isRiderAvailable: boolean = false
  pdfBytes: ArrayBuffer | null = null;
  display_discount_button: boolean;
  btn_disabled: boolean = true
  display_agentcode_button: boolean;
  enteredRoutingNumber: any;
  reEnteredRoutingNumber: any;
  enteredAccountNumber: any;
  reEnteredAccountNumber: any;
  routingNumberMismatch: boolean = false;
  routingNumberField: any = "";
  accountNumberMismatch: boolean = false;
  accountNumberField: any = "";
  routing_number: any;
  account_number: any;
  display_discount:boolean;
  display_effective_date:boolean;
  effective_start_date='';
  change_effective_date='';
  paymentMessage: any;
  @Input() agentCodeParam: any;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private router: Router, private location: Location, private viewportScroller: ViewportScroller, private route: ActivatedRoute, private toastr: ToastrService, private datePipe: DatePipe, private service: SharingService,) {
    this.enrollment_form = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      // gender: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      dob: ['', Validators.required],
      age: [''],
      phone: ['', Validators.required],
      member_id: [''],
      member_count: [''],
      child_Array: this.formBuilder.array([]),
      agentCode: [this.agentCodeParam],

    })
    this.Credit_Form = this.formBuilder.group({
      name: ['', Validators.required],
      cardNumber: ['', Validators.required,],
      cvv: ['', Validators.required],
      expiryMonth: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])$/)]],
      expiryYear: ['', [Validators.required, Validators.pattern(/^(2[1-9]|[3-9]\d)$/)]]
    }, {
      validators: this.futureDateValidator()


    });
    this.Banking_Form = this.formBuilder.group({
      name: ['', Validators.required],
      routingNumber: ['', Validators.required],
      // reenterRouting: ['', Validators.required],
      accountNumber: ['', Validators.required],
      // reenterAccount: ['', Validators.required],
      type: ['', Validators.required],

    });
    this.shipping_address_form = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]],
      effectiveStartDate: ['']
    })
    this.agentCodeParam = this.route.snapshot.paramMap.get('affiliate_code');
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0 && params.hasOwnProperty('affiliate_code')) {
        this.agentCodeParam = params['affiliate_code'];
      }
      if (Object.keys(params).length != 0 && params.hasOwnProperty('utm_aff')) {
        this.agentCodeParam = params['utm_aff'];
      }
    }); // This is not needed now as this is being get as a input param from the directenrollment component
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.discountType = 'code'; // It can be code or amount
        this.settings = data;
        this.companyId = data.company_id;
        this.parentCompanyId = data.parent_company;
        this.route_company_name = data.route_company;
        this.agent_text = data.agent_text;
        this.getProductData();
        this.getUSstates();
        if (this.agentCodeParam) {
          this.applyAgentCode(this.agentCodeParam);
          this.affiliate_active_index = 0;
        }
      }
    })


  }

  ngOnInit(): void {
    let today = new Date();
    this.maxDate = new Date();
    let invalidDate1 = new Date();
    invalidDate1.setDate(today.getDate() + 3);
    this.invalidDates1 = [today, invalidDate1];
    this.minimumDate = new Date();
    // Calculate tomorrow's date
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    // Calculate the date 65 days from today
    let next30Days = new Date();
    next30Days.setDate(today.getDate() + 65);

    this.effectiveMinDate = tomorrow;
    this.effectiveMaxDate = next30Days;

    const now = new Date();
    this.billing_date = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    this.family_id = this.route.snapshot.paramMap.get('id');
    this.maxDate = new Date(now);
    this.maxDate.setDate(now.getDate() + 60);
    this.minDate = now;

    this.choosenPayemntType = 'credit';
  }
  newChild(): FormGroup {
    return this.formBuilder.group({
      childFirstname: ['',],
      childLastname: ['',],
      member_id: [''],
    })
  }

  futureDateValidator(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const month = group.get('expiryMonth')?.value;
      const year = group.get('expiryYear')?.value;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100; // Get last two digits
      const currentMonth = currentDate.getMonth() + 1;

      if (!month || !year) {
        return null; // skip validation if any field is empty
      }

      if (year > currentYear || (year == currentYear && month >= currentMonth)) {
        return null; // null means valid
      } else {
        return { 'expiryInvalid': true }; // return an object if it is invalid
      }
    };
  }
  getUSstates() {
    const url = 'ListUsaStatesACC';
    this.service.getData(url).subscribe((Object: any) => {
      if (Object !== null) {
        this.usaStates = Object.data;
      }
    }, err => {
      console.log(err);
      this.toastr.error(err.error.message);
    })
  }




  onKeyup(item) {
    let ccnum = item.replace(/\s/g, "");
    // this.checkCardValid=false;
    var ccCheckRegExp = /[^\d\s-]/;
    var isValid = !ccCheckRegExp.test(ccnum);
    var i;
    if (isValid) {
      var cardNumbersOnly = ccnum.replace(/[\s-]/g, "");
      var cardNumberLength = cardNumbersOnly.length;
      var arrCheckTypes = ['visa', 'mastercard', 'amex', 'discover', 'dinners', 'jcb'];
      for (i = 0; i < arrCheckTypes.length; i++) {
        var lengthIsValid = false;
        var prefixIsValid = false;
        var prefixRegExp;
        switch (arrCheckTypes[i]) {
          case "mastercard":
            lengthIsValid = (cardNumberLength === 16);
            prefixRegExp = /5[1-5][0-9]|(2(?:2[2-9][^0]|2[3-9]|[3-6]|22[1-9]|7[0-1]|72[0]))\d*/;
            this.cardType = arrCheckTypes[i]
            break;

          case "visa":
            lengthIsValid = (cardNumberLength === 16 || cardNumberLength === 13);
            prefixRegExp = /^4/;
            this.cardType = arrCheckTypes[i]
            break;

          case "amex":
            lengthIsValid = (cardNumberLength === 15);
            prefixRegExp = /^3([47])/;
            this.cardType = arrCheckTypes[i]
            break;

          case "discover":
            lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
            prefixRegExp = /^(6011|5)/;
            this.cardType = arrCheckTypes[i]
            break;

          case "dinners":
            lengthIsValid = (cardNumberLength === 14);
            prefixRegExp = /^(300|301|302|303|304|305|36|38)/;
            break;

          case "jcb":
            lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
            prefixRegExp = /^(2131|1800|35)/;
            break;

          default:
            prefixRegExp = /^$/;
        }
        prefixIsValid = prefixRegExp.test(cardNumbersOnly);
        isValid = prefixIsValid && lengthIsValid;
        // Check if we found a correct one
        if (isValid) {
          this.notValidCardNumber = false;
          break;
        }
      }
    }

    if (!isValid) {
      this.notValidCardNumber = true;
      return false;
    }

    // Remove all dashes for the checksum checks to eliminate negative numbers
    ccnum = ccnum.replace(/[\s-]/g, "");
    // Checksum ("Mod 10")
    // Add even digits in even length strings or odd digits in odd length strings.
    var checksum = 0;
    for (i = (2 - (ccnum.length % 2)); i <= ccnum.length; i += 2) {
      checksum += parseInt(ccnum.charAt(i - 1));
    }
    // Analyze odd digits in even length strings or even digits in odd length strings.
    for (i = (ccnum.length % 2) + 1; i < ccnum.length; i += 2) {
      var digit = parseInt(ccnum.charAt(i - 1)) * 2;
      if (digit < 10) {
        checksum += digit;
      } else {
        checksum += (digit - 9);
      }
    }
    if ((checksum % 10) === 0) {
      this.checkCardValid = true;
    }
    return (checksum % 10) === 0;
  }
  memberEnrollment(values) {
    this.submitAttempt = true;
    // this.display_payment_section = false;
    if (this.enrollment_form.valid) {
      this.objectData.company_id = this.companyId;
      this.objectData.product_company_id = this.companyId;
      this.objectData.group_name =location.host.split('.')[0],
      this.objectData.agent_id = this.agent_Id;
      this.objectData.member_personal_data = new Array<any>();
      this.member_personal_data.firstname = values?.firstname;
      this.member_personal_data.lastname = values?.lastname;
      this.member_personal_data.member_id = values?.member_id;
      this.member_personal_data['relation'] = "Primary";
      this.member_personal_data.dob = this.datePipe.transform(values?.dob, "yyyy-MM-dd");
      this.member_personal_data.age = values?.age;
      this.member_personal_data.gender = 'NA';
      this.member_personal_data.email = values?.email;
      this.member_personal_data.phone = values?.phone.replace(/[^0-9\.]+/g, "");
      this.objectData.startdate = "";
      this.member_personal_data.weight = "";
      this.member_personal_data.smoker = "";
      this.member_personal_data.address1 = "";
      this.member_personal_data.address2 = "";
      this.member_personal_data.city = "";
      this.member_personal_data.state = "";
      this.member_personal_data.zip = "";
      this.member_personal_data.height = "";
      this.objectData.member_personal_data.push(this.member_personal_data);
      for (var i = 0; i < values?.child_Array.length; i++) {
        this.member_personal_data = {};
        if (!!values?.child_Array[i]?.childFirstname && !!values?.child_Array[i]?.childLastname) {
          this.member_personal_data.firstname = values?.child_Array[i]?.childFirstname;
          this.member_personal_data.lastname = values?.child_Array[i]?.childLastname;
          this.member_personal_data['relation'] = "Dependent";
          this.member_personal_data.member_id = values?.child_Array[i]?.member_id;
          this.member_personal_data.active = 1;
          this.member_personal_data.smoker = "";
          this.member_personal_data.weight = "";
          this.member_personal_data.dob = "";
          this.member_personal_data.age = "";
          this.member_personal_data.gender = "NA";
          this.member_personal_data.address1 = "";
          this.member_personal_data.address2 = "";
          this.member_personal_data.city = "";
          this.member_personal_data.state = "";
          this.member_personal_data.zip = "";
          this.member_personal_data.height = "";
          this.objectData.member_personal_data.push(this.member_personal_data);
        }
      }
      var finalObject: any = {}
      finalObject = this.objectData;
      var url = 'AddMemberD2C';
      this.loader = true;
      this.service.postData(url, finalObject).subscribe((Object: any) => {
          this.loader = false;
        if (Object.status === true) {
          this.user_company_id = Object.user_company_id;
          this.enrollment_type = Object.member_personal_data[0]?.enrollment_type;
          this.primary_info = Object.member_personal_data.filter(x => x['relation'] == "Primary");
          this.child_Info = Object.member_personal_data.filter(x => x['relation'] == "Dependent");
          if (this.primary_info) {
            this.enrollment_form.controls['member_id'].setValue(this.primary_info[0]?.member_id);
            this.family_id = this.primary_info[0]?.family_id;
            this.member_id = this.primary_info[0]?.member_id
            this.Credit_Form.controls.name.setValue(this.primary_info[0]?.firstname + ' ' + this.primary_info[0]?.lastname);
            this.Banking_Form.controls.name.setValue(this.primary_info[0]?.firstname + ' ' + this.primary_info[0]?.lastname);
            this.addCart();
          }
          this.submitAttempt = false;
          if (values?.member_count !== '') {
            const childArray = this.enrollment_form?.get('child_Array') as FormArray;
            childArray?.clear();
            this.active_index = 1;
            var member_count = values?.member_count.charAt(0);
            if (member_count !== '' && member_count !== '0') {
              this.display_dependents = true;
            } else {
              this.display_payment_section = false;
            }
            if (this.child_Info?.length > 0) {
              if (Number(member_count) === this.child_Info.length) {
                this.active_index = 2;
                this.display_payment_section = false;
              } else {
                this.active_index = 1;
              }
              for (var i = 0; i < member_count; i++) {
                this.child_Array().push(this.newChild());
                this.enrollment_form.controls['child_Array']['controls'][i].get('childFirstname').setValidators([Validators.required]);
                this.enrollment_form.controls['child_Array']['controls'][i].get('childLastname').setValidators([Validators.required]);
                if (this.child_Info[i]?.firstname) {
                  this.enrollment_form.controls['child_Array']['controls'][i].get('childFirstname').setValue(this.child_Info[i]?.firstname)
                }
                if (this.child_Info[i]?.lastname) {
                  this.enrollment_form.controls['child_Array']['controls'][i].get('childLastname').setValue(this.child_Info[i]?.lastname)
                }
                if (this.child_Info[i]?.member_id) {
                  this.enrollment_form.controls['child_Array']['controls'][i].get('member_id').setValue(this.child_Info[i]?.member_id)
                }
              }
            } else {
              for (var i = 0; i < member_count; i++) {
                this.child_Array().push(this.newChild());
                this.enrollment_form.controls['child_Array']['controls'][i].get('childFirstname').setValidators([Validators.required]);
                this.enrollment_form.controls['child_Array']['controls'][i].get('childLastname').setValidators([Validators.required]);
              }
            }
          } else {
            if (this.child_Info.length > 0) {
              this.active_index = 2;
              this.display_payment_section = false;
            } else {
              this.active_index = 1;
              this.display_payment_section = false;
            }
          }

        }
      }, err => {
        if (err.error.message == "Unauthenticated.") {
          this.loader = false;
          this.toastr.clear();
          this.toastr.error(err.error.message, '', {
            closeButton: true,
            timeOut: this.toasterTimeout,
          });
        }
        else {
          console.log(err);
          this.loader = false;
          this.errorMsgShow = true;
          this.emailError = err.error.email;
          this.phoneerrmsg = err.error.phone;
          this.toastr.clear();
          this.toastr.error(err.error.message, '', {
            closeButton: true,
            timeOut: this.toasterTimeout,
          });
        }
      })
    }

  }
  child_Array(): FormArray {
    return this.enrollment_form.get("child_Array") as FormArray
  }
  enrollmentForm(value) {
    if (value === 'payment') {
      this.submitBankingAttempt = false;
      this.submitCardAttempt = false;
      if (this.child_Info?.length > 0) {
        this.active_index = 2;
      } else {
        this.active_index = 1;
      }
    } else if (value === 'billing') {
      if (this.child_Info?.length > 0) {
        this.active_index = 3;
      } else {
        this.active_index = 2;
      }
    } else if (value === 'dependent') {
      this.active_index = 1;
    } else if (value === 'summary') {
      if (this.child_Info?.length > 0) {
        this.active_index = 4;
      } else {
        this.active_index = 3;
      }
    }
    else {
      this.active_index = 0;
    }
  }

  onOptionsSelected(value) {
    const childArray = this.enrollment_form?.get('child_Array') as FormArray;
    childArray?.clear();
    this.member_count = 0;
    this.member_count = value?.charAt(0);
    if (this.member_count !== '' && this.member_count !== '0') {
      if (!!this.family_id) {
        if (this.child_Info?.length > 0) {
          for (var i = 0; i < this.member_count; i++) {
            this.child_Array().push(this.newChild());
            if (this.child_Info[i]?.firstname) {
              this.enrollment_form.controls['child_Array']['controls'][i].get('childFirstname').setValue(this.child_Info[i]?.firstname)
            }
            if (this.child_Info[i]?.lastname) {
              this.enrollment_form.controls['child_Array']['controls'][i].get('childLastname').setValue(this.child_Info[i]?.lastname)
            }
            if (this.child_Info[i]?.member_id) {
              this.enrollment_form.controls['child_Array']['controls'][i].get('member_id').setValue(this.child_Info[i]?.member_id)
            }

          }
        } else {
          for (var i = 0; i < this.member_count; i++) {
            this.child_Array().push(this.newChild());
          }
        }
        this.display_dependents = true;
        this.family_type = "Primary + " + this.member_count;
      } else {
        this.display_dependents = true;
        this.family_type = "Primary + " + this.member_count;
      }
      this.getProductData();
    } else {
      this.display_dependents = false;
      this.family_type = 'Primary';
      this.getProductData()
    }
  }
  getProductData() {
    this.loader = true;
    let url = "ListCompanyProductD2C";
    let body = {
      state_id: 0,//need to implement dynamicaly
      family_type: this.family_type,
      company_id: this.companyId,
      family_id: 0
    }
    this.service.postData(url, body).subscribe((Object: any) => {
      this.loader = false;
      if (Object.status == true) {
        this.data = Object.data;
        this.selectedRecordForProducts = [];
        this.data.forEach((el) => {
          this.selectedRecordForProducts = el.product_info;
        })
      }
    }, (err) => {
      this.data ;
      this.selectedRecordForProducts = [];
      this.data.forEach((el) => {
        this.selectedRecordForProducts = el.product_info;
      })
      this.loader = false;
      this.toastr.clear()
      this.toastr.error(err.error.message, '', {
        closeButton: true,
        timeOut: this.toasterTimeout,
      });

    })
  }
  addCart() {
    this.loader = true;
    var url = 'AddCartProductD2C';
    var body = {
      family_id: this.family_id,
      company_id: this.companyId,
      cart_type: "enrollment",
      products: this.selectedRecordForProducts,
      user_company_id: this.user_company_id,
      enrollment_type: this.enrollment_type
    }
    this.service.postData(url, body).subscribe((Object: any) => {
      this.loader = false;
      if (Object != null || Object != undefined) {
        this.getMemberSummary();
        this.cart_id = Object.cart_data[0]?.id
        localStorage.setItem("cart_id", Object.cart_data[0].id);
        this.display_dependents_section = false;
      }
    }, err => {
      if (err.error.message == "Unauthenticated.") {
        this.toastr.clear();
        this.toastr.error(err.error.message, '', {
          closeButton: true,
          timeOut: this.toasterTimeout,
        });
        this.loader = false;
      }
      else {
        console.log(err);
        this.toastr.clear();
        this.toastr.error(err.error.message, '', {
          closeButton: true,
          timeOut: this.toasterTimeout,
        });
        this.loader = false;
      }
    })

  }
  // Function, called when agent code gets changed
  showApplyBtn() {
    if (!this.agentCodeParam) {
      this.agentCodeErrorMsg = "";
      this.isAgentCodeApplied = false;
      this.agent_Id = undefined;
      this.agentInfo = null;
    }
  }

  // Function to apply agent code
  applyAgentCode(code = null) {
    //If code is not recevied in the parameter, then read from FORM;
    if (!code) {
      code = this.enrollment_form.value.agentCode;
    }
    if (code) {
      this.loader=true;
      this.agent_Id = undefined;
      this.applyBtnText = "Applying...";
      this.agentCodeErrorMsg = "";
      let formData: FormData = new FormData();
      formData.append('agent_code', code);
      this.enrollment_form.controls.agentCode.setValue(code)
      var url = 'ApplyAgentCode/' + code;
      this.service.getData(url).subscribe((response: any) => {
        this.loader = false;
        this.applyBtnText = "Apply";
        if (response.status == true) {
          this.isAgentCodeApplied = true;
          this.display_agentcode_button = true
          this.agent_Id = response.data.id;
          this.agentInfo = response.data;
          this.agentCodeParam= this.agentInfo?.code
          if (!!this.family_id) {
            this.getMemberSummary();
          }
          this.applyAgentMiddleFlow(this.agent_Id);
        }
        else {
          this.isAgentCodeApplied = false;
          this.agentCodeErrorMsg = response.message;
          this.agent_Id = undefined;
          this.agentInfo = null;
        }
      }, (err) => {
        this.loader = false;
        if (err.error.message == "Unauthenticated.") {
          this.toastr.clear();
          this.toastr.error(err.error.message, '', {
            closeButton: true,
            timeOut: this.toasterTimeout,
          });
        }
        else {
          this.toastr.clear();
          this.toastr.error(err.error.message, '', {
            closeButton: true,
            timeOut: this.toasterTimeout,
          });
          console.log(err);
        }
      });
    }
    else {
        this.loader = false;
      this.isAgentCodeApplied = false;
    }
  }

  applyAgentMiddleFlow(agent_id) {
    if (agent_id === 0 && !this.family_id) {
      this.agent_Id = '';
      this.display_agentcode_button = false;
      this.agentInfo = '';
      this.agentCodeParam = null;
      this.enrollment_form.controls['agentCode'].setValue('');
    }
    this.loader = true;
    if (!!this.family_id && agent_id !== 0) {
      let body = { "agent_id": this.agent_Id, "family_id": this.family_id }
      var url = 'applyAgentCodeToEnrollment'
      this.service.postData(url, body).subscribe((Object: any) => {
        this.loader = false;
        if (Object) {
          this.display_agentcode_button = true;
          this.toastr.success(Object.message);
        }
      }, err => {
        this.loader = false;
        this.toastr.error(err.error.message);
      })
    } else if (!!this.family_id && agent_id === 0) {
      let body = { "family_id": this.family_id }
      var url = 'applyAgentCodeToEnrollment'
      this.service.postData(url, body).subscribe((Object: any) => {
          this.loader = false;
        if (Object) {
          this.agent_Id = '';
          this.display_agentcode_button = false;
          this.agentInfo = '';
          this.change_effective_date = "";
          this.agentCodeParam = null;
          this.enrollment_form.controls['agentCode'].setValue('');
          this.toastr.success(Object.message);
        }
      }, err => {
          this.loader = false;
        this.toastr.error(err.error.message);
      })
    }
    this.loader = false;
  }

  onKeyUpEnterRoutingNumber(value) {
    this.enteredRoutingNumber = value;
    if (!this.routingNumberMismatch) {
      if (this.enteredRoutingNumber != this.reEnteredRoutingNumber) {
        this.routingNumberMismatch = true;
      }
      else {
        this.routingNumberMismatch = false;
      }
    }
    if (this.routingNumberMismatch) {
      if (this.enteredRoutingNumber == this.reEnteredRoutingNumber) {
        this.routingNumberMismatch = false;
      }
      else {
        this.routingNumberMismatch = true;
      }
    }
  }

  onKeyUpRoutingNumberMismatch(value) {
    this.reEnteredRoutingNumber = value;
    if (this.enteredRoutingNumber == value) {
      this.routingNumberMismatch = false;
    }
    else {
      this.routingNumberMismatch = true;
    }
    if (this.routing_number) {
      if (this.routing_number == value || this.enteredRoutingNumber == value) {
        this.routingNumberMismatch = false;
      }
      else {
        this.routingNumberMismatch = true;
      }
    }
  }

  onKeyUpEnterAccountNumber(value) {
    this.enteredAccountNumber = value;
    if (!this.accountNumberMismatch) {
      if (this.enteredAccountNumber != this.reEnteredAccountNumber) {
        this.accountNumberMismatch = true;
      }
      else {
        this.accountNumberMismatch = false;
      }
    }
    if (this.accountNumberMismatch) {
      if (this.enteredAccountNumber == this.reEnteredAccountNumber) {
        this.accountNumberMismatch = false;
      }
      else {
        this.accountNumberMismatch = true;
      }
    }
  }

  onKeyUpAccountNumberMismatch(value) {
    this.reEnteredAccountNumber = value;
    if (this.enteredAccountNumber == value) {
      this.accountNumberMismatch = false;
    }
    else {
      this.accountNumberMismatch = true;
    }
    if (this.account_number) {
      if (this.account_number == value || this.enteredAccountNumber == value) {
        this.accountNumberMismatch = false;
      }
      else {
        this.accountNumberMismatch = true;
      }
    }
  }


  listEsignMemberDocumentA2C() {
      this.loader = true;
    let body = { "member_id": this.member_id, "category": 'Term_Condition' }
    var url = 'ListEsignMemberDocumentA2C'
    this.service.postData(url, body).subscribe((Object: any) => {
        this.loader = false;
      this.agreementData = Object.data;
      this.isRiderAvailable = Object.is_rider_available
      localStorage.setItem('isRiderAvailable', this.isRiderAvailable ? 'yes' : 'no')
      this.agreementData.forEach(element => {
        element.index = this.agreementData.indexOf(element) + 1;
      });

      this.member_document_id = this.agreementData[0]?.member_document_id;
      //  localStorage.setItem("A2c_familyId",this.decryptData[0].family_id)
      this.getUserInfo()
    }, err => {
        this.loader = false;
      this.toastr.error(err.error.message);
    })
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.registerPdfSaveCallback()
    }, 300);

  }

  registerPdfSaveCallback() {
    const pdfViewerApplication = (window as any).PDFViewerApplication;

    if (pdfViewerApplication) {
      // Ensure that the viewer is loaded and the APIs are accessible
      if (pdfViewerApplication.pdfDocument) {
        // Do nothing if pdfDocument is already available (typically the case when the PDF is already rendered)
      } else {
        // This listens to an event which indicates that the PDF has been loaded into the viewer
        document.addEventListener('pdfloaded', () => this.registerPdfSaveCallback());
      }
    }
  }

  getUserInfo() {
    this.loader = true;
    var url = 'DownloadEsignMemberDocument/' + this.member_document_id
    this.service.downloadData(url).subscribe((obj: any) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(new Blob([obj], { type: obj.type })); // Change to readAsArrayBuffer
      reader.onloadend = () => {
        this.pdfSrc = URL.createObjectURL(new Blob([reader.result as ArrayBuffer])); // Use URL.createObjectURL to create a Blob URL for ngx-extended-pdf-viewer
        this.pdfBytes = reader.result as ArrayBuffer; // Save the ArrayBuffer for use with pdf-lib
        this.getFormFieldNames();
      }
      this.cdr.detectChanges()
      this.loader = false;
      this.registerPdfSaveCallback()

    }, err => {
      this.loader = false;
    })
  }

  async getFormFieldNames() {
    if (!this.pdfBytes) {
      console.error("PDF not loaded");
      return undefined;
    }
    const pdfDoc = await PDFDocument.load(this.pdfBytes);
    const form = pdfDoc.getForm();
    const formFields = form.getFields();
    const formFieldNames = formFields.map(field => field.getName());

    return formFieldNames;
  }
  onleavePhonenumber() {
    let mobile = (<HTMLInputElement>document.getElementById("phone-number")).value;
    if (mobile.length < 10 && mobile.length > 0) {
      this.phnumbererrmsg = "Please enter a 10 digit number";
    }
    if (mobile.length >= 10) {
      this.phnumbererrmsg = "";
    }
  }
  emailValidation(value) {
    if (this.emailError !== null || this.phoneerrmsg !== null) {
      this.errorMsgShow = false;
    }
  }

  //===============Age Calculation==========//
  onSearchChange(searchValue: string): void {
    if (searchValue !== "") {
      const convertAge = new Date(searchValue);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.enrollment_form.controls['age'].setValue(this.Age);
      /*if (this.Age <= 17) {
        this.parentAge = "Applicant  can not be younger than 18 years of age";
      }
      if (this.Age > this.limit_age) {
        this.parentAge = "Applicant  can not be older than age" + this.limit_age;
      }
      if (this.Age < this.limit_age + 1 && this.Age > 18) {
        this.parentAge = "";
      }*/
    }
  }

  ageCalculate(value) {
    if (value.dob) {
      const convertAge = new Date(value.dob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.enrollment_form.controls['age'].setValue(this.Age);
    }
  }

  nextBillingAddress() {
    if (this.paymentType.controls['type'].value === "credit" || this.paymentType.controls['type'].value === "debit") {
      this.submitCardAttempt = true;
      this.markFormGroupTouched(this.Credit_Form);
    }
    if (this.paymentType.controls['type'].value === "ach") {
      this.submitBankingAttempt = true
      this.markFormGroupTouched(this.Banking_Form);
    }
    if (this.Credit_Form.valid || this.Banking_Form.valid) {
      if (this.child_Info.length > 0) {
        this.submitCardAttempt = false;
        this.submitBankingAttempt = false
        this.display_address_section = false;
        setTimeout(() => {
          this.active_index = 3;
        }, 300);
      } else {
        this.submitCardAttempt = false;
        this.submitBankingAttempt = false
        this.display_address_section = false;
        setTimeout(() => {
          this.active_index = 2;
        }, 300);
      }
    }

  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onPaymentTypeChange(type) {
    this.choosenPayemntType = type;
    this.Credit_Form.reset();
    this.Banking_Form.reset();
    this.Credit_Form.controls.name.setValue(this.primary_info[0]?.firstname + ' ' + this.primary_info[0]?.lastname);
    this.Banking_Form.controls.name.setValue(this.primary_info[0]?.firstname + ' ' + this.primary_info[0]?.lastname);
  }

  Next() {
    this.loader = true;
    this.cvvErr = undefined;
    this.card_number_Err = undefined;
    this.exp_date_err = undefined;
    if (this.choosenPayemntType == "credit" || this.choosenPayemntType == "debit") {
      let expiryMonth = this.Credit_Form.value?.expiryMonth.toString();
      let expiryYear = this.Credit_Form.value?.expiryYear.toString();
      let exp_date_card = expiryMonth + expiryYear;
      this.obj = {
        company_id: this.companyId,
        primary_member_id: this.member_id,
        cart_id: this.cart_id,
        payment_option: "CARD",
        payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
        card_type: this.paymentType.value.type,
        card_number: this.Credit_Form.value.cardNumber.replace(/\s/g, ''),
        exp_date: exp_date_card,
        cvv: this.Credit_Form.value.cvv,
        name_on_card: this.Credit_Form.value.name
      }
      if (!this.isShippingChecked) {
        this.obj['billing_as_shipping'] = 'N'
        this.obj['billing_address1'] = this.shipping_address_form.value.address1
        this.obj['billing_address2'] = this.shipping_address_form.value.address2
        this.obj['billing_city'] = this.shipping_address_form.value.city
        this.obj['billing_state'] = this.shipping_address_form.value.state
        this.obj['billing_zip'] = this.shipping_address_form.value.zip
      }
      if (this.isShippingChecked) {
        let cartDetails = localStorage.getItem("cartdetails");
        let parsedcartdetails = JSON.parse(cartDetails);
        parsedcartdetails.member_personal_data.forEach(ele => {
          this.obj['billing_as_shipping'] = 'Y'
          this.obj['billing_address1'] = ele.address1,
            this.obj['billing_address2'] = ele.address2
          this.obj['billing_city'] = ele.city
          this.obj['billing_state'] = ele.state
          this.obj['billing_zip'] = ele.zip
        });
      }


    } else {
      this.obj = {
        primary_member_id: this.member_id,
        cart_id: this.cart_id,
        payment_option: "BANK",
        payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
        routing_number: this.Banking_Form.value.routingNumber,
        account_number: this.Banking_Form.value.accountNumber,
        account_type: this.Banking_Form.value.type,
        name_on_account: this.Banking_Form.value.name,
      }
      if (!this.isShippingChecked) {
        this.obj['billing_as_shipping'] = 'N'
        this.obj['billing_address1'] = this.shipping_address_form.value.address1
        this.obj['billing_address2'] = this.shipping_address_form.value.address2
        this.obj['billing_city'] = this.shipping_address_form.value.city
        this.obj['billing_state'] = this.shipping_address_form.value.state
        this.obj['billing_zip'] = this.shipping_address_form.value.zip
      }
      if (this.isShippingChecked) {
        let cartDetails = localStorage.getItem("cartdetails");
        let parsedcartdetails = JSON.parse(cartDetails);
        this.obj['billing_as_shipping'] = 'Y'
        this.obj['billing_address1'] = this.shipping_address_form.value.address1;
        this.obj['billing_address2'] = this.shipping_address_form.value.address2;
        this.obj['billing_city'] = this.shipping_address_form.value.city;
        this.obj['billing_state'] = this.shipping_address_form.value.stat;
        this.obj['billing_zip'] = this.shipping_address_form.value.zip;
      }
    }
    if (this.paymentType.controls['type'].value == "ach" || this.paymentType.controls['type'].value == "credit" || this.paymentType.controls['type'].value == "debit") {
      this.submitCardAttempt = true;
      this.selectedform = "Banking_Form";
      this.Banking_Form.get('name').markAsTouched();
      this.Banking_Form.get('routingNumber').markAsTouched();
      // this.Banking_Form.get('reenterRouting').markAsTouched();
      this.Banking_Form.get('accountNumber').markAsTouched();
      // this.Banking_Form.get('reenterAccount').markAsTouched();
    }
    this.obj['effective_start_date'] = '';
    if (this.order_id) {// appending the order_id to the payload if the value is available, happens when back and forthe instead of linear flow
      this.obj['order_id'] = this.order_id;
    }
    let url = "CreateMemberPaymentMethod";
    if ((this.Credit_Form.valid || this.Banking_Form.valid) && this.shipping_address_form.valid) {
      this.service.postData(url, this.obj).subscribe(
        (Object: any) => {
            this.loader = false;
          this.display_summary_section = false;
          if (this.child_Info.length > 0) {
              this.active_index = 4;
          } else {
              this.active_index = 3;
          }
          this.listEsignMemberDocumentA2C();
          this.getMemberSummary();
        }, err => {
          this.loader = false;
          this.toastr.error(err.error.message);
          if (err.error?.errors?.cvv) {
            this.cvvErr = err.error?.errors?.cvv
          }
          if (err.error?.errors?.card_number) {
            this.card_number_Err = err.error?.errors?.card_number
          }
          if (err.error?.errors?.exp_date) {
            this.exp_date_err = err.error?.errors?.exp_date
          }
        })
    }
    this.loader = false;

  }
  DisplayDiscount(){
    this.display_discount=!this.display_discount;
  }
  ChangeEffectiveDate(){
   this.display_effective_date=!this.display_effective_date;
  }
  getMemberSummary() {
      this.loader = true;
    var url = 'GetProductFamilyStagingInfo/' + this.companyId + '/' + this.family_id
    this.service.getData(url).subscribe((Object: any) => {
        this.loader = false;
      if (Object.status === true) {
        this.members_info = Object.members;
        this.cart_products = Object?.cart_products;
        this.total_payment_today = Object?.cart_data[0]?.total;
        this.cart_fee = Object?.cart_fee;
        this.monthly_contribution = Object.monthly_contribution;
        this.order_id = Object?.order_details.id;
        this.cart_data = Object?.cart_data;

        if (this.members_info[0]?.enrollment_type == 'ACC') {
          this.shipping_address_form.patchValue({
            effectiveStartDate: this.datePipe.transform(this.members_info[0]?.effective_start_date, "MM/dd/yyyy")
          })
        }
        // Take out the onetime application fee
        this.onetimeAppFee = this.cart_fee.find(item => item.name.toLowerCase() == "enrollment fee")?.amount;
        if (this.members_info[0]?.enrollment_type == 'ACC' && this.settings?.discount_settings) {
          let discountConfig = JSON.parse(this.settings.discount_settings ?? {});
          if (discountConfig && discountConfig.enabled) {
            this.showDiscountSection = true;
            this.discountType = discountConfig.type;
            if (this.cart_data[0]?.discount !== 0) {
              // this.showDiscountSection = false;
            }
          }
        }
      }
    }, err => {
        this.loader = false;
      this.toastr.error(err.error.message);
    })
  }

  removeDiscount() {
      this.loader = true;
    this.discountError = '';
    this.discountValue = undefined;
    this.loader = true;
    let payload = {
      company_id: this.companyId,
      cart_id: this.cart_id,
      agent_id: this.agent_Id
    }
    const url = 'RemoveEnrollmentDiscountACC';
    this.service.postData(url, payload).subscribe((res: any) => {
      this.loader = false;
      if (res.status == true) {
        //  this.showDiscountSection = false;
        this.display_discount_button = false;
        this.getMemberSummary();
      } else {
        this.toastr.error(res.message);
        this.btndisabled = false;
      }
    }, (err) => {
      this.display_discount_button = false;
      this.loader = false;
      this.toastr.error(err.error.message);
      this.btndisabled = false;
    });

    return false;
  }

  // Apply discount function
  applyDiscount() {
    console.log('Discount value: ' + this.discountValue);
    if (this.discountValue == undefined) {
      this.discountError = "Please enter a valid discount " + this.discountType;
      return false;
    }

    this.discountError = "";
    if (this.discountType == 'amount') {
      if (this.discountValue < 0) {
        this.discountError = "Please enter a valid amount";
        return false;
      } else if (this.discountType == 'amount' && this.discountValue > this.onetimeAppFee) {
        this.discountError = "Discount can't exceed enrollment fee";
        return false;
      }
    }

    this.loader = true;
    let payload = {
      company_id: this.companyId,
      cart_id: this.cart_id,
      agent_id: this.agent_Id,
      discount_type: this.discountType,
      discount_value: this.discountValue ?? ''
    }
    // this.discountValue = undefined;
    var url = 'CreateEnrollmentDiscountACC';
    this.service.postData(url, payload).subscribe((res: any) => {
      this.loader = false;
      if (res.status == true) {
        this.display_discount_button = true;
        // this.showDiscountSection = false;
        this.toastr.success(res.message);
        this.getMemberSummary();
      } else {
        this.toastr.error(res.message);
        this.btndisabled = false;
      }
    }, (err) => {
      this.loader = false;
      this.toastr.error(err.error.message);
      this.btndisabled = false;
    });

    return false;
  }
  changeStatus(event) {
    if (event.target.checked == true) {
      this.btn_disabled = false
    } else {
      this.btn_disabled = true
    }
  }
  finalSubmit() {
    this.loader = true;
    var url = 'CreateProductOrderA2C';
    let payload = {
      parent_company_id: this.parentCompanyId,
      acknowledge_by: this.primary_info[0]?.firstname + ' ' + this.primary_info[0]?.lastname,
      order_id: this.order_id,
      company_id: this.companyId,
      effective_start_date:this.datePipe.transform(this.change_effective_date, "yyyy-MM-dd")
    }
    this.service.postData(url, payload).subscribe((res: any) => {
      this.loader = false;
      if (res.process_payment == true) {
        this.CreateMemberShipACC();
      } else {
        this.paymentMessage = res.message;
        this.isSubmitted = true;
        this.btndisabled = true;
      }
    }, (err) => {
      this.loader = false;
      this.toastr.error(err.error.message);
      this.btndisabled = false;
    });
  }

  CreateMemberShipACC() {
    var url = 'CreateMembershipA2C';
    this.loader = true;
    let payload = {
      order_id: this.order_id,
      company_id: this.companyId,
      effective_start_date:this.datePipe.transform(this.change_effective_date, "yyyy-MM-dd")
    }
    this.service.postData(url, payload).subscribe((Object: any) => {
      this.loader = false;
      if (Object.status == true) {
        localStorage.setItem('order_details', JSON.stringify(Object));
        this.router.navigate(['/order-confirmation', this.order_id]);
      }
    }, (err) => {
      this.loader = false;
      this.toastr.error(err.error.message);
    });
  }

  oncvvfocusout() {
    if (this.cardType != '') {
      if (this.cardType == "visa" || this.cardType == "mastercard") {
        // this.cvvLength = 3;
        this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
        if (this.value.length > 3) {
          (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid 3 digit cvv number";
        }
        if (this.value.length <= 3) {
          (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
        }
        //  this.objectService.pushcardType(this.cardType)
        // this.objectService.pushcardCheckCondition(this.checkCardValid)
      }
      else if (this.cardType == "amex") {
        this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
        if (this.value.length > 4) {
          (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid cvv number";
        }
        if (this.value.length <= 4) {
          (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
        }
        //  this.objectService.pushcardType(this.cardType)
        // this.objectService.pushcardCheckCondition(this.checkCardValid)
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeSpaces(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text/plain').replace(/\s/g, '');
    document.execCommand('insertText', false, pastedText);
  }

    backToEnrollment(){
      location.replace('/d2c/enrollment?utm_aff='+this.agentCodeParam);
    }
}
