import {Directive, HostListener} from "@angular/core";
import {NgControl} from "@angular/forms";

@Directive({
    selector: "[formControlName][socialSecurity]"
})
export class SocialSecurityDirective {
    constructor(public ngControl: NgControl) {
    }

    @HostListener("input", ["$event"])
    onKeyDown(event) {

        // if (event.inputType == "insertText") { //future use
        //   let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
        //   if (ssn.length == 11) {
        //     sessionStorage.setItem(event.target.id, ssn);
        //   }
        // }
        // if (event.inputType == "deleteContentBackward") {
        // }
        const input = event.target as HTMLInputElement;
        let trimmed = input.value.replace(/\s+/g, "");
        if (trimmed.length > 11) {
            trimmed = trimmed.substr(0, 11);
        }
        trimmed = trimmed.replace(/-/g, "");
        let numbers: string[] = [];
        numbers.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 2) !== "") numbers.push(trimmed.substr(3, 2));
        if (trimmed.substr(5, 4) != "") numbers.push(trimmed.substr(5, 4));
        input.value = numbers.join("-");
    }
}
