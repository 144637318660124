import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: 'input[numbersOnly]'
})
export class NumberDirective {

    @Output() valueChange = new EventEmitter()

    constructor(private el: NgControl) {
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        this.el.control?.patchValue(value.replace(/[^0-9]/g, ''));
    }

}
